<template>
  <div>
    <div class="forgot">
      <div class="forgot_box">
        <div class="forgot_top bg-white">
          <AppNavigator />
        </div>
        <div style="width: 100%; height: 79px; margin-top: 40px"></div>
        <div class="forgot_main" :style="{ minHeight: mainHeight + 'px' }">
          <div style="width: 86%; margin: 0 auto">
            <el-form :model="ruleForm" ref="ruleForm">
              <div style="display: flex; justify-content: space-between">
                <div style="width: calc(calc(100% - 80px) / 3)">
                  <div style="font-size: 21px; color: #000000">
                    {{ $t("lang.forgetPassword.title") }}
                  </div>
                </div>
                <div style="flex: 1; margin-left: 40px">
                  <div style="display: flex; height: 80px; line-height: 40px">
                    <div style="flex: 1">
                      <div>
                        <el-radio
                          v-model="radio"
                          name="username"
                          label="1"
                          @change="raioChange()"
                          >{{ $t("lang.forgetPassword.email") }}*</el-radio
                        >
                      </div>
                      <div>
                        <el-form-item label="" prop="email">
                          <el-input
                            type="text"
                            v-model="ruleForm.email"
                            :placeholder="emailHolder"
                            :disabled="radio == 2"
                            maxlength="128"
                            autocomplete="off"
                            :class="emailError ? 'error_border' : ''"
                          />
                          <div class="el-form-item__error">
                            {{ emailError }}
                          </div>
                        </el-form-item>
                      </div>
                    </div>
                    <div style="flex: 1; margin-left: 40px">
                      <div>
                        <el-radio
                          v-model="radio"
                          name="username"
                          label="2"
                          @change="raioChange()"
                          >{{ $t("lang.forgetPassword.phone") }}*</el-radio
                        >
                      </div>
                      <div>
                        <el-form-item label="" prop="phone">
                          <div style="display: flex">
                            <div
                              :style="
                                radio == 1
                                  ? 'color: #BCBCBC;'
                                  : 'color: #000000;'
                              "
                            >
                              +
                            </div>
                            <div style="width: 60px; margin-right: 20px">
                               <AreaSelect :disabled="isPhone"  @countryChange="countryChange($event)"/>
                            </div>
                            <div style="flex: 1">
                              <el-input
                                type="text"
                                v-model="ruleForm.phone"
                                :placeholder="phoneHolder"
                                :disabled="radio == 1"
                                maxlength="64"
                                autocomplete="off"
                                :class="phoneError ? 'error_border' : ''"
                              />
                            </div>
                          </div>
                          <div class="el-form-item__error">
                            {{ phoneError }}
                          </div>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div
                    style="position: relative; height: 80px; line-height: 40px"
                  >
                    <div style="color: #000000">{{ codeText }}*</div>
                    <div>
                      <el-form-item label="" prop="authCode">
                        <el-input
                          type="text"
                          v-model="ruleForm.authCode"
                          :placeholder="codeHolder"
                          maxlength="6"
                          autocomplete="off"
                          :class="authCodeError ? 'error_border' : ''"
                        />
                        <div class="el-form-item__error">
                          {{ authCodeError }}
                        </div>
                      </el-form-item>
                      <div
                        @click="sendCode()"
                        style="
                          position: absolute;
                          top: 40px;
                          right: 0px;
                          z-index: 5;
                          height: 30px;
                          line-height: 30px;
                        ">
                        <el-button
                          type="text"
                          style="color: #bbbbbb; opacity: 1; padding: 0"
                          :disabled="sendCodeDisabled">
                          {{ sendCodeText }}</el-button>
                      </div>
                    </div>
                  </div>
                  <div
                    style="position: relative; height: 80px; line-height: 40px"
                  >
                    <div style="color: #000000">
                      {{ $t("lang.forgetPassword.setPassword") }}*
                    </div>
                    <div>
                      <el-form-item label="" prop="password">
                        <el-input
                          type="text"
                          v-model="ruleForm.password"
                          :placeholder="
                            $t('lang.forgetPassword.youSetPassword')
                          "
                          :show-password="ispassword"
                          @focus="ispassword = true"
                          maxlength="64"
                          autocomplete="off"
                          :class="passwordError ? 'error_border' : ''"
                        />
                        <div class="el-form-item__error">
                          {{ passwordError }}
                        </div>
                      </el-form-item>
                      <!-- <div @click="showPassword()" style="position: absolute; top: 20px; right: 0px; z-index: 5">
                        <el-button type="text" style="color: #bbbbbb; opacity: 1">Show
                        </el-button>
                      </div> -->
                    </div>
                  </div>
                  <!-- <div style="margin-top:40px;display:flex;justify-content: space-between;">
                    <div><el-radio v-model="radio" label="1">Remember</el-radio></div>
                  </div> -->
                  <!-- <div style="margin-top:25px;font-size: 11px;color:#BCBCBC;" v-html="$t('lang.forgetPassword.content')"></div> -->
                  <div
                    style="margin-top: 25px; font-size: 11px; color: #bcbcbc"
                  >
                    <div style="margin-top: 20px; font-size: 12px">
                      {{ $t("lang.myMarkPlace.contentText3") }}
                      <span
                        @click="goToLegal(2)"
                        style="color: #ff7a00; cursor: pointer"
                        >{{ $t("lang.myMarkPlace.label1") }}</span
                      >
                      {{ $t("lang.myMarkPlace.label3") }}
                      <span
                        @click="goToLegal(1)"
                        style="color: #ff7a00; cursor: pointer"
                        >{{ $t("lang.myMarkPlace.label2") }}</span
                      >
                    </div>
                  </div>
                  <div style="margin-top: 25px; width: 100%">
                    <el-button
                      :type="buttonType"
                      round
                      style="
                        width: 100%;
                        color: white;
                        height: 25px;
                        padding: 0;
                        border-radius: 13px;
                      "
                      @click="submitForm()"
                      >{{ $t("lang.forgetPassword.title2") }}</el-button
                    >
                  </div>
                  <div style="margin-top: 50px; color: #000000">
                    * {{ $t("lang.forgetPassword.content") }}
                  </div>
                </div>
              </div>
            </el-form>
          </div>
        </div>
        <div class="footer">
          <AppFooter />
        </div>
      </div>
    </div>
    <!-- google 人机验证 -->
    <div id='recaptcha' style="position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);"></div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
import AreaSelect from "@/components/user/AreaSelect.vue";
export default {
  name: "ForgotPassword",
  components: {
    AppNavigator,
    AppFooter,
    AreaSelect
  },
  data() {
    return {
      radio: "1",
      sendCodeDisabled: false,
      codeText: this.$t("lang.forgetPassword.emailVer"),
      codeHolder: this.$t("lang.forgetPassword.yourEmailVer"),
      sendCodeText: this.$t("lang.forgetPassword.sendCode"),
      buttonType: "info",
      mainHeight: 0,
      ruleForm: {
        email: "",
        phone: "",
        authCode: "",
        password: "",
        countryId: "",
        countryList: "",
        timeout: "",
      },
      phoneHolder: "",
      emailHolder: this.$t("lang.forgetPassword.yourEmail"),
      emailError: "",
      phoneError: "",
      authCodeError: "",
      passwordError: "",
      isPhone:false,
      ispassword:false,
      inter:null,
      //初始化人机验证
      captcha:this.utils.renderGrecaptcha()
    };
  },
  watch: {
    //监听表单内容变化 控制注册按钮是否可用
    ruleForm: {
      handler(val, oldVal) {
        let $this = this;
        let flag = true;
        let attr = [
          { attr: "email" },
          { attr: "authCode", length: 6 },
          { attr: "password", length: 6 },
        ];
        if ($this.radio == 2) {
          attr = [
            { attr: "countryId" },
            { attr: "phone" },
            { attr: "authCode", length: 6 },
            { attr: "password", length: 6 },
          ];
        }
        attr.forEach(function (prop, index) {
          if (
            !$this.ruleForm[prop.attr] ||
            (prop.length && $this.ruleForm[prop.attr].length < prop.length)
          ) {
            flag = false;
          }
        });
        //如果表单都输入了值 sign up按钮变为可点。
        if (flag) {
          $this.buttonType = "primary";
        } else {
          $this.buttonType = "info";
        }
      },
      deep: true,
    },
    radio: {
      handler(newName, oldName) {
        this.countdown(newName);
  　　},
  　　immediate: true
    }
  },
  methods: {
    raioChange() {
      this.ruleForm.phone = "";
      this.ruleForm.email = "";
      this.ruleForm.authCode = "";
      this.ruleForm.password = "";
      if (this.radio == 1) {
        this.isPhone = false;
        this.phoneError = "";
        this.phoneHolder = "";
        this.emailHolder = this.$t("lang.forgetPassword.yourEmail");
        this.codeText = this.$t("lang.forgetPassword.emailVer");
        this.codeHolder = this.$t("lang.forgetPassword.yourEmailVer");
      } else {
        this.isPhone = true;
        this.emailError = "";
        this.phoneHolder = this.$t("lang.forgetPassword.yourPhone");
        this.emailHolder = "";
        this.codeText = this.$t("lang.forgetPassword.smsVer");
        this.codeHolder = this.$t("lang.forgetPassword.yourPhoneVer");
      }
    },
    countryChange(param) {
      this.ruleForm.countryId = param.id;
    },
    goToLegal(index) {
      let param = {
        name: "Privacy",
      };
      if (index == 2) {
        param = {
          name: "Terms",
        };
      }
      const { href } = this.$router.resolve(param);
      window.open(href, "_blank");
    },
    sendCode() {
      let _this = this;
      //倒计时没结束 不响应事件
      if (this.sendCodeDisabled) {
        return false;
      }
      //清空错误提示
      this.resetError();
      this.ruleForm.authCode = "";
      //验证是否输入
      if (this.radio == 1 && !this.ruleForm.email.trim()) {
        this.emailError = this.$t("lang.profile.required");
        return;
      } else if (this.radio == 2 && !this.ruleForm.phone.trim()) {
        this.phoneError = this.$t("lang.profile.required");
        return;
      }
      //启动人机验证
      this.captcha.call(this,'recaptcha');
    },
    handleCaptchaCallback(captchaToken) {
      console.log(captchaToken);
      //判断是email 还是phone
      if (this.radio == 1) {
        this.sendEmailCode(captchaToken);
      } else {
        this.sendSMSCode(captchaToken);
      }
    },
    //发送邮箱验证码
    sendEmailCode(captchaToken) {
      //点击按钮后禁用防止重复点击
      this.sendCodeDisabled = true;
      let $this = this;
      this.$http
        .post(
          "verifyCode/email",
          { email: $this.ruleForm.email, emailType: 2,captchaToken:captchaToken}
        )
        .then((res) => {
          //请求成功
          if (res.code === 0) {
            let count = 59;
            sessionStorage.setItem('forgotSendCodeType', this.radio);
            sessionStorage.setItem('forgotSendTime', new Date().getTime());
            $this.sendCodeText = count + "(s)";
            $this.initInterval(count);
            $this.utils.alert(
              $this.$t("lang.register.verCodeInfoSend"),
              $this.$t("lang.register.verCodeInfo")
            );
          }
          //请求失败
          else {
            $this.sendCodeDisabled = false;
            if (res.code === -3) {
              let errorList = res.msg.split(";");
              errorList.forEach(function (item, index, arr) {
                let attr = item.split(":")[0];
                let msg = item.split(":")[1];
                $this[attr + "Error"] = msg;
              });
            }
          }
        });
    },
    //发送短信验证码
    sendSMSCode(captchaToken) {
      let $this = this;
      //获取countryId
      let countryId = $this.ruleForm.countryId;
      if (!countryId) {
        $this.phoneError = "Invalid country/region code";
        return false;
      }
      //倒计时没结束 不响应事件
      if (this.sendCodeDisabled) {
        return false;
      }
      //点击按钮后禁用防止重复点击
      this.sendCodeDisabled = true;
      this.$http
        .post(
          "verifyCode/sms",
          {
            phone: $this.ruleForm.phone,
            countryId: countryId,
            smsType: 2,
            captchaToken:captchaToken
          }
        )
        .then((res) => {
          //请求成功
          if (res.code === 0) {
            let count = 59;
            sessionStorage.setItem('forgotSendCodeType', this.radio);
            sessionStorage.setItem('forgotSendTime', new Date().getTime());
            $this.sendCodeText = count + "(s)";
            $this.initInterval(count);
            $this.utils.alert(
              $this.$t("lang.register.verCodeInfoSend"),
              $this.$t("lang.register.verCodeInfo2")
            );
          }
          //请求失败
          else {
            $this.sendCodeDisabled = false;
            if (res.code === -3) {
              let errorList = res.msg.split(";");
              errorList.forEach(function (item, index, arr) {
                let attr = item.split(":")[0];
                let msg = item.split(":")[1];
                $this[attr + "Error"] = msg;
              });
            }
          }
        });
    },
    showPassword() {},
    //点击login触发
    submitForm(formName) {
      let $this = this;
      let param = {};
      let reqUrl = "user/password/forget/email";
      //清除页面错误信息
      $this.resetError();
      //页面表单没有填写完 不处理
      if ($this.buttonType == "info") {
        return false;
      }
      //邮箱注册请求参数
      if ($this.radio == 1) {
        param.email = $this.ruleForm.email;
        param.authCode = $this.ruleForm.authCode;
        param.password = $this.ruleForm.password;
      }
      //手机号注册请求参数
      if ($this.radio == 2) {
        param.countryId = $this.ruleForm.countryId;
        param.phone = $this.ruleForm.phone;
        param.authCode = $this.ruleForm.authCode;
        param.password = $this.ruleForm.password;
        reqUrl = "user/password/forget/phone";
      }
      //发送请求
      $this.$http.post(reqUrl, this.qs.stringify(param)).then((res) => {
        //请求成功
        if (res.code === 0) {
          $this.utils.alert("Note", "Password reset successfully").then(() => {
            $this.$router.push("/login");
          });
        }
        //请求失败
        else {
          if (res.code === -3) {
            let errorList = res.msg.split(";");
            errorList.forEach(function (item, index, arr) {
              let attr = item.split(":")[0];
              let msg = item.split(":")[1];
              $this[attr + "Error"] = msg;
            });
          }
        }
      });
    },
    resetError() {
      this.emailError = "";
      this.phoneError = "";
      this.authCodeError = "";
      this.passwordError = "";
    },
    goToLogin() {
      this.$router.push("/login");
    },
    //刷新页面后初始化发送验证码后的倒计时
    countdown(type) {
      let $this = this;
      if(type == sessionStorage.getItem('forgotSendCodeType')  && 
        sessionStorage.getItem('forgotSendTime')) {
        let sendTime = sessionStorage.getItem('forgotSendTime');
        let count = 59 - Math.floor((new Date().getTime() - sendTime)/1000);
        if(count > 0) {
          $this.sendCodeDisabled = true;
          $this.sendCodeText = count + "(s)";
          $this.initInterval(count);
        }
      }else {
        if($this.inter) {
          clearInterval($this.inter);
        }
        $this.sendCodeDisabled = false;
        $this.sendCodeText = $this.$t("lang.forgetPassword.sendCode");
      }
    },
    //发送验证码后的倒计时
    initInterval(count) {
      let $this = this;
      if($this.inter) {
        clearInterval($this.inter);
      }
      $this.inter = setInterval(function () {
        count--;
        $this.sendCodeText = count + "(s)";
        if (count == 0) {
          $this.sendCodeDisabled = false;
          sessionStorage.removeItem('forgotSendTime');
          sessionStorage.removeItem('forgotSendCodeType');
          $this.sendCodeText = $this.$t("lang.forgetPassword.sendCode");
          clearInterval($this.inter);
        }
      }, 1000);
    }
  },
  created() {
    this.mainHeight = window.screen.availHeight - 355;
  }
};
</script>

<style lang="less">
.forgot {
  width: 100%;
  height: 100%;
  font: 400 14px NeueHaasUnicaW1G !important;
  .el-button--info {
    background-color: #bcbcbc !important;
    border-color: #bcbcbc !important;
  }
  .el-autocomplete input {
    text-align: center !important;
  }
  .el-autocomplete input::placeholder {
    text-align: center !important;
  }
  .input-search {
    width: 250px !important;
  }
  /* 添加颜色类 */
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #000000 !important;
    border-color: #000000 !important;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #000000;
    font: 400 14px NeueHaasUnicaW1G !important;
  }

  .el-checkbox__input.is-focus .el-checkbox__inner,
  .el-checkbox__input:hover .el-checkbox__inner {
    border-color: #000000 !important;
  }
  .el-checkbox__label,
  .el-radio__label {
    font: 400 14px NeueHaasUnicaW1G !important;
  }
  .el-checkbox__inner {
    border-radius: 8px !important;
  }
  .el-form-item__content {
    line-height: 30px !important;
  }
  .login_btn:hover,
  .login_btn:focus {
    background-color: #ffffff !important;
  }
  input {
    color: #000000 !important;
  }
  .el-input input {
    width: 100%;
    height: 25px;
    line-height: 25px;
    border: none;
    outline: medium;
    border-bottom: 0.1em solid #000000;
    opacity: 1;
    border-radius: 0;
    padding: 0 !important;
  }

  .el-input input::placeholder {
    color: #bcbcbc !important;
  }
  .forgot_box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .el-input input:disabled {
      background-color: #ffffff !important;
      cursor: not-allowed;
    }

    .forgot_top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      // backdrop-filter: saturate(180%) blur(30px);
      border-bottom: 1px solid #000000;
    }

    .forgot_main {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      margin: 0 auto;
      // margin-bottom: 200px;
    }

    // .footer {
    //   width: 100%;
    //   position: fixed;
    //   bottom: 0px;
    // }
  }
}
</style>