<template>
  <div>
    <el-dialog
      center
      width="800px"
      :show-close="false"
      custom-class="ownership_dialog"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="ownershipForm">
        <div style="display: flex; font-size: 14px">
          <div style="flex: 1">
            <div class="iconBox" v-if="!info.videoUrl">
              <img
                :src="info.mainImg"
                style="
                  width: 100%;
                  border-top-left-radius: 10px;
                  border-bottom-left-radius: 10px;
                  display: block;
                "
              />

              <div class="iconClass"  v-if="false">
                                          <img
                  v-if="info.nftChain == 1"
                  src="../..//assets/Frontpage/ETH.png"
                />
                                          <img
                  v-else-if="info.nftChain == 2"
                  src="../../assets/Frontpage/matic.png"
                />
              </div>
            </div>

            <div class="iconBox" v-if="info.videoUrl">
              <video
                :src="info.videoUrl"
                autoplay
                loop="loop"
                style="margin: 0"
              >
                您的浏览器不支持视频播放。
              </video>

              <div class="iconClass"  v-if="false">
                                          <img
                  v-if="info.nftChain == 1"
                  src="../..//assets/Frontpage/ETH.png"
                />
                                          <img
                  v-else-if="info.nftChain == 2"
                  src="../../assets/Frontpage/matic.png"
                />
              </div>
            </div>
          </div>
          <div
            style="
              flex: 1;
              padding: 20px;
              display: flex;
              flex-direction: column;
              color: #000000;
            "
          >
            <div style="font-weight: 400">
              {{ $t("lang.cabinet.dialog.title") }}
            </div>
            <div style="margin-top: 15px">
              <div>{{ info.artistName }}</div>
              <div style="line-height: 16px">{{ info.productName }}</div>
            </div>
            <div style="font-size: 12px; color: #bcbcbc; margin-top: 15px">
              {{ $t("lang.cabinet.dialog.desc") }}
            </div>
            <div style="flex: 1; padding-top: 15px">
              <div style="color: #000000">
                {{ $t("lang.cabinet.dialog.userId") }}
              </div>
              <el-form-item>
                <div>
                  <el-input
                    type="text"
                    v-model="ownershipForm.userId"
                    placeholder="z0834560"
                    :class="ownershipForm.userIdError ? 'error_border' : ''"
                  />
                  <div class="el-form-item__error">
                    {{ ownershipForm.userIdError }}
                  </div>
                </div>
              </el-form-item>
              <div style="color: #000000" v-show="!needValid">
                {{ $t("lang.cabinet.dialog.password") }}
              </div>
              <el-form-item v-show="!needValid">
                <div class="pw-panel">
                  <el-input
                    :type="inputType"
                    @focus="inputType = 'password'"
                    v-model="ownershipForm.password"
                    :placeholder="$t('lang.login.yourPassword')"
                    :class="ownershipForm.passwordError ? 'error_border' : ''"
                  />
                  <div class="forget-pw" @click="goToForgetPassword()">
                    {{ $t("lang.login.forgetPassword") }}?
                  </div>
                  <div class="el-form-item__error">
                    {{ ownershipForm.passwordError }}
                  </div>
                </div>
              </el-form-item>
              <div v-show="needValid">
                  <div style="color: #000000;margin-top:30px;">
                    {{ $t("lang.myprofile.twofa.validText") }}
                  </div>
                  <div class="btn-panel" style="margin:20px 0 30px 0;">
                    <el-button class="btn" @click="startValid" :disabled="hasValid" style="width: 100%">{{validTxt}}</el-button>
                  </div>
              </div>
              <div style="color: #000000">
                {{ $t("lang.cabinet.dialog.code") }}
              </div>
              <el-form-item>
                <div style="position: relative">
                  <el-input
                    type="text"
                    v-model="ownershipForm.code"
                    :placeholder="$t('lang.cabinet.dialog.codeHolder')"
                    :class="ownershipForm.codeError ? 'error_border' : ''"/>
                  <div class="el-form-item__error">
                    {{ ownershipForm.codeError }}
                  </div>
                  <div
                    @click="sendCode()"
                    style="
                      position: absolute;
                      top: -8px;
                      right: 0px;
                      z-index: 5;">
                    <el-button
                      type="text"
                      style="color: #bbbbbb"
                      :disabled="sendCodeDisabled"
                      >{{ sendCodeText }}</el-button
                    >
                  </div>
                </div>
              </el-form-item>
            </div>
            <div style="text-align: center">
              <div slot="footer" class="dialog-footer">
                <el-button
                  round
                  :class="['ownership-btn', buttonType]"
                  @click="confirmOwnership"
                  :disabled="buttonType == 'disabled'">{{ $t("lang.cabinet.detail.conentText3") }}</el-button>
              </div>
              <div slot="footer" class="dialog-footer" style="margin-top: 10px">
                <el-button
                  @click="dialogClose"
                  round
                  style="
                    width: 200px;
                    border: none;
                    background-color: #ffffff;
                    color: #bcbcbc;
                    opacity: 1;
                    padding: 10px !important;
                  "
                  >{{ $t("lang.myMarkPlace.cancel") }}</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      center
      width="380px"
      custom-class="transfer_success_dialog"
      :visible.sync="dialogVisible1"
      :title="$t('lang.cabinet.dialog.success')"
      :close-on-click-modal="false"
      style="padding-top: 0"
    >
      <div style="padding: 0 50px 20px 50px">
        <div style="text-align: center; color: #000000">
          {{ getTipTxt($t("lang.cabinet.dialog.tip")) }}
        </div>
        <div style="text-align: center; margin-top: 10px">
          <span style="color: #bcbcbc; cursor: pointer" @click="gotoCabinet">{{
            $t("lang.cabinet.dialog.backCabinet")
          }}</span>
        </div>
      </div>
    </el-dialog>
    <!-- google 人机验证 -->
    <div id='recaptcha' style="position: absolute;top:35%;left:50%;"></div>
  </div>
</template>

<script>
export default {
  name: "Ownership",
  data() {
    return {
      userInfo: {},
      dialogVisible: false,
      dialogVisible1: false,
      sendCodeDisabled: false,
      sendCodeText: this.$t("lang.register.sendCode"),
      buttonType: "disabled",
      inputType:"text",
      ownershipForm: {
        userId: "",
        password: "",
        code: "",
        userIdError: "",
        passwordError: "",
        codeError: "",
      },
      needValid:false,
      validTxt:this.$t('lang.myprofile.twofa.startValid'),
      hasValid:false,
      inter:null,
      //初始化人机验证
      captcha:this.utils.renderGrecaptcha()
    };
  },
  props: ["ownershipVisible","info"],
  watch: {
    ownershipVisible(n, o) {
      //n为新值,o为旧值;
      this.ownershipForm.userId = "";
      this.ownershipForm.password = "";
      this.ownershipForm.code = "";
      if(n == true) {
        this.countdown();
      }
      this.dialogVisible = n;
    },
    ownershipForm: {
      handler(val, oldVal) {
        //如果表单都输入了值 sign up按钮变为可点。
        if (this.validateForm()) {
          this.buttonType = "active";
        } else {
          this.buttonType = "disabled";
        }
      },
      deep: true,
    },
  },
  methods: {
    sendCode() {
      //倒计时没结束 不响应事件
      if (this.sendCodeDisabled) {
        return false;
      }
      //启动人机验证
      this.captcha.call(this,'recaptcha');
    },
    handleCaptchaCallback(captchaToken) {
      //判断是email 还是phone
      if (this.userInfo.email) {
        this.sendEmailCode(captchaToken);
      } else if (this.userInfo.phone) {
        this.sendSMSCode(captchaToken);
      } else {
        this.utils.alert("", "account error");
      }
    },
    //发送邮箱验证码
    sendEmailCode(captchaToken) {
      //点击按钮后禁用防止重复点击
      this.sendCodeDisabled = true;
      let $this = this;
      this.$http
        .post(
          "verifyCode/email",
          { email: $this.userInfo.email, emailType: 4,captchaToken:captchaToken}
        )
        .then((res) => {
          //请求成功
          if (res.code === 0) {
            let count = 59;
            sessionStorage.setItem('transferSendTime', new Date().getTime());
            $this.sendCodeText = count + "(s)";
            $this.initInterval(count);
            $this.utils.alert(
              $this.$t("lang.register.verCodeInfoSend"),
              $this.$t("lang.register.verCodeInfo")
            );
          }
          //请求失败
          else {
            $this.sendCodeDisabled = false;
            if (res.code === -3) {
              let errorList = res.msg.split(";");
              errorList.forEach(function (item, index, arr) {
                let attr = item.split(":")[0];
                let msg = item.split(":")[1];
                $this.ownershipForm[attr + "Error"] = msg;
              });
            }
          }
        });
    },
    //发送短信验证码
    sendSMSCode(captchaToken) {
      let $this = this;
      //倒计时没结束 不响应事件
      if (this.sendCodeDisabled) {
        return false;
      }
      //点击按钮后禁用防止重复点击
      this.sendCodeDisabled = true;
      this.$http
        .post(
          "verifyCode/sms",{
            phone: $this.userInfo.phone,
            countryId: $this.userInfo.countryId,
            smsType: 4,
            captchaToken:captchaToken
          }
        )
        .then((res) => {
          //请求成功
          if (res.code === 0) {
            let count = 59;
            sessionStorage.setItem('transferSendTime', new Date().getTime());
            $this.sendCodeText = count + "(s)";
            $this.initInterval(count);
            $this.utils.alert(
              $this.$t("lang.register.verCodeInfoSend"),
              $this.$t("lang.register.verCodeInfo2")
            );
          }
          //请求失败
          else {
            $this.sendCodeDisabled = false;
            if (res.code === -3) {
              let errorList = res.msg.split(";");
              errorList.forEach(function (item, index, arr) {
                let attr = item.split(":")[0];
                let msg = item.split(":")[1];
                $this.ownershipForm[attr + "Error"] = msg;
              });
            }
          }
        });
    },
    //刷新页面后初始化发送验证码后的倒计时
    countdown() {
      let $this = this;
      if(sessionStorage.getItem('transferSendTime')) {
        let sendTime = sessionStorage.getItem('transferSendTime');
        let count = 59 - Math.floor((new Date().getTime() - sendTime)/1000);
        if(count > 0) {
          $this.sendCodeDisabled = true;
          $this.sendCodeText = count + "(s)";
          $this.initInterval(count);
        }
      }else {
        if($this.inter) {
          clearInterval($this.inter);
        }
        $this.sendCodeDisabled = false;
        $this.sendCodeText = $this.$t("lang.register.sendCode");
      }
    },
    //发送验证码后的倒计时
    initInterval(count) {
      let $this = this;
      if($this.inter) {
        clearInterval($this.inter);
      }
      $this.inter = setInterval(function () {
        count--;
        $this.sendCodeText = count + "(s)";
        if (count == 0) {
          $this.sendCodeDisabled = false;
          sessionStorage.removeItem('transferSendTime')
          $this.sendCodeText = $this.$t("lang.register.sendCode");
          clearInterval($this.inter);
        }
      }, 1000);
    },
    //提交产权转移申请
    confirmOwnership() {
      if (this.buttonType == "disabled") {
        return;
      }
      let param = { token: this.utils.getToken() };
      param.toUuid = this.ownershipForm.userId;
      param.password = this.ownershipForm.password;
      //判断是email 还是phone
      if (this.userInfo.email) {
        param.verifyCodeType = 0;
      } else if (this.userInfo.phone) {
        param.verifyCodeType = 1;
      }
      param.verifyCode = this.ownershipForm.code;
      param.chipCode = this.info.serialNo;
      //是否验证通过
      if(this.needValid) {
        param.isTowFA = this.hasValid;
      }else {
        param.isTowFA = false;
      }
      this.$http.post("property/transfer/apply", param).then((res) => {
        if (res.code === 0) {
          this.dialogVisible = false;
          this.dialogVisible1 = true;
        } else {
          this.utils.alert("", res.msg);
        }
      });
    },
    dialogClose() {
      this.dialogVisible = false;
      this.$emit("dialogClose", this.dialogVisible);
    },
    getTipTxt(text) {
      return text
        .replace("#username", this.info.artistName)
        .replace("#workname", this.info.productName);
    },
    gotoCabinet() {
      this.dialogVisible1 = false;
      this.$router.push({ path: "/myCabinet" });
    },
    //跳转到忘记密码组件
    goToForgetPassword() {
      this.$router.push("/forgotPassword");
    },
    validateForm() {
      let $this = this;
      let flag = true;
      let attr = [{prop:"userId"}, {prop:"password",length:6},  {prop:"code",length:6}];
      if(this.needValid) {
        attr = [{prop:"userId"},{prop:"code",length:6}];
      }
      attr.forEach(function (item, index) {
        if (!$this.ownershipForm[item.prop] || (item.length && $this.ownershipForm[item.prop].length < item.length)) {
          flag = false;
        }
      });
      if(this.needValid && !this.hasValid) {
         flag = false;
      }
      return flag;
    },
    startValid() {
      this.$parent.openValidDialog();
    },
    renderPage(needValid) {
      this.needValid = needValid;
    },
    //验证成功
    validSuccess() {
      this.validTxt = this.$t("lang.myprofile.twofa.authenticated");
      this.hasValid = true;
      //验证数据完整
      if(this.validateForm()) {
        this.buttonType = "active";
      }else {
        this.buttonType = "disabled";
      }
    }
  },
  mounted() {
    //获取用户信息
    this.$http
      .post("user/info", { token: this.utils.getToken() })
      .then((res) => {
        if (res.code === 0) {
          let data = res.data;
          this.userInfo = data;
        }
      });
  },
};
</script>

<style lang="less">
.ownership_dialog,
.transfer_success_dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  border-radius: 10px !important;
  font-size: 14px !important;
}
.ownership_dialog .el-input__icon {
  line-height: 20px;
}
.ownership_dialog .el-dialog__header {
  display: none;
}
.ownership_dialog video {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  margin-bottom: 40px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ownership_dialog .el-dialog__body,
.transfer_success_dialog .el-dialog__body {
  padding: 0 !important;
}
.ownership_dialog .el-dialog__body .ownership-btn {
  height: 25px;
  line-height: 25px;
  border: none;
  padding: 0;
  color: #ffffff;
}
.ownership_dialog .el-dialog__body .ownership-btn.active {
  background-color: #000000;
}
.ownership_dialog .el-dialog__body .ownership-btn.disabled {
  background-color: #bcbcbc;
}
.ownership_dialog .ownership-btn.is-disabled,
.ownership_dialog .ownership-btn.is-disabled:focus,
.ownership_dialog .ownership-btn.is-disabled:hover {
  color: #ffffff !important;
  background-color: #bcbcbc !important;
}
.ownership_dialog .el-form-item {
  margin-bottom: 15px;
}
.ownership_dialog .el-form-item__content {
  line-height: 25px !important;
}
.ownership_dialog input {
  color: #000000 !important;
}
.ownership_dialog .el-input input {
  width: 100%;
  height: 25px;
  line-height: 25px;
  border: none;
  outline: medium;
  border-bottom: 0.1em solid #000000;
  opacity: 1;
  border-radius: 0;
  padding: 0;
}
.ownership_dialog .btn-panel {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    .btn {
      background-color: #000000;
      color: #ffffff;
      height: 25px;
      line-height: 25px;
      padding: 0;
      margin: 0;
      width: 160px;
      border-radius: 13px;
    }
    .btn.default {
      background-color: #ff7a00;
    }

    .btn:disabled {
      background-color: #bcbcbc;
    }
}
.ownership_dialog .el-input input::placeholder {
  text-align: left !important;
}
.ownership_dialog .forget-pw {
  position: absolute;
  top: 0;
  right: 0;
  color: #bcbcbc;
  cursor: pointer;
}
.ownership_dialog .pw-panel {
  position: relative;
}
.ownership_dialog .pw-panel .el-input input {
  padding-right: 120px;
}
</style>