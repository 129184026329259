<template>
  <div class="orderCapture">
   
  </div>
</template>

<script>
export default {
  name: "OrderCapture",
  data(){
    return {
    }
  },
  methods: {
    goToSuccess(){
      this.$router.push('/marketPlace')
    }
  },
  //导航离开该组件的对应路由时调用
  beforeRouteLeave: (to, from, next) => {
       if(to.path === '/confirmOrder'){
         console.log(' beforeRouteLeave !')
         next(false)
       }else{
         next()
       }
  },
  created() {
    let token = this.$route.query.token;
    //确认订单状态
    this.$http
      .post(
        "order/confirm",
        {
          token:this.utils.getToken(),
          paypalOrderId: token
        }
      )
      .then((res) => {
        if (res.code === 0) {
          let result = res.data.result;
          let orderType = res.data.orderType;
          console.log(res.data);
          if(result == 1) {
            if(orderType == 1) {
              this.$router.push({path:'/orderComplete'});
            }else if(orderType == 2) {
              this.$router.push({path:'/withdraw',query:{pop:1}});
            }
          }else {
            sessionStorage.setItem('errorMsg',res.data.errorMsg);
            this.$router.push({path:'/orderNotComplete'});
          }
        }
      });
  }
};
</script>
