<template>
  <div class="cabinet_info">
    <div class="cabinet_info_box">
      <div class="cabinet_info_top bg-white">
        <AppNavigator />
      </div>
      <div class="cabinet_info_main" style="padding-top: 50px">
        <div style="width: 100%">
          <div
            style="
              width: 86%;
              height: 100%;
              margin: 0 auto;
              margin-bottom: 80px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div style="width: 25%; color: #000000; padding-right: 10px">
              <div
                class="product-left"
                v-show="isBottom"
                style="width: 20%; position: fixed; top: 85px; left: 7%"
              >
                <div>
                  <el-tooltip
                    class="item"
                    effect="light"
                    visible-arrow
                    :content="$t('lang.productType')[info.productType]?$t('lang.productType')[info.productType].hover:''"
                    placement="right-end">
                    <el-button class="category_btn">
                      {{$t("lang.productType")[info.productType]?$t("lang.productType")[info.productType].title:''}}
                      <i class="el-icon-warning-outline"></i>
                    </el-button>
                  </el-tooltip>
                </div>
                <div
                  style="font-size: 21px; font-weight: 400; margin-top: 10px"
                >
                  {{ info.artistName }}
                </div>
                <div
                  style="font-size: 21px; font-weight: 400; line-height: 21px"
                >
                  {{ info.productName }}
                </div>
              </div>
            </div>
            <div
              style="
                width: 50%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;">
              <!-- <div class="iconBox" v-if="info.videoUrl" style="margin-bottom:30px;">
                <video
                  :src="info.videoUrl"
                  muted
                  autoplay
                  loop="loop"
                  :height="imgHeight + 'px'">
                  您的浏览器不支持视频播放。
                </video>
                <div class="iconClass"  v-if="false">
                   <img v-if="info.nftChain == 1" src="../..//assets/Frontpage/ETH.png"/>
                   <img v-else-if="info.nftChain == 2" src="../../assets/Frontpage/matic.png"/>
                </div>
              </div> -->
              <div style="text-align: center">
                <el-carousel
                  arrow="never"
                  indicator-position="outside"
                  :autoplay="false"
                  :height="imgHeight + 'px'"
                  @click.native="onPreview()"
                  ref="carousel"
                  style="cursor: pointer">
                  <el-carousel-item
                    v-for="(item, index) in info.banners"
                    :key="index">
                    <el-image
                      :src="item.url"
                      v-if="item.type == 1"
                      ref="image"
                      :height="imgHeight"></el-image>
                    <video
                      :src="item.url"
                      muted
                      autoplay
                      loop="loop"
                      v-if="item.type == 2"
                      :height="imgHeight">
                      您的浏览器不支持视频播放。
                    </video>
                  </el-carousel-item>
                  <div class="iconBox">
                    <el-image-viewer
                      v-if="showViewer"
                      :on-close="closeViewer"
                      :url-list="viewerImgList"
                      :z-index="3000"/>

                    <div class="iconClass"  v-if="false">
                                                <img
                        v-if="info.nftChain == 1"
                        src="../..//assets/Frontpage/ETH.png"
                      />
                                                <img
                        v-else-if="info.nftChain == 2"
                        src="../../assets/Frontpage/matic.png"
                      />
                    </div>
                                
                  </div>
                </el-carousel>
              </div>

              <div style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  margin-bottom: 10px;
                  margin-top: 4px;">
                <div
                  v-for="(item, index) in info.banners"
                  :key="index"
                  class="min_img_box">
                  <div class="iconBox">
                    <img
                      ref="images"
                      v-if="item.type == 1"
                      :src="item.url"
                      :height="imgMinHeight"
                      @click="setActiveItem(index)"/>
                    <video
                        v-if="item.type == 2"
                        :src="item.url"
                        autoplay
                        muted
                        :height="imgMinHeight"
                        @click="setActiveItem(index)"
                        loop="loop">
                        您的浏览器不支持视频播放。
                      </video>
                    <div class="iconClass"  v-if="false">
                                                <img
                        v-if="info.nftChain == 1"
                        src="../..//assets/Frontpage/ETH.png"
                      />
                                                <img
                        v-else-if="info.nftChain == 2"
                        src="../../assets/Frontpage/matic.png"
                      />
                    </div>
                       
                  </div>
                </div>
              </div>
              <div style="margin-bottom: 25px;font-size:14px;line-height:17px;color:#000000;">
                {{ $t("lang.myMarkPlace.description") }}
              </div>
              <div style="margin-bottom: 30px">
                <span style="font-size: 14px; color: #000000">
                  {{ info.productIntro }}
                </span>
              </div>
              <div
                style="
                  border-top: 0.1em solid #000000;
                  border-bottom: 0.1em solid #000000;
                "
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    padding: 8px 0;
                    cursor: pointer;
                  "
                  @click="showRemark()"
                >
                  <div style="flex: 1; font-size: 14px; color: #000000">
                    {{ $t("lang.cabinet.detail.conentText")
                    }}{{$t("lang.productType")[info.productType]?$t("lang.productType")[info.productType].title:''}}?
                  </div>
                  <div>
                    <div
                      :class="isShowRemark ? 'common-minus' : 'common-plus'"
                      style="float: right"
                    ></div>
                  </div>
                </div>
                <div
                  v-show="isShowRemark"
                  style="color: #000000; font-size: 14px; padding-bottom: 20px;">
                  {{$t("lang.productType")[info.productType]?$t("lang.productType")[info.productType].remark:''}}
                </div>
              </div>
              <div style="border-bottom: 0.1em solid #000000">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    padding: 8px 0;
                    cursor: pointer;
                  "
                  @click="showOwner()"
                >
                  <div style="flex: 1; font-size: 14px; color: #000000">
                    {{ $t("lang.cabinet.detail.currentOWner") }}
                  </div>
                  <div>
                    <div
                      :class="isShowOwner ? 'common-minus' : 'common-plus'"
                      style="float: right"
                    ></div>
                  </div>
                </div>
                <div
                  v-show="isShowOwner"
                  style="
                    color: #000000;
                    font-size: 14px;
                    padding-bottom: 20px;
                    display: flex;
                  ">
                  <div style="width:150px;">
                    ZHEN. {{ $t("lang.myprofile.userId") }}
                  </div>
                  <div style="flex: 1; text-align: right">
                    {{ info.accountNumber }}
                  </div>
                </div>
              </div>
              <div style="border-bottom: 0.1em solid #000000; padding: 8px 0">
                <div
                  style="display: flex; align-items: center; cursor: pointer"
                  @click="showDetail"
                >
                  <div style="flex: 1; font-size: 14px; color: #000000">
                    {{ $t("lang.myMarkPlace.productDetail") }}
                  </div>
                  <div>
                    <div
                      :class="isShowDetail ? 'common-minus' : 'common-plus'"
                      style="float: right"
                    ></div>
                  </div>
                </div>
                <div
                  v-show="isShowDetail"
                  style="color: #000000; font-size: 14px; padding: 20px 0">
                  <div>
                    {{ $t("lang.cabinet.detail.editionNo") }}: {{ info.editionNo }}
                  </div>
                  <div style="margin-bottom:15px;">
                    {{ $t("lang.cabinet.detail.serialNo") }}: {{ info.serialNo }}
                  </div>
                  <div style="display: flex">
                    <div
                      style="flex: 1; line-height: 18px"
                      v-html="info.digitalInfo"
                      v-show="info.productType == 2 || info.productType == 3"
                    ></div>
                    <div
                      style="flex: 1; line-height: 18px"
                      v-html="info.physicalInfo"
                      v-show="info.productType == 2 || info.productType == 1"
                    ></div>
                  </div>
                </div>
              </div>
              <div style="border-bottom:1.5px solid #000000; padding: 8px 0">
                <div
                  style="display: flex; align-items: center; cursor: pointer"
                  @click="showHistory"
                >
                  <div style="flex: 1; font-size: 14px; color: #000000">
                    {{ $t("lang.myMarkPlace.history") }}
                  </div>
                  <div>
                    <div
                      :class="isShowHistory ? 'common-minus' : 'common-plus'"
                      style="float: right"
                    ></div>
                  </div>
                </div>
                <div
                  v-show="isShowHistory"
                  style="color: #000000; font-size: 14px; padding: 20px 0 0 0"
                >
                  <div
                    v-for="(log, index) in info.listTransactionLog"
                    :key="index"
                    style="
                      display: flex;
                      border-top: 1px solid #bcbcbc;
                      padding: 10px 0;
                    "
                  >
                    <div style="flex: 1">
                      <div :style="{color:log.infoColor?log.infoColor:''}">{{ log.info }}</div>
                      <div style="font-size: 12px; color: #bcbcbc">
                        {{ log.createTime }}
                        <a
                          :href="log.transactionUrl"
                          v-show="log.transactionUrl"
                          target="_blank"
                          :class="log.nftChain==1?'eth-txid':'matic-txid'"
                          >{{ $t("lang.myMarkPlace.view") }} TXID</a
                        >
                      </div>
                    </div>
                    <div style="text-align: right">
                      <div>{{ log.ethPrice }}</div>
                      <div style="font-size: 12px; color: #bcbcbc">
                        {{ log.usdPrice }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="metadata-panel">
                <div class="metadata-content" @click="showMetadata()">
                  <div class="metadata-content-txt">METADATA</div>
                  <div>
                    <div
                      :class="isShowMetadata ? 'common-minus' : 'common-plus'"
                      style="float: right"
                    ></div>
                  </div>
                </div>
                <div v-show="isShowMetadata" class="metadata-display-panel">
                  <el-button
                    class="metadata-btn"
                    :disabled="!info.ipfsFileUrl"
                    style="margin-right: 5%"
                    @click="viewIpfs"
                    >View on IPFS</el-button
                  >
                  <el-button
                    class="metadata-btn"
                    :disabled="!info.tokenUrl"
                    style="margin-left: 5%"
                    @click="viewEtherscan">View on {{info.nftChain==1?'Etherscan':'Polygonscan'}}</el-button>
                  <el-button
                    class="metadata-btn"
                    :disabled="!info.ipfsMetadataUrl"
                    @click="viewMetadata"
                    >View IPFS Metadata</el-button
                  >
                </div>
              </div>
            </div>
            <div
              style="
                width: 25%;
                text-align: right;
                diplay: flex;
                justify-content: flex-end;
                padding-left: 10px;">
              <div
                class="product-left"
                v-show="isBottom"
                style="width: 20%; position: fixed; top: 85px; right: 7%">
                <div style="color: #000000; font-size: 14px">
                  {{ $t("lang.cabinet.detail.conentText2") }}
                  {{ info.purchaseDate }}
                </div>
                <div v-if="info.transferType == 1" class="state-panel">
                  <div v-show="info.bcState == 0" @click="gotoHistory()">{{$t('lang.dashboard.withdraw.history.outgoing')}}</div>
                  <div v-show="info.bcState == 1" @click="gotoHistory()">{{$t('lang.dashboard.withdraw.withdrawn')}}</div>
                </div>
                <el-button
                  round
                  size="mini"
                  :class="[
                    'ownership-btn',
                    info.transferState == 1 ? 'active' : 'disabled',
                  ]"
                  :disabled="info.transferState != 1"
                  @click="transferOwnership"
                  >{{ $t("lang.cabinet.detail.conentText3") }}</el-button>
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            border-bottom: 0.1em solid #000000;
            width: 100%;
            height: 1px;
            margin-bottom: 80px;
          "
          ref="bottom"
        ></div>
        <div
          style="width: 86%;margin:0 auto;position:relative;padding-bottom:50px;"
        >
          <div style="color: #000000; position: absolute; top: 0; left: 0">
            {{ $t("lang.cabinet.detail.artist") }}
          </div>
          <div style="text-align: center">
            <div>
              <img :src="info.artistImg" style="width: 360px" />
            </div>
            <div
              style="
                margin-top: 25px;
                margin-bottom: 25px;
                color: #000000;
                font-size: 14px;
              "
            >
              {{ info.artistName }}
            </div>
            <div style="width: 560px; margin-bottom: 25px; margin: auto">
              <span style="font-size: 14px; color: #000000">
                {{ info.artistIntro }}
              </span>
            </div>
            <!-- <div style="width: 125px;height: 20px;font-size: 14px;font-family: Helvetica;color: #000000;">
              <el-button type="text" round
                style="background-color: #F4F4F4;border-radius: 20px;font-size: 14px;font-family: Helvetica;color: #000000;padding: 7px 25px !important;">
                Visit artist page</el-button>
            </div> -->
          </div>
          <Ownership
            ref="ownership"
            :ownershipVisible="ownershipVisible"
            :info="info"
            @dialogClose="changeVisible($event)"
          />
          <div class="video-full-screen" v-show="videoFullUrl" @click="videoFullUrl=''">
              <video :src="videoFullUrl" muted autoplay loop="loop"></video>
          </div>
        </div>
      </div>
    </div>

    <div class="cabinet_info_footer">
      <AppFooter />
    </div>
    <Authenticator ref="authenticator"></Authenticator>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
import Ownership from "@/components/user/Ownership.vue";
import Authenticator from "@/components/user/Authenticator.vue";
// 导入组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  name: "CabinetDetail",
  components: {
    AppNavigator,
    AppFooter,
    Ownership,
    ElImageViewer,
    Authenticator
  },
  data() {
    return {
      chipCode: "",
      info: "",
      imgHeight: "",
      imgMinHeight:0,
      isShowRemark: false,
      isShowDetail: false,
      autoplay: true,
      isShowOwner: false,
      isShowHistory: false,
      isShowMetadata: false,
      isBottom: true,
      ownershipVisible: false,
      showViewer: false,
      viewerImgList: [],
      videoFullUrl:''
    };
  },
  methods: {
    setActiveItem(index) {
      this.autoplay = false;
      this.$refs.carousel.setActiveItem(index);
      let $this = this;
      setTimeout(() => {
        $this.autoplay = true;
      }, 1000);
    },
    //打开查看器
    onPreview() {
      let index = this.$refs.carousel.activeIndex;
      let tempImgList = [];
      let banners = this.info.banners;
      //如果是图片 预览大图
      if(banners[index].type == 1) {
          for(let i=0; i < banners.length; i++) {
            if(banners[i].type == 1) {
                tempImgList.push(banners[i].url);
            }
          }
          if(tempImgList.length > 0) {
            this.showViewer = true;
          }
          let temp = [];
          for (let i = 0; i < index; i++) {
            temp.push(tempImgList.shift());
          }
          this.viewerImgList = tempImgList.concat(temp);
      }
      //如果是视频  全屏播放
      else if(banners[index].type == 2) {
        this.videoFullUrl = banners[index].url;
      }
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    autoplayImage() {
      this.autoplay = true;
    },
    showRemark() {
      this.isShowRemark = !this.isShowRemark;
    },
    showOwner() {
      this.isShowOwner = !this.isShowOwner;
    },
    showDetail() {
      this.isShowDetail = !this.isShowDetail;
    },
    showHistory() {
      this.isShowHistory = !this.isShowHistory;
    },
    showMetadata() {
      this.isShowMetadata = !this.isShowMetadata;
    },
    transferOwnership() {
       //获取是否已经绑定google身份验证
      this.$http
        .post("googleAuthenticator/isBinding", { token: this.utils.getToken() })
        .then((res) => {
          if (res.code === 0) {
            let data = res.data;
            //未绑定身份验证器
            if(data.isBinding == 1) {
              let _this = this;
              this.$refs.authenticator.showDownload(function() {
                //关闭下载框时的回调
                _this.ownershipVisible = true;
              });
            }
            //已绑定已开启
            else if(data.isBinding == 2 && data.isGa == 2) {
              this.needValid = true;
              this.ownershipVisible = true;
              this.$refs.ownership.renderPage(true);
            }
            //已绑定未开启
            else if(data.isBinding == 2 && data.isGa == 1) {
              this.needValid = false;
              this.ownershipVisible = true;
              this.$refs.ownership.renderPage(false);
            }
          }
      });
    },
    openValidDialog() {
      //调起验证码
      this.$refs.authenticator.showValid(3);
    },
    validSuccess() {
      this.$refs.ownership.validSuccess();
    },
    //绑定成功回调
    bindSuccess() {
      window.location.reload();
    },
    changeVisible(param) {
      this.ownershipVisible = param;
    },
    viewIpfs() {
      const url = this.info.ipfsFileUrl;
      if (url) {
        window.open(url);
      }
    },
    viewEtherscan() {
      const url = this.info.tokenUrl;
      if (url) {
        window.open(url);
      }
    },
    viewMetadata() {
      const url = this.info.ipfsMetadataUrl;
      if (url) {
        window.open(url);
      }
    },
    gotoHistory() {
      let transferId = this.$route.query.transferId;
      this.$router.push({path:"/withdraw",query:{transferId:transferId}});
    },
    scrollEvent() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let bottomTop = this.$refs.bottom.offsetTop;
      if (scrollTop >= bottomTop - 220) {
        this.isBottom = false;
      } else {
        this.isBottom = true;
      }
    },
    resizeEvent() {
      this.imgHeight =
        ((document.body.clientWidth * 0.86 - 20) * 0.5 - 8) / 0.8;
      this.imgMinHeight = (document.body.clientWidth * 0.86 * 0.5 - 50) / 5 / 0.8;
    },
  },
  created() {
    this.imgHeight = ((document.body.clientWidth * 0.86 - 20) * 0.5 - 8) / 0.8;
    this.imgMinHeight = (document.body.clientWidth * 0.86 * 0.5 - 50) / 5 / 0.8;
    this.chipCode = this.$route.query.chipCode;
    //查询橱窗详情
    this.$http
      .post(
        "product/info",
        this.qs.stringify({
          token: this.utils.getToken(),
          chipCode: this.chipCode,
        })
      )
      .then((res) => {
        if (res.code === 0) {
          this.info = res.data;
        }
      });
  },
  mounted() {
    // 监听窗口变化，使得轮播图高度自适应图片高度
    window.addEventListener("resize", this.resizeEvent, false);
    //滚动事件
    window.addEventListener("scroll", this.scrollEvent, false);
  },
  destroyed() {
    //解绑事件
    window.removeEventListener("scroll", this.scrollEvent, false);
    window.removeEventListener("resize", this.resizeEvent, false);
  },
};
</script>

<style lang="less">
.cabinet_info {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0.5;
  .state-panel {
    font-size:14px;
    color: #FF7A00;
    cursor: pointer;
  }
  video {
    width: 100%;
    display: block;
    object-fit: cover;
    margin-bottom: 30px;
  }
  .ownership-btn {
    width: 160px;
    height: 25px;
    line-height: 25px;
    padding: 0px !important;
    margin-top: 10px;
    border: none !important;
  }
  .ownership-btn.active {
    color: #ffffff;
    background-color: #000000;
  }
  .ownership-btn.disabled {
    color: #ffffff;
    background-color: #bcbcbc;
  }
  .ownership-btn.disabled:hover,
  .ownership-btn.disabled:focus {
    color: #ffffff;
    background-color: #bcbcbc;
  }
  .min_img_box {
    width: 20%;
    padding: 0 3px;
    box-sizing: border-box;
    margin-bottom: 10px;
    cursor: pointer;
  }

  // .min_img_box:first-child,.min_img_box:nth-child(5n+1) {
  //   padding-left: 0 !important;
  // }

  // .min_img_box:nth-child(5n) {
  //   padding-right: 0 !important;
  // }
  .category_btn {
    border: none;
    padding: 0 !important;
  }
  .category_btn:hover,
  .category_btn:focus {
    background: #fff !important;
    color: #000000;
  }
  /* 轮播图组件样式的修改 */
  .el-carousel__button {
    display: block;
    width: 10px !important;
    height: 10px !important;
    background-color: #ffffff;
    border: 1px solid #000000 !important;
    border-radius: 50% !important;
    outline: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: none !important;
  }
  .el-carousel__indicator--horizontal.is-active .el-carousel__button {
    background-color: #000000 !important;
  }

  .cabinet_info_box {
    width: 100%;

    .cabinet_info_top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      // backdrop-filter: saturate(180%) blur(30px) opacity(80%);
      border-bottom: 1px solid #000000;
    }

    .cabinet_info_main {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      margin-top: 40px;
      // padding-bottom: 200px;
      .metadata-panel {
        border-bottom: 0.1em solid #000000;
        .metadata-content {
          display: flex;
          align-items: center;
          padding: 8px 0;
          cursor: pointer;
          .metadata-content-txt {
            flex: 1;
            font-size: 14px;
            color: #000000;
          }
        }
        .metadata-display-panel {
          color: #000000;
          font-size: 14px;
          padding-bottom: 20px;
          .metadata-btn {
            background-color: #f4f4f4;
            color: #000000;
            height: 25px;
            line-height: 25px;
            padding: 0;
            margin: 10px 0 0 0;
            width: 45%;
            border-radius: 13px;
          }
        }
      }
    }
  }

  /* .cabinet_info_footer {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      bottom: 0px;
      z-index: 0.5;
    } */
}
//轮播 开始
.cabinet_info .el-carousel__indicator--horizontal .el-carousel__button {
  border: 1px solid #000000 !important;
}
.cabinet_info
  .el-carousel__indicator--horizontal.is-active
  .el-carousel__button {
  background-color: #000000 !important;
}
//轮播 结束
</style>