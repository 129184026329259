<template>
  <div class="support">
    <div class="box">
      <div :class="['top',bgClass]">
        <AppNavigator/>
      </div>
      <div class="middle"></div>
      <div class="main">
          <div style="position: relative;background: rgb(3,2,0);height: 600px;text-align: center;">
              <img src="../../assets/Frontpage/21.jpg" style="height:100%;" alt="">  
              <div style=" position: absolute;left:50%;top:50%;font-size: 50px;line-height:50px;font-weight: 400;color: #FFFFFF;transform: translate(-50%,-50%);text-align: center;" v-html="$t('lang.footer.support.title')"></div>
          </div>          
          <div class="content" style="margin: 0 auto;margin-top: 80px;width: 72%;display: flex; justify-content: space-between;font-size:14px;" ref="content">
             <div v-for="(item,index) in $t('lang.footer.support.menuContext')"  :key="index">
                  <div  style="padding: 10px 0;" class="setFont" v-html="item.title" > </div>
                  <div  class="setFont" v-html="item.content "></div>
                  <div class="setFont" style="margin-top:25px;" @click="goToWeb(index)">
                      <el-button type="info" round>{{ item.footerContent }}</el-button>
                  </div>
             </div>    
          </div>
      </div>  
      <div class="footer">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue"
import AppFooter from "@/components/common/AppFooter.vue"
export default {
  name: "Support",
  components: {
    AppNavigator,
    AppFooter,
  },
  data(){
    return {
      bgClass:'bg-black',
      mainHeight:0
    }
  },
  methods: {
   goToWeb(index){
      switch (index) {
          case 0:
            this.$router.push("/ownership-transfer");
            break;
          case 1:
            this.$router.push("/faq");
            break;
          case 2:
            //需要安装邮件客户端软件为前提
            location="mailto:support@zhen.io?subject=ZHEN. Contact Request";
            break;
          default:
            
        }
   },
   scrollEvent() {
      let $this = this;
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let offsetTop =  $this.$refs.content.offsetTop;
      if(scrollTop >= offsetTop) {
        $this.bgClass = 'bg-white';
      }else{
        $this.bgClass = 'bg-black';
      }
   }
  },
  mounted(){
    //绑定事件
    window.addEventListener('scroll',this.scrollEvent,false);
  },
  destroyed() {
    window.removeEventListener('scroll',this.scrollEvent,false);
  }
};
</script>

<style lang="less" scoped>
.support {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: #000000;
  .box {
    .setFont {
        text-align: center;        
    }

    .el-button--info {
        background-color: #F4F4F4 !important;
        font-family: 'NeueHaasUnicaW1G-Regular, NeueHaasUnicaW1G';
        font-weight: 400;
        color: #000000;
        border: none !important;
        width: 160px !important;
    }
    .el-button {
        background-color:  #F4F4F4 !important;
        border: none !important;
        width: 160px !important;
        padding: 0;
        height: 25px;
        line-height: 25px;
    }

    .top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      /*backdrop-filter: saturate(180%) blur(30px) opacity(80%); */
    }
    .middle {
       height: 41px;
       background-color: #000000;
       width: 100%;
    }
    .main {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-bottom: 280px;
    }
    /* // .footer {
    //  width: 100%;
    //   box-sizing: border-box;
    //   position: fixed;
    //   bottom: 0px;
    // } */
  }
}
</style>
