export const lang = {
    navigator:{
        technology: '技術',
        experience: '經驗',
        editorial: '社區' ,
        clientServices: '客戶服務',
        login: '登入',
        markPlace: '交易市場',
        language: '繁'
    },
    profile: {
        login: '登入',
        register: '註冊',
        password: '密碼',
        forgotPassword: '忘記密碼',
        loginOut: '登出',
        transfer:'產權轉移',
        confirmDialog: {
            title:'產權轉移確認',
            desc1:'您收到一份產權轉移請求。若接受此臻品的產權轉移，請點擊下麵的確認轉移按鈕。',
            desc2:'若此次轉移未經過協商或您不清楚此次轉移的情況，請點擊取消按鈕，併發郵件至support@zhen.io與我們聯繫。',
            confirm:'確認轉移'
        },
        required:'必填項'
    },
    login: {
        title: '登入',
        email: '郵箱',
        yourEmail:'請輸入你的郵箱',
        editPassword: '編輯密碼',
        currentPassword: '你的密碼',
        yourPassword: '你的密碼',
        newPassword: '新的密碼',
        confirm: '確認',
        passwordInfo: '請輸入至少8個字符',
        cancel: '取消',
        loginInfo: '您的密碼已重置,請登入.',
        forgetPassword: '忘記密碼',
        phone: '手機號碼',
        yourPhone:'請輸入你的手機號碼',
        password: '密碼',
        yourPassword:'請輸入你的密碼',
        remember: '記住密碼',
        errorInfo: '國家/地區代碼無效',
        content: '在使用我們的服務過程中，您需要同意至臻的<span style="color:#FF7A00;">條件條款</sapn>和<span style="color:#FF7A00;">隱私協議</span>',
        content2: '必填項.',
        content3: '還沒有帳號？',
        content4:'點擊此處進行註冊.' 
    },
    register:{
        title: '註冊',
        title2: '註冊',
        email: '郵箱',
        yourEmail:'請輸入你的郵箱',
        phone: '手機號碼',
        yourPhone:'請輸入你的手機號碼',
        emailVer: '郵箱驗證',
        yourEmailVer:'請輸入驗證碼',
        fullName:'姓名',
        sMSVerification: '短信驗證碼',
        yourSmsVer: '請輸入短信驗證碼',
        setPassword: '設置密碼',
        youSetPassword: '請輸入你的密碼',
        sendCode: '發送驗證碼',
        verCodeInfoSend: '驗證碼已發送',
        verCodeInfo: '您的驗證碼已發送到提供的電子郵件地址。如果您在5分鐘內沒有收到您的代碼，請檢查您的垃圾郵件文件夾或再次請求.',
        verCodeInfo2: '您的驗證碼已發送至提供的電話號碼。如果您在5分鐘內沒有收到您的代碼，請申請一個新代碼.',
        note: '註意',
        registerTitle: '註冊成功',
        registerInfo: '請您登錄以繼續',
        errorInfo: '國家/地區代碼無效',
        content: '在使用我們的服務過程中，您需要同意至臻的<span style="color:#FF7A00;">條件條款</span>和<span style="color:#FF7A00;">隱私協議</span>.',
        content2: '已經有賬號了?',
        content3: '點擊此處進行登入',
        updatePWTitle:'更新密碼',
        updatePWContent:'為了保證您的帳戶安全，請重置系統默認發送給您的密碼。',
        updatePWBtn:'重置密碼'
    },
    cabinet: {
        title: '我的櫥窗',
        title2: '我的櫥窗',
        all: '全部',
        edition: '臻品',
        editions:'臻品',
        nftPremium: 'NFT優質版',
        nft: 'NFT',
        noText:'您的櫥窗暫時還沒有任何收藏品喔！<br>可以通過流覽交易市場或者下載ZHEN.app添加您的臻品',
        dialogTitle:'您的櫥窗暫時還沒有商品喔！',
        dialogContent:'可以前往ZHEN.app或者在交易市場中發現屬於您的臻品。',
        dialogBtn1:'下載',
        dialogBtn2:'交易市場',
        detail: {
            description: '描述',
            conentText: ' 什麽是',
            conentText2: '購買時間',
            conentText3: '轉讓所有權',
            artist: '藝術家',
            currentOWner:'當前產權方',
            productInfo:'產品信息',
            publishedBy:'出版商:',
            producedBy:'製造商:',
            material:'材料',
            color:'顏色',
            editionOf:'版數',
            measurements:'尺寸',
            weight:'重量',
            manufacturingYear:'製作年份',
            editionInfo:'版本信息',
            editionNo:'版號',
            serialNo:'序列號',
            ownershipInfo:'產權信息',
            currentOwner:'所有者:',
            accountNumber:'賬號',
            purchaseDate:'購買日期',
            ownershipTransfer:'產權轉移',
            txid:'交易哈希',
        },
        dialog: {
            title:'產權轉移',
            desc:'為了維護所有權的安全性，ZHEN.需要確保當前進行操作的是您本人。請提供以下資訊以繼續產權轉移操作。',
            userId:'請輸轉入用戶的ZHEN. ID',
            password:'密碼',
            code:'驗證碼',
            codeHolder:'請輸入驗證碼',
            success:'操作成功！',
            tip:'恭喜！已成功轉移您的#username #workname所有權。',
            backCabinet:'返回櫥窗'
        }
    },
    myMarkPlace: {
        shopNow: '立即購買',
        dropsCalendar: '投放預告',
        notifyMe: '通知我',
        artist: '藝術家',
        description: '編輯寄語',
        conentText: ' 什麽是優質版NFT?',
        productDetail:'商品信息',
        productDetail2:'商品信息',
        history:'交易記錄',
        view:'查看',
        content: '要完成您的訂單，您需要授予 ZHEN。訪問您的 MetaMask 錢包。請選擇“連接錢包”以繼續.',
        digitalWallet: '電子錢包',
        connectwallet: '連接錢包',
        day: '天',
        cancel: '取消',
        title: '輸入',
        installText:'安装',
        installText1:'安装',
        dialogTitle1:'连接钱包',
        label1:'用戶協議',
        label2:'隱私條款。',
        label3:'和',
        conentText2: '感謝您參與抽獎。您將很快收到結果通知，因此請務必在ZHEN應用程序中打開通知。祝你好運！',
        contentText3:'在使用我們的服務過程中，您需要同意至臻的',
        contentText4:'安裝 MetaMask 擴展程式，再將其與ZEHN.進行連結，之後繼續進行支付。',
        checkOut:'結算',
        alertText1:"感謝訂閱，我們會按時通知您。",
        alertText2:"您還沒有填寫郵箱信息，請前往個人資料頁面完善。",
        alertTitle:'您的ETH幣不足以完成此次支付',
        alertText3:'請確保您的錢包裏有足夠的主網路ETH以繼續支付。',
        alertBtn:'查看幫助文檔',
        comingsoon:'即將開放',
        raffleRule:'抽簽詳情',
        event:'活動'
    },
    myprofile: {
        title: '個人資料',
        title2: '個人資料',
        detail: '個人資料',
        userId: '用戶ID',
        yourWallet: '你的錢包',
        connect: '未連接.',
        connectWallet: '連接錢包',
        disconnect: '斷開',
        editImg: '編輯頭像',
        editName: '編輯姓名',
        confirm: '確認',
        cancel: '取消',
        fullName: '姓名',
        youNewName: '請輸入你的姓名',
        editPhone: '編輯手機號碼',
        phone: '手機號碼',
        newPhone: '手機號碼',
        youNewPhone: '請輸入你的手機號碼',
        email: '郵箱',
        newEmail: '新的郵箱',
        youEmail: '請輸入你的郵箱',
        editPassword: '編輯密碼',
        cureentPassword: '你的密碼',
        password: '密碼',
        youPassword: '請輸入你的密碼',
        newPassword: '請輸入新的密碼',
        enterPassword: '請輸入你的密碼',
        edit: '編輯',    
        sMSVerification: '短信驗證碼',
        smsVerCode: '請輸入驗證碼',
        sendCode: '發送驗證碼',
        accountPassword: '賬戶密碼',
        editEmail: '編輯郵箱',
        emailVerification: '郵箱驗證碼',
        errorInfo: '國家/地區代碼無效',
        verificationCodeSent: '驗證碼已發送',
        verCodeInfo: '您的驗證碼已發送到提供的電子郵件地址。如果您在 5 分鐘內沒有收到您的代碼，請檢查您的垃圾郵件文件夾或再次請求.',
        verCodeInfo2: '您的驗證碼已發送至提供的電話號碼。如果您在 5 分鐘內沒有收到您的代碼，請申請一個新代碼.',
        note: '註意',
        uploadInfo: '上傳的文件不是有效的圖像。僅允許使用JPG、JPEG和PNG文件.',
        orderHistory: {
            title: '訂單歷史',
            viewOrder: '查看訂單',
            order: '訂單',
            productOverView: '商品信息',
            deliveryFee: '運費',
            total: '總計',
            deliveryaddr: '收件地址',
            parcels: '包裹',
            contactUs: '聯系我們',
            nodata:"承運商未提供貨運信息，請稍後訪問。"
        },
        noWithdrawHistory:'您沒有提取歷史。',
        withdrawHistory:'提取歷史',
        noTransfer:'暫無產權轉移記錄。',
        noOrder:'暫無訂單記錄。',
        twofa: {
            title:'開啟兩步身份驗證',
            authenticator:'谷歌身份驗證器',
            bind:'去綁定',
            unbind:'解除綁定',
            downDialogTitle:'安全提醒',
            downDialogContent:'ZHEN. 建議您通過啟用雙重認證來保護您的帳戶。使用谷歌身份驗證器以獲得額外的保護。',
            downDialogBtn1:'啟用雙重認證',
            downDialogBtn2:'暫不啟用',
            bindTitle:'使用谷歌身份驗證器並啟用雙重認證',
            bindRemark:'掃描該二維碼或者復製下方的代碼用於綁定谷歌兩步身份驗證。',
            bindRemark1:'在您的移動設備上打開谷歌身份驗證器應用程序。點擊 "+"按鈕並掃描下面的二維碼，或輸入下面提供的一串文字，然後彈窗內的繼續按鈕。',
            bindRemark2:'請不要與任何人分享這些認證細節。如果對您的賬戶安全有疑問或擔心，請聯系我們：support@zhen.io。',
            continue:'下一步',
            enterCode:'輸入六位谷歌身份驗證器提供的數字驗證碼。',
            confirm:'確認',
            copySuccessTitle:'密鑰已復製',
            copySuccess:'您的密鑰已經復製到您的剪貼板上。',
            unbindTitle:'禁用？',
            unbindContent:'禁用谷歌驗證器將會移除您賬戶的額外安全保障。',
            unbindAlertBtn:'確認禁用',
            validText:'兩步身份驗證',
            startValid:'開始身份驗證',
            authenticated:'已驗證',
            errorMsg:'錯誤的驗證碼',
            notComplete:'您的訂單未完成'
        }
    },
    forgetPassword: {
        title: '忘記密碼',
        title2: '重置密碼',
        email: '郵箱',
        yourEmail:'請輸入你的郵箱',
        phone: '手機號碼',
        yourPhone:'請輸入你的手機號碼',
        emailVer: '郵箱驗證',
        smsVer: '短信驗證碼',
        yourEmailVer:'請輸入郵箱驗證碼',
        yourPhoneVer:'請輸入短信驗證碼',
        setPassword: '設置密碼',
        youSetPassword: '請輸入你的密碼',
        sendCode: '發送驗證碼',
        content: '必填項 .'
    },
    home: {
        reimagine:'重新構想所有權',
        systemRemark:'全世界最具安全性的藝術品所有權鑒別系統，一切盡在你的掌控之中。',
        shopNow:'立即購買',
        technologyText:'我們使用的技術',
        technologyRemark:'通過由區塊鏈賦能的NFT鑒別藝術品。',
        nftText:'的開發基於以太坊（ERC-721）具有以下特點',
        nftFeature:'去中心化<br>不可篡改<br>高度安全.<br>公開透明',
        nftAuthenticity:'所有權及真實性受區塊鏈保護',
        tapText:'一鍵式鑒別',
        tapFeature:'直觀且毫不費力<br>鑒別真的很簡單<br>一切盡在你的掌控之中',
        experienceText:'體驗',
        ownershipText:'全新的所有權<br>體驗等待著您',
        ownershipRemark:'確定所有權最快捷的方法<br>無論在哪，您都可以查驗產品真實性和出處。',
        artText:'你所有的藝術藏品',
        artText1:'全部都收納在自己的口袋裏。',
        artRemark:'一種前所未有的方式來展示你的實體和數字收藏。<br>收藏不止於此。',
        appText:'你所需要的一切都在一款APP中',
        authMethod:'掃描您的ZHEN.-不費吹灰之力就能登記產權和確定真偽。',
        productText:'產品出處及歷史記錄',
        productOwnership:'獲得關於您的藏品編號來自何處的詳細信息，並通過認證您的所有權，為藏品留下您的專屬記號。',
        collectorsText:'收藏者櫥窗',
        collectorsRemark:'同時欣賞你所感興趣的的實物作品和數碼作品。',
        contentText:'獨家內容',
        contentRemark:'通過我們的原創內容帶你暢遊藝術科技的世界。',
        shopText:'商城與發現',
        shopRemark:'探索由至臻精心策劃的投放及抽籤活動，還可以逛一逛我們的臻選區。',
        nftDrops:'NFT投放，前所未見的形式',
        nftDropsRemark:'通過建立數字與實體之間的紐帶，我們已經重新構想了NFT。',
        best:'這就是我們最擅長的事情。',
        artistTitle:'我們合作的藝術家',
        artistRemark:'很榮幸獲得了世界各地有影響力的一些藝術家的授權。',
        miss:'不要錯過',
        joinUs:'快來訂閱我們的最新資訊吧。',
        holder:'請輸入郵箱',
        holder2:'郵箱不能為空',
        holder3:'請輸入正確的郵箱格式',
        holder4: '感謝訂閱.',
        note:'提示',
        confirm: '確認',
        cancel: '取消',
        holder5: '你的郵箱',
        runningText1:'空山基的首個NFT作品已被投放',
        runningText2:'快來加入抽簽吧',
        runningText3:'點擊這裏立即參與',
    },
    manual: {
        youGuideTo: '使用',
        ownerShipTransfer: '產權轉移功能指南',
        ask: '要將你的臻品轉讓給別人?',
        que: '只需簡單的幾個步驟, 你就可以安全地將臻品的數字產權轉移給新的<br>所有者.',
        initiation: '啟用功能',
        content: '在發起產權轉移前, 你需要重新識別產品進入產權轉移流程。在此過程中請確保當前為已登入的狀態.',
        contentList: [
            {
                list: '1  &nbsp;&nbsp;&nbsp;&nbsp 在至臻APP內再次識別您的產品.'
            },
            {
                list: '2  &nbsp;&nbsp;&nbsp;&nbsp 在鑒別頁面會顯示“產權轉移”按鈕.'
            },
            {
                list: '3  &nbsp;&nbsp;&nbsp;&nbsp 點擊按鈕繼續完成產權轉移流程.'
            }
        ],
        information: {
            title: '提示資訊',
            content: '買方 (未來的所有者) 將會收到一封產權轉移請求郵件，將自己的臻品轉移至對方名下.'
        },
        informations:{
            list: [
                {
                    ask: '1 &nbsp;&nbsp;&nbsp;&nbsp 未來所有者ZHEN. ID',
                    que: '在第一行添加關聯的未來所有者 ZHEN. ID 。注意：為了保證產權轉移流程順利進行，未來所有者也必須是至臻的用戶.'
                },
                {
                    ask: '2 &nbsp;&nbsp;&nbsp;&nbsp 你的ZHEN.密碼',
                    que: "輸入密碼連接你的 ZHEN. 帳號 (當前所有者的帳號).每個帳號唯一的 ZHEN. ID可以再個人資料頁面中找到." 
                },
                {
                    ask: '3 &nbsp;&nbsp;&nbsp;&nbsp 請求驗證碼',
                    que: '點擊“發送驗證碼” 通過郵件或短信收取唯一的驗證碼以驗證您的身份(驗證碼將發送至與帳號相關聯的郵箱或手機號中。)'
                },
                {
                    ask: '4 &nbsp;&nbsp;&nbsp;&nbsp 輸入驗證碼',
                    que: '收到驗證碼後請輸入至驗證碼填寫區域.'
                },
                {
                    ask: '5 &nbsp;&nbsp;&nbsp;&nbsp 成功發起請求',
                    que: "資訊填寫完成後, “產權轉移”按鈕會變為可點擊的狀態, 你可以點擊此按鈕開始進行產權轉移成功。發起申請後, 會出現彈框提示確認此次申請操作."    
                },
                {
                    ask: '6 &nbsp;&nbsp;&nbsp;&nbsp 收到產權轉移請求',
                    que: "你將會收到一封郵件，內容為ZHEN已收到你的產權轉移請求。此時您的產權轉移請求正在處理中." 
                }
            ]
        },
        transfer: {
            title: '轉移',
            content: '買方(未來所有者) 將會收到一封郵件，內容為有人向您發起了臻品的產權轉移請求.',
        },
        transfers: {
            list: [
                {
                    ask: '1. &nbsp;&nbsp;&nbsp;&nbsp 確認郵件',
                    que: '當前所有者成功發起轉移申請後, 未來所有者將會收到一封郵件通知，並且需要立即登入同意該申請.'
                },
                {
                    ask: '2.  &nbsp;&nbsp;&nbsp;&nbsp 轉移完成',
                    que: '接受後, 產權轉移流程已全部完成。每條轉移請求都會經過至臻的驗證，並且通過區塊鏈確保為有效交易，此過程可能需要幾個工作日才可以完成.'
                },
            ]
        },
        help: "需要幫助?我們就在這裏."
    },
    download: {
        title: '立即下載',
        content: '<span style="font-size:50px;font-weight:400;">現在下載ZHEN. ，<br>目前已支持全球所有的應用商店。</span>',
        content2: '<span style="font-size:21px;">讓你感受到NFT的力量, 使得藝術品鑒別如此簡單 — 一切都在你的掌控中。</span>',
        ios: 'ios',
        android: '安卓',
        contentList: {
            content: '迅速獲取你的最新所有權體驗許可權。通過至臻，你可以體驗:',
            list:[
                {
                    ask: '藝術品鑒別',
                    que: '臻品產權登記.'
                },
                {
                    ask: '發現',
                    que: '遇見最好的至臻--探索臻品.'
                },
                {
                    ask: '收藏',
                    que: '在一處展示你的實物及數碼藏品，前所未有的體驗.'
                },
                {
                    ask: '收穫',
                    que: '瞭解你最喜歡的藝術家, 還可以獲取有關區塊鏈以及藝術相關的獨家資訊.'
                },
        ]
        }
    },
    footer:{
        legal: '法律',
        // carreers: '職業者',
        // company: '公司',        
        // data: '數據',
        warranty: {
            title: "質保條款"
        },
        support:{
            title: '你所需要的支持,<br>全部都在這裏。',
            menus: '支持',
            menuContext: [
                {
                    title: '所有權+鑒別<br>' ,
                    content: '新入手的藏品可以在這裏獲取所有資訊',
                    footerContent: '查看手冊'
                },
                {
                    title: '常見問題<br>' ,
                    content: "至臻一些緊急問題的答案",
                    footerContent: '查看更多'
                },
                {
                    title: '聯繫我們<br>' ,
                    content: "還需要其他幫助？可以線下諮詢我們的支持團隊",
                    footerContent: '取得聯繫'
                }
                ]
        }, 
        faq: {
            title: '常見問題',
            menus: ['General', 'AUTHENTICATION', 'Logistics', 'Data and Privacy', 'Bugs/Troubleshooting', 'NFT Withdrawal and Deposit NFT', 'HOW TO PURCHASE NFT', 'HOW TO TRANSFER NFT OWNERSHIP TO A ZHEN. USER', 'HOW TO WITHDRAWAL ZHEN. NFT TO WALLET', 'Augmented Reality (AR)'],
            menuContext: [
                {
                    title: "常見問題",
                    faq: [
                        {
                            ask: "ZHEN. 是什麼?",
                            que: "ZHEN. 是一家利用區塊鏈的透明度和安全性進行藝術認證的科技公司。使用 ZHEN. 手機應用程序 (iOS 和 Android) 和區塊鏈技術 (Ethereum 和 Polygon)，收藏家可以通過 NFT 認證和體驗藝術品。<br /><br />"
                        },
                        {
                            ask: "ZHEN. 是怎樣運作?",
                            que: "我們正在建立一個藝術品認證的生態系統。通過在藝術品中植入 NFC芯片和由區塊鏈驅動的擁有權驗證系統，我們能夠保證每件經 ZHEN. 認證的藝術品是正品，並提供數碼擁有權的出處。<br /><br />"
                        },
                        {
                            ask: "你現在想與任何人合作嗎?",
                            que: "當然！我們一直在尋找新的藝術家和單位與我們合作。請將您的想法發送至<a class=\"email2\" href=\"mailto:partnerships@zhen.io\">partnerships@zhen.io</a> 與我們聯繫。<br /><br />"
                        },
                        {
                            ask: "ZHEN. 是什麼意思?",
                            que: "ZHEN.取自中文拼音，融合真品、珍藏、至臻之意。單音節的ZHEN.，體現了我們全部的價值追求。<br>同時，ZHEN.的發音亦類似“Generation”或“Genesis”中的“Gen”, 對我們來說，ZHEN.亦標誌著重新構想、新世代收藏家和藝術的開端。<br /><br />"
                        }
                    ]
                },
                {
                    title: "區塊鏈認證",
                    faq: [
                        {
                            ask: "當您們說“區塊鏈認證”時，是什麼意思？您們如何鑑定藝術品?",
                            que: "藝術品中嵌入了安檢芯片成功地把認證過程提升到了一個新水平。但是，我們不止步於此；亦認為藝術認證過程不應僅限於物理形式，而數碼擁有權與物理所有權一樣重要。<br><br>" +
                                "ZHEN., 數碼擁有權採用區塊鏈上非同質化代幣（“NFT”）的形式，這是一個用於記錄交易的開放、不可變的分類帳。每個 NFT 對應一個唯一的項目，並存儲有關數碼擁有權、ID 序列號、項目描述和其他產品信息的編輯信息。(有關完整列表，請參閱此常見問題解答中的“數據和隱私”部分。)<br><br>" +
                                "當您掃描我們認證的收藏品時，ZHEN. 將為您檢查並使用區塊鏈上的數據進行認證。這個過程是非常簡單，安全和準確。我們相信將徹底改變藝術品的鑑定方式。<br><br>"
                        },
                        {
                            ask: "如何認證和註冊產品的所有權?",
                            que: "在大多情況下，請登錄ZHEN. 應用程式並按下認證圖標[插入圖標]進行藝術品認證，按照屏幕上的說明進行操作。每個由ZHEN.認證的藝術品品都配有插圖手冊（owner's manual，說明手冊或卡片，凸版卡片，清楚表明 NFC芯片(位於 ZHEN. 標誌內)特定位置，並附上驗證碼。請參閱說明手冊以獲得更多幫助。<br><br>" +
                                "如果您在二手市場上購買藝術品藏品，但沒有附帶用戶手冊和需要任何其他幫助，請發送電子郵件至 <a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a> 並提供您的產品信息和 ZHEN. ID。<br><br>"
                        },
                        {
                            ask: "擁有權如何存儲和轉移?",
                            que: "每個收藏品都嵌入了一個 ZHEN. NFC 芯片與唯一的 Ethereum 或 Polygon NFT對應，可以在 Etherscan 或 Polygonscan 上使用關聯的 TXID 進行搜索。每一個NFT 中都存儲產品信息和芯片信息。<br><br>" +
                                "為了進一步保障完整的收藏體驗可啟用ZHEN.應用程序內的擁有權轉移。賣家通過掃描嵌入的 NFC 芯片並點擊應用程序的按鈕來啟動程序，將數碼擁有權轉移給買方。轉移請求將發到我們的後端進行確認。<br><br>" + 
                                "一旦我們的團隊確認為有效轉移，將通過智能合約把擁有權轉讓在區塊鏈上註冊，並生成唯一的 TXID來確認。<br><br>"
                        },
                        {
                            ask: "我無法掃描我的產品/找不到芯片。你們能幫忙嗎?",
                            que: "ZHEN. 認證的藝術品包裝內附有用戶手冊。手冊的動畫或插圖將向您展示芯片的位置。在掃描完成之前，請務必將手機保持在原位5秒。<br><br>" +
                                "請檢查手機上的 NFC 功能是否已啟用，並將手機盡可能靠近產品。<br><br>" + 
                                "如您仍然無法掃描產品，請聯繫我們 — <a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a><br><br>"
                        }
                    ]
                },
                {
                    title: "物流運輸",
                    faq: [
                        {
                            ask: "你們在全球範圍內發貨嗎?",
                            que: "每種產品的可用性可能因地區而異，具體取決於版本和發行版。如需諮詢特定產品，請通過 <a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a> 聯繫我們。<br><br>"
                        },
                        {
                            ask: "你們從哪裡發貨?",
                            que: "此信息取決於每個訂單因產品而異。請通過<a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a>聯繫我們。感謝您的耐心等待！<br><br>"
                        },
                        {
                            ask: "運費是多少?",
                            que: "運費是根據產品的最終目的地以及每個地區計算。因此，每個產品的運費可能有所不同。如需諮詢特定產品，請通過 <a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a> 聯繫我們。"
                        }
                    ]
                },
                {
                    title: "數據和隱私",
                    faq: [
                        {
                            ask: "ZHEN. 將如何存儲我的數據?",
                            que: "您的隱私和安全是 ZHEN. 的優先事項。您的數據使用 MD5 消息摘要算法加密並存儲在安全服務器中。我們致力於保護您的數據，並不斷更新我們的內部實踐，以跟上最新的保障和發展。<br><br>"
                        },
                        {
                            ask: "你們的服務器在哪裡?",
                            que: "我們的數據存儲在位於新加坡的亞馬遜雲服務器中。<br><br>"
                        },
                        {
                            ask: "在區塊鏈上可以查看哪些信息?",
                            que: "目前在區塊鏈上可以看到以下信息：<br>" +
                                "-	產品信息：產品名稱、藝術家姓名、出版商、製造商、版本號、序列號<br>" +
                                "-	材料, 顏色, 尺寸, 重量, 製造年份<br>" +
                                "-	芯片信息：令牌版本號、芯片唯一標識符（=產品序列號）、圖像 URL<br>" +
                                "-	用戶身份<br>" +
                                "<br><br>"+
                                " 用户 ID 將是任何買家租賣家的識別並確認賣家當前的藝術品擁有權。因此我們鼓勵買家通過 ZHEN.轉移擁有權，這樣我們可以驗證賣家的身份，確保您購買的是正品。" +
                                "<br><br>" +
                                " 請放心這些信息不涉及個人信息，您的隱私受到了保護。<br><br>"
                        },
                        {
                            ask: "為什麼ZHEN. 需要這些個人信息?",
                            que: "我們從傳統拍賣行中汲取靈感，行業標準是能夠指出任何藝術品的出處。這規範記錄了市場上的所有利益相關者受益包括: 藝術家、畫廊、收藏家和拍賣行在ZHEN. 上的一切。" +
                                "<br><br>" +
                                "話雖如此，數據隱私始終是 ZHEN. 的首要。請放心，擁有者和收藏者的身份將在公共領域和區塊鏈上保持匿名，我們以維護高水平的安全和隱私標準積極工作。<br><br>"
                        },
                    ]
                },
                {
                    title: "錯誤/疑難排解",
                    faq: [
                        {
                            ask: "應用程序無法在我的手機上運行，你們能幫忙嗎?",
                            que: "我們已盡最大努力確保用戶能夠享受到完整的 ZHEN. app 體驗，但操作系統和手機硬件問題是ZHEN. 的兩個常見原因引致應用程序無法在您的手機上按預期工作。請向我們發送電子郵件，提供更多信息，包括當前的操作系統和手機型號 — <a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a><br><br>"
                        },
                        {
                            ask: "我收到了損壞的藝術品。你們能幫忙嗎?",
                            que: "我們很抱歉在您收到損壞了您的藝術品。我們一直與供應商和速遞公司配合，以確保我們所有的產品在包裝和運送給您之前都處於良好狀態。不幸的是，一旦包裹離開我們的配送中心，我們就不能控制產品的狀況。如果損壞發生在生產過程中，我們即可處理請求。請通過圖片和損壞產品的描述聯繫 <a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a> 並在此處熟讀我們的<a class=\"email2\" href=\"/warranty\">保養條文</a>。<br><br>"
                        }
                    ]
                },
                {
                    title: "NFT 提現和存入",
                    faq: [
                        {
                            ask: "提現完成後，我在哪裡可以看到我的NFT?",
                            que: "當您的提現申後請成功後，您的 NFT 將在去中心化平台（如 OpenSea）上通過您配置的加密錢包登錄可見。<br><br>"
                        },
                        {
                            ask: "要存入，NFT 必須在錢包中。如何將 NFT 添加到我的加密錢包?",
                            que: "當 NFT 在您的錢包中時，您必須採取額外的步驟才能在您的錢包中查看。你可以在去中心化平台（如 OpenSea）的詳細信息中找到 NFT 的代幣 ID 和合約地址。以太坊有一個簡單的教程描述 <a href='https://ethereum.org/de/developers/tutorials/how-to-view-nft-in-metamask/' target='_blank'>在此</a>。<br><br>"
                        },
                        {
                            ask: "我在哪裡可以找到我的 NFT 的合約地址?",
                            que: "如果你的 NFT 存儲在你的 ZHEN. 上。您可以在 Dashboard 的信息頁面中 METADATA 部分看到您的 NFT 合約地址。<br><br>"+
                                "至於從二級市場存入 ZHEN. 的 NFT，您可以在<a href='/deposit'>歷史記錄中</a>查看合約地址。<br><br>"+
                                "至於從 ZHEN. 中提現的 NFT。平台，您可以在 ZHEN. 平台<a href='/withdraw'>歷史記錄中</a>查看合約地址。<br><br>"+
                                "如果你的 NFT 目前不在 ZHEN. 上，你可以在正在使用的去中心化平台的詳情頁面看到它的合約地址(如 OpenSea)。<br><br>"
                        },
                        {
                            ask: "我可以在 ZHEN. 櫥窗上看到我擁有的非 ZHEN. NFT嗎?",
                            que: "ZHEN. 僅支援查看 ZHEN. 創建的 NFT，其他的NFT 在存入時可能無法正常運行。<br><br>"
                        },
                        {
                            ask: "如我的 NFT 在提現後發生任何事情，ZHEN. 會否負責？",
                            que: "一旦您選擇從ZHEN. 中提現 NFT，我們將不對您的 NFT 負責。如果您的 NFT 在我們監管範圍之外發生任何事情，我們也不承擔任何責任。千萬不要與任何人分享您的加密錢包密碼或助字詞。<br><br>"
                        }
                    ]
                },
                {
                    title: "如何購買 NFT",
                    faq: [
                        {
                            ask: "如何從 ZHEN. 註冊 NFT?",
                            que: "ZHEN. 將通過其市場平台發佈 NFT。您將需要一個有效的 ZHEN. 帳戶，透過手機號碼或電子郵件地址進行註冊。登錄到您的 ZHEN. 帳戶後，您將能夠參與 NFT 發售活動。<br><br>"
                        },
                        {
                            ask: "如何從 ZHEN. 購買 NFT?",
                            que: "獲勝者被抽籤結果選中後將會收到短信或電子郵件通知，並在其指定的時間內購買 NFT。您可以通過在 ZHEN. 市場平台上使用法幣透過 Stripe 或支付寶完成交易來購買您的 NFT。<br><br>"
                        },
                        {
                            ask: "我需要一個加密錢包來購買 NFT 嗎?",
                            que: "否，目前不需要加密錢包從 ZHEN. 上購買任何東西。<br><br>"
                        },
                        {
                            ask: "我購買了 NFT，請問在哪裡可以查看?",
                            que: "一旦您的付款完成並確認，您的 NFT 將顯示在您的 ZHEN. 櫥窗上。通過應用程序或登錄 ZHEN. 網站在“我的櫥窗”查看。<br><br>"
                        },
                        {
                            ask: "為什麼我的 NFT 沒有出現在我的 ZHEN. 櫥窗上?",
                            que: "如您的交易出現在訂單歷史記錄中，請放心，您的 NFT 交易已經完成並將顯示在您的櫃窗上。根據不同情況，可能需要幾分鐘或幾個小時中出現。如 48 小時後仍未顯示，請聯繫<a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a><br><br>"
                        }
                    ]  
                },
                {
                    title: "如何將 NFT 擁有權轉讓給 ZHEN. 用戶",
                    faq: [
                        {
                            ask: "我可以將我的 NFT 賣給另一位 ZHEN. 用戶嗎?",
                            que: "目前這是不可能的。但是，我們的 NFT 與 OpenSea 兼容，因此您可以使用他們的平台進行二手市場交易。我們亦希望盡快將這功能包含在 ZHEN. 中。<br><br>"
                        },
                        {
                            ask: "我如何將我的 NFT 轉移到另一個 ZHEN. 用戶?",
                            que: "目前這是不可能的。此功能即將在 ZHEN.推出。<br><br>"
                        }
                    ]  
                },
                {
                    title: "如何提現 ZHEN. NFT 到錢包",
                    faq: [
                        {
                            ask: "我的 ZHEN. 帳戶可以連接哪些加密錢包?",
                            que: "在桌面瀏覽器上，ZHEN. 的網站支援 MetaMask。目前，用戶只能在桌面瀏覽器上連接他們的錢包，但我們正尋求將功能擴展到我們的手機應用程序。<br><br>"
                        },
                        {
                            ask: "ZHEN. 需要什麼資料連接我的加密錢包?",
                            que: "您需要將您的加密錢包連接到您的 ZHEN。首先在網站上登錄您的 ZHEN. 帳戶，這一步不能在ZHEN. 應用程式上進行。登錄後，請瀏覽“錢包”選項並點擊“連接錢包”按鈕，該按鈕將提示連接您的錢包。錢包擴展程序必須在您的桌面瀏覽器上運行。ZHEN. 永遠不會要求您提供您的錢包密碼或您的秘密恢復短語。<br><br>"
                        },
                        {
                            ask: "如何轉移我的 ZHEN. NFT 到我的加密錢包？",
                            que: "轉移你的 ZHEN. NFT 到你的錢包，你需要點擊“提現”部分下的“提現”按鈕，選擇你要提現的 NFT，然後手動輸入你的錢包地址。您將被要求輸入您的 ZHEN. 帳戶密碼和一個安全代碼，該代碼將發送到您的電子郵件或 SMS並取決於用於註冊帳戶時的撰項。<br><br>"
                        },
                        {
                            ask: "如有與 ZHEN. NFT 相關的空投或未來實用功能, 該 NFT 是不是一定要在我的ZHEN. 櫥窗上才可接收?",
                            que: "正確。如希望體驗與您的 NFT 相關的任何額外功能，它必須保留在您的 ZHEN. 櫥窗上。如將來發生任何更改，我們將對外宣布。<br><br>"
                        }
                    ]  
                },
                {
                    title: "擴增實境",
                    faq: [
                        {
                            ask: "什麼是擴增實境?",
                            que: "擴增實境或 AR，通常被稱為是現實的改變版本，其對象的數據或 3D 圖像覆蓋在現實世界上。換句話說，AR 是現實世界和數碼世界的結合，用戶可以在其中與擴增的環境進行交互。<br><br>"
                        },
                        {
                            ask: "擴增實境是如何實踐?",
                            que: "許多不同類型的擴增實境濾鏡或效果，但它們都涉及融合現實世界和虛擬世界以創造一種獨特的體驗。 ZHEN. 支援 的一些AR效果是：<br><br>" + 
                            "- 標記：覆蓋特定標記的效果，僅當該標記在視野中時觸發。<br>" + 
                            "使用圖像識別和映射技術，AR 體驗可以在指定的圖像標記上播放，或者在初始標記被識別後的其他地方播放。生成的效果可以是 2D 或 3D。<br>" + 
                            "- 非標記：這種效果沒有什麼特別錨定的，讓您可以隨時隨地放置您的 AR 雕塑或藝術品與四周的環境互動。<br>您可以將 AR 對象放在咖啡桌上，或者在火車站候大樓附近走動時觀看真人大小的霸王龍——可能性是無窮無盡。<br>" + 
                            "- 面部追踪：這是響應您的面部的交互式效果。通過面部識別和映射技術，面部跟踪器使用前置攝像頭檢測面部的位置和方向播放效果。<br><br>" + 
                            "使用我們的 AR 功能意味著我們的應用程序必須訪問您的前後攝像頭並掃描您的周圍環境和/或面部。我們非常重視用户的隱私，在體驗過程中接收或傳輸的任何視覺信息都不會用於非 AR 目的上。您可以在此處查看我們的<a class=\"email2\" href=\"/privacy\">隱私政策</a> 。"
                        },
                        {
                            ask: "擴增實境 (AR) 與虛擬實境 (VR) 有何不同?",
                            que: "虛擬現實或 VR 提供完全身臨其境的體驗，使用耳機等感官設備將物理世界拒之門外，而增強現實 (AR) 通過將數碼組件覆蓋在現實世界上，創造了現實世界和數字世界的完美融合。在 ZHEN. 我們目前的重點仍然是將藝術體驗帶入 AR，作為我們認證和擁有權體驗的一部分。<br><br>"
                        },
                        {
                            ask: "如何體驗AR?",
                            que: "AR可以在智能手機、平板電腦、智能眼鏡上體驗，也可以通過普通桌面電腦體驗。通過 ZHEN. 的手機應用程序，用戶可以體驗基於圖像識別、物體識別和地理位置的 AR。動作、音頻、熱成像和顏色識別等事物也可以觸發 AR。<br><br>" + 
                            "當設備“了解”環境，AR 也可以在空間上定位。<br><br>"
                        },
                        {
                            ask: "如何激活我的 AR 體驗?",
                            que: "一般來說，ZHEN. 可以按照以下步驟體驗 AR： <br><br>" + 
                            "- 打開 ZHEN. 應用程序<br>" + 
                            "- 如果擴增實境體驗與實體版相關聯，首先請通過掃描 NFC 芯片對藝術品進行認證，並確保在您的 ZHEN. 櫥窗上中可以看到該藝術品。<br>" + 
                            "- 當藝術品在你的 ZHEN. 櫥櫃上可見，點擊其右上角的 AR 圖標<br>" + 
                            "- 按照屏幕上的說明進行操作<br>" + 
                            "- AR 體驗將被激活並相應地呈現<br><br>" + 
                            "依照您的互聯網連接以及手機的品牌和型號，加載效果可能需要更長的時間。<br><br>"
                        },
                        {
                            ask: "我只能通過我的 ZHEN. app 體驗 AR 效果嗎?",
                            que: "由 ZHEN. 認證的藝術品和/或 NFT 提供的 AR 體驗只能通過 ZHEN. 應用程序呈現。這是為了確保只有您，即合法擁有者，才能獨家享受僅限擁有者的體驗。<br><br>"
                        },
                        {
                            ask: "我無法加載我的 ZHEN. 獨家 AR 效果。可以幫忙嗎?",
                            que: "建議連接穩定的 WiFi 網絡來體驗 ZHEN. 提供的擴增實境效果。此外，在一個沒有太多物體或雜物且光線充足的空間可以提供更好的 AR 體驗。<br><br>"
                        },
                        {
                            ask: "為什麼 ZHEN. 一直在創建擴增實境體驗?",
                            que: "擴增實境或 AR 與我們正在實踐的重新構想擁有權的使命一致。因為 ZHEN. ，用户不僅擁有實體版或數碼版的藝術品，還擁有連接的 NFT 解鎖體驗，通過技術提升擁有權的重要性。使用 AR 為收藏家創造獨特和特別時刻的可能性是無限的。隨著 AR 連接數碼和物理領域，我們正在開闢一條新的道路，探索全新的擁有權概念，並為藝術家和收藏家提供更有意義的體驗。<br><br>"
                        }
                    ]  
                }
            ],
        },
        contactUs: '加入我們',
        downloadApp: '立即下載',
        privacyPolicy: {
            title: '隱私條款'
        },
        termsAndConditions: '用戶協議',
        tc: {
            title: '用戶協議'
        },
    },
    pay: {
        checkOut: '結算',
        product: '1.商品',
        shipping: '2.收貨信息',
        payMent: '3.確認與結算',
        payMent1: '2.確認與結算',
        productOverView: '商品概覽',
        subTotal:'小計',
        content: '運費計入下一步驟中.',
        next: '下一步 >',
        payMethod: '支付方式',
        digitalWallet: '電子錢包',
        other: '在線支付',
        shipTo: '收貨信息',
        deliveryFee: '運費',
        total: '總計',
        back: '返回',
        confirmPayMent: '確認付款',
        content5: '您的訂單已完成！',
        content2: '確認郵件已發送至您的郵箱，請您確認!',
        content3: '繼續瀏覽',
        firstName: '名字',
        lastName: '姓氏',
        address: '地址',
        phoneNumber: '手機號碼',
        select1: '州/ 省/ 地區',
        city: '城市',
        email: '郵箱',
        select2: '郵編/郵政編碼',
        country: '國家/地區',
        next2: '下一步',
        content4: '必填項目',
        phoneHolder:'',
        firstNameHolder:'',
        lastNameHolder:'',
        addressHolder:'',
        stateHolder:'',
        cityHolder:'',
        emailHolder:'',
        codeHolder:'',
        countryHolder:'',
        note: '提示',
        confirm: '確認',
        cancel: '取消',
        back2: '返回',
        content6: '哎呀！很抱歉，你所選擇的商品已售罄。請繼續瀏覽其他商品。',
        content7: '價格變動通知!<br />由於匯率變動，您的支付金額已發生變化並自動更新。請您繼續完成支付。',
        content5: '您的訂單已取消！',
    },
    other: {
        title: '你沒有登入',
        content: '立即登入或註冊以全面體驗ZHEN.市場並接收更新.',
        login: '登入',
        register: '註冊',
        continue: '繼續瀏覽',
        cookiesText:'該網站期望獲得cookies使用權限來提升您的用戶體驗。',
        readMore:'了解更多',
        acceptAll:'全部接受',
        noAreaText:'無匹配的國家或地區'
    },
    notFind: {
        title:'頁面找不到了',
        content1:'糟糕，我們無法鑒別此頁面是否存在。',
        content2:'但是，請放心，我們這裏所有的商品都是經過鑒別的。',
    },
    wallet: {
        wallet: '錢包',
        error:  '錯誤',
        confirm : '確認',
        cancel: '取消',
        connectTitle:'鏈接錢包',
        connectRemark:'這是您的數字錢包，您將使用該錢包進行ETH數字貨幣支付。',
        walletLabel:'您認證的錢包地址:',
        connectWallet:'ZHEN.沒有連接到您的錢包。請連接您的錢包以繼續。',
        copyLabel:'復製',
        depositTitle:'商城錢包',
        depositWalletLabel:'商城的錢包地址:',
        depositRemark1:'只可將ZHEN.發行的NFT發送至此地址。',
        depositRemark2:'發送任何其他NFT可能會導致其永久損毀。非ZHEN.發行的NFT目前與ZHEN.平臺不兼容，不會在您的ZHEN.櫥窗或網站的其他任意部分正確顯示或呈現。請謹慎操作。',
        scanLabel:'掃碼',
        withdrawTitle:'提出藝術品',
        withdrawDialogTitle:'NFT 提取',
        withdrawRemark1:'您賬戶裡的這些NFT可以被提取。',
        withdrawRemark2:'請注意，提取成功後，被提取的NFT將不會再出現在您的ZHEN.櫥窗中。不過您隨時可以將它重新轉入，轉入成功後，它將重新出現在您的ZHEN.櫥窗中。',
        withdrawBtn:'提出藝術品',
        copySuccessTitle:'已復製您的錢包地址',
        copySuccessContent:'您的錢包地址已經復製到您的粘貼板上。',
        copySuccessTitle1:'已復製ZHEN.的存儲地址',
        copySuccessContent1:'ZHEN.的儲存地址已經復製到您的粘貼板上。',
        walletAddress:'您的#nftChain錢包地址',
        walletAddressError:'請輸入正確的區塊鏈錢包地址！',
        submit:'提交',
        close:'關閉',
        ok:'好的',
        successTitle:'請求成功',
        successMsg:'您的提取請求已經被受理，該提取流程將至多花費三個工作日處理完。',
        noWithdraw:'目前您沒有與您ZHEN.賬戶相關聯的NFTs。',
        penddingTitle:'正在審核您的請求',
        penddingContent:'該請求正在審核中，我們預計將在三個工作日內處理完畢，感謝您的配合。'
    },
    surpsise: {
        title:'使用驚喜密碼進入',
        remark:'要訪問產品頁面並購買空山基的首款NFT產品，<br>請在下面的字段中輸入您的秘密邀請碼。',
        inputLabel:'驚喜邀請碼',
        inputHolder:'在此輸入您的邀請碼',
        btnText:'輸入'
    },
    productType: {
        1: {
            title: "臻品",
            hover:"ZHEN,認證的臻選商品聲明了具有數字證明的物理所有權。",
            remark:"一個經過ZHEN.認證的臻選產品：即以最安全方式擁有藝術品。用戶通過掃描我們的NFC安全芯片，為您的實體藝術品和收藏品提供數字所有權進行認證，每個芯片都與區塊鏈上的唯一NFT綁定。結合區塊鏈的不變性，安全性，不可分割性，真實性的出處等特性相結合，給ZHEN.提供全新的藝術品所有權和收藏體驗的驅動力。",
        },
        2: {
            title: "NFT優質版",
            hover:"ZHEN. 優質NFT提供實物和數字產權的混合體驗。",
            remark:"ZHEN. 優質的NFT產品打通了物理世界和數字世界的界限，重新想象和定義了數字藝術品所有權的含義。不僅僅是存在於區塊鏈上的數字藝術品體驗，優質NFT會與實物對應（如藝術品本身、收藏品或其他現實存在的物品），有時也會與增強現實體驗緊密相連。有了ZHEN.上的優質NFT，您就可完備地探索所有權在各個維度上的可能性了。",
        },
        3: {
            title: "NFT",
            hover:"ZHEN. NFT 將您最喜愛的藝術家作品帶入數字領域。",
            remark:"一個 ZHEN.的 NFT將傳統的藝術所有權體驗領入了數字世界。通過對三維動畫動的忠實再現和在增強虛擬現實中的突破性重塑，在一個全新的維度上展示您最喜愛的藝術家的作品。ZHEN.的NFT不僅僅是普通的NFT作品，它更多的是一種新技術推動了數字藝術所有權的界限。",
        }
    },
    dashboard: {
        title:"交易看板",
        orders:"訂單歷史",
        transfer:"產權轉移歷史",
        withdraw:{
            title:"提取",
            eligible:'可提取的NFT',
            please:'為了提起申請，請您填寫以下信息。',
            selectPayment:'選擇支付方式',
            selectPaymentHolder:'選擇支付方式',
            payRemark1:'由於提取操作將需要礦工費等費用，我們對每筆交易收取相應的費用。若出現提取未完成的特殊情況，我們將退還已收取的這筆費用。',
            payRemark2:'當提取完成後，您的ZHEN.櫥窗將不再展示這個NFT。但若您之後進行轉入操作，則轉入成功後，櫥窗將恢復該NFT的展示。 ',
            agree:'已閱讀並同意',
            transactionFee:'手續費',
            noTransactionFee:'這是您購買此NFT之後的首次提取操作。為感謝您選擇ZHEN.，本次我們將免費為您服務，不收取任何費用。',
            successTitle: '提取申請已提交',
            successMsg: '提取將在3個工作日內完成。相關詳情將發送到您賬號預留的聯系方式上。您也可在提取記錄中看到您的本次申請。',
            withdrawn:'已提取',
            history: {
                viewDetails:'查看詳情',
                requestTime:'申請時間',
                withdrawTime:'完成時間',
                paymentDetails:'手續費詳情',
                paymentMethod:'支付方式',
                transactionId:'交易ID',
                total:'總金額',
                withdrawDetails:'提取詳情',
                contractAddress:'合約地址',
                serialNo:'序列號',
                txHash:'哈希',
                walletAddress:'錢包地址',
                outgoing:'提取中',
                failed:'提取失敗',
                failedMsg1:'您的提取申請沒有成功。',
                failedMsg2:'若您有需要，請再試一次。'
            }
        },
        deposit:{
            title:"轉入",
            notConnect:'請先連接錢包，方可進行轉入操作。',
            depositRemark1:'僅ZHEN.發行的NFT可轉入。',
            depositRemark2:'若要查詢您的NFT是否可被轉入，請點擊',
            depositRemark3:'進行轉入操作前，請先連接您的錢包。',
            here:'此處。',
            depositBtnTxt:'轉入',
            depositNFT:'轉入NFT',
            walletAddress:'錢包地址: ',
            depositNFTLabel:'僅ZHEN.發行的NFT可轉入。我們在您的錢包中找到了這些可以轉入的NFT。',
            contractAddress:'合約地址',
            contractAddressError:'請輸入正確的區塊鏈合約地址！',
            checkTitle:'查詢',
            checkRemarK:'僅ZHEN.發行的NFT可轉入。您於下方輸入您NFT的合約地址，查詢您的NFT是否可被轉入。',
            contractLabel:'NFT合約地址',
            contractInputHolder:'你的NFT合約地址',
            checkBtnText:'查詢',
            compatible:'您的NFT可以轉入',
            compatibleRemark:'您的NFT可以轉入ZHEN.。您可以於轉入面板中進行轉入操作。',
            notCompatible:'不可轉入',
            notCompatibleRemark:'此NFT不可轉入。',
            coming:'轉入中',
            submitTitle:'轉入申請已提交',
            submitRemark:'轉入的完成時間受鏈的當前狀態影響，因此我們不確定將於何時完成。但您可以隨時在轉入歷史中查看進展。',
            submitRemark1:'我們建議您不要再進行任何手工加速操作，因為這可能導致訂單發生變化，從而導致我們找不到您的轉入記錄。',
            checkStatus:'查看進展',
            helpMsg:'怎麼找NFT的合約地址？ ',
            notFound:'沒有發現NFT',
            notFoundRemark:'未找到可轉入的NFT。如果您剛剛將NFT轉入錢包，可能需要等待幾分鐘，它才會出現。另外，如果您非常確定您擁有可轉入的NFT，那麼請檢查您的錢包，看看當前是否選擇了正確的賬戶和網絡。',
            helpDeposit:'進行轉入操作前，您需要先將NFT添加顯示於您的錢包。如何才能在錢包裡顯示我的NFT？',
            depositDetails:'轉入詳情',
            depositTime:'完成時間',
            depositHistory:'轉入歷史',
            failedMsg1:'您的轉入申請沒有成功。',
            noDepositHistory: '您沒有轉入歷史。'
        },
        transferHistory: {
            transferState:{
                0: { text: "進行中", className: "pending" },
                1: { text: "待確認"},
                2: { text: "審核通過", className: "approved" },
                3: { text: "審核駁回", className: "rejected" },
                4: { text: "取消", className: "canceled" },
            }
        }
    }

}