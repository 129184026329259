<template>
  <div class="download">
    <div class="box">
      <div class="top bg-white" style="border-bottom:1px solid #000000;">
        <AppNavigator/>
      </div>
      <div class="main">
             <div style="display:flex;justify-content: space-between;margin-top: 80px;">
                <div style="flex:2;text-align:left">
                    <div>
                        <!-- <div style="font-weight: 400;font-size: 14px;color: #000000;">{{ $t('lang.download.title') }}</div> -->
                        <div style="margin-top:10px;line-height:55px;font-size:50px;" v-html="$t('lang.download.content')"></div>
                        <div style="margin-top:20px;font-weight:400;line-height:24px;" v-html="$t('lang.download.content2') "></div>
                    </div>
                    <div style="margin-top:20px;display:flex;justify-content: flex-start;">
                        <div>
                          <el-button  @click="goToDownApple" style="width: 140px !important;background-color:#000000 !important;padding:0px;color: #fff;" round>{{ $t('lang.download.ios') }}</el-button>
                        </div>
                        <div style="margin-left:30px;">
                          <el-button  @click="goToDownAndroid" style="width: 140px !important;background-color:#f4f4f4 !important;color: #000000;padding:0px;" round> {{ $t('lang.download.android') }}</el-button>
                        </div>
                    </div>
                </div>
                <!-- <div style="flex:1;text-align:right;position:relative;">
                  <img style="width:60%;position: absolute;top: 0; right: -40%;" src="../../assets/Frontpage/Download 1.png" alt="">
                  <img style="width:60%;margin-right:20%;" src="../../assets/Frontpage/Download 1.png" alt="">
                </div> -->
             </div>
             <div style="display: flex;justify-content: space-between;margin-top:100px;">
               <div style="flex:1;position: relative;">
                <img style="width:50%;" src="../../assets/Frontpage/Download 5.png" alt="">
                <img style="width:50%;position: absolute;top: 16%; left: 35%;" src="../../assets/Frontpage/Download 6.png" alt="">
               </div>
               <div style="flex:1;text-align:left;padding-left: 20px;">
                 <div style="font-size:21px;font-weight: 400;color: #000000;margin-top:18%;" v-html="$t('lang.download.contentList.content')"></div>
                 <div v-for="(data,ti) in $t('lang.download.contentList.list')" :key="ti">
                      <div style="margin:40px 10px 0 0;font-size:14px;font-weight: 400;color: #000000;">{{ data.ask }}</div>
                      <div  style="font-size:14px;font-weight: 400;color: #000000;" v-html="data.que"></div>
                 </div>        
               </div>
             </div>
      </div>
      <div style="width: 100%;height:200px;background-color:#000000;">
          <div style=" box-sizing: border-box;padding-top: 60px;text-align:center;font-weight: 400;color: #FFFFFF;font-size:21px;">{{ $t('lang.manual.help') }}</div>
          <div style=" box-sizing: border-box;margin-top: 20px;display: flex; justify-content: center">
               <div>
                  <el-button @click="goToFaq" round style="font-weight: 400;color: #000000;">{{ $t('lang.footer.faq.title') }}</el-button>
               </div>
               <div style="margin-left: 20px;">
                  <el-button @click="goToSupport" round style="font-weight: 400;color: #000000;">{{ $t('lang.footer.support.menus') }}</el-button>
               </div>
          </div>
      </div>  
      <div class="footer">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue"
import AppFooter from "@/components/common/AppFooter.vue"
export default {
  name: "Download",
  components: {
    AppNavigator,
    AppFooter,
  },
  data(){
    return {
    }
  },
  methods: {
     goToFaq(){
        this.$router.push("/faq");
     },
     goToSupport(){
        this.$router.push("/support");
     },
     goToDownAndroid(){
       this.utils.logEvent('DownloadAndroidClickEvent');
       window.open('https://play.google.com/store/apps/details?id=com.gn.zhen')
     },
     goToDownApple(){
       this.utils.logEvent('DownloadIosClickEvent');
       window.open('https://apps.apple.com/app/id1526634198')
     }

  },
  mounted(){
    this.utils.logEvent('DownloadAppPageViewEvent');
  }
};
</script>

<style lang="less" scoped>
.download {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: #000000;
  overflow: hidden;
   .el-button--info {
      background-color: #f4f4f4 !important;
      font-weight: 400;
      color: #000000;
      border: none !important;
      width: 160px !important;
    }
    .el-button {
      background-color: #f4f4f4 !important;
      border: none !important;
      width: 140px !important;
      height: 25px;
      padding: 0;
      font-size: 14px;
    }


  .box {
    .top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      // backdrop-filter: saturate(180%) blur(30px) opacity(80%);
    }
    .middle {
       margin-top: 40px;
       width: 100%;
       height: 1px;
       border-top:0.1em solid  #000000;
    }
    .main {
      width: 86%;
      height: 100%;
      box-sizing: border-box;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 280px;
      .main_box {
        width: 86%;
        margin: 0 auto;
        margin-top: 80px;  
        display: flex;
        justify-content: space-between;     
       
      }
    }
    .footer {
     width: 100%;
      box-sizing: border-box;
      //position: fixed;
      bottom: 0px;
    }
  }
}
</style>
