<template>
    <div class="authenticator-panel">
      <el-dialog
        center
        width="360px"
        :show-close="false"
        custom-class="down-authenticator-dialog"
        :visible.sync="dialogVisible1"
        :title="$t('lang.myprofile.twofa.downDialogTitle')"
        :close-on-click-modal="false">
        <div class="content">
          <div>
            {{ $t("lang.myprofile.twofa.downDialogContent") }}
          </div>
          <div style="text-align: center; margin-top: 20px">
            <div>
              <el-button
                class="btn"
                :showloading="showloading1"
                @click="showBind()"
                style="width: 160px;">{{ $t("lang.myprofile.twofa.downDialogBtn1") }}</el-button>
            </div>
            <el-button
              @click="downloadClose"
              round
              style="
                width: 200px;
                border: none;
                background-color: #ffffff;
                color: #bcbcbc;
                opacity: 1;
                padding: 10px !important;
              ">{{ $t("lang.myprofile.twofa.downDialogBtn2") }}</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        center
        width="420px"
        :show-close="false"
        custom-class="bind-dialog"
        :visible.sync="dialogVisible2"
        :close-on-click-modal="false">
        <div slot="title" style="text-align:left;">
            {{$t('lang.myprofile.twofa.bindTitle')}}
        </div>
        <div class="content">
          <div style="color:#bcbcbc;font-size:12px;">
            {{ $t("lang.myprofile.twofa.bindRemark") }}
          </div>
          <div style="color:#bcbcbc;font-size:12px;margin-top:5px;">
            {{ $t("lang.myprofile.twofa.bindRemark1") }}
          </div>
          <div style="color:#bcbcbc;font-size:12px;margin-top:5px;">
            {{ $t("lang.myprofile.twofa.bindRemark2") }}
          </div>
          <div style="text-align:center;padding:15px 0;">
              <img :src="qrcode" class="scan-img">
          </div>
          <div class="copy-panel">
              <div style="flex:1;">{{secretCode}}</div>
              <div>
                <el-button class="btn" 
                v-clipboard:copy="secretCode"
                v-clipboard:success="copyClick"
                v-clipboard:error="onCopyError" 
                style="width:120px;">{{ $t("lang.wallet.copyLabel") }}</el-button>
              </div>
          </div>
          <div style="text-align: center; margin-top: 20px">
            <div>
              <el-button
                class="btn"
                @click="showValid(1)"
                style="width:100%;">{{ $t("lang.myprofile.twofa.continue") }}</el-button>
            </div>
            <el-button
              @click="dialogVisible2 = false"
              round
              style="
                width: 200px;
                border: none;
                background-color: #ffffff;
                color: #bcbcbc;
                opacity: 1;
                padding: 10px !important;
              ">{{ $t("lang.wallet.close") }}</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        center
        width="360px"
        :show-close="false"
        custom-class="valid-dialog"
        :visible.sync="dialogVisible3"
        :close-on-click-modal="false">
        <div slot="title" style="text-align:left;">
            {{$t('lang.myprofile.twofa.authenticator')}}
        </div>
        <div class="content">
          <div style="color:#bcbcbc;font-size:12px;">
            {{ $t("lang.myprofile.twofa.enterCode") }}
          </div>
          <div style="margin-top:30px;" :class="{'valid-error':validError}">
            <div style="display:flex;justify-content: space-between;">
              <el-input v-model="code1" ref="input1" class="code-input" :readonly="activeIndex!=1" maxlength="1" @input="codeInput(1)"/>
              <el-input v-model="code2" ref="input2" class="code-input" :readonly="activeIndex!=2" maxlength="1" @input="codeInput(2)"/>
              <el-input v-model="code3" ref="input3" class="code-input" :readonly="activeIndex!=3" maxlength="1" @input="codeInput(3)"/>
              <el-input v-model="code4" ref="input4" class="code-input" :readonly="activeIndex!=4" maxlength="1" @input="codeInput(4)"/>
              <el-input v-model="code5" ref="input5" class="code-input" :readonly="activeIndex!=5" maxlength="1" @input="codeInput(5)"/>
              <el-input v-model="code6" ref="input6" class="code-input" :readonly="activeIndex!=6" maxlength="1" @input="codeInput(6)"/>
            </div>
            <div class="error-panel">{{errorMsg}}</div>
          </div>
          <div style="text-align:center;margin-top:20px;">
            <div>
              <el-button
                class="btn"
                :showloading="showloading2"
                @click="confirmCode"
                :disabled="!isFull"
                style="width:100%;">{{ $t("lang.myprofile.twofa.confirm") }}</el-button>
            </div>
            <el-button
              @click="closeValidDialog"
              round
              style="
                width: 200px;
                border: none;
                background-color: #ffffff;
                color: #bcbcbc;
                opacity: 1;
                padding: 10px !important;
              ">{{ $t("lang.wallet.close") }}</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        center
        width="360px"
        :show-close="false"
        custom-class="unbind-dialog"
        :visible.sync="dialogVisible4"
        :close-on-click-modal="false">
        <div slot="title">
            {{$t('lang.myprofile.twofa.unbindTitle')}}
        </div>
        <div class="content">
          <div style="text-align:center;">
            {{ $t("lang.myprofile.twofa.unbindContent") }}
          </div>
          <div style="text-align:center;margin-top:20px;">
            <div>
              <el-button
                class="btn"
                @click="unbindClick"
                style="width:160px;">{{ $t("lang.myprofile.twofa.unbindAlertBtn") }}</el-button>
            </div>
            <el-button
              @click="dialogVisible4 = false"
              round
              style="
                width: 200px;
                border: none;
                background-color: #ffffff;
                color: #bcbcbc;
                opacity: 1;
                padding: 10px !important;
              ">{{ $t("lang.wallet.close") }}</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
export default {
  name: "Authenticator",
  components: {
  },
  data() {
    return {
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4:false,
      code1:'',
      code2:'',
      code3:'',
      code4:'',
      code5:'',
      code6:'',
      activeIndex:1,
      isFull:false,
      secretCode:'',
      qrcode:'',
      //验证状态：1:绑定 2:解绑 3：验证 4：开启关闭
      validState:1,
      //开启关闭回调
      callback:'',
      validError:false,
      errorMsg:'',
      showloading1:false,
      showloading2:false
    };
  },
  methods: {
    codeInput(index) {
      this.errorMsg = '';
      this.validError = false;
      this['code'+index] = this['code'+index].replace(/[^0-9]/g, '');
      //判断光标位置
      if(this['code'+index] && index < 6) {
        if(!this['code'+(index+1)]) {
            this.activeIndex = index+1;
            this.$refs['input'+(index+1)].focus();
        }
      }else {
        this.$refs['input6'].blur(); 
      }
      //检查是否输入完成
      this.checkFull();
    },
    checkFull() {
      //判断是否全部输入
      let flag = true;
      for(let i = 1;i < 7; i++) {
        if(!this['code'+i]) {
          flag = false;  
        }
      }
      if(flag) {
        this.isFull = true;
      } else {
        this.isFull = false;
      }
    },
    showDownload(closeDownloadFunc) {
      //关闭下载框的回调
      this.closeDownloadFunc = closeDownloadFunc;
      this.dialogVisible1 = true;
    },
    downloadClose() {
      this.dialogVisible1 = false;
      //产权转移的关闭需要弹出产权转移的框
      if(this.closeDownloadFunc) {
        this.closeDownloadFunc();
      }
    },
    showBind() {
      let _this = this;
      this.showloading1 = true;
      return new Promise((resolve, reject)=> {
        _this.$http
        .post("googleAuthenticator/binding", { token: _this.utils.getToken()})
        .then((res) => {
          _this.showloading1 = false;
          if (res.code === 0) {
            let data = res.data;
            _this.qrcode = data.qrcode;
            _this.secretCode = data.secretCode;
            //如果下载框存在则关闭
            if(_this.dialogVisible1) {
              _this.dialogVisible1 = false;
            }
            _this.dialogVisible2 = true;
            resolve();
          }
        });
      })
    },
    showValid(state) {
      //如果有传验证状态更新验证状态
      if(state) {
        this.validState = state;
      }
      //清空
      this.clearCode();
      //显示dialog
      this.dialogVisible3 = true;
      //设置首个code框获得焦点
      let _this = this;
      setTimeout(function() {
        _this.$refs['input1'].focus();
      },500);
    },
    closeValidDialog() {
      this.dialogVisible3 = false;
    },
    clearCode() {
      //清空
      for(let i=1;i < 7;i++) {
        this['code'+i] = "";
      }
      this.errorMsg = '';
      this.validError = false;
      this.activeIndex = 1;
    },
    copyClick() {
      const title = this.$t("lang.myprofile.twofa.copySuccessTitle");
      const content = this.$t("lang.myprofile.twofa.copySuccess");
      this.utils.alert(title, content, {
        autoClose: false,
      });
    },
    onCopyError() {
    },
    confirmCode() {
      this.showloading2 = true;
      //拼接code
      let code = '',url = 'googleAuthenticator/verification';
      for(let i=1;i < 7;i++) {
        code+=this['code'+i];
      }
      //请求参数
      let param = { token: this.utils.getToken(),code:code};
      //如果是绑定添加参数
      if(this.validState == 1) {
        param.isBinding = 1;
      }
      //发送验证请求
      this.$http
      .post(url, param)
      .then((res) => {
        if (res.code === 0) {
          let data = res.data;
          if(data == 1) {
            this.dialogVisible3 = false;
            if(this.validState == 1) {
              //调用父类定义的绑定成功方法
              if(this.$parent.bindSuccess) {
                this.$parent.bindSuccess();
              }else {
                console.log(new Error('未在父组件找到绑定成功的回调函数bindSuccess'));
              }
            }else if(this.validState == 2) {
              //调用父类定义的解绑成功方法
              if(this.$parent.unbindSuccess) {
                this.$parent.unbindSuccess();
              }else {
                console.log(new Error('未在父组件找到解除绑定成功的回调函数unbindSuccess'));
              }
            }else if(this.validState == 3) {
              //调用父类定义的验证成功方法
              if(this.$parent.validSuccess) {
                this.$parent.validSuccess();
              }else {
                console.log(new Error('未在父组件找到验证成功的回调函数validSuccess'));
              }
            }else if(this.validState == 4) {
              //调用父类定义的开启关闭成功方法
              if(this.$parent.openAndCloseSuccess) {
                this.$parent.openAndCloseSuccess();
              }else {
                console.log(new Error('未在父组件找到开启关闭成功的回调函数openAndCloseSuccess'));
              }
            }
          }else {
            this.validError = true;
            this.errorMsg = this.$t("lang.myprofile.twofa.errorMsg");
          }
          this.showloading2 = false;
        }
      });
    },
    showUnbind() {
      this.dialogVisible4 = true;
    },
    unbindClick() {
      this.validState = 2;
      this.dialogVisible4 = false;
      this.showValid();
    },
    documentKeyup(event) {
      if(event.keyCode == 8) {
        if(this.dialogVisible3) {
          let index = 1;
          for(let i=7;i > 0;i--) {
            if(this['code'+i]) {
              index = i;
              break;
            }
          }
          this.activeIndex = index;
          this['code'+index] = '';
          this.$refs['input'+index].focus(); 
          //检查是否输入完成
          this.checkFull();
        }
      }
    }
  },
  created() {
    // 监听窗口变化，使得轮播图高度自适应图片高度
    window.addEventListener("keyup",this.documentKeyup, false);
  },
  mounted() {
  },
  destroyed() {
    this.utils.logEvent("ProductInfoPageViewEvent", {
      time: new Date() - this.date,
    });
    //解绑事件
    window.removeEventListener("keyup",this.documentKeyup, false);
  }
};
</script>
<style lang="less">
.authenticator-panel {
  .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*height:600px;*/
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
    border-radius: 10px;
    color: #000000;
    .content {
      font-size: 14px;
    }
    .el-dialog__headerbtn {
      display: none;
    }
    .el-dialog__body {
      padding: 0 20px 20px 20px !important;
    }
    .btn {
      background-color: #000000;
      color: #ffffff;
      height: 25px;
      line-height: 25px;
      padding: 0;
      margin: 0;
      width: 160px;
      border-radius: 13px;
    }
    .btn:disabled {
      background-color: #bcbcbc;
      color: #ffffff;
    }
    .scan-img {
      width: 50%;
    }
    .copy-panel {
      display: flex;
      height: 40px;
      line-height: 40px;
      border-top: 1.5px solid #bcbcbc;
      border-bottom: 1.5px solid #bcbcbc;
    }
    .code-input {
      width: 40px;
    }
    .code-input input{
      width:40px;
      height:40px;
      line-height:40px;
      border:1px solid #bcbcbc;
      text-align: center;
      padding-bottom: 0 !important;
    }
    .valid-error input{
      color: #ff0000 !important;
    }
    .error-panel {
      height: 20px;
      margin-top: 20px;
      text-align: center;
      color: #ff0000 !important;
    }
  }
}
</style>