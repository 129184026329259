<template>
  <div class="connect-wallet">
       <el-dialog
        center
        width="360px"
        custom-class="metamask_dialog"
        :visible.sync="dialogVisible1"
        :title="$t('lang.myMarkPlace.dialogTitle1')"
        :close-on-click-modal="false"
      >
        <div style="padding: 0 30px">
          <div style="text-align: center"></div>
          <div style="text-align: center; margin-top: 10px">
            <el-button class="metamask_btn" @click="connectMetamask()">
              MetaMask
            </el-button>
          </div>
          <div style="margin-top: 20px; font-size: 12px; text-align: center">
            {{ $t("lang.myMarkPlace.contentText3") }}
            <span @click="goToLegal(2)" style="color: #ff7a00; cursor: pointer"
              >{{ $t("lang.myMarkPlace.label1") }}
            </span>
            {{ $t("lang.myMarkPlace.label3") }}
            <span
              @click="goToLegal(1)"
              style="color: #ff7a00; cursor: pointer"
              >{{ $t("lang.myMarkPlace.label2") }}</span
            >
          </div>
        </div>
      </el-dialog>
      <el-dialog
        center
        width="360px"
        :show-close="false"
        custom-class="install_metamask_dialog"
        :visible.sync="dialogVisible2"
        :close-on-click-modal="false"
      >
        <div style="color: #000000; font-size: 16px">
          <div style="text-align: center">
            {{ $t("lang.myMarkPlace.installText1") }} METAMASK
          </div>
          <div style="margin-top: 10px; text-align: center; font-size: 14px">
            {{ $t("lang.myMarkPlace.contentText4") }}
          </div>
          <div style="text-align: center; margin-top: 10px">
            <el-button class="install_metamask_btn" @click="installMetamask()">
              {{ $t("lang.myMarkPlace.installText") }} MetaMask
            </el-button>
          </div>
          <div style="text-align: center; margin-top: 5px">
            <el-button
              @click="dialogVisible2 = false"
              round
              style="
                width: 200px;
                border: none;
                background-color: #ffffff;
                color: #bcbcbc;
                opacity: 1;
                padding: 10px !important;
              "
              >{{ $t("lang.myMarkPlace.cancel") }}</el-button
            >
          </div>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import obj from "../../common/utils";
export default {
  name: "ConnectWallet",
  components: {
  },
  data() {
    return {
      dialogVisible1:false,
      dialogVisible2:false,
    };
  },
  methods: {
    //连接钱包插件
    connectMetamask() {
      let _this = this;
      //连接钱包
      obj.connectWallet().then(() => {
        //再次判断是否确认已连接metamask,并保存了数据
        if (localStorage.getItem("from")) {
          _this.dialogVisible1 = false;
          _this.$parent.connectSuccess();
        } else {
        }
      });
    },
    installMetamask() {
      window.open("https://metamask.io/download.html");
      location.reload();
    },
    goToLegal(index) {
      let param = {
        name: "Privacy",
      };
      if (index == 2) {
        param = {
          name: "Terms",
        };
      }
      const { href } = this.$router.resolve(param);
      window.open(href, "_blank");
    },
    showDialog(type) {
      if(type == 1) {
        this.dialogVisible1 = true;
      }else if(type == 2) {
        this.dialogVisible2 = true;
      }
    }
  },
  created() {
  },
};
</script>
<style lang="less">
.connect-wallet {
  .el-dialog {
      display: flex;
      flex-direction: column;
      margin: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /*height:600px;*/
      max-height: calc(100% - 30px);
      max-width: calc(100% - 30px);
      border-radius: 10px;
    }
    .metamask_btn {
      width: 160px;
      height: 25px;
      padding: 0 18px 0 0 !important;
      text-align: center;
      border-radius: 15px;
      border: none !important;
      color: #fff;
      background: #000000 url("../../assets/Frontpage/mm-logo.png") no-repeat;
      background-position: 110px 5px;
      background-size: 18px;
      cursor: pointer;
    }

    .install_metamask_btn {
      width: 160px;
      height: 25px;
      padding: 0 !important;
      text-align: center;
      border-radius: 13px;
      border: none !important;
      color: #fff;
      background-color: #000000;
      margin-top: 10px;
    }

    .install_metamask_btn:hover,
    .install_metamask_btn:focus,
    .metamask_btn:hover,
    .metamask_btn:focus {
      background-color: #000000;
      color: #fff;
    }

    .metamask_dialog,
    .install_metamask_dialog,
    .cancel_dialog {
      border-radius: 15px;
    }

    .el-dialog .el-dialog__body {
      flex: 1;
      overflow: auto;
    }

    .metamask_dialog .el-dialog__body {
      padding-top: 0px !important;
    }
    .install_metamask_dialog .el-dialog__header,
    .confirm_ownership_dialog .el-dialog__header,
    .wallet_code_dialog .el-dialog__header,
    .cancel_dialog .el-dialog__header {
      display: none;
    }
    .el-dialog__headerbtn {
      display: none;
    }
}

</style>

