<template>
  <div class="secondNavigator">
    <div class="secondNavigator_box">
      <div class="secondNavigator_main">
        <div style="flex: 1;text-align: center;position: relative;" >
          <el-button type="text" v-for="(item, index) in list" :key="index"  :class="activeIndex==index?'secondTitle btn_active':'secondTitle'" @click="goToWeb(index)" style="color: #000000; opacity: 1;margin: 0 20px;">
            {{ item.title }}
          </el-button>
          <div
            style="
              color: #bcbcbc;
              opacity: 1;
              text-align: right;
              flex: 4;
              cursor: pointer;
              position: absolute;
              top: 0;
              right: 0;
              font-size:14px;
            "
            @click="loginOut()">
            {{ $t("lang.profile.loginOut") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecondNavigator",
  data() {
    return {
      list: [
        { title: this.$t("lang.cabinet.title2") },
        { title: this.$t("lang.dashboard.title") },
        { title: this.$t("lang.myprofile.title2") },
      ],
    };
  },
  props:["activeIndex"],
  methods: {
    goToWeb(index) {
      switch (index) {
        case 0:
          this.$router.push({
            path: "/myCabinet",
          });
          break;
        case 1:
          this.$router.push({
            path: "/orders",
          });
          break;
        case 2:
          this.$router.push({
            path: "/profile",
          });
          break;
      }
    },
    loginOut() {
      localStorage.removeItem("token");
      // localStorage.removeItem("lang");
      localStorage.removeItem("chainId");
      localStorage.removeItem("from");
      localStorage.removeItem("profilePhoto");
      localStorage.removeItem("credential");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("productInfo");
      sessionStorage.removeItem("shipping");
      sessionStorage.removeItem("surpriseCode");
      sessionStorage.removeItem("surpriseCode");
      this.$router.push({
        path: "/login",
      });
    },
  }
};
</script>

<style lang="less" scoped>
.secondNavigator {
  width: 100%;
  backdrop-filter: saturate(180%) blur(30px);
  .secondNavigator_box {
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
    margin: 0 auto;
    .secondNavigator_main {
      width: 86%;
      box-sizing: border-box;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .secondTitle.btn_active {
        font-weight: bold !important;
      }
    }
  }
}
// .setColor {
//     font-weight: bold;
//     font-size:24px;
// }
// .setColor2 {
//     font-weight: bold;
//     font-size:12px;
//     color: red;
// }
</style>