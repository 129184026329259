<template>
  <div class="marketPlace">
    <div class="market_box">
      <div class="market_top bg-white">
        <AppNavigator/>
      </div>
      <div class="market_main">
        <div style="width: 100%">
          <div class="main-flex">
            <div class="flex-left">
              <div class="left-title" @click="jump(banner.activityId,banner.waresId,banner.activitySubId,1)">
                {{ banner.title }}
              </div>
              <div class="left-subtitle" @click="jump(banner.activityId,banner.waresId,banner.activitySubId,1)">{{banner.subTitle}}</div>
              <div class="left-description" v-html="banner.description" @click="jump(banner.activityId,banner.waresId,banner.activitySubId,1)"></div>
              <div class="left-shop-panel">
                <el-button type="text" class="shop-now-btn" @click="jump(banner.activityId,banner.waresId,banner.activitySubId,1)">
                  {{ $t("lang.myMarkPlace.shopNow") }}</el-button>
              </div>
            </div>
            <div class="flex-right">
              <div class="banner-img-panel" :style="{minHeight:bannerHeight+'px'}">
                 <img :src="banner.imgUrl" class="banner-img" @click="jump(banner.activityId,banner.waresId,banner.activitySubId,1)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
         <div class="split-line"></div>
      </div>
      <div class="activity-panel">
        <div class="activity-content">
          <div class="content-top">
            <div style="flex:1;">{{ $t("lang.myMarkPlace.dropsCalendar") }}</div>
            <div :class="['switch-panel',switchClass]">
              <span @click="changeImage(1)" style="margin-right:80px;cursor: pointer;"><i class="el-icon-arrow-left"></i></span>
              <span @click="changeImage(2)" style="cursor: pointer;"><i class="el-icon-arrow-right"></i></span>
            </div>
          </div>
          <div :class="['setImg',paddingType]" :style="{height:(imgHeight>0?imgHeight:800)+'px'}">
            <vuescroll :ops="ops" ref="vscroll" @handle-scroll="handleScroll" style="width:100%;color:#000000;">
                <div
                v-for="(item, index) in market.listActivity"
                :key="index"
                class="img_box" :id="'box'+index" ref="imgBox" :data-index="index" :style="{width:panelWidth+'px'}">
                <div style="padding: 25px 0 10px 0;font-size:14px;">
                  <div v-if="item.state!=1">
                  <span>{{ item.startDate }}</span><span style="margin-left: 30px">{{ item.startTime }}</span></div>
                  <div v-if="item.state==1"><span>{{$t('lang.myMarkPlace.comingsoon')}}</span></div>
                </div>
                <div class="update" @click.stop="jump(item.activityId,item.waresId,item.activitySubId,2,item.state)">
                  <img @load="imgLoad(index)" :src="item.imgUrl"
                    style="width: 100%;cursor:pointer;" draggable="false" @mousedown.stop="mousedownEvent"/>
                </div>
                <div style="
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;
                    align-items: center;"
                  class="isShowSubscription">
                  <div style="font-size:14px;">
                    <div v-if="item.state!=1" style="line-height:17px;">{{ item.title}}</div>
                    <div v-if="item.state!=1" style="line-height:17px;">{{ item.subTitle }}</div>
                    <div v-if="item.state==1" style="line-height:17px;color:#fff;">common soon</div>
                    <div v-if="item.state==1" style="line-height:17px;color:#fff;">common soon</div>
                  </div>
                  <!-- <div>
                    <el-button
                      type="text"
                      round
                      class="notify"
                      v-show="item.subscription == 0"
                      @click="getUserInfo(item)"
                    >
                      {{ $t("lang.myMarkPlace.notifyMe") }}</el-button
                    >
                    <el-button
                      type="text"
                      round
                      class="subscription"
                      v-show="item.subscription == 2"
                      disabled
                    >
                      {{ $t("lang.myMarkPlace.notifyMe") }}</el-button
                    >
                  </div> -->
                </div>
                </div>
            </vuescroll>
          </div>
        </div>
      </div>

      <div class="market_footer">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
import vuescroll from 'vuescroll'
export default {
  name: "marketPlace",
  components: {
    AppNavigator,
    AppFooter,
    vuescroll,
  },
  data() {
    return {
      market: {},
      banner: {},
      bannerHeight:0,
      panelWidth:0,
      imgHeight:0,
      imgWidth:0,
      isShow: false,
      isLoad:false,
      scrollLeft:0,
      switchClass:'start',
      paddingType:'padding_left',
      ops: {
        // vuescroll: {
        //   sizeStrategy: 'number',
        // },
        scrollPanel: {
          // 是否禁止x或y方向上的滚动
          scrollingX: true,
          scrollingY: false,
        },
        bar: {
          background: '#000000',
          keepShow: true,
          size: '3px',
          minSize: false,
          specifyBorderRadius:'10px',
          opacity: 1
        },
        rail: {
          opacity:0.7,
          background: '#000000',
          // border: '1px solid #fff',
          size: '1px',
          gutterOfSide: '0px',
          gutterOfEnds:(document.body.clientWidth*0.07)+'px'
        }
      }
    };
  },
  methods: {
    showAll() {},
    mousedownEvent(ev) {
      const _this = this;
      const {scrollTop, scrollLeft} = _this.$refs["vscroll"].getPosition();
      const disX = ev.clientX;
      const sl = scrollLeft;
      const el = _this.$refs["vscroll"].$el;
      el.onmousemove = function (ev) {
        const distanceX = ev.clientX - disX;
        _this.$refs["vscroll"].scrollTo({x:sl - distanceX},500);
      }
      el.onmouseup = function (ev) {
        el.onmousemove = null;
        el.onmouseup = null;
        if(disX != ev.clientX) {
          _this.ismove = true;
        }else {
          _this.ismove = false;
        }
      }
    },
    jump(id,waresId,activitySubId,type,state) {
      if(this.ismove && type == 2 || state == 1) {
        return;
      }
      //有商品id 直接跳详情
      if(waresId) {
        this.$router.push({path:"/product",query:{waresId:waresId,activitySubId:activitySubId}});
      }else {
        this.$router.push({
          path: "/collection",
          query: { activityId: id },
        });
      }
    },
    notifyMe(item) {
      if (!this.utils.getToken()) {
        this.$store.state.notLoginDialog = true;
        return;
      }
      //通知
      this.$http
        .post(
          "message/subscribe",
          this.qs.stringify({
            token: this.utils.getToken(),
            type: 2,
            activityId: item.activityId,
          })
        )
        .then((res) => {
          if (res.code === 0) {
            let title = this.$t("lang.register.note");
            let text = this.$t("lang.myMarkPlace.alertText1");
            this.utils
              .alert(title, text)
              .then(() => {
                item.subscription = 2;
              });
          }
        });
    },
    getUserInfo(item) {
      let $this = this;
      //获取用户信息
      $this.$http
        .post("user/info", { token: $this.utils.getToken() })
        .then((res) => {
          if (res.code === 0) {
            let data = res.data;
            if(data.email) {
                $this.notifyMe(item);
            }else {
              let title = this.$t("lang.register.note");
              let text = this.$t("lang.myMarkPlace.alertText2");
              $this.utils.alert(title, text);
            }
          }
      });
    },
    getMarketList() {
      let $this = this;
      //市场首页列表
      this.$http
        .post(
          "market/index",
          this.qs.stringify({ token: this.utils.getToken() })
        )
        .then((res) => {
          if (res.code === 0) {
            this.market = res.data;
            this.banner = res.data.listBanner[0];
          }
        });
    },
    imgLoad(index) {
      let $this = this;
      if(!$this.isLoad) {
        $this.isLoad = true;
        let panelHeight = $this.$refs.imgBox[index].clientHeight;
        let panelWidth = $this.$refs.imgBox[index].clientWidth;
        //设置宽高防止图片加载慢时组件无法初始化宽高
        $this.imgHeight = panelHeight;
        $this.imgWidth = panelWidth;
        //设置滚动条插件的位置
        $this.ops.rail.gutterOfSide = panelHeight-15+'px';
        //初始化左边对齐   右边填满
        $this.$refs.imgBox[0].style.marginLeft = Math.floor(document.body.clientWidth*0.07)+'px';
        $this.$refs["vscroll"].refresh();
      }
    },
    handleScroll(vertical, horizontal, nativeEvent) {
      let last = this.market.listActivity.length;
      const process = this.$refs["vscroll"].getScrollProcess();
      if(process.h == 0) {
        this.switchClass = 'start';
        this.$refs.imgBox[last-1].style.marginRight = '0px';
        this.$refs.imgBox[0].style.marginLeft = Math.floor(document.body.clientWidth*0.07)+'px';
      }else if(process.h > 0.9) {
        this.switchClass = 'end';
        this.$refs.imgBox[0].style.marginLeft = '0px';
        this.$refs.imgBox[last-1].style.marginRight = Math.floor(document.body.clientWidth*0.07)+'px';
      }
    },
    changeImage(type) {
      //获取当前已滚动距离
      const {scrollTop, scrollLeft} = this.$refs["vscroll"].getPosition();
      //获取滚动到下一张图片的所需的偏移量
      let offset = this.getOffset(type);
      if(type == 1) {
        //滚动到上一张
        this.$refs["vscroll"].scrollTo({x:(scrollLeft-offset)},500);
      }else if(type == 2) {
        //滚动到下一张
        this.$refs["vscroll"].scrollTo({x:(scrollLeft+offset)},500);
      }
    },
    //获取元素偏移量
    getOffset(type) {
      //获取当前可视元素列表
      const boxList = this.$refs["vscroll"].getCurrentviewDom();
      const last = this.market.listActivity.length;
      let offset = 0;
      //左移
      if(type == 1) {
        //获取最后一个可视元素的偏移量
        let el = boxList[boxList.length-1].getBoundingClientRect();
        let marginLeft = 0;
        if(boxList[0].dataset.index == 1) {
          marginLeft = Math.floor(document.body.clientWidth*0.07);
        }
        //需要滚动的距离就等于当前文档宽度-左偏移量-右边的margin值
        offset = document.body.clientWidth - el.left - marginLeft;
      }
      //右移
      else if(type == 2) {
        //获取第一个可视元素的偏移量
        let el = boxList[0].getBoundingClientRect();
        //获取第一个可视元素的margin值
        let marginLeft = 0;
        if(boxList[boxList.length-1].dataset.index == last-2) {
          marginLeft = Math.floor(document.body.clientWidth*0.07);
        }
        //需要滚动的距离就等于左偏移量-左边的margin值
        offset = el.right+5-marginLeft;
      }
      return offset;
    },
    resizeEvent() {
      window.location.reload();
    }
  },
  created() {
    this.panelWidth = Math.floor(document.body.clientWidth*0.43-25);
    this.bannerHeight = (document.body.clientWidth/2)/1.25-10;
    this.getMarketList();
    // 监听窗口变化
    window.addEventListener("resize", this.resizeEvent, false);
  },
  destroyed() {
    //解绑事件
    window.removeEventListener("resize", this.resizeEvent, false);
  }
};
</script>

<style lang="less" scoped>
.marketPlace {
  width: 100%;
  height: 100%;
  .setImg {
    width: 100%;
    white-space: nowrap;
    box-sizing: border-box;
  }
  .market_box {
    width: 100%;
    box-sizing: border-box;
    .market_top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
    }

    .market_main {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      /* margin-left: 7%; */
      margin-top: 41.5px;
      .main-flex {
        width: 100%;
        display: flex;
        align-items: flex-end;
        .flex-left {
          flex: 1;
          color: #000000;
          padding-bottom: 100px;
          .left-title {
            font-size: 50px;
            font-weight:400;
            line-height:50px;
            padding:0 40px 0 14%;
            cursor: pointer;
          }
          .left-subtitle {
            font-size: 50px;
            font-weight:400;
            line-height:50px;
            padding:0 40px 0 14%;
            cursor: pointer;
          }
          .left-description {
            margin-top: 20px;
            font-size:14px;
            padding:0 40px 0 14%;
            cursor: pointer;
          }
          .left-shop-panel {
            margin-top: 20px;
            padding:0 40px 0 14%;
            .shop-now-btn {
              background-color: #f4f4f4 !important;
              border-radius: 13px !important;
              font-size: 14px !important;
              color: #000000 !important;
              padding:0 !important;
              width:140px !important;
              height:25px !important;
            }
          }
        }
        .flex-right {
          flex: 1;
          .banner-img-panel {
              width: 100%;
          }
          .banner-img {
            width: 100%;
            display: block;
            cursor: pointer;
          } 
        }
      }
    }
    .split-line {
      width: 100%;
      height: 1px;
      background-color: #000000;
    }
    .activity-panel {
      width: 100%;
      margin-bottom: 150px;
      .activity-content {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        .content-top {
          margin:30px 7% 20px 7%;
          display:flex;
          font-size:14px;
          color: #000000;
        }
      }
    }
    .img_box {
      color: #000000;
      margin: 0 40px 0 0;
      display: inline-block;
    }

    .img_box:last-child {
      margin-right: 0;
    }
    /* .padding_left .img_box:nth-child(2){
      margin-left: 7% !important;
    }
    .padding_right .img_box:last-child{
      margin-right: 7% !important;
    } */
    .notify,
    .not_notify,
    .subscription {
      background-color: #f4f4f4;
      border-radius: 20px;
      font-size: 12px;
      font-family: Helvetica;
      color: #000000;
      padding: 4px 40px !important;
    }
    .notify:hover {
      background: #000000 !important;
      color: #fff;
    }
    .switch-panel {
      flex:1;
      text-align: right;
      font-size:21px;
    }
    .switch-panel.start span:first-child i{
      color: #BCBCBC;
    }
    .switch-panel.end span:last-child i{
      color: #BCBCBC;
    }
    /* .market_footer {
        width: 100%;
        height: 200px;
        box-sizing: border-box;
        position: fixed;
        bottom: 0px;
      } */
  }
}
</style>