<template>
  <div class="profile">
    <div class="profile_box">
      <div class="profile_top bg-white">
        <AppNavigator :parentData="userImg" />
      </div>
      <div class="profile_sec bg-white">
        <SecondNavigator activeIndex="2" />
      </div>
      <div class="profile_main" :style="{ minHeight: mainHeight + 'px' }">
        <div class="profile_content">
          <div
            style="
              width: calc(calc(100% - 80px) / 3);
              font-size: 21px;
              font-weight: 400;
            "
          >
            {{ $t("lang.myprofile.title") }}
          </div>
          <div style="flex: 1; margin-left: 40px">
            <div>
              <div style="margin-bottom: 20px">
                {{ $t("lang.myprofile.detail") }}
              </div>
              <div style="display: flex">
                <div style="flex: 1">
                  <div style="width: 165px; height: 165px">
                    <img
                      :src="userImg"
                      @load="userImgLoad"
                      v-show="showUserImg"
                      style="
                        width: 165px;
                        height: 165px;
                        background: #d8d8d8;
                        border-radius: 50%;
                      "
                    />
                  </div>
                  <div>
                    <el-upload
                      class="upload-demo"
                      action=""
                      :before-upload="beforeAvatarUpload"
                    >
                      <el-button
                        size="medium"
                        type="primary"
                        round
                        style="
                          background: #ffffff;
                          color: #bcbcbc;
                          border: none;
                          padding-left: 0 !important;
                        "
                        >{{ $t("lang.myprofile.editImg") }}</el-button
                      >
                    </el-upload>
                  </div>
                </div>
                <div style="flex: 1; margin-left: 40px">
                  <div>ZHEN. {{ $t("lang.myprofile.userId") }}</div>
                  <div>
                    <input
                      type="text"
                      v-model="userId"
                      readonly
                      style="border-bottom: 0.1em solid #dedede"
                    />
                  </div>
                  <div style="margin-top: 20px">
                    {{ $t("lang.myprofile.yourWallet") }}
                  </div>
                  <div :class="[setWalletAddr ? setAddr : setAddr2]">
                    {{ walletAddress }}
                  </div>
                  <div style="margin-top: 20px" @click="connectWallet">
                    <el-button
                      type="warning"
                      round
                      style="
                        width: 100%;
                        height: 25px;
                        line-height: 25px;
                        padding: 0;
                        background: #ff7a00;
                        color: #ffffff;
                        border: none;
                      "
                      :class="{ active: isActive }"
                      >{{ info }}</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="profile_content2">
          <div style="width: calc(calc(100% - 80px) / 3)"></div>
          <div style="flex: 1; margin-left: 40px">
            <div style="display: flex; margin-top: 20px">
              <div style="flex: 1; width: 100%; position: relative">
                <div>{{ $t("lang.myprofile.fullName") }}</div>
                <div style="width: 100%">
                  <input
                    type="text"
                    v-model="fullName"
                    readonly
                    style="border-bottom: 0.1em solid #dedede"
                  />
                  <div
                    @click="editName"
                    style="position: absolute; top: 12px; right: 0; z-index: 5"
                  >
                    <el-button type="text" style="color: #bbbbbb; opacity: 1"
                      >{{ $t("lang.myprofile.edit") }}
                    </el-button>
                  </div>
                  <div>
                    <el-dialog
                      center
                      width="360px"
                      :title="$t('lang.myprofile.editName')"
                      :visible.sync="dialogFormVisible1"
                      :close-on-click-modal="false"
                      :show-close="false"
                    >
                      <el-form :model="fullNameForm">
                        <div style="color: #000000">
                          {{ $t("lang.myprofile.fullName") }}
                        </div>
                        <el-form-item>
                          <div style="margin-top: 5px">
                            <el-input
                              type="text"
                              v-model="fullNameForm.fullName"
                              :placeholder="$t('lang.myprofile.youNewName')"
                              autocomplete="off"
                              maxlength="128"
                            />
                          </div>
                        </el-form-item>
                      </el-form>
                      <div slot="footer" class="dialog-footer">
                        <el-button
                          :type="fullNameForm.buttonType"
                          @click="dialogUpdateName"
                          round
                          :disabled="fullNameForm.buttonType == 'info'"
                          size="mini"
                          class="dialog-confirm"
                          >{{ $t("lang.myprofile.confirm") }}</el-button
                        >
                      </div>
                      <div
                        slot="footer"
                        class="dialog-footer"
                        style="margin-top: 10px"
                      >
                        <el-button
                          @click="dialogFormVisible1 = false"
                          round
                          style="
                            width: 200px;
                            border: none;
                            background-color: #ffffff;
                            color: #6d7278;
                            opacity: 1;
                            padding: 10px !important;
                          "
                          >{{ $t("lang.myprofile.cancel") }}</el-button
                        >
                      </div>
                    </el-dialog>
                  </div>
                </div>
              </div>
              <div style="flex: 1; position: relative; margin-left: 40px">
                <div>{{ $t("lang.myprofile.phone") }}</div>
                <div>
                  <input
                    type="text"
                    v-model="phone"
                    readonly
                    style="border-bottom: 0.1em solid #dedede"
                  />
                  <div
                    @click="editPhone"
                    style="position: absolute; top: 12px; right: 0; z-index: 5"
                  >
                    <el-button type="text" style="color: #bbbbbb; opacity: 1"
                      >{{ $t("lang.myprofile.edit") }}
                    </el-button>
                  </div>
                  <div>
                    <el-dialog
                      center
                      width="360px"
                      :title="$t('lang.myprofile.editPhone')"
                      :visible.sync="dialogFormVisible2"
                      :close-on-click-modal="false"
                      :show-close="false"
                    >
                      <el-form :model="phoneForm">
                        <div style="color: #000000">
                          {{ $t("lang.myprofile.newPhone") }}
                        </div>
                        <el-form-item>
                          <div style="margin-top: 5px; display: flex">
                            <div style="padding: 0 5px">+</div>
                            <div style="width: 60px">
                              <AreaSelect :disabled="true"  @countryChange="countryChange($event)"/>
                            </div>
                            <div style="width: 20px"></div>
                            <el-input
                              type="text"
                              v-model="phoneForm.phone"
                              :placeholder="$t('lang.myprofile.youNewPhone')"
                              autocomplete="off"
                              maxlength="64"
                              :class="
                                phoneForm.phoneError ? 'error_border' : ''
                              "
                            />
                          </div>
                          <div class="el-form-item__error">
                            {{ phoneForm.phoneError }}
                          </div>
                        </el-form-item>
                        <div style="color: #000000">
                          {{ $t("lang.myprofile.sMSVerification") }}
                        </div>
                        <el-form-item>
                          <div style="margin-top: 5px">
                            <el-input
                              type="text"
                              v-model="phoneForm.authCode"
                              :placeholder="$t('lang.myprofile.smsVerCode')"
                              autocomplete="off"
                              maxlength="6"
                              :class="
                                phoneForm.authCodeError ? 'error_border' : ''
                              "
                            />
                            <div class="el-form-item__error">
                              {{ phoneForm.authCodeError }}
                            </div>
                          </div>
                          <div
                            style="position: absolute; right: 0; top: -3px"
                            @click="dialogUpdateSendCode"
                          >
                            <el-button
                              type="text"
                              style="color: #bcbcbc; opacity: 1"
                              >{{ phoneForm.sendCodeText }}
                            </el-button>
                          </div>
                        </el-form-item>
                        <div style="color: #000000">
                          {{ $t("lang.myprofile.accountPassword") }}
                        </div>
                        <el-form-item>
                          <div style="margin-top: 5px">
                            <el-input
                              type="text"
                              v-model="phoneForm.password"
                              :placeholder="$t('lang.myprofile.enterPassword')"
                              :show-password="ispassword"
                              @focus="ispassword = true"
                              maxlength="64"
                              autocomplete="off"
                              :class="
                                phoneForm.passwordError ? 'error_border' : ''
                              "
                            />
                            <div class="el-form-item__error">
                              {{ phoneForm.passwordError }}
                            </div>
                          </div>
                        </el-form-item>
                      </el-form>
                      <div slot="footer" class="dialog-footer">
                        <el-button
                          :type="phoneForm.buttonType"
                          @click="updatePhone"
                          :disabled="phoneForm.buttonType == 'info'"
                          round
                          size="mini"
                          class="dialog-confirm"
                          >{{ $t("lang.myprofile.confirm") }}</el-button
                        >
                      </div>
                      <div
                        slot="footer"
                        class="dialog-footer"
                        style="margin-top: 10px"
                      >
                        <el-button
                          @click="dialogFormVisible2 = false"
                          round
                          style="
                            width: 200px;
                            border: none;
                            background-color: #ffffff;
                            color: #6d7278;
                            opacity: 1;
                            padding: 10px !important;
                          "
                          >{{ $t("lang.myprofile.cancel") }}</el-button
                        >
                      </div>
                    </el-dialog>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex; margin-top: 20px; margin-bottom: 40px">
              <div style="flex: 1; position: relative">
                <div>{{ $t("lang.myprofile.email") }}</div>
                <div>
                  <input
                    type="text"
                    v-model="email"
                    readonly
                    style="border-bottom: 0.1em solid #dedede"
                  />
                  <div
                    @click="editEmail"
                    style="position: absolute; top: 12px; right: 0; z-index: 5"
                  >
                    <el-button type="text" style="color: #bbbbbb; opacity: 1"
                      >{{ $t("lang.myprofile.edit") }}
                    </el-button>
                  </div>
                  <div>
                    <el-dialog
                      center
                      width="360px"
                      :title="$t('lang.myprofile.editEmail')"
                      :visible.sync="dialogFormVisible3"
                      :close-on-click-modal="false"
                      :show-close="false"
                    >
                      <el-form :model="emailForm">
                        <div style="color: #000000">
                          {{ $t("lang.myprofile.newEmail") }}
                        </div>
                        <el-form-item>
                          <div style="margin-top: 5px">
                            <el-input
                              type="text"
                              v-model="emailForm.email"
                              :placeholder="$t('lang.myprofile.youEmail')"
                              maxlength="128"
                              autocomplete="off"
                              :class="
                                emailForm.emailError ? 'error_border' : ''
                              "
                            />
                            <div class="el-form-item__error">
                              {{ emailForm.emailError }}
                            </div>
                          </div>
                        </el-form-item>
                        <div style="color: #000000">
                          {{ $t("lang.myprofile.emailVerification") }}
                        </div>
                        <el-form-item>
                          <div style="margin-top: 5px">
                            <el-input
                              type="text"
                              v-model="emailForm.authCode"
                              :placeholder="$t('lang.myprofile.smsVerCode')"
                              maxlength="6"
                              autocomplete="off"
                              :class="
                                emailForm.authCodeError ? 'error_border' : ''
                              "
                            />
                            <div class="el-form-item__error">
                              {{ emailForm.authCodeError }}
                            </div>
                          </div>
                          <div
                            style="position: absolute; right: 0; top: -3px"
                            @click="sendEmailCode()"
                          >
                            <el-button
                              type="text"
                              style="color: #bcbcbc; opacity: 1"
                              :disabled="emailForm.sendCodeDisabled"
                              >{{ emailForm.sendCodeText }}</el-button
                            >
                          </div>
                        </el-form-item>
                        <div style="color: #000000">
                          {{ $t("lang.myprofile.accountPassword") }}
                        </div>
                        <el-form-item>
                          <div style="margin-top: 5px">
                            <el-input
                              type="text"
                              v-model="emailForm.password"
                              :placeholder="$t('lang.myprofile.enterPassword')"
                              :show-password="ispassword1"
                              @focus="ispassword1 = true"
                              maxlength="64"
                              autocomplete="off"
                              :class="
                                emailForm.passwordError ? 'error_border' : ''
                              "
                            />
                            <div class="el-form-item__error">
                              {{ emailForm.passwordError }}
                            </div>
                          </div>
                        </el-form-item>
                      </el-form>

                      <div slot="footer" class="dialog-footer">
                        <el-button
                          :type="emailForm.buttonType"
                          @click="updateEmail"
                          :disabled="emailForm.buttonType == 'info'"
                          round
                          size="mini"
                          class="dialog-confirm"
                          >{{ $t("lang.myprofile.confirm") }}</el-button
                        >
                      </div>
                      <div
                        slot="footer"
                        class="dialog-footer"
                        style="margin-top: 10px"
                      >
                        <el-button
                          @click="dialogFormVisible3 = false"
                          round
                          style="
                            width: 200px;
                            border: none;
                            background-color: #ffffff;
                            color: #6d7278;
                            opacity: 1;
                            padding: 10px !important;
                          "
                          >{{ $t("lang.myprofile.cancel") }}</el-button
                        >
                      </div>
                    </el-dialog>
                  </div>
                </div>
              </div>
              <div style="flex: 1; position: relative; margin-left: 40px">
                <div>{{ $t("lang.myprofile.password") }}</div>
                <div>
                  <input
                    type="text"
                    v-model="password"
                    readonly
                    style="border-bottom: 0.1em solid #dedede"
                  />
                  <div
                    @click="editPwd"
                    style="position: absolute; top: 12px; right: 0; z-index: 5"
                  >
                    <el-button type="text" style="color: #bbbbbb; opacity: 1"
                      >{{ $t("lang.myprofile.edit") }}
                    </el-button>
                  </div>
                  <div>
                    <el-dialog
                      center
                      width="360px"
                      :title="$t('lang.myprofile.editPassword')"
                      :visible.sync="dialogFormVisible4"
                      :close-on-click-modal="false"
                      :show-close="false"
                    >
                      <el-form :model="passwordForm">
                        <div style="color: #000000">
                          {{ $t("lang.myprofile.cureentPassword") }}
                        </div>
                        <el-form-item>
                          <div style="margin-top: 5px">
                            <el-input
                              type="text"
                              v-model="passwordForm.quondam"
                              :placeholder="$t('lang.myprofile.youPassword')"
                              :show-password="ispassword2"
                              @focus="ispassword2 = true"
                              autocomplete="off"
                              maxlength="64"
                              :class="
                                passwordForm.quondamError ? 'error_border' : ''
                              "
                            />
                            <div class="el-form-item__error">
                              {{ passwordForm.quondamError }}
                            </div>
                          </div>
                        </el-form-item>
                        <div style="color: #000000">
                          {{ $t("lang.myprofile.newPassword") }}
                        </div>
                        <el-form-item>
                          <div style="margin-top: 5px">
                            <el-input
                              type="text"
                              v-model="passwordForm.pristine"
                              :placeholder="$t('lang.myprofile.enterPassword')"
                              :show-password="ispassword3"
                              @focus="ispassword3 = true"
                              autocomplete="off"
                              maxlength="64"
                              :class="
                                passwordForm.pristineError ? 'error_border' : ''
                              "
                            />
                            <div class="el-form-item__error">
                              {{ passwordForm.pristineError }}
                            </div>
                          </div>
                        </el-form-item>
                      </el-form>

                      <div slot="footer" class="dialog-footer">
                        <el-button
                          :type="passwordForm.buttonType"
                          @click="updatePwd"
                          :disabled="passwordForm.buttonType == 'info'"
                          round
                          size="mini"
                          class="dialog-confirm"
                          >{{ $t("lang.myprofile.confirm") }}</el-button
                        >
                      </div>
                      <div
                        slot="footer"
                        class="dialog-footer"
                        style="margin-top: 10px"
                      >
                        <el-button
                          @click="dialogFormVisible4 = false"
                          round
                          style="
                            width: 200px;
                            border: none;
                            background-color: #ffffff;
                            color: #6d7278;
                            opacity: 1;
                            padding: 10px !important;
                          "
                          >{{ $t("lang.myprofile.cancel") }}</el-button
                        >
                      </div>
                    </el-dialog>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="profile_content3">
          <div style="width: calc(calc(100% - 80px) / 3)"></div>
          <div style="flex: 1;margin-left: 40px;">
            <div class="line"></div>
            <div style="margin-top:10px;">{{ $t("lang.myprofile.twofa.title") }}</div>
            <div class="fa-panel">
                <div style="width:160px;">{{ $t("lang.myprofile.twofa.authenticator") }}</div>
                <div style="flex:1;">
                  <el-switch 
                    v-model="switchModel"
                    v-show="isBind == 2" 
                    @change="switchChange"
                    active-color="#000000"
                    active-value="2"
                    inactive-value="1" 
                    inactive-color="#bcbcbc">
                  </el-switch>
                </div>
                <div style="flex:1;text-align:right;">
                    <el-button class="bind-btn" :showloading="bindloadding" @click="bindClick" v-show="isBind == 1">{{ $t("lang.myprofile.twofa.bind") }}</el-button>
                    <el-button class="bind-btn" @click="unbindClick" v-show="isBind == 2">{{ $t("lang.myprofile.twofa.unbind") }}</el-button>
                </div>
            </div>
            <div class="line"></div>
          </div>
        </div>
        <el-dialog
          center
          width="360px"
          custom-class="metamask_dialog"
          :visible.sync="dialogVisible1"
          :title="$t('lang.myMarkPlace.dialogTitle1')"
          :close-on-click-modal="false"
        >
          <div style="padding: 0 30px">
            <div style="text-align: center"></div>
            <div style="text-align: center; margin-top: 10px">
              <el-button class="metamask_btn" @click="connectMetamask()">
                MetaMask
              </el-button>
            </div>
            <div style="margin-top: 20px; font-size: 12px; text-align: center">
              {{ $t("lang.myMarkPlace.contentText3") }}
              <span
                @click="goToLegal(2)"
                style="color: #ff7a00; cursor: pointer"
                >{{ $t("lang.myMarkPlace.label1") }}
              </span>
              {{ $t("lang.myMarkPlace.label3") }}
              <span
                @click="goToLegal(1)"
                style="color: #ff7a00; cursor: pointer"
                >{{ $t("lang.myMarkPlace.label2") }}</span
              >
            </div>
          </div>
        </el-dialog>
        <el-dialog
          center
          width="360px"
          :show-close="false"
          custom-class="install_metamask_dialog"
          :visible.sync="dialogVisible2"
          :close-on-click-modal="false">
          <div style="color: #000000; font-size: 16px">
            <div style="text-align: center">
              {{ $t("lang.myMarkPlace.installText1") }} METAMASK
            </div>
            <div style="margin-top: 10px; text-align: center; font-size: 14px">
              {{ $t("lang.myMarkPlace.contentText4") }}
            </div>
            <div style="text-align: center; margin-top: 10px">
              <el-button
                class="install_metamask_btn"
                @click="installMetamask()">
                {{ $t("lang.myMarkPlace.installText") }} MetaMask
              </el-button>
            </div>
            <div style="text-align: center; margin-top: 5px">
              <el-button
                @click="dialogVisible2 = false"
                round
                style="
                  width: 200px;
                  border: none;
                  background-color: #ffffff;
                  color: #bcbcbc;
                  opacity: 1;
                  padding: 10px !important;
                ">{{ $t("lang.myMarkPlace.cancel") }}</el-button
              >
            </div>
          </div>
        </el-dialog>
        <Authenticator ref="authenticator"></Authenticator>
        <!-- vueCropper 剪裁图片实现-->
        <!-- <el-dialog title="图片剪裁" :show-close="false" :visible.sync="imgDialogVisible" :close-on-click-modal="false">
          <div class="cropper-content">
            <div class="cropper" style="text-align:center;height:500px;">
              <vueCropper
                  ref="cropper"
                  :img="option.img"
                  :outputSize="option.size"
                  :outputType="option.outputType"
                  :info="true"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :fixed="option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :centerBox="option.centerBox"
                  :infoTrue="option.infoTrue"
                  :fixedBox="option.fixedBox"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"></vueCropper>
            </div>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="imgDialogVisible = false">取 消</el-button>
            <el-button type="primary">确认</el-button>
          </div>
        </el-dialog> -->
      </div>
      <div class="profile_footer">
        <AppFooter />
      </div>
    </div>
    <!-- google 人机验证 -->
    <div id='recaptcha' style="position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);"></div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
import SecondNavigator from "@/components/common/SecondNavigator.vue";
import AreaSelect from "@/components/user/AreaSelect.vue";
import Authenticator from "@/components/user/Authenticator.vue";
import obj from "../../common/utils";
export default {
  name: "Profile",
  components: {
    AppNavigator,
    AppFooter,
    SecondNavigator,
    AreaSelect,
    Authenticator
  },
  data() {
    return {
      userImg: "",
      showUserImg: false,
      userId: "",
      fullName: "",
      email: "",
      password: "********",
      phone: "",
      isActive: false,
      setWalletAddr: true,
      setAddr: "setAddr",
      setAddr2: "setAddr2",
      info: this.$t("lang.myprofile.connectWallet"),
      walletAddress: this.$t("lang.myprofile.connect"),
      fullNameForm: {
        fullName: "",
        buttonType: "info",
      },
      phoneForm: {
        phone: "",
        authCode: "",
        password: "",
        phoneError: "",
        authCodeError: "",
        passwordError: "",
        sendCodeDisabled: false,
        restaurants: [],
        sendCodeText: this.$t("lang.myprofile.sendCode"),
        countryId: "",
        timeout: null,
        buttonType: "info",
        inter:null
      },
      emailForm: {
        email: "",
        password: "",
        authCode: "",
        emailError: "",
        authCodeError: "",
        passwordError: "",
        sendCodeText: this.$t("lang.myprofile.sendCode"),
        sendCodeDisabled: false,
        buttonType: "info",
        inter:null
      },
      passwordForm: {
        quondam: "",
        pristine: "",
        quondamError: "",
        pristineError: "",
        buttonType: "info",
      },
      dialogFormVisible1: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      dialogFormVisible4: false,
      isConnect: false,
      dialogVisible1: false,
      dialogVisible2: false,
      imgDialogVisible: false,
      // 裁剪组件的基础配置option
      // option: {
      //   img: "", // 裁剪图片的地址
      //   info: true, // 裁剪框的大小信息
      //   outputSize: 0.8, // 裁剪生成图片的质量
      //   outputType: "jpg", // 裁剪生成图片的格式
      //   canScale: false, // 图片是否允许滚轮缩放
      //   canMove: false, // 图片是否可移动
      //   autoCrop: true, // 是否默认生成截图框
      //   autoCropWidth: 100, // 默认生成截图框宽度
      //   autoCropHeight: 100, // 默认生成截图框高度
      //   fixedBox: true, // 固定截图框大小 不允许改变
      //   fixed: true, // 是否开启截图框宽高固定比例
      //   fixedNumber: [1, 1], // 截图框的宽高比例
      //   full: true, // 是否输出原图比例的截图
      //   canMoveBox: true, // 截图框能否拖动
      //   original: false, // 上传图片按照原始比例渲染
      //   centerBox: false, // 截图框是否被限制在图片里面
      //   infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      // },
      mainHeight: 0,
      ispassword:false,
      ispassword1:false,
      ispassword2:false,
      ispassword3:false,
      switchModel:'1',
      showBind:false,
      isBind:1,
      bindloadding:false,
      //初始化人机验证
      captcha:this.utils.renderGrecaptcha()
    };
  },
  watch: {
    //监听表单内容变化 控制注册按钮是否可用
    fullNameForm: {
      handler(val, oldVal) {
        let $this = this;
        //如果表单都输入了值 修改按钮可点击
        if ($this.fullNameForm.fullName) {
          $this.fullNameForm.buttonType = "primary";
        } else {
          $this.fullNameForm.buttonType = "info";
        }
      },
      deep: true,
    },
    //监听表单内容变化 控制注册按钮是否可用
    phoneForm: {
      handler(val, oldVal) {
        let $this = this;
        let flag = true;
        let attr = [
          { attr: "countryId" },
          { attr: "phone" },
          { attr: "authCode", length: 6 },
          { attr: "password", length: 6 },
        ];
        //根据校验规则进行简单校验
        attr.forEach(function (prop, index) {
          if (
            !$this.phoneForm[prop.attr] ||
            (prop.length && $this.phoneForm[prop.attr].length < prop.length)
          ) {
            flag = false;
          }
        });
        //控制提交按钮是否可用
        if (flag) {
          $this.phoneForm.buttonType = "primary";
        } else {
          $this.phoneForm.buttonType = "info";
        }
      },
      deep: true,
    },
    //监听表单内容变化 控制注册按钮是否可用
    emailForm: {
      handler(val, oldVal) {
        let $this = this;
        let flag = true;
        let attr = [
          { attr: "email" },
          { attr: "authCode", length: 6 },
          { attr: "password", length: 6 },
        ];
        //根据校验规则进行简单校验
        attr.forEach(function (prop, index) {
          if (
            !$this.emailForm[prop.attr] ||
            (prop.length && $this.emailForm[prop.attr].length < prop.length)
          ) {
            flag = false;
          }
        });
        //控制提交按钮是否可用
        if (flag) {
          $this.emailForm.buttonType = "primary";
        } else {
          $this.emailForm.buttonType = "info";
        }
      },
      deep: true,
    },
    //监听表单内容变化 控制注册按钮是否可用
    passwordForm: {
      handler(val, oldVal) {
        let $this = this;
        let flag = true;
        let attr = ["quondam", "pristine"];
        //根据校验规则进行简单校验
        attr.forEach(function (prop, index) {
          if (
            !$this.passwordForm[prop] ||
            $this.passwordForm[prop].length < 6
          ) {
            flag = false;
          }
        });
        //控制提交按钮是否可用
        if (flag) {
          $this.passwordForm.buttonType = "primary";
        } else {
          $this.passwordForm.buttonType = "info";
        }
      },
      deep: true,
    }
  },
  methods: {
    userImgLoad() {
      this.showUserImg = true;
    },
    //连接钱包
    async connectWallet() {
      let _this = this;
      //先判断是否安装了metamask插件
      if (window.ethereum) {
        //判断localStorage是否保存用户地址
        if (localStorage.getItem("from") && _this.isActive == true) {
          //判断钱包是否连接
          let isUnlockResult = await obj.isWalletLocked();
          if (isUnlockResult) {
            //未锁
            localStorage.removeItem("from");
            localStorage.removeItem("chainId");
            _this.isActive = false;
            _this.info = _this.$t("lang.myprofile.connectWallet");
            _this.walletAddress = _this.$t("lang.myprofile.connect");
          } else {
            await obj.connectWallet();
            localStorage.removeItem("from");
            localStorage.removeItem("chainId");
            _this.isActive = false;
            _this.info = _this.$t("lang.myprofile.connectWallet");
            _this.walletAddress = _this.$t("lang.myprofile.connect");
          }
        } else if (localStorage.getItem("from")) {
          let isUnlockResult2 = await obj.isWalletLocked();
          if (isUnlockResult2) {
            localStorage.removeItem("from");
            localStorage.removeItem("chainId");
            _this.isActive = false;
            _this.info = _this.$t("lang.myprofile.connectWallet");
            _this.walletAddress = _this.$t("lang.myprofile.connect");
          } else {
            await obj.connectWallet();
            localStorage.removeItem("from");
            localStorage.removeItem("chainId");
            _this.isActive = false;
            _this.info = _this.$t("lang.myprofile.connectWallet");
            _this.walletAddress = _this.$t("lang.myprofile.connect");
          }
        } else {
          //localStorage没有地址执行
          _this.dialogVisible1 = true;
        }
      } else {
        //没有安装钱包插件,先安装钱包插件
        this.dialogVisible2 = true;
        this.isConnect = false;
      }
    },
    //修改手机号
    updatePhone() {
      this.phoneForm.phoneError = "";
      this.phoneForm.authCodeError = "";
      this.phoneForm.passwordError = "";
      let $this = this;
      let token = this.utils.getToken();
      let param = {};
      //获取countryId
      let countryId = $this.phoneForm.countryId;
      if(!countryId) {
        $this.phoneForm.phoneError = $this.$t("lang.myprofile.errorInfo");
        return false;
      }
      param.token = token;
      param.phone = $this.phoneForm.phone;
      param.countryId = countryId;
      param.authCode = $this.phoneForm.authCode;
      param.password = $this.phoneForm.password;
      $this.$http
        .post("user/modifyPhone", $this.qs.stringify(param))
        .then((res) => {
          if (res.code === 0) {
            //查询用户信息
            $this.getUserInfo();
            $this.dialogFormVisible2 = false;
          } else {
            if (res.code === -3) {
              let errorList = res.msg.split(";");
              errorList.forEach(function (item, index, arr) {
                let attr = item.split(":")[0];
                let msg = item.split(":")[1];
                $this.phoneForm[attr + "Error"] = msg;
              });
            }
          }
        });
    },
    //连接钱包插件
    connectMetamask() {
      let _this = this;
      //连接钱包
      obj.connectWallet().then(() => {
        //再次判断是否确认已连接metamask,并保存了数据
        if (localStorage.getItem("from")) {
          _this.dialogVisible1 = false;
          _this.walletAddress = localStorage.getItem("from");
          _this.isActive = true;
          _this.info = _this.$t("lang.myprofile.disconnect");
        } else {
        }
      });

      //location.reload();
    },
    installMetamask() {
      window.open("https://metamask.io/download.html");
      location.reload();
    },
    goToLegal(index) {
      let param = {
        name: "Privacy",
      };
      if (index == 2) {
        param = {
          name: "Terms",
        };
      }
      const { href } = this.$router.resolve(param);
      window.open(href, "_blank");
    },
    countryChange(param) {
      this.phoneForm.countryId = param.id;
    },
    //修改密码
    updatePwd() {
      let $this = this;
      let token = this.utils.getToken();
      this.$http
        .post(
          "user/password/modify",
          this.qs.stringify({
            quondam: this.passwordForm.quondam,
            pristine: this.passwordForm.pristine,
            token: token,
          })
        )
        .then((res) => {
          if (res.code === 0) {
            this.passwordForm.quondam = "";
            this.passwordForm.pristine = "";
            this.dialogFormVisible4 = false;
          } else {
            if (res.code === -3) {
              let errorList = res.msg.split(";");
              errorList.forEach(function (item, index, arr) {
                let attr = item.split(":")[0];
                let msg = item.split(":")[1];
                $this.passwordForm[attr + "Error"] = msg;
              });
            }
          }
        });
    },
    //修改昵称
    dialogUpdateName() {
      let token = this.utils.getToken();
      this.$http
        .post(
          "user/modifyNickname",
          this.qs.stringify({
            nickName: this.fullNameForm.fullName,
            token: token,
          })
        )
        .then((res) => {
          if (res.code === 0) {
            this.dialogFormVisible1 = false;
            this.fullName = this.fullNameForm.fullName;
          }
        });
    },
    //在edit phone弹窗中发送验证码
    dialogUpdateSendCode() {
      this.phoneForm.phoneError = "";
      this.phoneForm.authCodeError = "";
      this.phoneForm.passwordError = "";
      let $this = this;
      if (!$this.phoneForm.countryId) {
        $this.phoneForm.phoneError = $this.$t("lang.myprofile.errorInfo");
        return false;
      }
      //倒计时没结束 不响应事件
      if (this.phoneForm.sendCodeDisabled) {
        return false;
      }
      //判断是否填写手机号
      if(!this.phoneForm.phone.trim()) {
        this.phoneForm.phoneError = this.$t("lang.profile.required");
        return;
      }
      //启动人机验证
      this.captcha.call(this,'recaptcha');
    },
    //人机验证回调
    handleCaptchaCallback(captchaToken) {
      if(this.dialogFormVisible2 == true) {
          this.phoneCallback(captchaToken)
      }else if(this.dialogFormVisible3 == true) {
          this.emailCallback(captchaToken)
      }
      
    },
    phoneCallback(captchaToken) {
      let $this = this;
      //点击按钮后禁用防止重复点击
      this.phoneForm.sendCodeDisabled = true;
      this.$http
        .post(
          "verifyCode/sms",
          {
            phone: $this.phoneForm.phone,
            countryId: $this.phoneForm.countryId,
            smsType: 3,
            captchaToken:captchaToken
          }
        )
        .then((res) => {
          //请求成功
          if (res.code === 0) {
            let count = 59;
            sessionStorage.setItem('phoneSendTime', new Date().getTime());
            $this.phoneForm.sendCodeText = count + "(s)";
            $this.initPhoneInterval(count);
            $this.utils.alert(
              $this.$t("lang.myprofile.verificationCodeSent"),
              $this.$t("lang.myprofile.verCodeInfo2")
            );
          }
          //请求失败
          else {
            $this.phoneForm.sendCodeDisabled = false;
            if (res.code === -3) {
              let errorList = res.msg.split(";");
              errorList.forEach(function (item, index, arr) {
                let attr = item.split(":")[0];
                let msg = item.split(":")[1];
                $this.phoneForm[attr + "Error"] = msg;
              });
            }
          }
        });
    },
    //刷新页面后初始化发送验证码后的倒计时
    phoneCountdown() {
      let $this = this;
      if(sessionStorage.getItem('phoneSendTime')) {
        let sendTime = sessionStorage.getItem('phoneSendTime');
        let count = 59 - Math.floor((new Date().getTime() - sendTime)/1000);
        if(count > 0) {
          $this.phoneForm.sendCodeDisabled = true;
          $this.phoneForm.sendCodeText = count + "(s)";
          $this.initPhoneInterval(count);
        }
      }else {
        if($this.phoneForm.inter) {
          clearInterval($this.phoneForm.inter);
        }
        $this.phoneForm.sendCodeDisabled = false;
        $this.phoneForm.sendCodeText = $this.$t("lang.myprofile.sendCode");
      }
    },
    //发送验证码后的倒计时
    initPhoneInterval(count) {
      let $this = this;
      if($this.phoneForm.inter) {
        clearInterval($this.phoneForm.inter);
      }
      $this.phoneForm.inter = setInterval(function () {
        count--;
        $this.phoneForm.sendCodeText = count + "(s)";
        if (count == 0) {
          $this.phoneForm.sendCodeDisabled = false;
          sessionStorage.removeItem('phoneSendTime');
          $this.phoneForm.sendCodeText = $this.$t("lang.myprofile.sendCode");
          clearInterval($this.phoneForm.inter);
        }
      }, 1000);
    },
    //修改邮件
    updateEmail() {
      this.emailForm.emailError = "";
      this.emailForm.authCodeError = "";
      this.emailForm.passwordError = "";
      let $this = this;
      let token = this.utils.getToken();
      this.$http
        .post(
          "user/modifyEmail",
          this.qs.stringify({
            email: this.emailForm.email,
            authCode: this.emailForm.authCode,
            password: this.emailForm.password,
            token: token,
          })
        )
        .then((res) => {
          if (res.code === 0) {
            this.email = this.emailForm.email;
            this.dialogFormVisible3 = false;
          } else {
            if (res.code === -3) {
              let errorList = res.msg.split(";");
              errorList.forEach(function (item, index, arr) {
                let attr = item.split(":")[0];
                let msg = item.split(":")[1];
                $this.emailForm[attr + "Error"] = msg;
              });
            }
          }
        });
    },
    //发送邮箱验证码
    sendEmailCode() {
      this.emailForm.emailError = "";
      this.emailForm.authCodeError = "";
      this.emailForm.passwordError = "";
      let $this = this;
      //倒计时没结束 不响应事件
      if (this.emailForm.sendCodeDisabled) {
        return false;
      }
      //判断是否填写邮箱
      if(!this.emailForm.email.trim()) {
        this.emailForm.emailError = this.$t("lang.profile.required");
        return;
      }
      //启动人机验证
      this.captcha.call(this,'recaptcha');
    },
    emailCallback(captchaToken) {
      let $this = this;
      //点击按钮后禁用防止重复点击
      this.emailForm.sendCodeDisabled = true;
      this.$http
        .post(
          "verifyCode/email",
          { email: $this.emailForm.email, emailType: 3,captchaToken:captchaToken}
        )
        .then((res) => {
          //请求成功
          if (res.code === 0) {
            let count = 59;
            sessionStorage.setItem('emailSendTime', new Date().getTime());
            $this.emailForm.sendCodeText = count + "(s)";
            $this.initEmailInterval(count);
            $this.utils.alert(
              $this.$t("lang.myprofile.verificationCodeSent"),
              $this.$t("lang.myprofile.verCodeInfo")
            );
          }
          //请求失败
          else {
            $this.emailForm.sendCodeDisabled = false;
            if (res.code === -3) {
              let errorList = res.msg.split(";");
              errorList.forEach(function (item, index, arr) {
                let attr = item.split(":")[0];
                let msg = item.split(":")[1];
                $this.emailForm[attr + "Error"] = msg;
              });
            }
          }
        });
    },
    //刷新页面后初始化发送验证码后的倒计时
    emailCountdown() {
      let $this = this;
      if(sessionStorage.getItem('emailSendTime')) {
        let sendTime = sessionStorage.getItem('emailSendTime');
        let count = 59 - Math.floor((new Date().getTime() - sendTime)/1000);
        if(count > 0) {
          $this.emailForm.sendCodeDisabled = true;
          $this.emailForm.sendCodeText = count + "(s)";
          $this.initEmailInterval(count);
        }
      }else {
        if($this.emailForm.inter) {
          clearInterval($this.emailForm.inter);
        }
        $this.emailForm.sendCodeDisabled = false;
        $this.emailForm.sendCodeText = $this.$t("lang.myprofile.sendCode");
      }
    },
    //发送验证码后的倒计时
    initEmailInterval(count) {
      let $this = this;
      if($this.emailForm.inter) {
        clearInterval($this.emailForm.inter);
      }
      $this.emailForm.inter = setInterval(function () {
        count--;
        $this.emailForm.sendCodeText = count + "(s)";
        if (count == 0) {
          $this.emailForm.sendCodeDisabled = false;
          sessionStorage.removeItem('emailSendTime');
          $this.emailForm.sendCodeText = $this.$t("lang.myprofile.sendCode");
          clearInterval($this.emailForm.inter);
        }
      }, 1000);
    },
    //在edit password弹窗中发送验证码
    dialogUpdatePassword() {},
    goToWeb() {
      switch (index) {
        case 0:
          this.$router.push({
            path: "/myCabinet",
          });
          break;
        case 1:
          this.$router.push({
            path: "/profile",
          });
          break;
      }
    },
    //图片上传
    beforeAvatarUpload(file) {
      let $this = this;
      let token = $this.utils.getToken();
      let suffix = "jpg,png,jpeg";
      //判断图片格式
      if (suffix.indexOf(file.name.split(".")[1]) < 0) {
        $this.utils.alert(
          $this.$t("lang.myprofile.note"),
          $this.$t("lang.myprofile.uploadInfo")
        );
        return false;
      }
      //图片转成base64
      $this.utils.getBase64(file).then((base64) => {
        // $this.option.img = base64;
        // $this.imgDialogVisible = true;
        $this.$http
          .post("user/info/uploadAvatar", {
            photo: base64.split(",")[1],
            suffix: "jpg",
            token: token,
          })
          .then((res) => {
            if (res.code === 0) {
              $this.userImg = res.data;
              localStorage.setItem("profilePhoto", res.data);
            }
          });
      });
      return false;
    },
    editName() {
      this.fullNameForm.fullName = "";
      this.fullNameForm.buttonType = "info";
      this.dialogFormVisible1 = true;
    },
    editPhone() {
      //重置表单数据
      let fields = [
        "phone",
        "password",
        "authCode",
        "phoneError",
        "authCodeError",
        "passwordError",
      ];
      for (let i = 0; i < fields.length; i++) {
        let attr = fields[i];
        this.phoneForm[attr] = "";
      }
      this.phoneCountdown();
      this.dialogFormVisible2 = true;
    },
    editEmail() {
      //重置表单数据
      let fields = [
        "email",
        "password",
        "authCode",
        "emailError",
        "authCodeError",
        "passwordError",
      ];
      for (let i = 0; i < fields.length; i++) {
        let attr = fields[i];
        this.emailForm[attr] = "";
      }
      this.emailCountdown();
      this.dialogFormVisible3 = true;
    },
    editPwd() {
      //重置表单数据
      let fields = ["quondam", "pristine", "quondamError", "pristineError"];
      for (let i = 0; i < fields.length; i++) {
        let attr = fields[i];
        this.phoneForm[attr] = "";
      }
      this.dialogFormVisible4 = true;
    },
    switchChange(switchModel) {
      let _this = this;
      if(_this.switchModel == '1') {
        _this.switchModel = '2';
      }else {
        _this.switchModel = '1';
      }
      this.$refs.authenticator.showValid(4);
    },
    bindClick() {
      let _this = this;
      _this.bindloadding = true;
      this.$refs.authenticator.showBind().then(()=>{
        _this.bindloadding = false;
      });
    },
    unbindClick() {
      this.$refs.authenticator.showUnbind();
    },
    //开启关闭成功回调
    openAndCloseSuccess() {
      let switchModel = 1;
      if(this.switchModel == 1) {
        switchModel = 2;
      }
      this.$http
      .post("googleAuthenticator/derail", 
      {token:this.utils.getToken(),derail:switchModel})
      .then((res) => {
        if (res.code === 0) {
          window.location.reload();
        }
      });
    },
    //绑定成功回调
    bindSuccess() {
      window.location.reload();
    },
    //解除绑定成功回调
    unbindSuccess() {
      this.$http
      .post('googleAuthenticator/unbound', {token: this.utils.getToken()})
      .then((res) => {
        if (res.code === 0) {
          window.location.reload();
        }
      });
      
    },
    getUserInfo() {
      let $this = this;
      //获取用户信息
      $this.$http
        .post("user/info", { token: $this.utils.getToken() })
        .then((res) => {
          if (res.code === 0) {
            let data = res.data;
            $this.userImg =
              data.profilePhoto || require("../../assets/Frontpage/20.jpg");
            $this.userId = data.uuid;
            $this.fullName = data.nickName;
            $this.email = data.email;
            if(data.areaCode) {
              $this.phone = '+'+data.areaCode+' '+data.phone;
            }else {
              $this.phone = data.phone;
            }
            if (data.profilePhoto) {
              localStorage.setItem("profilePhoto", data.profilePhoto);
            }
          }
        });
    }
  },
  created() {
    let $this = this;
    //查询用户信息
    $this.getUserInfo();
    //获取是否已经绑定google身份验证
    $this.$http
      .post("googleAuthenticator/isBinding", { token: $this.utils.getToken() })
      .then((res) => {
        if (res.code === 0) {
          let data = res.data;
          $this.isBind = data.isBinding;
          $this.switchModel = data.isGa.toString();
        }
    });
  },
  mounted() {
    if (localStorage.getItem("from")) {
      this.walletAddress = localStorage.getItem("from");
      this.isActive = true;
      this.info = this.$t("lang.myprofile.disconnect");
    }
    this.mainHeight = window.screen.availHeight - 390;
  },
};
</script>
<style lang="less">
.el-autocomplete-update-phone-input {
  width: 250px !important;
}
.profile {
  width: 100%;
  color: #000000 !important;
  box-sizing: border-box;
  border: 1px solid #fff;
  .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*height:600px;*/
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
    border-radius: 10px;
  }
  .metamask_btn {
    width: 160px;
    height: 25px;
    padding: 0 18px 0 0 !important;
    text-align: center;
    border-radius: 15px;
    border: none !important;
    color: #fff;
    background: #000000 url("../../assets/Frontpage/mm-logo.png") no-repeat;
    background-position: 110px 5px;
    background-size: 18px;
    cursor: pointer;
  }

  .install_metamask_btn,
  .bind-btn {
    width: 160px;
    height: 25px;
    padding: 0 !important;
    text-align: center;
    border-radius: 13px;
    border: none !important;
    color: #fff;
    background-color: #000000;
    margin-top: 10px;
  }

  .install_metamask_btn:hover,
  .install_metamask_btn:focus,
  .metamask_btn:hover,
  .metamask_btn:focus,
  .bind-btn:hover,
  .bind-btn:focus{
    background-color: #000000;
    color: #fff;
  }

  .metamask_dialog,
  .install_metamask_dialog {
    border-radius: 15px;
  }
  .el-dialog .el-dialog__body {
    flex: 1;
    overflow: auto;
  }

  .metamask_dialog .el-dialog__body {
    padding-top: 0px !important;
  }

  .install_metamask_dialog .el-dialog__header {
    display: none;
  }

  input {
    width: 100%;
    border: none;
    outline: medium;
    border-bottom: 1px solid #000000;
    padding-bottom: 5px;
    font: 400 14px NeueHaasUnicaW1G !important;
  }
  .dialog-confirm {
    width: 200px;
    padding: 0 !important;
    height: 25px !important;
    line-height: 25px !important;
  }
  .el-form-item__content {
    line-height: 30px !important;
  }
  .setAddr {
    font-weight: 400;
    font-size: 12px;
    margin-top: 10px;
    color: #bcbcbc;
  }

  .setAddr2 {
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;
    color: #000000;
  }

  .active {
    background-color: #000000 !important;
  }

  /* 设置input下placeholder文字颜色 */
  input::placeholder {
    color: #bcbcbc !important;
    text-align: left;
    opacity: 1;
  }

  .el-autocomplete input {
    text-align: center !important;
  }

  .el-input input {
    width: 100%;
    height: 25px;
    line-height: 25px;
    border: none;
    outline: medium;
    border-bottom: 1px solid #000000;
    opacity: 1;
    border-radius: 0;
    padding: 0 !important;
    font: 400 14px NeueHaasUnicaW1G !important;
    padding-bottom: 5px !important;
  }

  .el-input input::placeholder {
    text-align: left;
  }
  // .el-dialog {
  //   border-radius: 20px !important;
  //   margin: 250px auto 50px !important;
  // }

  .setButtonColor {
    background-color: #000000;
    opacity: 1;
  }

  // .el-dialog__body {
  //   padding: 0 20px !important;
  // }
  .profile_box {
    width: 100%;
    // padding-bottom: 240px;
    box-sizing: border-box;

    .profile_top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      // backdrop-filter: saturate(180%) blur(30px) opacity(80%);
    }

    .profile_sec {
      width: 100%;
      position: fixed;
      top: 41.5px;
      left: 0;
      z-index: 999;
      box-sizing: border-box;
      // backdrop-filter: saturate(180%) blur(30px) opacity(80%);
    }

    .profile_main {
      width: 100%;
      box-sizing: border-box;
      margin-top: 150px;
      font-size: 14px;
      padding-bottom: 150px;
      .el-button {
        font: 400 14px NeueHaasUnicaW1G !important;
      }
      .profile_content {
        width: 86%;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .profile_content2 {
        width: 86%;
        height: 100%;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 40px;
      }
      .profile_content3 {
        width: 86%;
        height: 100%;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 40px;
        .line {
          border: none;
          border-top: 0.1em solid #000000;
          width: 100%;
          height: 1px;
        }
        .fa-panel {
          display: flex;
          height: 40px;
          line-height: 40px;
          align-items: center;
          margin-top: 30px;
          margin-bottom: 30px;
          border-top: 1.5px solid #bcbcbc;
          border-bottom: 1.5px solid #bcbcbc;
        }
      }
    }

    // .profile_footer {
    //   width: 100%;
    //   box-sizing: border-box;
    //   position: fixed;
    //   bottom: 0px;
    // }
  }
}
</style>