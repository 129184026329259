<template>
  <div class="home" v-show="isLoad">
    <div class="box">      
      <div :class="['top',bgClass]">
          <AppNavigator/>
          <TextScroll></TextScroll>
      </div>
      <div class="main">
         <div  class="main-box1">        
           <div class="title">{{$t("lang.home.reimagine")}}</div> 
            <div class="sub-title" v-html="$t('lang.home.systemRemark')"></div>
            <div class="button-box"> 
              <button  round v-if="$store.state.showMarketplace" @click="goToMarket()">{{$t("lang.home.shopNow")}}</button>
              <button  round v-if="!$store.state.showMarketplace" @click="goToDownloadApp()">{{$t("lang.footer.downloadApp")}}</button>
            </div>
        </div>
        <div class="main-box2">
          <img src="../assets/Frontpage/1.jpg" @load="imageLoad">
          <div class="border-left-mask"></div>
          <div class="border-right-mask"></div>
          <div class="text-box">
            <div class="text1">{{$t("lang.home.technologyText")}}</div>
            <div class="text2" v-html="'ZHEN. ' +$t('lang.home.technologyRemark')"></div>
          </div>
        </div>
        <div class="main-box3">
            <div class="box-content">
              <!-- <div style="width:1px;height:15em;position: absolute;left:0;top:-16.5em;background:#fff;z-index:999;"></div> -->
              <div><span style="font-weight:bold;">ZHEN. </span> <span style="opacity:0.9;">{{$t("lang.home.nftText")}}</span></div>
              <div class="text" v-html="$t('lang.home.nftFeature')"></div>
              <div style="opacity:0.9;">{{$t("lang.home.nftAuthenticity")}}</div>
            </div>
        </div>
        <div class="main-box4">
          <div class="box-content">
            <div class="left">
              <div class="title" v-html="$t('lang.home.tapText')"></div>
              <div class="sub-title" v-html="$t('lang.home.tapFeature')"></div>
            </div>
            <div class="right"><img src="../assets/Frontpage/2.jpg" style="width:100%;margin-left:-200px;"></div>
          </div>
        </div>
        <div class="main-box5" ref="panel1">
          <div class="box-content1">
            <div class="title-box">
              <div class="small-title">{{$t("lang.home.experienceText")}}</div>
              <div class="title" v-html="$t('lang.home.ownershipText')"></div>
              <div class="sub-title" v-html="$t('lang.home.ownershipRemark')"></div>
            </div>
            <div class="img-box"><img src="../assets/Frontpage/3.png" style="width:100%;"></div>
          </div>
          <div class="box-content2">
            <div class="img-box">
              <img src="../assets/Frontpage/4.png" class="img1">
              <img src="../assets/Frontpage/5.png" class="img2">
            </div>
          </div>
        </div>
        <div class="main-box6">
          <div class="title-box">
              <div class="title1">{{$t("lang.home.artText")}}</div>
              <div class="title2">{{$t("lang.home.artText1")}}</div>
              <div class="title3" v-html="$t('lang.home.artRemark')"></div>
          </div>
          <div class="img-box">
            <img src="../assets/Frontpage/9.jpg" style="width:25%;">
            <div class="box-content">
              <div class="left">
                <img src="../assets/Frontpage/6.png" class="img1">
                <img src="../assets/Frontpage/7.png" class="img2">
                <img src="../assets/Frontpage/8.png" class="img3">
              </div>
              <div class="right">
                <img src="../assets/Frontpage/10.png" class="img1">
                <img src="../assets/Frontpage/11.png" class="img2">
              </div>
            </div>
          </div>
        </div>
        <div class="main-box7">
          <div class="left">
            <img src="../assets/Frontpage/12.png" style="width:60%;">
          </div>
          <div class="right">
              <div>
                <div class="title" v-html="$t('lang.home.appText')"></div>
                <div class="flex-panel">
                  <div class="flex-row">
                    <div @click="shrinkEvent(1)" class="text">{{$t("lang.home.tapText").replace('&ltbr&gt',' ')}}</div>
                    <div @click="shrinkEvent(1)" style="cursor: pointer;"><div :class="class1=='show'?'common-minus':'common-plus'" style="float: right;"></div></div>
                  </div>
                  <div :class="['remark',class1]">{{$t("lang.home.authMethod")}}</div>
                </div>
                <div class="flex-panel">
                  <div class="flex-row">
                    <div @click="shrinkEvent(2)" class="text">{{$t("lang.home.productText")}}</div>
                    <div @click="shrinkEvent(2)" style="cursor: pointer;"><div :class="class2=='show'?'common-minus':'common-plus'" style="float: right;"></div></div>
                  </div>
                  <div :class="['remark',class2]">{{$t("lang.home.productOwnership")}}</div>
                </div>
                <div class="flex-panel">
                  <div class="flex-row">
                    <div @click="shrinkEvent(3)" class="text">{{$t("lang.home.collectorsText")}}</div>
                    <div @click="shrinkEvent(3)" style="cursor: pointer;"><div :class="class3=='show'?'common-minus':'common-plus'" style="float: right;"></div></div>
                  </div>
                  <div :class="['remark',class3]">{{$t("lang.home.collectorsRemark")}}</div>
                </div>
                <div class="flex-panel">
                  <div class="flex-row">
                    <div @click="shrinkEvent(4)" class="text">{{$t("lang.home.contentText")}}</div>
                    <div @click="shrinkEvent(4)" style="cursor: pointer;"><div :class="class4=='show'?'common-minus':'common-plus'" style="float: right;"></div></div>
                  </div>
                  <div :class="['remark',class4]">{{$t("lang.home.contentRemark")}}</div>
                </div>
                <div class="flex-panel">
                  <div class="flex-row">
                    <div @click="shrinkEvent(5)" class="text">{{$t("lang.home.shopText")}}</div>
                    <div @click="shrinkEvent(5)" style="cursor: pointer;"><div :class="class5=='show'?'common-minus':'common-plus'" style="float: right;"></div></div>
                  </div>
                  <div :class="['remark',class5]">{{$t("lang.home.shopRemark")}}</div>
                </div>
              </div>
          </div>
        </div>
        <div class="main-box8" v-if="$store.state.showMarketplace">
            <div class="text1" v-html="$t('lang.home.nftDrops')"></div>
            <div class="text2">{{$t("lang.home.nftDropsRemark")}}</div>
            <div class="text3">{{$t("lang.home.best")}}</div>
            <div style="margin-top: 1em;"><el-button round class="shop-btn" @click="goToMarket()">{{$t("lang.home.shopNow")}}</el-button></div>
            <img src="../assets/Frontpage/13.jpg" class="img">
        </div>
        <div class="main-box9" ref="panel2">
          <div class="left">
            <div class="title">{{$t("lang.home.artistTitle")}}</div>
            <div class="sub-title"><span style="font-weight:800;">ZHEN.</span> {{$t("lang.home.artistRemark")}}</div>
            <div class="author_name" :style="isDefault?'color:#bcbcbc':''" @mouseover="mouseoverEvent(0)" @mouseout="mouseoutEvent()">DANIEL ARSHAM</div>
            <div class="author_name" @mouseover="mouseoverEvent(1)" @mouseout="mouseoutEvent()">HAJIME SORAYAMA</div>
            <div class="author_name" @mouseover="mouseoverEvent(2)" @mouseout="mouseoutEvent()">JAVIER CALLEJA</div>
            <div class="author_name" @mouseover="mouseoverEvent(3)" @mouseout="mouseoutEvent()">EDGAR PLANS</div>
            <div class="author_name" @mouseover="mouseoverEvent(4)" @mouseout="mouseoutEvent()">JAMES JARVIS</div>
            <div class="author_name" @mouseover="mouseoverEvent(5)" @mouseout="mouseoutEvent()">HAROSHI</div>
          </div>
          <div class="right"> 
            <img v-show="imgType == 1" src="../assets/Frontpage/14.jpg"  class="author_img">
            <img v-show="imgType == 2" src="../assets/Frontpage/15.jpg"  class="author_img">
            <img v-show="imgType == 3" src="../assets/Frontpage/16.jpg"  class="author_img">
            <img v-show="imgType == 4" src="../assets/Frontpage/17.jpg"  class="author_img">
            <img v-show="imgType == 5" src="../assets/Frontpage/18.jpg"  class="author_img">
            <img v-show="imgType == 6" src="../assets/Frontpage/19.jpg"  class="author_img">
          </div>
      </div>
      </div>
      <div class="footer_sub">
        <div class="footer_subcontent">
          <div class="data1">{{$t("lang.home.miss")}}</div>
          <div class="data2">{{$t("lang.home.joinUs")}}</div>
          <!--
          <div class="footer_box" @change="subscribeEmail('ruleForm')">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
               <el-form-item label="" prop="yourEmail">
                  <el-input v-model="ruleForm.yourEmail" :placeholder="$t('lang.home.holder')"></el-input>
               </el-form-item>
            </el-form>
          </div>
        -->
        <!--  <div>
              <el-dialog
                :title="$t('lang.home.note')"
                :visible.sync="centerDialogVisible"
                width="25%"
                center>
                <span style="text-align: center !important">{{ $t('lang.home.holder4') }}</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="centerDialogVisible = false">{{ $t('lang.home.cancel') }}</el-button> 
                  <el-button type="primary" @click="centerDialogVisible = false">{{ $t('lang.home.confirm') }}</el-button>
                </span>
              </el-dialog>
          </div>
          -->        
          <div class="footer_box" >
            <div style="position:absolute;right:20px;width:16px;height:5px;" @click="checkEmail">
              <i v-if="isShow" class="el-icon-right" style="color:rgb(188, 188, 188);cursor: pointer;" ref="addicon" ></i>
            </div>
            <input type="email" ref="addEmail" v-model="emailData" class="setInput" :readonly="readonly" :placeholder="holder"  @focus="foucusStyle"  @input="inputStyle"/>
          </div>
        </div>
      </div>
      <div class="footer">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue"
import AppFooter from "@/components/common/AppFooter.vue"
import TextScroll from "@/components/common/TextScroll.vue"
export default {
  name: "Home",
  components: {
    AppNavigator,
    AppFooter,
    TextScroll
  },
   data() {
  //   var checkEmail = (rule, value, callback) => {
  //   const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
  //   if (!value) {
  //     return callback(new Error(this.$t('lang.home.holder2')))
  //   }
  //   setTimeout(() => {
  //     if (mailReg.test(value)) {
  //       callback()
  //     } else {
  //       callback(new Error(this.$t('lang.home.holder3')))
  //     }
  //   }, 100)
  // }
    return {
      isLoad:false,
      class1:'hide',
      class2:'hide',
      class3:'hide',
      class4:'hide',
      class5:'hide',
      isDefault:false,
      holder: this.$t('lang.home.holder'),
      emailData: '',
      fixedData: '',
      isShow: false,
      readonly:false,
      imgType:'',
      bgClass:'bg-black',
      showScroll:true
     // centerDialogVisible: false,
    //   ruleForm: {
    //       yourEmail: ''
    //   },
    //   rules: {
    //        yourEmail: [
    //           { required: true, message: this.$t('lang.home.holder2'), trigger: 'blur' },
    //           { validator: checkEmail, trigger: 'blur' } 
    //         ],
    //   }
     }
  },
  methods: {
    imageLoad() {
      this.isLoad = true;
    },
    foucusStyle(){
      if(this.readonly) {
        return false;
      }
      this.$refs.addEmail.style.paddingLeft = "30"+"px";
      this.$refs.addEmail.style.paddingRight = "40"+"px";
      this.$refs.addEmail.style.color = "#BCBCBC";
      this.$refs.addEmail.setAttribute("placeholder",this.$t('lang.home.holder5'));
      this.$refs.addEmail.setAttribute("class","setInput change");
      //清除错误提示信息
      if(this.$t('lang.home.holder3') ==  this.$refs.addEmail.value) {
        this.$refs.addEmail.value = "";
      }
      this.$refs.addEmail.style.color = "#000000";
      this.$refs.addEmail.style.textAlign = "left";
      this.isShow = true;
    },

    inputStyle(){   
      this.$refs.addicon.style.color = "#000000";
      this.$refs.addEmail.style.color = "#000000";
    },

    checkEmail(){
        var _this = this
        var reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
      　if(this.emailData === ""){ //输入不能为空
             this.$refs.addEmail.style.color = "red";
      　　　　this.$refs.addEmail.value = this.$t('lang.home.holder2');
      　　　　return false;
      　}else if(!reg.test(this.emailData)){ //正则验证不通过，格式不对
             this.$refs.addEmail.style.color = "red";
      　　　　this.$refs.addEmail.value = this.$t('lang.home.holder3');
      　　　　return false;
      　}else{
             this.$http.post('/email/subscription', {email: _this.emailData}).then(res => {
                   if (res.code === 0) {
                      _this.isShow = false;
                      _this.$refs.addEmail.style.color = "#000000";
                      _this.$refs.addEmail.style.paddingLeft = "0"+"px";
                      _this.$refs.addEmail.style.paddingRight = "0"+"px";
                      _this.$refs.addEmail.style.textAlign = "center";
                      _this.emailData = _this.$t('lang.home.holder4');
                      _this.readonly = true;
                      setTimeout(() => {
                        _this.emailData = "";
                        _this.$refs.addEmail.setAttribute("placeholder",_this.holder);                     
                        _this.$refs.addEmail.removeAttribute("class","setInput change");
                        _this.readonly = false;     
                       }, 3000)
                   }
                })
      　　　　return true;
      　}
    },
    goToMarket(){
      this.$router.push("/marketPlace");
    },
    goToDownloadApp() {
      this.$router.push("/app");
    },
    //收缩展开事件
    shrinkEvent(param) {
      let $this = this;
      //关闭其他
      for(let i=1;i<6;i++) {
        if(i != param) {
          $this['class'+i] = 'hide';
        }
      }
      $this['class'+param] = $this['class'+param] == 'hide'?'show':'hide';
    },
    //鼠标放上去展示艺术家作品
    mouseoverEvent(param) {
      this.isDefault = false;
      this.imgType = param+1;
    },
    scrollClose() {
      this.showScroll = false;
    },
    //鼠标离开隐藏艺术家作品
    mouseoutEvent(){
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let offsetTop =  this.$refs.panel2.offsetTop;
      if(scrollTop < offsetTop) {
        this.imgType = 0;
        this.isDefault = false;
      }else {
        this.imgType = 1;
        this.isDefault = true;
      }
    },
    scrollEvent() {
      let $this = this;
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let offsetTop1 =  $this.$refs.panel1.offsetTop;
      let offsetTop2 =  $this.$refs.panel2.offsetTop;
      if(scrollTop >= offsetTop1 && scrollTop < offsetTop2) {
        $this.bgClass = 'bg-white';
      }else{
        $this.bgClass = 'bg-black';
      }
      if(scrollTop+1000 >= offsetTop2) {
        $this.imgType = 1;
        $this.isDefault = true;
      }else{
        $this.isDefault = false;
        $this.imgType = '';
      }
    }
  },
  created() {
    this.utils.logEvent('HomePageViewEvent');
  },
  mounted() {
    //绑定事件
    window.addEventListener('scroll',this.scrollEvent,false);
    //this.openFullScreen2();
  },
  destroyed() {
    //解绑事件
    window.removeEventListener('scroll',this.scrollEvent,false);
  }
};
</script>
<style lang="less" scoped>
 
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
  .icon-jiantou:hover {
    color: #000000 !important;
    font-weight: bold;
  }
  .author_name {
    font-size:50px;
    cursor: pointer;
    line-height: 55px;
  }
  .author_name:hover {
    color: #888;
  }
  input {
    font-family: 'NeueHaasUnicaW1G-Regular' !important;
    font-size: 14px !important;
    line-height: 17px !important;
    // padding-right: 40px;
  }
  input:focus {
    outline: none !important;
  }
  input::-ms-input-placeholder {
    text-align: center !important;
    color: #000000 !important;
    font-weight: 600 !important;
    font-family: 'NeueHaasUnicaW1G-Regular' !important;
    font-size: 14px !important;
    line-height: 17px !important;
  }
  input::-webkit-input-placeholder {
    text-align: center !important;
    color: #000000 !important;
    font-family: 'NeueHaasUnicaW1G-Regular' !important;
    font-size: 14px !important;
    line-height: 17px !important;
  }
  .flex-panel {
    border-bottom:0.1em solid #000000;
  }
  .flex-row {
    display: flex;
    align-items: center;
    height: 40px;
    line-height: 40px;
  }
  .el-input__inner {
    border: 0px;
    outline: none;
    height: 26px;
    width: 250px;
    background: #ffffff;
    opacity: 1;
    border-radius: 18px !important;
    text-align: center
  }

  .el-form-item__error {
    color: #ffffff !important;
  }
  .box {
    .top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      /* backdrop-filter: saturate(180%) blur(30px) opacity(80%); */
      border-bottom: 1px solid #fff;
    }
    .text-divider {
      width: 100%;
      height: 1px;
      border-top: 1px solid #000000;
    }
    .main {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      .main-box1 {
        padding:100px 0 0 7%;
        color: #FFFFFF;
        background-color:#000000;
        .title {
          font-size:50px;
          font-weight: 400;
        }
        .sub-title {
          color:#fff;
          font-size: 21px;
          line-height:24px;
        }
        .button-box {
          margin-top:20px;
          button {
            font-weight: 400;
            width:140px;
            height:25px;
            line-height: 25px;
            font-size:14px;
            background: #F4F4F4;
            border-radius: 20px;
            border: none;
            cursor: pointer;
          }
        }
      }
      .main-box2 {
        background-color: #000;
        position: relative;
        .border-right-mask {
          width:10px;
          height:100%;
          position:absolute;
          top: 0;
          right: 5%;
          background-color: #000;
        }
        .border-left-mask {
          width:10px;
          height:100%;
          position:absolute;
          top: 0;
          left: 15%;
          background-color: #000;
        }
        .text-box {
          position: absolute;
          left: 7%;
          bottom:35%;
          width:40%;
          .text1 {
            opacity: 0.9;
            font-size:14px;
          }
          .text2 {
            font-weight: 400;
            font-size:50px;
            margin-top:10px;
            line-height:50px;
          }
        }
        img {
          width: 80%;
          margin-left: 15%;
        }
      }
      .main-box3 {
        background-color:#000000;
        width:100%;
        font-size:21px;
        .box-content {
          margin-left:40%;
          position: relative;
          padding-left: 20px;
          .text {
            margin:10px 0;
            line-height: 24px;
            opacity:0.9;
          }
        }
      }
      .main-box4 {
        padding:250px 7% 50px 7%;
        background-color:#000000;
        .box-content {
          display:flex;
          .left {
            width:50%;
            padding:55px 15px 0 0;
            .title {
              font-weight:400;
              font-size:50px;
              line-height: 50px;
            }
            .sub-title {
              margin:15px 0;
              font-size:21px;
              opacity: 0.9;
              line-height:24px;
            }
          }
          .right {
            width:50%;
            text-align:center;
          }
        }
      }
      .main-box5 {
        padding:100px 7% 0 7%;
        position:relative;
        .box-content1 {
          position: relative;
          .title-box {
            width:55%;
            padding:15px 15px 0 0;
            color:#000000;
            .small-title {
              font-size:14px;
              font-weight:400;
            }
            .title {
              font-weight:400;
              font-size:50px;
              margin-top:10px;
              line-height:50px;
            }
            .sub-title {
              margin:15px 0;
              font-size:21px;
              font-weight:400;
              line-height:24px;
            }
          }
          .img-box {
            width:45%;
            position:absolute;
            right: 0;
            top: 30px;
          }
        }
        .box-content2 {
          text-align: center;
          padding-top: 50px;
          .img-box {
            width:55%;
            text-align: right;
            position:relative;
            .img1 {
              width:60%;
            }
            .img2 {
              width:60%;
              position:absolute;
              top:16%;
              right:-30%;
            }
          }
        }
      }
      .main-box6 {
        padding-top:20em;
        position:relative;
        .title-box {
          width:55%;
          padding:15px 15px 0 0;
          color:#000000;
          padding:0 7%;
          .title1 {
            font-size:50px;
            font-weight:400;
            line-height:50px;
          }
          .title2 {
            font-weight:400;
            font-size:50px;
            line-height:50px;
          }
          .title3 {
            margin:15px 0;
            font-size:21px;
            font-weight:400;
            line-height:24px;
          }
        }
        .img-box {
          text-align:center;
          margin-top:7em;
          position:relative;
          .box-content {
            display:flex;
            align-items:center;
            position:absolute;
            top:50%;
            left:0;
            margin-top:-10%;
            .left {
              flex:1;
              .img1 {
                width:33%;
                margin-left:-12%;
              }
              .img2 {
                width:33%;
                margin-left:6%;
                margin-bottom:-10em;
              }
              .img3 {
                width:33%;
                margin-left:7%;
                margin-right:-5px;
              }
            }
            .right {
              flex:1;
              text-align:right;
              .img1 {
                width:33%;
                margin-right:6%;
              }
              .img2 {
                width:33%;
                margin-right:-12%;
                margin-bottom:-10em;
              }
            }
          }
        }
      }
      .main-box7 {
        display: flex;
        margin-top:10em;
        .left {
          flex: 1;
          text-align: center;
        }
        .right {
          flex: 1;
          color: #000000 !important;
          padding-top:12%;
          padding-right:7%;
          .title {
            font-size:50px;
            line-height:50px;
            font-weight:400;
            padding-bottom:1.5em;
          }
          .text {
            flex:1;
            cursor: pointer;
            opacity: 0.9;
            font-size: 14px;
          }
          .remark {
            font-size:21px;
            line-height:24px;
            padding-bottom:20px;
          }
        }
      }
      .main-box8 {
        margin-top:10em;
        color:#000000 !important;
        text-align:center;
        font-weight:400;
        .text1 {
          font-size:50px;
          line-height:50px;
        }
        .text2 {
          font-size:21px;
          margin-top:10px;
          line-height:24px;
        }
        .text3 {
          font-size:21px;
          line-height:24px;
        }
        .shop-btn {
          background-color:#000000;
          color:#fff;
          width:150px;
          line-height:25px;
          padding:0;
          border:none;
        }
        .img {
          width:80%;
          margin-top:1em;
        }
      }
      .main-box9 {
        height:718px;
        margin-top:10em;
        background-color:#000000;
        font-weight:400;
        padding:0 7% 40px 7%;
        display: flex;
        align-items: center;
        .left {
          flex: 1;
          .title {
            font-size:14px;
            line-height:17px;
          }
          .sub-title {
            font-size:21px;
            line-height:24px;
            padding:15px 0;
          }
        }
        .right {
          flex: 1;
          text-align:right;
          .author_img {
            height:25em;
            vertical-align: middle;
            padding-top: 80px;
          }
        }
      }
      .main_footer {
        width: 100%;
        height: 379px;
        background: #000000;
        color: #ffffff;
      }
    }
    .footer_sub {
      width: 100%;
      height: 165px;
      background-color: #FF7A00;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      .footer_subcontent {
        padding-bottom: 8px;
        .data1 {
          font-size: 21px;
          color: #fff;
          opacity: 1;
          text-align: center;
          line-height: 24px;
        }
        .data2 {
          font-size: 21px;
          color: #fff;
          opacity: 1;
          text-align: center;
          line-height: 24px;
        }
        .footer_box {
          width: 250px;
          margin-top: 10px;
          box-sizing: border-box;
          position: relative;
          input {
            box-sizing: border-box;
            border: 0px;
            outline: none;
            height: 26px;
            width: 250px;
            background: #ffffff;
            opacity: 1;
            border-radius: 18px;
          } 
          .change::-webkit-input-placeholder {
             color:rgb(188, 188, 188) !important;
             text-align: left !important;         
          }    
         .change::-moz-placeholder {
             color:rgb(188, 188, 188) !important;
             text-align: left !important;       
          }                  
          .change::-ms-input-placeholder {
             color:rgb(188, 188, 188) !important;
             text-align: left !important;
          }   
        }
      }
    }
    .footer {
      width: 100%;
      box-sizing: border-box;
    }
  }
}
</style>
