<template>
  <div class="ownership-panel">
    <div
      style="
        border: none;
        border-top: 0.1em solid #000000;
        width: 100%;
        height: 1px;
      "></div>
    <!-- <div
              style="
                display: flex;
                align-items: center;
                padding: 10px 0;
                cursor: pointer;
              "
              @click="showTransfer()"
            >
              <div style="flex: 1; text-align: left">
                <span>{{ $t("lang.profile.transfer") }}</span>
              </div>
              <div style="flex: 1; text-align: right">
                <div
                  :class="isShowTransfer ? 'common-minus' : 'common-plus'"
                  style="float: right"
                ></div>
              </div>
            </div> -->
    <div
      v-show="transferData.listTransfer && transferData.listTransfer.length > 0"
      style="margin-bottom: 40px">
      <div
        v-for="(item, index) in transferData.listTransfer"
        :key="index"
        class="transfer-unit"
      >
        <div style="flex: 1">{{ item.chipCode }}</div>
        <div
          style="
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: flex-end;">
          <div v-if="item.state != 1" :class="$t('lang.dashboard.transferHistory.transferState')[item.state].className">
            {{ $t("lang.dashboard.transferHistory.transferState")[item.state].text }}
          </div>
          <div v-else>
            <el-button round class="confirm" @click="confirmTransfer(item)">{{ $t("lang.dashboard.transferHistory.transferState")[item.state].text }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="
        transferData.listTransfer && transferData.listTransfer.length == 0
      "
      class="no-data"
    >
      {{ $t("lang.myprofile.noTransfer") }}
    </div>
    <el-dialog
      center
      width="800px"
      :show-close="false"
      custom-class="confirm_ownership_dialog"
      :visible.sync="dialogVisible3"
      :close-on-click-modal="false"
    >
      <div style="display: flex; font-size: 16px">
        <div style="flex: 1">
          <div class="iconBox" v-if="!confirmInfo.videoUrl">
            <img
              :src="confirmInfo.mainImg"
              style="
                width: 100%;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                display: block;
              "
            />

            <div class="iconClass" v-if="false">
               <img
                v-if="confirmInfo.nftChain == 1"
                src="../..//assets/Frontpage/ETH.png"
              />
               <img
                v-else-if="confirmInfo.nftChain == 2"
                src="../../assets/Frontpage/matic.png"
              />
            </div>
          </div>

          <div class="iconBox" v-if="confirmInfo.videoUrl">
            <video :src="confirmInfo.videoUrl" autoplay loop="loop">
              您的浏览器不支持视频播放。
            </video>

            <div class="iconClass" v-if="false">
                <img
                v-if="confirmInfo.nftChain == 1"
                src="../..//assets/Frontpage/ETH.png"
              />
                <img
                v-else-if="confirmInfo.nftChain == 2"
                src="../../assets/Frontpage/matic.png"
              />
            </div>
          </div>
        </div>
        <div
          style="
            flex: 1;
            padding: 20px 30px;
            display: flex;
            flex-direction: column;
            color: #000000;
          "
        >
          <div style="font-weight: 400">
            {{ $t("lang.profile.confirmDialog.title") }}
          </div>
          <div style="margin-top: 15px">
            <div>{{ confirmInfo.artistName }}</div>
            <div style="line-height: 16px">
              {{ confirmInfo.productName }}
            </div>
          </div>
          <div style="font-size: 12px; color: #bcbcbc; margin-top: 15px">
            {{ $t("lang.profile.confirmDialog.desc1") }}
          </div>
          <div style="font-size: 12px; color: #bcbcbc; margin-top: 15px">
            {{ $t("lang.profile.confirmDialog.desc2") }}
          </div>
          <div style="flex: 1; padding-top: 15px"></div>
          <div style="text-align: center">
            <div slot="footer" class="dialog-footer">
              <el-button
                round
                class="confirm-ownership-btn"
                @click="commitTransferOwnership"
                >{{ $t("lang.profile.confirmDialog.confirm") }}</el-button
              >
            </div>
            <div slot="footer" class="dialog-footer" style="margin-top: 10px">
              <el-button
                @click="dialogVisible3 = false"
                round
                style="
                  width: 200px;
                  border: none;
                  background-color: #ffffff;
                  color: #bcbcbc;
                  opacity: 1;
                  padding: 10px !important;
                "
                >{{ $t("lang.myMarkPlace.cancel") }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "OwnershipHistory",
  data() {
    return {
      transferData: [],
      isShowTransfer: false,
      dialogVisible3: false,
      confirmInfo: {}
    };
  },
  methods: {
    getTransferState(state) {
      let lang = localStorage.getItem("lang") || "en";
      return this.transferState[lang][state];
    },
    confirmTransfer(item) {
      if (item.state != 1) {
        return;
      }
      this.dialogVisible3 = true;
      this.confirmInfo = item;
    },
    commitTransferOwnership() {
      let $this = this;
      this.$http
        .post("property/transfer/confirm", {
          token: this.utils.getToken(),
          transferId: this.confirmInfo.transferId,
        })
        .then((res) => {
          if (res.code === 0) {
            $this.dialogVisible3 = false;
            $this.confirmInfo.state = 2;
          } else {
            $this.utils.alert("", res.msg);
          }
        });
    },
  },
  created() {
    this.$parent.activeIndex = this.$route.meta.tab;
    //查询产权转移列表
    this.$http
      .post("property/transfer/list", { token: this.utils.getToken() })
      .then((res) => {
        if (res.code === 0) {
          let data = res.data;
          this.transferData = data;
        }
      });
  },
  mounted() {
    //初始化底部栏位 防止上下闪跳
    this.$parent.initFooter();
  }
};
</script>

<style lang="less">
.ownership-panel {
  .no-data {
    color: #bcbcbc;
    font-size: 14px;
    padding: 10px 0 30px 0;
  }

  .pending {
    color: #FF7A00;
  }

  .approved {
    color: #000000;
  }

  .rejected {
    color: #BCBCBC;
  }

  .canceled {
    color: #BCBCBC;
  }

  .confirm-ownership-btn,
  .confirm  {
    width: 180px;
    height: 25px;
    line-height: 22px;
    border: none;
    padding: 0 !important;
    color: #ffffff;
    background: #000000;
  }

  .confirm-ownership-btn,
  .confirm-ownership-btn:focus,
  .confirm-ownership-btn:hover,
  .confirm,.confirm:focus,.confirm:hover  {
    color: #ffffff !important;
    background-color: #000000 !important;
  }

  .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*height:600px;*/
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
    border-radius: 10px;
  }

  .confirm_ownership_dialog {
    border-radius: 15px;
  }

  .confirm_ownership_dialog video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    margin-bottom: 40px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .el-dialog .el-dialog__body {
    flex: 1;
    overflow: auto;
  }

  .confirm_ownership_dialog .el-dialog__body {
    padding: 0 !important;
  }

  .confirm_ownership_dialog .el-dialog__header {
    display: none;
  }
  .transfer-unit {
    height: 40px;
    line-height: 40px;
    display: flex;
    border-bottom: 1.5px solid #bcbcbc;
  }
}
</style>