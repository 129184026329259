<template>
  <div :class="scrollClass">
    <div class="scroll-panel">
      <div class="text-panel" ref="scrollPanel" @click="goto()">
        <div  ref="textScroll" class="scroll-text" style="padding:0 150px;">
          <span style="margin-right:300px;">{{$t("lang.home.runningText1")}}</span>
          <span style="margin-right:300px;">{{$t("lang.home.runningText2")}}</span>
          <span>{{$t("lang.home.runningText3")}}</span>
        </div>
        <div  ref="textScrollAfter" class="scroll-text" style="padding:0 150px;">
          <span style="margin-right:300px;">{{$t("lang.home.runningText1")}}</span>
          <span style="margin-right:300px;">{{$t("lang.home.runningText2")}}</span>
          <span>{{$t("lang.home.runningText3")}}</span>
        </div>
      </div>
      <div class="scroll-close" @click="scrollClose"><i class="el-icon-close"></i></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TextScroll",
    data() {
      return {
        scrollClass:'hide',
        timer:""
      };
    },
    methods: {
      scrollClose() {
        //销毁定时器
        if(this.timer) {
          clearInterval(this.timer);
        }
        this.scrollClass = 'hide';
      },
      move () {
        // 获取文字text 的计算后宽度  （由于overflow的存在，直接获取不到，需要独立的node计算）
        let scrollWidth = this.$refs.scrollPanel.getBoundingClientRect().width;
        let textWidth = this.$refs.textScroll.getBoundingClientRect().width;
        let distance = scrollWidth-150; // 位移距离
        let distance1 = scrollWidth*2-150;
        // 如果滚动文字超过滚动区域的宽度
        if(textWidth >= scrollWidth) {
          distance1 = scrollWidth+textWidth-150;
        }
        // 设置位移
        this.timer = setInterval(() => {
          distance = distance - 1.3;
          distance1 = distance1 - 1.3;
          this.$refs.textScroll.style.transform = 'translateX(' + distance + 'px)';
          this.$refs.textScrollAfter.style.transform = 'translateX(' + distance1 + 'px)';
          // 如果滚动文字超过滚动区域的宽度
          if(textWidth >= scrollWidth) {
            //第一轮字幕如果已经超出屏幕 重新到后面排队
            if(distance < -textWidth) {
              distance = scrollWidth+(textWidth-scrollWidth);
            }
            //第二轮字幕如果已经超出屏幕 重新到后面排队
            if(distance1 < -textWidth) {
              distance1 = scrollWidth+(textWidth-scrollWidth);
            }
          }else {
            //第一轮字幕如果已经超出屏幕 重新到后面排队
            if(distance < -scrollWidth) {
              distance = scrollWidth;
            }
            //第二轮字幕如果已经超出屏幕 重新到后面排队
            if(distance1 < -scrollWidth) {
              distance1 = scrollWidth;
            }
          }
        }, 20)
      },
      goto() {
        this.$router.push({
          path:'/collection',
          query:{activityId:1}
        });
      }
    },
    mounted() {
      let _this = this;
      this.$nextTick(() => {
        setTimeout(() => {
          _this.move();
        }, 500);
      });
    },
    destroyed() {
      //销毁定时器
      if(this.timer) {
        clearInterval(this.timer);
      }
    }
  };
</script>
<style lang="less" scoped>
 .scroll-panel {
   width:100%;
   height:40px;
   line-height:40px;
   /* background:#000000; */
  //  position: fixed;
  //  top: 0;
  //  left: 0;
   z-index: 999;
   box-sizing: border-box;
 }
 .text-panel {
   width: calc(100% - 60px);
   height:40px;
   line-height:40px;
   position: relative;
   overflow: hidden;
   white-space: nowrap;
   box-sizing: border-box;
   cursor: pointer;
 }
 .show {
   width: 100%;
   height:40px;
   backdrop-filter: saturate(180%) blur(30px);
 }
 .hide {
   height:0;
 }
 .scroll-text {
    font-size:14px;
    transform: translate(99999px);
    position: absolute;
    box-sizing: border-box;
  }
  /* .scroll-text-after {
    color:#fff;
    font-size:14px;
    position: absolute;
    animation-name:mymoveafter;
    animation-duration:35s;
    animation-delay:21s;
    animation-timing-function:linear;
    animation-iteration-count:infinite;
  } */
  .scroll-close {
    float: right;
    margin-top: -40px;
    font-size:14px;
    margin-right: 10px;
    cursor: pointer;
  }
 
</style>