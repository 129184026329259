<template>
  <div class="collection">
    <div class="collection_box">
      <div class="collection_top bg-white">
        <AppNavigator />
      </div>
      <div style="margin-top: 40px; width: 100%; height: 1px"></div>
      <div class="collection_main">
        <div style="width: 100%; padding-bottom: 50px">
          <div style="width: 100%; display: flex">
            <div
              style="
                flex: 1;
                color: #000000;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-right: 80px;
              "
            >
              <div style="flex: 1">
                <div
                  style="font-size: 50px; font-weight: 400; line-height: 50px"
                >
                  {{ detail.title }}
                </div>
                <div
                  style="font-size: 50px; font-weight: 400; line-height: 50px"
                >
                  {{ detail.subTitle }}
                </div>
              </div>
              <div>
                <div
                  style="
                    margin-top: 20px;
                    font-weight: 400;
                    font-size: 21px;
                    padding-bottom: 20px;
                    line-height: 24px;
                  "
                  v-html="detail.intro"
                ></div>
              </div>
            </div>
            <div style="flex: 1">
              <div :style="{ minHeight: bannerHeight + 'px' }">
                <img
                  :src="detail.imgPath"
                  style="width: 100%; display: block"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="width: 100%; height: 1px; border-top: 0.1em solid #000000"
      ></div>
      <div
        style="width: 100%; border-bottom: 0.1em solid #000000"
        v-for="(item, index) in detail.listSubActivity"
        :key="index"
      >
        <div style="width: 86%; margin: 0 auto; padding: 40px 0; display: flex">
          <div style="font-size: 14px; font-family: Helvetica; color: #000000">
            <el-tooltip
              class="item"
              visible-arrow
              effect="light"
              :content="item.nameHover"
              placement="right-end"
            >
              <el-button class="category_btn"
                >{{ item.name }} <i class="el-icon-warning-outline"></i
              ></el-button>
            </el-tooltip>
          </div>
          <div style="flex: 1; text-align: right">
            <div style="font-size: 14px; color: #ff7a00">
              {{ $t("lang.myMarkPlace.event") }}{{ getStateStr(item.state) }}
              <span v-show="item.state == 1 && timeObj['day' + index] > 0"
                >{{ timeObj["day" + index] }} {{ $t("lang.myMarkPlace.day")
                }}<span
                  v-if="
                    timeObj['day' + index] > 1 &&
                    $t('lang.myMarkPlace.day') == 'Day'
                  "
                  >s</span
                ></span
              >&nbsp;
              <span
                v-show="
                  item.state == 1 &&
                  (timeObj['day' + index] > 0 ||
                    timeObj['hour' + index] > 0 ||
                    timeObj['minute' + index] > 0 ||
                    timeObj['second' + index] > 0)
                "
                >{{
                  timeObj["hour" + index] > 9
                    ? timeObj["hour" + index]
                    : "0" + timeObj["hour" + index]
                }}
                :
              </span>
              <span
                v-show="
                  item.state == 1 &&
                  (timeObj['day' + index] > 0 ||
                    timeObj['hour' + index] > 0 ||
                    timeObj['minute' + index] > 0 ||
                    timeObj['second' + index] > 0)
                "
                >{{
                  timeObj["minute" + index] > 9
                    ? timeObj["minute" + index]
                    : "0" + timeObj["minute" + index]
                }}
                :
              </span>
              <span
                v-show="
                  item.state == 1 &&
                  (timeObj['day' + index] > 0 ||
                    timeObj['hour' + index] > 0 ||
                    timeObj['minute' + index] > 0 ||
                    timeObj['second' + index] > 0)
                "
                >{{
                  timeObj["second" + index] > 9
                    ? timeObj["second" + index]
                    : "0" + timeObj["second" + index]
                }}</span
              >
            </div>
          </div>
        </div>
        <div class="collection_content2">
          <div
            v-for="(product, index1) in item.listWares"
            :key="index1"
            class="img_box"
            @click="goToProduct(product.waresId, item.activitySubId)"
          >
            <div>
              <div class="iconBox" v-if="product.imgUrl && !product.videoUrl">
                <img
                  :src="product.imgUrl"
                  ref="waresImg"
                  alt=""
                  :height="imgHeight"
                  style="display: block"
                />
                <div class="iconClass" v-if="false">
                                            <img
                    v-if="product.nftChain == 1"
                    src="../..//assets/Frontpage/ETH.png"
                  />
                                            <img
                    v-else-if="product.nftChain == 2"
                    src="../../assets/Frontpage/matic.png"
                  />
                </div>
              </div>

              <div class="iconBox" v-if="product.videoUrl">
                <video
                  :src="product.videoUrl"
                  muted
                  autoplay
                  loop="loop"
                  width="100%"
                  :height="imgHeight"
                >
                  您的浏览器不支持视频播放。
                </video>

                <div class="iconClass" v-if="false">
                                            <img
                    v-if="product.nftChain == 1"
                    src="../..//assets/Frontpage/ETH.png"
                  />
                                            <img
                    v-else-if="product.nftChain == 2"
                    src="../../assets/Frontpage/matic.png"
                  />
                </div>
              </div>
            </div>
            <div :class="['category_box', 'category_' + product.category]">
              <el-tooltip
                class="item"
                effect="light"
                visible-arrow
                :content="
                  $t('lang.productType')[product.category]
                    ? $t('lang.productType')[product.category].hover
                    : ''
                "
                placement="right-end"
              >
                <el-button class="category_text">
                  {{
                    $t("lang.productType")[product.category]
                      ? $t("lang.productType")[product.category].title
                      : ""
                  }}<i class="el-icon-warning-outline"></i>
                </el-button>
              </el-tooltip>
            </div>
            <div class="bottom_info">
              <div
                style="
                  display: flex;
                  color: #000000;
                  margin-top: 5px;
                  height: 17px;
                  line-height: 17px;
                "
              >
                <div style="flex: 1">{{ product.artistName }}</div>
                <div style="flex: 1; text-align: right">
                  {{ product.price }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  color: #000000;
                  height: 17px;
                  line-height: 17px;
                "
              >
                <div style="width: 60%">{{ product.waresName }}</div>
                <div style="width: 40%; text-align: right; color: #ff7a00">
                  {{ product.state }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          width: 86%;
          height: 100%;
          margin: 50px auto 80px auto;
          position: relative;
        "
      >
        <div
          style="
            color: #000000;
            position: absolute;
            top: 0;
            left: 0;
            font-size: 14px;
          "
        >
          {{ $t("lang.myMarkPlace.artist") }}
        </div>
        <div style="text-align: center">
          <div>
            <img :src="detail.artistImgUrl" style="width: 360px" />
          </div>
          <div
            style="
              margin-top: 25px;
              margin-bottom: 25px;
              color: #000000;
              font-size: 14px;
            "
          >
            {{ detail.artistName }}
          </div>
          <div style="margin-bottom: 25px; width: 600px; margin: auto">
            <span style="font-size: 14px; color: #000000">{{
              detail.artistIntro
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="collection_footer" v-show="isLoaded">
      <AppFooter />
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
export default {
  name: "detail",
  components: {
    AppNavigator,
    AppFooter,
  },
  data() {
    return {
      imgHeight: 0,
      bannerHeight: 0,
      isLoaded: false,
      activityId: "",
      detail: {},
      timeObj: { timers: [] },
      isShowCategory: false,
      stateObj: {
        0: {
          en: "COMING SOON",
          cn: "即将发布",
          tw: "即將發布",
        },
        1: {
          en: "CLOSE IN：",
          cn: "距离结束",
          tw: "距離結束",
        },
        2: {
          en: "SOLD OUT",
          cn: "已售罄",
          tw: "已售罄",
        },
      },
      msg: "Connect Wallet",
      to: "0xA8A80d3e7B25920636d1eEc896CF924C39a0a5EF",
    };
  },
  methods: {
    //跳转到产品购买页面
    goToProduct(waresId, activitySubId) {
      this.$router.push({
        path: "/product",
        query: {
          waresId: waresId,
          activityId: this.activityId,
          activitySubId: activitySubId,
        },
      });
    },
    getStateStr(state) {
      let lang = localStorage.getItem("lang") || "en";
      return this.stateObj[state][lang];
    },
    showCategory(type) {
      if (type == 1) {
        this.isShowCategory = true;
      } else {
        this.isShowCategory = false;
      }
    },
    computeSecondToDate(index) {
      let $this = this;
      $this.timeObj["timers"][index] = setTimeout(function () {
        if ($this.timeObj["second" + index] > 0) {
          $this.timeObj["second" + index] =
            parseInt($this.timeObj["second" + index]) - 1;
          $this.computeSecondToDate(index);
        } else {
          if ($this.timeObj["minute" + index] > 0) {
            $this.timeObj["second" + index] = 59;
            $this.timeObj["minute" + index] =
              $this.timeObj["minute" + index] - 1;
          } else {
            if ($this.timeObj["hour" + index] > 0) {
              $this.timeObj["second" + index] = 59;
              $this.timeObj["minute" + index] = 59;
              $this.timeObj["hour" + index] = $this.timeObj["hour" + index] - 1;
            } else {
              if ($this.timeObj["day" + index] > 0) {
                $this.timeObj["second" + index] = 59;
                $this.timeObj["minute" + index] = 59;
                $this.timeObj["hour" + index] = 23;
                $this.timeObj["day" + index] =
                  parseInt($this.timeObj["day" + index]) - 1;
              }
            }
          }
          if (
            $this.timeObj["day" + index] == 0 &&
            $this.timeObj["hour" + index] == 0 &&
            $this.timeObj["minute" + index] == 0 &&
            $this.timeObj["second" + index] == 0
          ) {
            clearTimeout($this.timeObj["timers"][index]);
            $this.getList();
          } else {
            $this.computeSecondToDate(index);
          }
        }
      }, 1000);
    },
    clearAllTimeout(vm) {
      if (vm.timeObj["timers"] && vm.timeObj["timers"].length > 0) {
        for (let i = 0; i < vm.timeObj["timers"].length; i++) {
          if (vm.timeObj["timers"][i]) {
            clearTimeout(vm.timeObj["timers"][i]);
          }
        }
      }
    },
    validParam() {
      let flag = true,
        re = /^[0-9]+[0-9]*/;
      if (!this.activityId) {
        flag = false;
      } else if (!re.test(this.activityId)) {
        flag = false;
      } else if (this.activityId.length > 7) {
        flag = false;
      }
      return flag;
    },
    getList() {
      if (!this.validParam()) {
        this.$router.push({ path: "/notFind" });
        return;
      }
      //查询橱窗详情
      this.$http
        .post(
          "market/detail",
          this.qs.stringify({
            token: this.utils.getToken(),
            activityId: this.activityId,
          })
        )
        .then((res) => {
          if (res.code === 0) {
            this.detail = res.data;
            let list = res.data.listSubActivity;
            this.clearAllTimeout(this);
            if (list && list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                let item = list[i];
                if (item.lastSecond && item.lastSecond > 0) {
                  this.$set(
                    this.timeObj,
                    "day" + i,
                    this.utils.getDateBySecond(item.lastSecond, "D")
                  );
                  this.$set(
                    this.timeObj,
                    "hour" + i,
                    this.utils.getDateBySecond(item.lastSecond, "H")
                  );
                  this.$set(
                    this.timeObj,
                    "minute" + i,
                    this.utils.getDateBySecond(item.lastSecond, "M")
                  );
                  this.$set(
                    this.timeObj,
                    "second" + i,
                    this.utils.getDateBySecond(item.lastSecond, "S")
                  );
                  clearTimeout(this.timeObj["timer" + i]);
                  this.computeSecondToDate(i);
                }
              }
            }
          }
          this.isLoaded = true;
        });
    },
    resizeEvent() {
      this.imgHeight = (document.body.clientWidth * 0.86 - 80) / 3 / 0.8 - 8;
      this.bannerHeight =
        (document.body.clientWidth * 0.86 - 80) / 2 / 1.25 - 10;
    },
    documentHidden() {
      if(document.hidden) {
        if(this.timeObj.timer) {
          clearAllTimeout(this);
        }
      }else {
        this.getList();
      }
    }
  },
  beforeRouteLeave: (to, from, next) => {
    next((vm) => {
      clearAllTimeout(vm);
    });
  },
  created() {
    this.imgHeight = (document.body.clientWidth * 0.86 - 80) / 3 / 0.8 - 8;
    this.bannerHeight = (document.body.clientWidth * 0.86 - 80) / 2 / 1.25 - 10;
    this.activityId = this.$route.query.activityId;
    this.getList();
    // 监听窗口变化
    window.addEventListener("resize", this.resizeEvent, false);
    //页面不可见时
    document.addEventListener("visibilitychange", this.documentHidden, false);
  },
  destroyed() {
    //解绑事件
    window.removeEventListener("resize", this.resizeEvent, false);
    document.removeEventListener("visibilitychange", this.documentHidden, false);
  },
};
</script>

<style lang="less" scoped>
.collection {
  width: 100%;
  height: 100%;
  .collection_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    .collection_top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      // backdrop-filter: saturate(180%) blur(20px);
    }

    .collection_main {
      width: 86%;
      padding-top: 50px;
      box-sizing: border-box;
    }
    .category_btn {
      border: none;
      padding: 0 !important;
    }
    .category_btn:hover,
    .category_btn:focus {
      background: #fff !important;
      color: #000000;
    }

    .collection_content2 {
      width: 86%;
      height: 100%;
      box-sizing: border-box;
      margin: 0 auto;
      margin-bottom: 50px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      .img_box {
        font-size: 14px;
        margin-bottom: 40px;
        width: calc(calc(100% - 80px) / 3);
        padding-bottom: 36px;
      }
      .img_box video {
        display: block;
        object-fit: cover;
      }
      .category_box {
        display: none;
      }
      .img_box:hover {
        border-radius: 12px;
        box-shadow: 0 5px 10px #bcbcbc;
        padding-bottom: 0 !important;
        overflow: hidden;
      }
      .img_box:hover .bottom_info {
        padding: 0 15px 10px 15px;
      }
      .img_box:hover .category_1 {
        height: 26px;
        line-height: 26px;
        background: #000000;
        color: #ffffff;
        display: block;
        padding-left: 15px;
      }
      .img_box:hover .category_2 {
        height: 26px;
        line-height: 26px;
        padding-left: 15px;
        background: #ff7a00;
        display: block;
      }
      .img_box:hover .category_3 {
        height: 26px;
        line-height: 26px;
        padding-left: 15px;
        display: block;
        background: #ffffff;
        color: #000000 !important;
        font-weight: bold !important;
      }
      .img_box:hover .category_3 button {
        font-weight: bold !important;
      }
      .category_text {
        border: none;
        padding: 0 !important;
        background: inherit !important;
        color: inherit;
      }
      .category_text:hover,
      .category_text:focus {
        background: #ff7a00 !important;
        background: inherit !important;
        color: inherit;
      }
      .img_box img {
        width: 100%;
        // height: 100%;
      }

      .img_box:nth-child(3n + 2) {
        margin-left: 40px;
        margin-right: 40px;
      }
    }

    /* .collection_footer {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      bottom: 0px;
    } */
  }
}
</style>