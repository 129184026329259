<template>
  <div class="order_info_panel">
    <div class="order_info_content">
      <div style="display: flex">
        <div style="flex: 1; font-weight: 400">
          {{ $t("lang.myprofile.orderHistory.order") }} #{{ orderId }}
        </div>
        <div style="flex: 1; color: #bcbcbc; text-align: right">
          {{ info.orderTime }}
        </div>
      </div>
      <div>
        <div
          v-for="(item, index) in info.listItem"
          :key="index"
          style="display: flex; margin-top: 20px"
        >
          <div style="width: 70%; display: flex">
            <div style="width: 25%">
              <div class="iconBox" v-if="!item.videoUrl">
                <img :src="item.imgUrl" style="max-width: 100%" />

                <div class="iconClass" v-if="false">
                                            <img
                    v-if="item.nftChain == 1"
                    src="../..//assets/Frontpage/ETH.png"
                  />
                                            <img
                    v-else-if="item.nftChain == 2"
                    src="../../assets/Frontpage/matic.png"
                  />
                </div>
              </div>

              <div class="iconBox" v-if="item.videoUrl">
                <video :src="item.videoUrl" muted autoplay loop="loop">
                  您的浏览器不支持视频播放。
                </video>

                <div class="iconClass" v-if="false">
                                            <img
                    v-if="item.nftChain == 1"
                    src="../..//assets/Frontpage/ETH.png"
                  />
                                            <img
                    v-else-if="item.nftChain == 2"
                    src="../../assets/Frontpage/matic.png"
                  />
                </div>
              </div>
            </div>
            <div style="margin-left: 50px">
              <div>{{ item.artistName }}</div>
              <div style="margin-top: 5px">{{ item.waresName }}</div>
            </div>
            <div style="flex: 1; font-size: 14px; text-align: center">
              x{{ item.quantity }}
            </div>
          </div>
          <div style="width: 30%; text-align: right">
            <div>{{ item.salePrice }}</div>
            <div style="margin-top: 5px" v-show="item.ethSalePrice">
              {{ item.ethSalePrice }}
            </div>
          </div>
        </div>
      </div>
      <div
        style="margin-top: 30px; padding: 10px 0; border-top: 2px solid #000000"
      >
        <div style="display: flex; flex-wrap: wrap">
          <div style="width: 50%" v-if="info.deliveryAddressInfo">
            {{ $t("lang.myprofile.orderHistory.deliveryFee") }}
          </div>
          <div
            style="width: 50%; text-align: right"
            v-if="info.deliveryAddressInfo"
          >
            {{ info.shippingTotal }}
          </div>
          <div style="width: 50%; font-weight: bold">
            {{ $t("lang.myprofile.orderHistory.total") }}
          </div>
          <div style="width: 50%; font-weight: bold; text-align: right">
            {{ info.orderTotal }}
          </div>
        </div>
      </div>
      <div
        style="
          margin-top: 40px;
          border-top: 2px solid #000000;
          padding: 20px 0 50px 0;
        "
        v-if="info.deliveryAddressInfo"
      >
        <div>{{ $t("lang.myprofile.orderHistory.deliveryaddr") }}</div>
        <div v-html="info.deliveryAddressInfo" style="margin-top: 10px"></div>
      </div>
      <div
        style="padding: 20px 0 40px 0; border-top: 2px solid #000000"
        v-if="info.deliveryAddressInfo"
      >
        <div>{{ $t("lang.myprofile.orderHistory.parcels") }}</div>
        <div v-show="info.listDelivery && info.listDelivery.length > 0">
          <div
            v-for="(item, index) in info.listDelivery"
            :key="index"
            style="margin-top: 20px"
          >
            <div>{{ item.shippingCompany }}</div>
            <div>{{ item.shippingNo }}</div>
          </div>
        </div>
        <div
          v-show="info.listDelivery && info.listDelivery.length == 0"
          style="margin-top: 20px; color: #bcbcbc"
        >
          {{ $t("lang.myprofile.orderHistory.nodata") }}
        </div>
      </div>
    </div>
    <div class="btn-panel">
      <el-button class="btn" @click="contactUs" style="width: 100%">{{$t("lang.myprofile.orderHistory.contactUs")}}</el-button>
    </div>
    <div class="back-panel"><span class="back-btn" @click="back">{{ $t("lang.pay.back") }}</span></div>
  </div>
</template>

<script>
export default {
  name: "OrderInfo",
  components: {},
  data() {
    return {
      info: {},
    };
  },
  props: ["orderId"],
  watch: {
    orderId(n, o) {
      //n为新值,o为旧值;
      //查询订单详情
      this.$http
        .post(
          "order/detail",
          this.qs.stringify({
            token: this.utils.getToken(),
            orderId: n,
          })
        )
        .then((res) => {
          if (res.code === 0) {
            this.info = res.data;
          }
        });
    },
  },
  methods: {
    back() {
      this.$parent.back();
    },
    contactUs() {
      location="mailto:support@zhen.io?subject=ZHEN. Contact Request";
    }
  },
  created() {},
};
</script>

<style lang="less">
.order_info_panel {
  border-top: 1.5px solid #000000;
  padding-top: 20px;
  video {
    width: 100%;
    display: block;
    object-fit: cover;
  }
  .order_info_top {
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    top: 0px;
    z-index: 99;
    // backdrop-filter: saturate(180%) blur(30px) opacity(80%);
  }

  .order_info_sec {
    width: 100%;
    position: fixed;
    top: 41.5px;
    left: 0;
    box-sizing: border-box;
    // backdrop-filter: saturate(180%) blur(30px) opacity(80%);
    z-index: 99;
  }
  .order_info_content {
    color: #000000;
    font-size: 14px;
  }
  .btn-panel {
    margin-top: 80px;
    .btn {
      background-color: #F4F4F4;
      color: #000000;
      height: 25px;
      line-height: 25px;
      padding: 0;
      margin: 0;
      width: 160px;
      border-radius: 13px;
    }
  }
  .back-panel {
    text-align:center;
    margin-top:20px;
    .back-btn {
      color: #BCBCBC;
      cursor: pointer;
    }
  }
}
</style>