import axios from 'axios'
import {
    Vue
} from 'vue-property-decorator'
import store from '../store'
import router from '../router'
import utils from '../common/utils'
// let baseUrl = 'http://192.168.50.156:8050/' //开发
// let baseUrl = 'http://192.168.50.121:8050/' //开发
// let baseUrl = 'http://192.168.50.77:8072/'  //内网测试
// let baseUrl = 'http://nft-uat.zheneration.com/'; // 测试
let baseUrl = 'https://market-api.zhen.io/' //现网
// let imgUploadUrl = 'http://192.168.50.156:8070/'; //开发
// let imgUploadUrl = 'http://192.168.50.77:8070/'; //内网测试
// let imgUploadUrl = 'http://admintest.zheneration.com/'; //测试
let imgUploadUrl = 'https://admin.zheneration.com/'; //现网


const service = axios.create({
    baseURL: baseUrl,
    timeout: 10000,
})

service.interceptors.request.use(function (config) {
    //图片上传
    if (config.url == 'user/info/uploadAvatar') {
        config.baseURL = imgUploadUrl;
    }

    // if(config.url == '/notifyHash') {
    //     config.baseURL = "http://localhost:5000/";
    // }
    let language = localStorage.getItem('lang') || 'en';
    config.headers.language = language;
    config.headers.version = "v1.0.1";
    config.headers['Content-Type'] = 'application/json';
    config.headers.platform = 2;
    //图片上传
    if (config.url == 'user/info/uploadAvatar') {
        config.baseURL = imgUploadUrl;
    } else {
        //后端接口都定义为post
        if (config.method === "post") {
            //如果参数不是JSON string 转成JSON string
            if (typeof config.data !== 'string') {
                config.data = config.data ? JSON.stringify(config.data) : "{}";
            }
            //如果是string 因为之前有一些接口是用qs.stringify 转出来的不是标准的json 格式 这里重新转换一下
            else {
                config.data = JSON.stringify((Vue.prototype.qs.parse(config.data)));
            }
        }
        //参数加密
        let encryptObj = utils.encrypt(config.data);
        //请求头带上加密key
        config.headers.authorizationKey = encryptObj.key;
        //加密参数
        config.data = encryptObj.content;
    }
    // if (config.method == "post") {
    //     // 判断参数类型如果是string转成JSON
    //     if (typeof config.data == 'string') {
    //         config.data = Vue.prototype.qs.parse(config.data);
    //     }
    // }

    return config;
},
    function (error) {
        // if (config.method === 'post') {
        //     // loading.close();
        // }
        return Promise.reject(error);
    });


service.interceptors.response.use(response => {
    // loading.close();
    if (response.status !== 200) {
        return Promise.reject(new Error('Request Error'));
    } else {
        //解密  图片上传接口不需要解密特殊处理
        let res = {};
        if (response.config.url != 'user/info/uploadAvatar') {
            res = JSON.parse((utils.decrypt(response.data, response.config.headers.authorizationKey)));
        } else {
            res = response.data;
        }
        //业务错误
        if (res.code !== 0) {
            // 用户未登录
            if (res.code === -10001) {
                //弹出登录窗口
                store.state.notLoginDialog = true;
            }
            // 用户登录过期 
            else if (res.code === -10002) {
                //清除token
                localStorage.removeItem("token")
                sessionStorage.removeItem("token")
                //弹出登录窗口
                store.state.notLoginDialog = true;
            }
            // // 参数错误
            // else if (res.code === -3) {
            //     return res;
            // }
            // 404
            else if (res.code === -4) {
                router.push({
                    path: "/notFind"
                });
            } else {
                return res;
            }

        }
        //成功 
        else {

            return res
        }
    }
});

//响应拦截器
export default service;