<template>
  <div class="myCabinet">
    <div class="myCabinet_box">
      <div
        class="myCabinet_top bg-white"
        style="border-bottom: 1px solid #000000"
      >
        <AppNavigator />
      </div>
      <div class="myCabinet_sec bg-white">
        <SecondNavigator activeIndex="0" />
      </div>
      <div class="myCabinet_main" :style="{ minHeight: mainHeight + 'px' }">
        <div class="myCabinet_content" style="position: relative">
          <div
            style="
              position: absolute;
              top: 30px;
              left: 0;
              font-size: 21px;
              color: #000000;
            "
          >
            {{ $t("lang.cabinet.title") }}
          </div>
          <div style="padding-top: 40px">
            <div style="width: 165px; height: 165px; margin: auto">
              <img
                :src="userImg"
                v-show="showUserImg"
                @load="userImgLoad"
                style="
                  width: 165px;
                  height: 165px;
                  background: #d8d8d8;
                  border-radius: 50%;
                "
              />
            </div>
          </div>
          <div
            style="
              margin-top: 20px;
              color: #000000;
              font-weight: bold;
              font-size: 21px;
            "
          >
            {{ userName }}
          </div>
          <div style="margin-top: 20px" :class="dataClass">
            <el-radio-group v-model="radio" @change="radioChange()">
              <el-radio :label="4" :class="!dataClass?'has-all':''">{{ $t("lang.cabinet.all") }}</el-radio>
              <el-radio :label="23" :disabled="!hasNft  && !dataClass" :class="hasNft&&!dataClass?'has-nft':''">{{ $t("lang.cabinet.nft") }}</el-radio>
              <el-radio :label="1" :disabled="!hasEdition && !dataClass" :class="hasEdition&&!dataClass?'has-edition':''">{{ $t("lang.cabinet.editions") }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div
          class="myCabinet_content2"
          v-show="totalList && totalList.length > 0"
        >
          <div
            v-for="(item, index) in list"
            :key="index"
            class="img_box"
            @click="itemClick(item.chipCode,item.propertyTransferId,item.productType)"
          >
            <div>
              <div class="iconBox" v-if="item.imgUrl && !item.videoUrl">
                <img
                  :src="item.imgUrl"
                  ref="cabinetImg"
                  alt=""
                  :height="imgHeight"
                  style="display: block"/>
                <div class="iconClass"  v-if="false">
                                            <img
                    v-if="item.nftChain == 1"
                    src="../..//assets/Frontpage/ETH.png"
                  />
                                            <img
                    v-else-if="item.nftChain == 2"
                    src="../../assets/Frontpage/matic.png"
                  />
                </div>
              </div>

              <div class="iconBox" v-if="item.videoUrl">
                <video
                  :src="item.videoUrl"
                  muted
                  autoplay
                  loop="loop"
                  width="100%"
                  :height="imgHeight">
                  您的浏览器不支持视频播放。
                </video>

                <div class="iconClass"  v-if="false">
                                            <img
                    v-if="item.nftChain == 1"
                    src="../..//assets/Frontpage/ETH.png"
                  />
                                            <img
                    v-else-if="item.nftChain == 2"
                    src="../../assets/Frontpage/matic.png"
                  />
                </div>
              </div>
            </div>
            <div class="tip1" :style="getStyle(item.productType)">
              <el-tooltip
                class="item"
                effect="light"
                visible-arrow
                :content="$t('lang.productType')[item.productType]?$t('lang.productType')[item.productType].hover:''"
                placement="right-end">
                <el-button class="category_btn">
                  {{$t("lang.productType")[item.productType]?$t("lang.productType")[item.productType].title:''}}
                  <i class="el-icon-warning-outline"></i>
                </el-button>
              </el-tooltip>
            </div>
            <div class="tip2">
              <div style="flex:1;">{{ item.artistName }}</div>
              <div class="state" v-show="item.state == 1">{{$t('lang.dashboard.withdraw.history.outgoing')}}</div>
            </div>
            <div class="tip3">{{ item.productName }}</div>
          </div>
        </div>
        <div
          v-show="totalList && totalList.length === 0"
          style="
            height: 200px;
            width: 100%;
            position: relative;
            margin-bottom: 50px;
          "
        >
          <div class="empty-panel" v-html="$t('lang.cabinet.noText')"></div>
        </div>
      </div>
      <div class="myCabinet_footer">
        <AppFooter />
      </div>
    </div>
    <el-dialog
      center
      width="420px"
      :show-close="false"
      custom-class="not_login_dialog"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
    >
      <div style="padding: 0 30px; color: #000000; font-size: 14px">
        <div style="text-align: center">
          {{ $t("lang.cabinet.dialogTitle") }}
        </div>
        <div style="margin-top: 10px; text-align: center; line-height: 17px">
          {{ $t("lang.cabinet.dialogContent") }}
        </div>
        <div style="text-align: center; margin-top: 10px">
          <button class="goto_login_btn" @click="goToDownload()">
            {{ $t("lang.cabinet.dialogBtn1") }}
          </button>
        </div>
        <div style="text-align: center; margin-top: 10px">
          <button class="goto_sign_btn" @click="goToMarketplace()">
            {{ $t("lang.cabinet.dialogBtn2") }}
          </button>
        </div>
        <div style="text-align: center; margin-top: 5px">
          <el-button
            @click="hideDialog"
            round
            style="
              width: 200px;
              border: none;
              background-color: #ffffff;
              color: #bcbcbc;
              opacity: 1;
              padding: 10px !important;
            "
          >
            {{ $t("lang.other.continue") }}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
import SecondNavigator from "@/components/common/SecondNavigator.vue";
export default {
  name: "MyCabinet",
  components: {
    AppNavigator,
    AppFooter,
    SecondNavigator,
  },
  data() {
    return {
      userImg: this.utils.getProfilePhoto(),
      showUserImg: false,
      userName: "",
      totalList: [],
      showDialog: false,
      list: [],
      radio: 4,
      mainHeight: 0,
      imgHeight: 0,
      dataClass: "",
      showPage: false,
      hasNft:false,
      hasEdition:false,
    };
  },
  methods: {
    userImgLoad() {
      this.showUserImg = true;
    },
    radioChange() {
      let list = this.totalList;
      let type = this.radio;
      let array = [];
      list.forEach(function (item, index) {
        if (item.productType == type) {
          array.push(item);
        } else if (type.toString().indexOf(item.productType) > -1) {
          array.push(item);
        } else if (type == 4) {
          array.push(item);
        }
      });

      this.list = array;
    },
    getStyle(type) {
      if (type == 1) {
        return "background-color:#000000;color:#ffffff;";
      } else if (type == 2) {
        return "background-color:#FF7A00;color:#FFFFFF;";
      } else {
        return "color:#000000;";
      }
    },
    itemClick(chipCode,transferId, type) {
      if (type == 1) {
        this.$router.push({
          path: "/editionDetail",
          query: { chipCode: chipCode },
        });
      } else {
        let param = {chipCode: chipCode};
        if(transferId) {
          param.transferId = transferId;
        }
        this.$router.push({
          path: "/cabinetDetail",
          query:param,
        });
      }
    },
    hideDialog() {
      this.showDialog = false;
    },
    goToDownload() {
      this.showDialog = false;
      this.$router.push("/app");
    },
    goToMarketplace() {
      this.showDialog = false;
      this.$router.push("/marketPlace");
    },
    playVideoEvent(item, index) {
      item.showVideo = true;
      item.showImg = false;
      var myVideo = document.getElementById("video" + index);
      myVideo.play();
    },
    resizeEvent() {
      this.imgHeight = ((document.body.clientWidth * 0.86 - 80) / 3) / 0.8 - 10;
    }
  },
  created() {
    let $this = this;
    //查询用户信息
    $this.$http
      .post("user/info", this.qs.stringify({ token: $this.utils.getToken() }))
      .then((res) => {
        if (res.code === 0) {
          let data = res.data;
          $this.userName = data.nickName;
        }
      });
    //查询收藏品列表
    $this.$http
      .post(
        "user/collection",
        this.qs.stringify({
          token: $this.utils.getToken(),
          currentPage: 1,
          pageSize: 1000,
        })
      )
      .then((res) => {
        if (res.code === 0) {
          let dataList = res.data || [];
          $this.list = dataList;
          $this.totalList = dataList;
          if (!$this.totalList || $this.totalList.length === 0) {
            $this.dataClass = "no-data";
            $this.showDialog = true;
          }else {
            //判断是否存在nft或者edition的数据控制单选框样式
            dataList.forEach(function (item, index) {
              if(item.productType == 1) {
                $this.hasEdition = true;
              }
              if('23'.indexOf(item.productType) > -1) {
                $this.hasNft = true;
              }
            });
          }
        }
      });
  },
  mounted() {
    this.mainHeight = window.screen.availHeight - 340;
    this.imgHeight = ((document.body.clientWidth * 0.86 - 80) / 3) / 0.8 - 10;
    // 监听窗口变化，使得轮播图高度自适应图片高度
    window.addEventListener("resize", this.resizeEvent, false);
  },
  destroyed() {
    //解绑事件
    window.removeEventListener("resize", this.resizeEvent, false);
  }
};
</script>

<style lang="less">
.myCabinet {
  width: 100%;
  border-top: 1px solid #fff;
  .no-data .el-radio {
    color: #bcbcbc !important;
  }
  .no-data .el-radio__input.is-checked + .el-radio__label {
    color: #bcbcbc !important;
  }
  .no-data .el-radio__input.is-checked .el-radio__inner {
    background-image:url("../../assets/Frontpage/Selected-grey.png") !important;
    background-repeat:no-repeat !important;
    background-size:100% 100% !important;
  }
  .has-nft .el-radio__input .el-radio__inner,
  .has-edition .el-radio__input .el-radio__inner,
  .has-all .el-radio__input .el-radio__inner {
    background-image:url("../../assets/Frontpage/Unselected.png") !important;
    background-repeat:no-repeat !important;
    background-size:100% 100% !important;
  }
  .has-nft .el-radio__input.is-checked .el-radio__inner,
  .has-edition .el-radio__input.is-checked .el-radio__inner,
  .has-all .el-radio__input.is-checked .el-radio__inner {
    background-image:url("../../assets/Frontpage/Selected.png") !important;
    background-repeat:no-repeat !important;
    background-size:100% 100% !important;
  }
  .has-nft .el-radio__input + .el-radio__label,
  .has-edition .el-radio__input + .el-radio__label,
  .has-all .el-radio__input + .el-radio__label {
    color: #000000 !important;
  }
  .empty-panel {
    color: #bcbcbc;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    line-height: 17px;
  }
  .category_btn {
    border: none;
    padding: 0 !important;
    background: inherit;
    color: inherit;
  }
  .category_btn:hover,
  .category_btn:focus {
    background: inherit;
    color: inherit;
  }
  // .myCabinet_footer {
  //   width: 100%;
  //   box-sizing: border-box;
  //   position: fixed;
  //   bottom: 0px;
  // }
  video {
    display: block;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .myCabinet_box {
    width: 100%;

    .myCabinet_top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      // backdrop-filter: saturate(180%) blur(30px)  opacity(80%);
      z-index: 999;
    }

    .myCabinet_sec {
      width: 100%;
      position: fixed;
      top: 41.5px;
      left: 0;
      z-index: 999;
      box-sizing: border-box;
      // backdrop-filter: saturate(180%) blur(30px) opacity(80%);
    }

    .myCabinet_main {
      width: 100%;
      box-sizing: border-box;
      margin-top: 100px;
      // padding-bottom: 200px;
      .myCabinet_content {
        width: 86%;
        padding-bottom: 50px;
        box-sizing: border-box;
        text-align: center;
        margin: 0 auto;
      }

      .myCabinet_content2 {
        width: 86%;
        height: 100%;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 200px;
        .img_box {
          margin-bottom: 40px;
          padding-bottom: 10px;
          width: calc(calc(100% - 80px) / 3);
          border-radius: 10px;
          background-color: #ffffff;
          box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.16);
        }

        .img_box:nth-child(3n + 2) {
          margin-left: 40px;
          margin-right: 40px;
        }
        .img_box .video-play-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 100px;
          cursor: pointer;
        }
        .img_box img {
          width: 100%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          background-color: #f4f4f4;
        }

        .tip1 {
          padding: 5px 15px;
          font-weight: bold;
        }

        .tip2 {
          margin-top: 5px;
          display: flex;
        }
        .tip2 .state {
          width:100px;
          color: #FF7A00;
          text-align:right;
        }
        .tip2,
        .tip3 {
          font-size: 14px;
          padding: 0 15px;
          color: #000000;
          line-height: 17px;
        }

        .remark {
          font-size: 12px;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>