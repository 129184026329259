<template>
  <div> 
    <div class="deposit-panel">
      <div class="content-top">
        <div class="title">{{ $t("lang.dashboard.deposit.title") }}</div>
        <div
          :class="isShowDeposit ? 'common-minus' : 'common-plus'"
          @click="showDeposit"
        ></div>
      </div>
      <div class="content-center" v-show="isShowDeposit">
        <div class="left">
          <div class="remark">
            <div>{{ $t("lang.dashboard.deposit.depositRemark1") }}</div>
            <div>
              {{ $t("lang.dashboard.deposit.depositRemark2") }}
              <a
                href="javascript:void(0);"
                style="color: #000000"
                @click="checkNFT"
                >{{ $t("lang.dashboard.deposit.here") }}</a
              >
            </div>
            <div v-show="!isActive">
              {{ $t("lang.dashboard.deposit.depositRemark3") }}
            </div>
          </div>
        </div>
        <div class="right">
          <div class="wallet-label">{{ $t("lang.myprofile.yourWallet") }}：</div>
          <div class="wallet-value" v-html="walletAddress"></div>
          <div style="margin-top: 20px">
            <el-button
              :class="{ btn: true, default: !isActive }"
              @click="connectWallet"
              style="width: 100%"
              >{{ connectLabel }}</el-button
            >
          </div>
          <div style="margin-top: 50px">
            <el-button
              class="btn"
              :showloading="showloading1"
              :disabled="!isActive"
              @click="depositNFT"
              style="width: 100%"
              >{{ $t("lang.dashboard.deposit.depositBtnTxt") }}</el-button
            >
          </div>
        </div>
      </div>
      <ConnectWallet ref="connectWallet"></ConnectWallet>
      <el-dialog
        center
        width="880px"
        :show-close="false"
        custom-class="deposit-dialog"
        :visible.sync="dialogVisible1"
        :close-on-click-modal="false">
        <div slot="title" class="title">
          <div class="left">{{ $t("lang.dashboard.deposit.depositNFT") }}</div>
          <div class="right">
            {{ $t("lang.dashboard.deposit.walletAddress") }}{{ walletAddress }}
          </div>
        </div>
        <div class="content">
          <div>{{ $t("lang.dashboard.deposit.depositNFTLabel") }}</div>
          <div class="data-table">
            <div class="header">
              <div class="row">
                <div class="col" style="width:200px;">NFT</div>
                <div class="col" style="width: 100px">Token ID</div>
                <div class="col" style="flex: 1">
                  {{ $t("lang.dashboard.deposit.contractAddress") }}
                </div>
                <div class="col" style="width:200px;"></div>
              </div>
            </div>
            <div class="body">
              <div class="row" v-for="(item, index) in deposit" :key="index">
                <div class="col ellipsis" style="width:200px;">
                  {{ item.productName }}
                </div>
                <div class="col" style="width: 100px">{{ item.tokenId }}</div>
                <div class="col ellipsis" style="flex: 1">
                  {{ item.contractAddress }}
                </div>
                <div class="col" style="width:200px;text-align: center">
                  <el-button
                    class="btn"
                    :showloading="item.showloading"
                    :disabled="item.disabled"
                    v-if="item.isEnter == 1"
                    @click="sendDeposit(item)"
                    style="width: 80%"
                    >{{ $t("lang.dashboard.deposit.depositBtnTxt") }}</el-button>
                  <span v-if="item.isEnter == 2" style="color: #ff7a00">{{
                    $t("lang.dashboard.deposit.coming")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div style="text-align: center; margin-top: 20px">
            <el-button
              @click="dialogVisible1 = false"
              round
              style="
                width: 200px;
                border: none;
                background-color: #ffffff;
                color: #bcbcbc;
                opacity: 1;
                padding: 10px !important;
              "
              >{{ $t("lang.myMarkPlace.cancel") }}</el-button
            >
          </div>
        </div>
      </el-dialog>
      <el-dialog
        center
        width="400px"
        :show-close="false"
        custom-class="check-dialog"
        :visible.sync="dialogVisible2"
        :close-on-click-modal="false"
      >
        <div slot="title" class="title" v-show="isCompatible == 0">
          {{ $t("lang.dashboard.deposit.checkTitle") }}
        </div>
        <div slot="title" class="title" v-show="isCompatible == 1">
          {{ $t("lang.dashboard.deposit.compatible") }}
        </div>
        <div slot="title" class="title" v-show="isCompatible == 2">
          {{ $t("lang.dashboard.deposit.notCompatible") }}
        </div>
        <div class="content">
          <el-form>
            <div v-show="isCompatible == 0">
              {{ $t("lang.dashboard.deposit.checkRemarK") }}
            </div>
            <div v-show="isCompatible == 1">
              {{ $t("lang.dashboard.deposit.compatibleRemark") }}
            </div>
            <div v-show="isCompatible == 2">
              {{ $t("lang.dashboard.deposit.notCompatibleRemark") }}
            </div>
            <div style="margin-top: 20px">
              {{ $t("lang.dashboard.deposit.contractLabel") }}
            </div>
            <el-form-item>
              <div>
                <el-input
                  type="text"
                  v-model="contractAddr"
                  :placeholder="$t('lang.dashboard.deposit.contractInputHolder')"
                  :class="contractAddrError ? 'error_border' : ''"
                  :readonly="isCompatible != 0"
                  maxlength="42"
                />
                <div class="el-form-item__error">
                  {{ contractAddrError }}
                </div>
              </div>
            </el-form-item>
          </el-form>
          <div style="text-align:center;"><a href="javascript:void(0);" @click="gotoFaq" style="color:#BCBCBC;">{{$t("lang.dashboard.deposit.helpMsg")}}</a></div>
          <div style="text-align: center; margin-top: 20px">
            <el-button
              :showloading="showloading"
              class="btn"
              @click="checkCompatibility"
              :disabled="!checkButtonActive"
              v-show="isCompatible == 0"
              style="width: 100%"
              >{{ $t("lang.dashboard.deposit.checkBtnText") }}</el-button
            >
            <el-button
              @click="closeCheckDialog"
              round
              style="
                width: 200px;
                border: none;
                background-color: #ffffff;
                color: #bcbcbc;
                opacity: 1;
                padding: 10px !important;
              "
              >{{ $t("lang.myMarkPlace.cancel") }}</el-button
            >
          </div>
        </div>
      </el-dialog>
      <el-dialog
        center
        width="420px"
        :show-close="false"
        custom-class="submit-dialog"
        :visible.sync="dialogVisible3"
        :close-on-click-modal="false">
        <div slot="title" class="title">
          {{ $t("lang.dashboard.deposit.submitTitle") }}
        </div>
        <div class="content">
          <div>
            {{ $t("lang.dashboard.deposit.submitRemark") }}
          </div>
          <div style="margin-top:10px;">{{$t("lang.dashboard.deposit.submitRemark1")}}</div>
          <div style="text-align: center; margin-top: 20px">
            <div>
              <el-button
                class="btn"
                @click="checkStatus"
                style="width: 160px;">{{ $t("lang.dashboard.deposit.checkStatus") }}</el-button>
            </div>
            <el-button
              @click="dialogVisible3 = false"
              round
              style="
                width: 200px;
                border: none;
                background-color: #ffffff;
                color: #bcbcbc;
                opacity: 1;
                padding: 10px !important;
              ">{{ $t("lang.wallet.close") }}</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        center
        width="360px"
        :show-close="false"
        custom-class="nft-not-found-dialog"
        :visible.sync="dialogVisible4"
        :close-on-click-modal="false">
        <div slot="title" class="title">
          {{ $t("lang.dashboard.deposit.notFound") }}
        </div>
        <div class="content">
          <div>
            {{ $t("lang.dashboard.deposit.notFoundRemark") }}
          </div>
          <div style="text-align: center;margin-top:10px">
            <el-button
              @click="dialogVisible4 = false"
              round
              style="
                width: 200px;
                border: none;
                background-color: #ffffff;
                color: #bcbcbc;
                opacity: 1;
                padding: 10px !important;
              "
              >{{ $t("lang.wallet.ok") }}</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="deposit-history-panel">
      <div class="line"></div>
      <div style="
                  display: flex;
                  align-items: center;
                  padding: 10px 0;
                  cursor: pointer;
                " @click="showHistory()">
        <div style="flex: 1; text-align: left">
          <span>{{ $t("lang.dashboard.deposit.depositHistory") }}</span>
        </div>
        <div style="flex: 1; text-align: right">
          <div :class="isShowHistory ? 'common-minus' : 'common-plus'" style="float: right"></div>
        </div>
      </div>
      <div v-show="isShowHistory && historyList.length > 0" style="margin-bottom: 40px; margin-top: 10px">
        <div v-for="(item, index) in historyList" :key="index" class="history-unit">
          <div class="summary">
            <div style="flex: 1"><div v-show="!item.isOpen">{{item.productName}}</div></div>
            <div style="flex: 1">
              <span style="color: #bcbcbc; margin-right: 10px"><div v-show="!item.isOpen">{{item.createTime}}</div></span>
              <!-- <a :href="item.transactionUrl" target="_blank" :class="item.nftChain==1?'eth-txid':'matic-txid'">{{
                $t("lang.myMarkPlace.view") }} TXID</a> -->
            </div>
            <div class="view-details" v-show="!item.isOpen" @click="viewDetails(item)">{{$t('lang.dashboard.withdraw.history.viewDetails')}}</div>
            <div class="view-details" v-show="item.isOpen" @click="closeDetails(item)">{{$t('lang.wallet.close')}}</div>
          </div>
          <div class="summary-info" v-show="item.isOpen">
            <div style="display: flex;">
                <div style="flex: 1;display: flex;">
                    <div style="width: 30%;cursor:pointer;" @click="gotoCabinetDetail(item)">
                      <div class="iconBox" v-if="item.type == 1">
                        <img :src="item.imgUrl"/>
                      </div>
                      <div class="iconBox" v-if="item.type == 2">
                        <video :src="item.videoUrl" muted autoplay loop="loop">
                          您的浏览器不支持视频播放。
                        </video>
                      </div>
                    </div>
                    <div style="width: 70%;padding-left:30px;">
                      <div  @click="gotoCabinetDetail(item)" style="cursor:pointer;">{{item.productName}}</div>
                      <div>{{item.artistName}}</div>
                    </div>
                </div>
                <div class="info-right">
                    <div>{{$t('lang.dashboard.withdraw.history.requestTime')}}: {{item.createTime}}</div>
                    <div>
                      <div v-show="item.bcState == 1">{{$t('lang.dashboard.deposit.depositTime')}}: {{item.updateTime}}</div>
                      <div v-show="item.bcState == 0" style="color:#FF7A00;">
                        {{$t('lang.dashboard.deposit.coming')}}
                      </div>
                      <div v-show="item.bcState == 0" style="margin-top:15px;"><img :src="refreshIcon" @click="refreshData(item)" class="refresh-icon"></div>
                      <div v-show="item.bcState == 2">
                        <a :href="item.nftUrl" target="_blank" style="color:#BCBCBC;">{{$t('lang.dashboard.withdraw.history.failed')}}</a>
                      </div>
                    </div>
                </div>
            </div>
            <div style="display: flex;">
              <div style="flex: 1;">
                <div style="margin-top:30px;">{{$t('lang.dashboard.deposit.depositDetails')}}</div>
                <div style="margin-top:10px;">Token ID: {{item.tokenId}}</div>
                <div>{{$t('lang.dashboard.withdraw.history.contractAddress')}}: {{item.contractAddress}}</div>
                <div>{{$t('lang.dashboard.withdraw.history.serialNo')}}: {{item.serialNo}}</div>
                <div>{{$t('lang.dashboard.withdraw.history.txHash')}}: <a :href="item.nftUrl" target="_blank">{{item.txHash}}</a></div>
                <div>{{$t('lang.dashboard.withdraw.history.walletAddress')}}: {{item.walletAddr}}</div>
              </div>
              <div class="failed-msg" v-show="item.isOrder == 2">
                <div style="margin-top:30px;">{{$t('lang.dashboard.deposit.failedMsg1')}}</div>
                <div>{{$t('lang.dashboard.withdraw.history.failedMsg2')}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="line grey"></div>
      </div>
      <div v-show="isShowHistory && historyList.length == 0" class="no-data">
        {{ $t("lang.dashboard.deposit.noDepositHistory") }}
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/user/ConnectWallet.vue";
import obj from "../../common/utils";
import zhen from "../../contract/zhen";
//步骤零:创建异步对象
const ajax = new XMLHttpRequest();
export default {
  name: "WithdrawHistory",
  components: {
    ConnectWallet,
  },
  data() {
    return {
      isShowDeposit: true,
      isShowHistory:false,
      isActive: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4:false,
      connectLabel: this.$t("lang.myprofile.connectWallet"),
      walletAddress: this.$t("lang.dashboard.deposit.notConnect"),
      contractAddr: "",
      contractAddrError: "",
      checkButtonActive: false,
      showloading: false,
      showloading1: false,
      isCheck: false,
      isCompatible: 0,
      deposit: [],
      historyList:[],
      inweb3: null,
      refreshIcon:require('../../assets/Frontpage/refresh.png'),
      refreshFlag:false
    };
  },
  watch: {
    contractAddr(val, oldVal) {
      if(val && val.length == 42) {
        this.contractAddrError = "";
        this.checkButtonActive = true;
      }else {
        this.contractAddrError = this.$t("lang.dashboard.deposit.contractAddressError");
        this.checkButtonActive = false;
      }
    },
  },
  methods: {
    //连接钱包
    async connectWallet() {
      let _this = this;
      //先判断是否安装了metamask插件
      if (window.ethereum) {
        this.inweb3 = new obj.Web3(window.ethereum);
        if (
          localStorage.getItem("from") &&
          localStorage.getItem("chainId") &&
          _this.isActive == true) {
          //判断钱包是否连接
          let isUnlockResult = await obj.isWalletLocked();
          if (isUnlockResult) {
            //未锁
            localStorage.removeItem("from");
            localStorage.removeItem("chainId");
            _this.isActive = false;
            _this.connectLabel = _this.$t("lang.myprofile.connectWallet");
            _this.walletAddress = _this.$t("lang.dashboard.deposit.notConnect");
            // _this.connectWallet();
          } else {
            await obj.connectWallet();
            localStorage.removeItem("from");
            localStorage.removeItem("chainId");
            _this.isActive = false;
            _this.connectLabel = _this.$t("lang.myprofile.connectWallet");
            _this.walletAddress = _this.$t("lang.dashboard.deposit.notConnect");
          }
        } else if (localStorage.getItem("from") && localStorage.getItem("chainId")) {
          let isUnlockResult2 = await obj.isWalletLocked();
          if (isUnlockResult2) {
            localStorage.removeItem("from");
            localStorage.removeItem("chainId");
            _this.isActive = false;
            _this.connectLabel = _this.$t("lang.myprofile.connectWallet");
            _this.walletAddress = _this.$t("lang.dashboard.deposit.notConnect");
            _this.connectWallet();
          } else {
            await obj.connectWallet();
            localStorage.removeItem("from");
            localStorage.removeItem("chainId");
            _this.isActive = false;
            _this.connectLabel = _this.$t("lang.myprofile.connectWallet");
            _this.walletAddress = _this.$t("lang.dashboard.deposit.notConnect");
          }
        } else {
          //localStorage没有地址执行
          this.$refs.connectWallet.showDialog(1);
        }
      } else {
        //没有安装钱包插件,先安装钱包插件
        this.$refs.connectWallet.showDialog(2);
      }
    },
    showDeposit() {
      this.isShowDeposit = !this.isShowDeposit;
    },
    showHistory() {
      this.isShowHistory = !this.isShowHistory;
    },
    connectSuccess() {
      this.walletAddress = localStorage.getItem("from");
      this.isActive = true;
      this.connectLabel = this.$t("lang.myprofile.disconnect");
    },
    depositNFT() {
      this.showloading1 = true;
      let chainId = parseInt(localStorage.getItem("chainId"));
      let nftChain, type;
      //eth
      if (chainId == 1 || chainId == 4) {
        nftChain = 1;
      }
      //matic
      if (chainId == 137 || chainId == 80001) {
        nftChain = 2;
      }
      //主网
      if (chainId == 1 || chainId == 137) {
        type = 1;
      }
      //测试
      if (chainId == 4 || chainId == 80001) {
        type = 2;
      }
      this.$http
        .post("wallet/findByChain", {
          token: this.utils.getToken(),
          chain: nftChain,
          type: type,
        })
        .then((res) => {
          if (res.code == 0) {
            let contractList = res.data || [];
            if (contractList.length > 0) {
              if (nftChain == 2) {
                this.findNFTByMaticContract(contractList,nftChain);
              } else if (chainId == 1) {
                this.findNFTByETHContract(nftChain);
              }
            }else {
              this.showloading1 = false;
              this.dialogVisible4 = true;
            }
          }
        });
    },
    findNFTByETHContract(nftChain) {
      let _this = this;
      let chainId = parseInt(localStorage.getItem("chainId"));
      this.$http.post("wallet/findUserNftList", {
        token: this.utils.getToken(),
        chainId: chainId,
        walletAddress:this.walletAddress
      }).then((res) => {
        if (res.code === 0) {
          console.log(res);
          let arr = [];
          let ajaxData2 = JSON.parse(res.data);
          let datas = ajaxData2.data.content;
          if (datas && datas.length > 0) {
            for (var i = 0; i < datas.length; i++) {
              arr.push({
                nftChain: nftChain,
                tokenId: parseInt(datas[i].token_id),
                contractAddress: datas[i].nft_contract_address,
              });
            }
            //发送请求给后台
            _this.sendData(arr);
          } else {
            _this.showloading1 = false;
            _this.dialogVisible4 = true;
          }
        }
      });
    },
    findNFTByMaticContract(contractList, nftChain) {
      let chainId = parseInt(localStorage.getItem("chainId"));
      let _this = this;
      this.$http.post("wallet/findUserNftList", {
          token: this.utils.getToken(),
          chainId: chainId,
          walletAddress:this.walletAddress
        }).then((res) => {
          if (res.code === 0) {
            var ajaxData = JSON.parse(res.data);
            const nftList = ajaxData.result;
              let contractUtil = "";
              if (nftList && nftList.length > 0) {
                //筛选nft 踢掉不属于zhen.合约和自己钱包得nft
                let arr = _this.filterNFT(nftList, contractList);
                if (arr.length > 0) {
                  let param = [];
                  let count = 0;
                  arr.forEach((item) => {
                    nftChain = 2;
                    contractUtil = new obj.maticweb3.eth.Contract(
                      zhen.nftAbi,
                      item.contractAddress
                    );
                    //检查nft产权是否属于自己
                    contractUtil.methods
                      .ownerOf(parseInt(item.tokenID))
                      .call()
                      .then((res) => {
                        count++;
                        if (res.toLocaleLowerCase() == _this.walletAddress) {
                          //判断是否有重复的tokenId
                          if(!_this.compareArray(param,item.tokenID)) {
                              let data = {};
                              data.tokenId = parseInt(item.tokenID);
                              data.nftChain = nftChain;
                              data.contractAddress = item.contractAddress;
                              param.push(data);
                          }
                        }
                        //检查完毕
                        if (count == arr.length) {
                          //如果存在属于自己产权得nft
                          if (param.length > 0) {
                            _this.sendData(param);
                          } else {
                            _this.showloading1 = false;
                            _this.dialogVisible4 = true;
                          }
                        }
                      });
                  });
                } else {
                  _this.showloading1 = false;
                  _this.dialogVisible4 = true;
                }
              } else {
                _this.showloading1 = false;
                _this.dialogVisible4 = true;
              }
          }
        });
    },
    compareArray(array,tokenId) {
      let flag = false;
      for (var i = 0; i < array.length; i++) {
        if(array[i].tokenId == tokenId) {
          flag = true;
          break;
        }
      }
      return flag;
    },
    //筛选nft
    filterNFT(nftList, contractList) {
      let result = [];
      for (let i = 0; i < nftList.length; i++) {
        let nft = nftList[i];
        for (let j = 0; j < contractList.length; j++) {
          if (
            nft.contractAddress == contractList[j].toLocaleLowerCase() &&
            nft.to == this.walletAddress
          ) {
            result.push(nft);
            break;
          }
        }
      }
      return result;
    },
    //后台查询nft
    sendData(param) {
      this.$http
        .post("wallet/sureInListNft", {
          token: this.utils.getToken(),
          currencyEnterReqs: JSON.stringify(param),
        })
        .then((res) => {
          this.showloading1 = false;
          if (res.code === 0) {
            let datas = res.data || [];
            if(datas.length > 0) {
              datas.forEach((item)=> {
                item.showloading = false;
                item.disabled = false;
              });
              this.deposit = datas;
              this.dialogVisible1 = true;
            }else {
              this.dialogVisible4 = true;
            }
          }
        });
    },
    updateButtonState(isDisabled) {
      this.deposit.forEach((item)=> {
        if(!item.showloading) {
          item.disabled = isDisabled;
        }
      });
    },
    async sendDeposit(item) {
      item.showloading = true;
      this.updateButtonState(true);
      let _this = this;
      this.$http
        .post("wallet/getCompanyWalletAddress", {token: this.utils.getToken(),propertyId:item.propertyId})
        .then(async (res) => {
          if (res.code === 0) {
            let data = res.data;
            this.bossAddress = data;
            //拉起metamask签名
            let isVerified = await this.verifyUserAccount(
              item.tokenId,
              item.contractAddress
            );
            //取消签名
            if (isVerified.code == 4001) {
              item.showloading = false;
              _this.updateButtonState(false);
            }
            //签名确认 
            else {
              const hash = await obj.deposit(item.tokenId, item.contractAddress,_this.bossAddress);
              _this.$http
                .post("wallet/currencyEntry", {
                  token: _this.utils.getToken(),
                  propertyId: item.propertyId,
                  fromWalletAddr:this.walletAddress,
                  txId: hash
                })
                .then((res) => {
                  item.showloading = false;
                  _this.updateButtonState(false);
                  if (res.code === 0) {
                    item.isEnter = 2;
                    _this.dialogVisible3 = true;
                  }
                });
            }
          }
      });
    },
    //拉起metamask签名
    async verifyUserAccount(tokenId, contractAddr) {
      let _this = this,nftChain='';
      let chainId = parseInt(localStorage.getItem("chainId"));
      //eth
      if (chainId == 1 || chainId == 4) {
        nftChain = 'ETH';
      }
      //matic
      if (chainId == 137 || chainId == 80001) {
        nftChain = 'MATIC';
      }
      let nonce = await this.inweb3.eth.getTransactionCount(
        _this.walletAddress,
        _this.inweb3.eth.defaultBlock.pending
      );
      return new Promise((resolve, reject) => {
        _this.inweb3.eth.personal
          .sign(
            `   network：${nftChain}
                from: ${_this.walletAddress}
                to: ${_this.bossAddress}
                tokenId: ${tokenId}
                nonce: ${nonce}
                contractAddress: ${contractAddr}
              `,
              this.walletAddress,
            ""
          )
          .then((sign, err) => {
            if (err) {
              console.log(err);
            } else {
              _this.inweb3.eth.personal
                .ecRecover(
                            `network：${nftChain}
                            from: ${_this.walletAddress}
                            to: ${_this.bossAddress}
                            tokenId: ${tokenId}
                            nonce: ${nonce}
                            contractAddress: ${contractAddr}`,
                  sign
                )
                .then((res, err) => {
                  if (err) {
                    console.log(err);
                  } else {
                    resolve(res);
                  }
                })
                .catch((err) => {
                  resolve(err);
                });
            }
          })
          .catch((error) => {
            resolve(error);
          });
      });
    },
    getUrlByChain() {
      let chainId = parseInt(localStorage.getItem("chainId")),
        url = "";
      //eth 主网
      switch (chainId) {
        //eth 主网
        case 1:
          url = `https://restapi.nftscan.com/gw/token?apiKey=${zhen.nftscanApiKey}&apiSecret=${zhen.nftscanApiSecret}`;
          break;
        //eth 测试
        case 4:
          // url = `https://api-rinkeby.etherscan.io/api?module=account&action=tokennfttx&contractaddress=${contractAddress}&address=${this.walletAddress}&page=1&offset=${zhen.offset}&startblock=${zhen.ethteststartblock}&endblock=${zhen.endblock}&sort=asc&apikey=${zhen.apiKey}`;
          break;
        //matic 主网
        case 137:
          url = `https://api.polygonscan.com/api?module=account&action=tokennfttx&address=${this.walletAddress}&startblock=${zhen.maticstartblock}&endblock=${zhen.endblock}&sort=asc&apikey=${zhen.maticApiKey}`;
          break;
        //matic 测试
        case 80001:
          url = `https://api-testnet.polygonscan.com/api?module=account&action=tokennfttx&address=${this.walletAddress}&startblock=${zhen.maticteststartblock}&endblock=${zhen.endblock}&sort=asc&apikey=${zhen.maticApiKey}`;
          break;
      }
      return url;
    },
    checkNFT() {
      this.contractAddr = "";
      this.dialogVisible2 = true;
    },
    checkCompatibility() {
      this.showloading = true;
      this.$http
        .post("wallet/findByContractAddress", {
          contractAddress: this.contractAddr,
          token: this.utils.getToken(),
        })
        .then((res) => {
          this.showloading = false;
          //请求成功
          if (res.code === 0) {
            //属于zhen.平台得合约
            if (res.data == 1) {
              this.isCompatible = 1;
            } else {
              this.isCompatible = 2;
            }
          }
        });
    },
    closeCheckDialog() {
      let _this = this;
      _this.dialogVisible2 = false;
      setTimeout(()=>{
        _this.isCompatible = 0;
      },1000)
    },
    checkStatus() {
      this.getHistoryList(true);
    },
    gotoFaq() {
      let routeData = this.$router.resolve({
          name: "Faq",
          query:{tab:'4-2'}
      });
      window.open(routeData.href, "_blank");
    },
    viewDetails(item) {
      item.isOpen = true;
    },
    closeDetails(item) {
      item.isOpen = false;
    },
    gotoCabinetDetail(item) {
      if(item.bcState == 1) {
        this.$router.push({
          path: "/cabinetDetail",
          query: { chipCode: item.serialNo },
        });
      }
    },
    getHistoryList(isCheck) {
      //查询入币记录列表
      this.$http
        .post("wallet/findByEnterAndOutCurrency", { token: this.utils.getToken(), direction: 2 })
        .then((res) => {
          if (res.code === 0) {
            let data = res.data || [];
            if(data.length > 0) {
              data.forEach((item)=> {
                item.isOpen = false;
              });
              //提币成功后点击check status 打开最新一条记录
              if(isCheck) {
                this.isShowHistory = true;
                this.dialogVisible3 = false;
                this.dialogVisible1 = false;
                data[0].isOpen = true;
              }
              this.historyList = data;
            }
          }
        });
    },
    getCompanyWalletAddress() {
      this.$http
        .post("wallet/getCompanyWalletAddress", { token: this.utils.getToken()})
        .then((res) => {
          if (res.code === 0) {
            let data = res.data;
            this.bossAddress = data;
          }
        });
    },
    //刷新状态
    refreshData(item) {
      if(this.refreshFlag) {
        return;
      }
      this.refreshFlag = true;
      this.refreshIcon = require('../../assets/Frontpage/refresh-active.gif');
      this.$http.post(
        "wallet/findEnterStatus",
        { token: this.utils.getToken(),id:item.propertyTransferId}
      ).then((res) => {
        if (res.code === 0) {
          item.bcState = res.data.bcState;
          item.updateTime = res.data.updateTime;
        }
        setTimeout(()=> {
          this.refreshIcon = require('../../assets/Frontpage/refresh.png');
          this.refreshFlag = false;
        },2000);
      });
      
    }
  },
  created() {
    this.$parent.activeIndex = this.$route.meta.tab;
    //获取入币记录
    this.getHistoryList(false);
  },
  mounted() {
    //初始化底部栏位 防止上下闪跳
    this.$parent.initFooter();
  }
};
</script>
<style lang="less">
.deposit-panel {
  .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*height:600px;*/
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
    border-radius: 10px;
  }

  .deposit-dialog .title {
    display: flex;
  }

  .deposit-dialog .title .right {
    flex: 1;
    color: #bcbcbc;
    font-size: 14px;
    text-align: right;
  }

  .deposit-dialog .el-dialog__body,
  .check-dialog .el-dialog__body,
  .submit .el-dialog__body,
  .nft-not-found-dialog .el-dialog__body {
    padding: 0 20px 20px 20px !important;
  }

  .deposit-dialog .content,
  .check-dialog .content,
  .submit .content,
  .nft-not-found-dialog .content {
    color: #000000;
    font-size: 14px;
  }

  .deposit-dialog .content .data-table {
    margin-top: 10px;
  }

  .deposit-dialog .content .data-table .header {
    color: #bcbcbc;
  }

  .deposit-dialog .content .data-table .header .row,
  .deposit-dialog .content .data-table .body .row {
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: flex;
    border-bottom: 1.5px solid #bcbcbc;
  }

  .deposit-dialog .content .data-table .body .row .col.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .deposit-dialog .content .data-table .btn,
  .check-dialog .content .btn,
  .submit-dialog .content .btn {
    background-color: #000000;
    color: #ffffff;
    height: 25px;
    line-height: 25px;
    padding: 0;
    margin: 0;
    width: 160px;
    border-radius: 13px;
  }
  .check-dialog .content .btn:disabled {
    background-color: #bcbcbc;
  }
  .check-dialog .el-input input {
    width: 100%;
    height: 25px;
    line-height: 25px;
    border: none;
    outline: medium;
    border-bottom: 0.1em solid #000000;
    opacity: 1;
    border-radius: 0;
    padding: 0;
  }
  .check-dialog .el-input input::placeholder {
    text-align: left !important;
  }
  .content-top {
    display: flex;
    align-items: center;
    height: 40px;
    line-height: 40px;
    border-top: 1.5px solid #000000;

    .title {
      flex: 1;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .content-center {
    display: flex;
    padding-bottom: 30px;

    .left {
      flex: 1;
      margin-right: 30px;

      .remark {
        font-size: 14px;
        line-height: 17px;
        margin-top: 5px;
      }
    }

    .right {
      flex: 1;
      margin-left: 30px;
      padding-top: 5px;

      .wallet-label {
        font-size: 14px;
        line-height: 17px;
      }

      .wallet-value {
        font-size: 14px;
        line-height: 17px;
        margin-top: 5px;
      }

      .btn {
        background-color: #000000;
        color: #ffffff;
        height: 25px;
        line-height: 25px;
        padding: 0;
        margin: 0;
        width: 160px;
        border-radius: 13px;
      }

      .btn.default {
        background-color: #ff7a00;
      }

      .btn:disabled {
        background-color: #bcbcbc;
      }

      .image-panel {
        display: flex;
        flex-wrap: wrap;

        .no-withdraw {
          font-size: 14px;
          line-height: 17px;
          color: #bcbcbc;
        }

        .image-box {
          width: 30%;
          margin-right: 2.5%;
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;

          .pendding {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: #bcbcbc;
            opacity: 0.5;
            cursor: pointer;
          }

          video {
            width: 100%;
            display: block;
            object-fit: cover;
          }

          .el-radio__label {
            color: #ffffff !important;
          }
        }
      }
    }
  }
}
.deposit-history-panel {
    padding-top: 20px;
    .no-data {
      color: #bcbcbc;
      font-size: 14px;
      padding: 10px 0 30px 0;
    }
    .history-unit {
      .summary {
        display: flex;
        height: 40px;
        line-height: 40px;
        border-top: 1.5px solid #BCBCBC;
        .view-details {
          width: 100px;
          cursor: pointer;
          text-align:right;
        }
      }
      .summary-info {
        padding-bottom:20px;
        .refresh-icon {
          width:16px;
          vertical-align:middle;
          cursor:pointer;
        }
        .info-right {
          flex: 1;
          color: #BCBCBC;
          text-align: right;
        }
      }
      .failed-msg {
        flex: 1;
        color:#BCBCBC;
        text-align: right;
      }
    }
    .line {
      border: none;
      border-top: 1.5px solid #000000;
      width: 100%;
    }
    .line.grey {
      border-top: 1.5px solid #BCBCBC !important;
    }
    .iconBox video,
    .iconBox img {
      width: 100%;
    }
  }
.iconBox video {
  margin-bottom: 0px !important;
}

.el-dialog__headerbtn {
  display: none;
}
</style>