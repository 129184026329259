<template>
  <div class="faq">
    <div class="box">
      <div class="top bg-white" style="border-bottom:1px solid  #000000;">
        <AppNavigator/>
      </div>
      <div class="middle"></div>
      <div class="main" :style="{minHeight:mainHeight+'px'}">
           <div class="main_box">
              <div class="main_title"><div style=" position: fixed;top:120px;left: 7%;">{{ $t('lang.footer.faq.title') }}</div></div>
              <div class="main_content">
                <div class="content-box" v-for="(item,index) in $t('lang.footer.faq.menuContext')" :key="index">
                  <div class="box-title">
                    {{item.title}}
                  </div>
                  <div class="box-content" v-for="(item1,index1) in item.faq" :key="index1">
                    <div style="display: flex;align-items:center;padding:12px 0;cursor:pointer;" @click="showHide(index,index1)">
                      <div style="flex: 1;">{{item1.ask}}</div>
                      <div><div :class="show[index+''+index1]?'common-minus':'common-plus'"></div></div>
                    </div>
                    <div :class="show[index+''+index1]?'show':'hide'" v-html="item1.que"></div>
                  </div>
                </div>
              </div>
            </div>         
      </div>  
      <div class="footer">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue"
import AppFooter from "@/components/common/AppFooter.vue"
export default {
  name: "Faq",
  components: {
    AppNavigator,
    AppFooter,
  },
  data(){
    return {
      currentIndex : 0,
      show:{

      },
      mainHeight:0
    }
  },
  methods: {
    menuClick(index) {
        this.currentIndex = index;
    },
    showHide(index,index1){
      if(!this.show[index+''+index1]) {
        this.$set(this.show,index+''+index1,true);
      }else {
        this.$set(this.show,index+''+index1,false)
      }
    },
    //滚动到对应标签页
    scollTag(){
        let top = this.scrollTop();
     }
  },
  created() {
    let tab = this.$route.query.tab;
    if(tab && tab.indexOf('-') > 0) {
      let list = tab.split('-');
      this.showHide(list[0],list[1]);
      setTimeout(()=> {
        document.documentElement.scrollTop = 5000;
      },200);
    }
  },
  mounted() {
    this.mainHeight = window.screen.availHeight - 360;
  }
};
</script>

<style lang="less">


.faq {
  width: 100%;
  box-sizing: border-box;
  font-family: Helvetica;
  color: #000000;
  border-top: 1px solid #fff;
  .box {
    .top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      // backdrop-filter: saturate(180%) blur(30px) opacity(80%);
    }
    .middle {
       margin-top: 40px;
       width: 100%;
       height: 1px;
    }
    .main {
      width: 100%;
      box-sizing: border-box;
      margin-top: 40px;
      // margin-bottom: 280px;
      .email2 {
        color: #bcbcbc !important;
      }
      .email2:hover {
        opacity: 0.8;
      }
      .main_box {
        width: 86%;
        margin: 0 auto;
        margin-top: 80px;  
        display: flex;
        justify-content: space-between;     
        .main_title {
          flex: 1;
          font-size: 21px;
          white-space:nowrap;
        }
        .main_content {
          flex: 2;
          .content-box {
            margin-bottom:30px;
            .box-title {
              font-size: 14px;
              font-weight: 400;
              margin:10px 0;
            }
            .box-content {
              border-bottom:0.1em solid #000000;
              font-size:14px;
              font-weight: 400;
            }
          }
          .show {
            display: block;
          }
          .hide {
            display: none;
          }
        }
      }
    }
    .footer {
     width: 100%;
      box-sizing: border-box;
      // position: fixed;
      // bottom: 0px;
    }
  }
}
</style>
