import Vue from 'vue'
import vue from '../main.js'
import store from '../store/index'
import axios from 'axios'
import zhen from "../contract/zhen"
import CryptoJS from 'crypto-js'
import { createNamespacedHelpers } from 'vuex'
const Web3 = require("web3")
const network = 1; //区块链环境 1：主网 2：测试网
let chainId, maticChainId, web3, maticweb3, ethContract, maticNftContract;
//根据网络初始化数据
if (network == 1) {
    //正式 eth
    chainId = "1";
    //正式 matic
    maticChainId = "137";
    //正式 eth
    web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'));
    //正式 matic
    maticweb3 = new Web3(new Web3.providers.HttpProvider('https://polygon-rpc.com'));
} else {
    //测试 eth
    chainId = "4";
    //测试 matic
    maticChainId = "80001";
    //测试 eth
    web3 = new Web3(new Web3.providers.HttpProvider('https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'))
    //测试 matic
    maticweb3 = new Web3(new Web3.providers.HttpProvider('https://matic-mumbai.chainstacklabs.com'));
    //eth 支付
    // myContract = new web3.eth.Contract(zhen.depositAbi, zhen.depositContract);
}

function utils() {
    this.loading = null;
    this.web3 = web3;
    this.Web3 = Web3;
    this.maticweb3 = maticweb3;
    this.chainId = chainId;
}


utils.prototype = {
    getToken() {
        return localStorage.getItem('token') || sessionStorage.getItem('token') || "";
    },
    getProfilePhoto() {
        return localStorage.getItem('profilePhoto') || require('../assets/Frontpage/20.jpg');
    },
    alert(title, content, options) {
        let defaultObj = { autoClose: true, showCancelButton: true, cancelButtonClass: "cancel-btn", cancelButtonText: vue.$t("lang.wallet.close"), showConfirmButton: false, duration: 5000, customClass: 'nft-alert' };
        Object.assign(defaultObj, options);
        Vue.prototype.$alert(content, title, defaultObj).then((action) => {
            if (action == 'confirm') {
                //按钮点击回调
                if (options.ok) {
                    options.ok();
                }
            }
        }).catch(err => {
            console.log(err);
        });
        //自动关闭弹窗
        if (defaultObj && defaultObj.autoClose) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    let messageBox = document.getElementsByClassName("el-message-box__headerbtn");
                    if (messageBox) {
                        messageBox[0].click();
                    }
                    resolve();
                }, defaultObj.duration)

            })
        }
    },
    getBase64(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            let fileResult = "";
            reader.readAsDataURL(file);
            //开始转
            reader.onload = function () {
                fileResult = reader.result;
            };
            //转 失败
            reader.onerror = function (error) {
                reject(error);
            };
            //转 结束  咱就 resolve 出去
            reader.onloadend = function () {
                resolve(fileResult);
            };
        });
    },
    getDateBySecond(second, type) {
        let time = 0;
        let day = parseInt(second / 3600 / 24);
        let hour = parseInt(second / 3600) - day * 24;
        let minute = second > 60 ? parseInt(second / 60) - (day * 24 * 60 + hour * 60) : 0;
        let ss = second % 60 > 0 ? parseInt(second % 60) : 0;
        switch (type) {
            case 'D':
                time = day;
                break;
            case 'H':
                time = hour;
                break;
            case 'M':
                time = minute;
                break;
            case 'S':
                time = ss;
                break;
            default: ;
        }
        return time;
    },
    openLoading: function () {
        this.loading = Vue.prototype.$loading({
            lock: true,
            body: true,
            fullscreen: false,
            // text: 'Loading',
            spinner: 'nft-loading-icon',
            background: 'transparent'
        });

    },
    closeLoading: function () {
        if (this.loading) {
            this.loading.close();
        }
    },
    //数据埋点
    logEvent: function (eventName, param) {
        if (window.analytics) {
            // analytics.logEvent('select_content', {
            //     content_type: 'image',
            //     content_id: 'P12453',
            //     items: [{ name: 'Kittens' }]
            // });
            window.analytics.logEvent(eventName, param);
        }

    },
    renderGrecaptcha() {
        var captchaId = null;
        return function (containerId){
            console.log(captchaId)
            if(captchaId == null) {
                captchaId = grecaptcha.render(containerId, {
                    //dev 6LdSeTAfAAAAAOnmeV_22V03MsLo5ImSx7xfzARh
                    //pro 6LdcfzYfAAAAAOkADJtmN8tPzf1-At8ZWGttqgvH
                    "sitekey": "6LdSeTAfAAAAAOnmeV_22V03MsLo5ImSx7xfzARh",
                    "size":"invisible",
                    //验证成功后的回调函数
        　　　　　　 "callback": this.handleCaptchaCallback,
        // 　　　　　　//验证码超时后的回调函数
        // 　　　　　　"expired-callback": this.handleCaptchaExpired,
        // 　　　　　　//验证失败时的回调函数
        // 　　　　　　"error-callback": this.handleCaptchaError
                });
                grecaptcha.execute(captchaId);
            }else {
                grecaptcha.reset(captchaId);
                grecaptcha.execute(captchaId);
            }
        }
        
    },
    //加密
    encrypt(param) {
        //生成32位随机key
        let guid = "";
        for (let i = 1; i <= 32; i++) {
            let n = Math.floor(Math.random() * 16.0).toString(16);
            guid += n;
        }
        let key = CryptoJS.enc.Utf8.parse(guid);
        let iv = CryptoJS.enc.Utf8.parse('ZHEN.UjKQ$i#YE&q');
        let srcs = CryptoJS.enc.Utf8.parse(param);
        //aes cbc加密
        let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        return { content: encrypted.toString(), key: guid };
    },
    //解密
    decrypt(data, keyStr) {
        let iv = CryptoJS.enc.Utf8.parse('ZHEN.UjKQ$i#YE&q');
        let key = CryptoJS.enc.Utf8.parse(keyStr);
        //aes cbc解密
        let decrypt = CryptoJS.AES.decrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    },
    goto() {
        let routeData = this.$router.resolve({
            name: "login"
        });
        window.open(routeData.href, "_blank");
    },

    //连接钱包
    async connectWallet() {
        let _this = this;
        try {
            const accounts = await ethereum.request({
                method: "eth_requestAccounts",
            });
            let account = accounts[0];
            //外部引入store只能显示调用mutations里的方法           
            ethereum.on('chainChanged', async (_chainId) => {
                localStorage.removeItem("from");
                localStorage.removeItem("chainId");
                setTimeout(function () {
                    window.location.reload();
                }, 1000)
            });
            ethereum.on('accountsChanged', async (_newAccount) => {
                localStorage.removeItem("from");
                localStorage.removeItem("chainId");
                setTimeout(function () {
                    window.location.reload();
                }, 1000)
            });
            store.commit("setWalletAddr", account);
            localStorage.setItem("chainId", (ethereum.networkVersion) + "");
        } catch (err) {
            _this.$alert(err, _this.$t('lang.wallet.error'), {
                confirmButtonText: _this.$t('lang.wallet.confirm'),
                callback: (action) => {
                    _this.$message({
                        type: "info",
                        message: `action: ${action}`,
                    });
                },
            });
        }
    },

    //获取chainId
    async getChainId() {
        return await web3.eth.getChainId();
    },

    //获取eth gasPrice
    async getPrice() {
        if (parseInt(localStorage.getItem("chainId")) == 4) {

            return await 10;

        } else if (parseInt(localStorage.getItem("chainId")) == 1) {

            return (await axios.get(`https://api-cn.etherscan.com/api?module=gastracker&action=gasoracle&apikey=${zhen.apiKey}`)).data.result.FastGasPrice;

        } else if (parseInt(localStorage.getItem("chainId")) == 80001) {

            return (await axios.get("https://gasstation-mumbai.matic.today")).data.fastest;

        } else if (parseInt(localStorage.getItem("chainId")) == 137) {

            return (await axios.get("https://gasstation-mainnet.matic.network")).data.fastest;

        } else {

        }
    },

    //判断钱包是否已经解锁
    async isWalletLocked() {

        return await ethereum._metamask.isUnlocked()

    },
    //支付
    async pay(orderId, price) {

        //判断metamask是否已经解锁
        const isUnlockResult = await this.isWalletLocked()
        let nonce = await web3.eth.getTransactionCount(
            localStorage.getItem('from'),
            web3.eth.defaultBlock.pending
        );
        const transactionParameters = {
            nonce: web3.utils.toHex(nonce++),
            gas: web3.utils.toHex(parseInt(200000)),
            gasPrice: web3.utils.toHex(parseInt((await this.getPrice()) * 10 ** 9)),
            to: zhen.depositContract,
            from: localStorage.getItem('from'),
            value: web3.utils.toHex(web3.utils.toWei(price, 'ether')),
            data: myContract.methods.receiveETH(orderId, "0", web3.utils.toHex(web3.utils.toWei(price, 'ether')), 1).encodeABI()
        };
        if (isUnlockResult) {

            //获取哈希
            return ethereum.request({
                method: "eth_sendTransaction",
                params: [transactionParameters],
            }).then((tx) => {
                return tx;
            }).catch(err => {
                return err;
            })

        } else {
            //连接metamask是锁的,则连接钱包，让其弹出解锁钱包页面
            await this.connectWallet()
            //尝试新方法
            return ethereum.request({
                method: "eth_sendTransaction",
                params: [transactionParameters],
            }).then((tx) => {
                return tx;
            }).catch(err => {
                return err;
            })
        }
    },

    async sendTranscationData(tokenId, web3, contractApi, contractAddress, bossAddress) {
        let gaslimit = zhen.ethGasLimit;
        let nonce = await web3.eth.getTransactionCount(
            localStorage.getItem('from'),
            web3.eth.defaultBlock.pending
        );
        const transactionParameters = {
            nonce: web3.utils.toHex(nonce++),
            gas: web3.utils.toHex(parseInt(gaslimit)),
            gasPrice: web3.utils.toHex(parseInt((await this.getPrice()) * 10 ** 9)),
            to: contractAddress,
            from: localStorage.getItem('from'),
            value: "0x00",
            data: contractApi.methods.safeTransferFrom(localStorage.getItem("from"), bossAddress, tokenId).encodeABI()
        };
        return ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters],
        }).then((tx) => {
            return tx;
        }).catch(err => {
            return err;
        })

    },

    //存nft
    async deposit(tokenId, contractAddress, bossAddress) {
        if (parseInt(localStorage.getItem("chainId")) == 4) {
            let contractApi = new this.web3.eth.Contract(
                zhen.nftAbi,
                contractAddress);
            return await this.sendTranscationData(tokenId, this.web3, contractApi, contractAddress, bossAddress);
        } else if (parseInt(localStorage.getItem("chainId")) == 1) {
            let contractApi = new this.web3.eth.Contract(
                zhen.nftAbi,
                contractAddress);
            return await this.sendTranscationData(tokenId, this.web3, contractApi, contractAddress, bossAddress);
        } else if (parseInt(localStorage.getItem("chainId")) == 80001) {
            let contractApi = new this.maticweb3.eth.Contract(
                zhen.nftAbi,
                contractAddress);
            return await this.sendTranscationData(tokenId, this.maticweb3, contractApi, contractAddress, bossAddress);
        } else if (parseInt(localStorage.getItem("chainId")) == 137) {
            let contractApi = new this.maticweb3.eth.Contract(
                zhen.nftAbi,
                contractAddress);
            return await this.sendTranscationData(tokenId, maticweb3, contractApi, contractAddress, bossAddress);
        } else {

        }
    }
}
let obj = new utils();
export default obj;



