<template>
  <div>
    <div class="withdraw-panel">
      <div class="step-1" v-show="!isNext">
        <div class="content-top">
          <div class="title">{{ $t("lang.dashboard.withdraw.eligible") }}</div>
          <div :class="isShowWithdraw ? 'common-minus' : 'common-plus'" @click="showWithdraw"></div>
        </div>
        <div class="content-center" v-show="isShowWithdraw">
          <div class="left">
            <div class="remark">
              <div>{{ $t("lang.wallet.withdrawRemark1") }}</div>
              <div style="margin-top: 20px">
                {{ $t("lang.wallet.withdrawRemark2") }}
              </div>
            </div>
          </div>
          <div class="right">
            <div class="image-panel" ref="imagePanel">
              <div v-if="list.length == 0" class="no-withdraw">
                {{ $t("lang.wallet.noWithdraw") }}
              </div>
              <div class="image-box" v-for="(item, index) in list" :key="index">
                <div style="flex: 1; position: relative">
                  <div class="iconBox" v-if="item.nftType == 1">
                    <img :src="item.nftUrl" ref="withdraImg" style="width: 100%; display: block" />

                    <div class="iconClass" v-if="false">
                        <img v-if="item.nftChain == 1" src="../..//assets/Frontpage/ETH.png" />
                       <img v-else-if="item.nftChain == 2" src="../../assets/Frontpage/matic.png" />
                    </div>
                  </div>

                  <div class="iconBox" v-if="item.nftType == 2">
                    <video :src="item.nftUrl" muted autoplay loop="loop" :height="videoHeight + 'px'">
                      您的浏览器不支持视频播放。
                    </video>
                    <div class="iconClass" v-if="false">
                                           <img v-if="item.nftChain == 1" src="../..//assets/Frontpage/ETH.png" />
                                           <img v-else-if="item.nftChain == 2" src="../../assets/Frontpage/matic.png" />
                    </div>
                  </div>
                  <div class="pendding" v-if="item.state == 0" @click="penddingClick"></div>
                </div>
                <div style="color: #bcbcbc; font-size: 12px; margin-top: 5px">
                  <div v-if="item.nftChain == 1">Ethereum</div>
                  <div v-if="item.nftChain == 2">Polygon</div>
                </div>
                <div>
                  <el-radio v-model="radio" :label="index" name="imageRadio" :disabled="item.state == 0"></el-radio>
                </div>
              </div>
            </div>
            <div class="btn-panel">
              <el-button class="btn" @click="withdrawClick" :disabled="radio < 0" style="width: 100%">{{
                $t("lang.wallet.withdrawBtn") }}</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="step-2" v-show="isNext">
        <div class="content-top">
          <div class="title">{{ $t("lang.wallet.withdrawDialogTitle") }}</div>
          <div :class="isShowWithdraw ? 'common-minus' : 'common-plus'" @click="showWithdraw"></div>
        </div>
        <div class="content-center" v-show="isShowWithdraw">
          <div style="display: flex;">
            <div class="left">
              <div style="display: flex;">
                <div style="flex: 1;">
                  <div class="iconBox" v-if="info.nftType == 1">
                    <img :src="info.nftUrl" class="main-image" />
                  </div>
                  <div class="iconBox" v-if="info.nftType == 2">
                    <video :src="info.nftUrl" muted autoplay loop="loop">
                      您的浏览器不支持视频播放。
                    </video>
                  </div>
                </div>
                <div style="flex: 2; padding-left: 20px">
                  <div>{{ info.artistName }}</div>
                  <div style="line-height: 16px">{{ info.productName }}</div>
                  <div style="margin-top: 20px; color: #3398dc">
                    {{ info.nftChain == 1 ? "Ethereum" : "Polygon" }}
                  </div>
                </div>
              </div>
              <div style="font-weight:bold;margin-top:20px;">
                <div style="display: flex;" v-show="info.isCommission == 1">
                  <div style="flex: 1;" v-show="info.withdrawFee">{{ $t("lang.dashboard.withdraw.transactionFee") }}</div>
                  <div style="flex: 1;text-align: right;" v-show="info.withdrawFee">{{info.withdrawFee}}</div>
                </div>
                <div v-show="info.isCommission == 2">{{$t("lang.dashboard.withdraw.noTransactionFee")}}</div>
              </div>
            </div>
            <div class="right">
              <div style="color: #bcbcbc">
                {{ $t("lang.dashboard.withdraw.please") }}
              </div>
              <div style="margin-top:20px;">
                <el-form :model="withdrawForm">
                  <div style="color: #000000">
                    {{ $t("lang.wallet.walletAddress").replace('#nftChain',(info.nftChain == 1?'Ethereum':'Polygon')) }}
                  </div>
                  <el-form-item>
                    <div>
                      <el-input type="text" v-model="withdrawForm.walletAddr" :placeholder="
                        $t('lang.wallet.walletAddress').replace(
                          '#nftChain',
                          info.nftChain == 1 ? 'Ethereum' : 'Polygon'
                        )
                      " :class="withdrawForm.walletAddrError ? 'error_border' : ''" />
                      <div class="el-form-item__error">
                        {{ withdrawForm.walletAddrError }}
                      </div>
                    </div>
                  </el-form-item>
                  <div class="btn-panel">
                    <el-button :class="{ btn: true, default: !isActive }" @click="connectWallet" style="width: 100%">
                      {{connectLabel }}</el-button>
                  </div>
                  <div style="color: #000000" v-show="!needValid">
                    {{ $t("lang.cabinet.dialog.password") }}
                  </div>
                  <el-form-item v-show="!needValid">
                    <div class="pw-panel">
                      <el-input :type="inputType" @focus="inputType = 'password'" v-model="withdrawForm.password"
                        :placeholder="$t('lang.login.yourPassword')"
                        :class="withdrawForm.passwordError ? 'error_border' : ''" />
                      <div class="forget-pw" @click="goToForgetPassword()">
                        {{ $t("lang.login.forgetPassword") }}?
                      </div>
                      <div class="el-form-item__error">
                        {{ withdrawForm.passwordError }}
                      </div>
                    </div>
                  </el-form-item>
                  <div style="color: #000000">
                    {{ $t("lang.cabinet.dialog.code") }}
                  </div>
                  <el-form-item>
                    <div style="position: relative">
                      <el-input type="text" v-model="withdrawForm.verifyCode"
                        :placeholder="$t('lang.cabinet.dialog.codeHolder')"
                        :class="withdrawForm.verifyCodeError ? 'error_border' : ''" />
                      <div class="el-form-item__error">
                        {{ withdrawForm.verifyCodeError }}
                      </div>
                      <div @click="sendCode()" class="send-code">
                        <el-button type="text" style="color: #bbbbbb" :disabled="sendCodeDisabled">{{ sendCodeText }}
                        </el-button>
                      </div>
                    </div>
                  </el-form-item>
                  <div v-show="needValid">
                      <div style="color: #000000;margin-top:30px;">
                        {{ $t("lang.myprofile.twofa.validText") }}
                      </div>
                      <div class="btn-panel" style="margin:20px 0 30px 0;">
                        <el-button class="btn" @click="startValid" :disabled="hasValid" style="width: 100%">{{validTxt}}</el-button>
                      </div>
                  </div>
                  <div v-show="info.isCommission == 1">
                    <div>{{$t('lang.dashboard.withdraw.selectPayment')}}</div>
                    <div class="select-payment">
                      <div style="flex:1;" @click="selectPayment = true">
                        <span v-show="!withdrawForm.selectItem.name"
                          style="color:#BCBCBC;">{{$t('lang.dashboard.withdraw.selectPaymentHolder')}}</span>
                        <span v-show="withdrawForm.selectItem.name">{{withdrawForm.selectItem.name}}</span>
                      </div>
                      <div class="select-box" v-show="selectPayment">
                        <div class="box-item" v-for="(item,index) in payment" :key="index"
                          @click="withdrawForm.selectItem = item;selectPayment = false">
                          <div style="flex:1;">{{item.name}}</div>
                          <div><i class="el-icon-check" style="font-weight: bold;color:#FF7A00;"
                              v-show="withdrawForm.selectItem.id == item.id"></i></div>
                        </div>
                      </div>
                      <i class="el-icon-arrow-down" style="font-weight: bold;"></i>
                    </div>
                    <div style="margin-top:20px;"><i class="el-icon-warning-outline"></i>
                      {{$t('lang.dashboard.withdraw.payRemark1')}}</div>
                    <div style="margin-top:20px;"><i class="el-icon-warning-outline"></i>
                      {{$t('lang.dashboard.withdraw.payRemark2')}}</div>
                  </div>
                  <div style="margin-top:20px;">
                    <el-checkbox v-model="withdrawForm.agree" @click="withdrawForm.agree = !withdrawForm.agree">
                    </el-checkbox>
                    <span style="margin-left: 10px;">{{$t('lang.dashboard.withdraw.agree')}} </span>
                    <a href="javascript:void(0);" style="color: #000000;"
                      @click="gotoTC">{{$t('lang.myMarkPlace.label1')}}</a>.
                  </div>
                </el-form>
              </div>
              <ConnectWallet ref="connectWallet"></ConnectWallet>
            </div>
          </div>
          <div class="btn-panel">
            <el-button class="btn" @click="confirmWithdraw" :showloading="showloading" :class="[buttonType]"
              :disabled="buttonType == 'disabled'" style="width: 100%">{{$t('lang.wallet.withdrawBtn')}}</el-button>
          </div>
          <div class="cancel-btn" @click="cancel"><span>{{ $t("lang.myMarkPlace.cancel")
              }}</span></div>
        </div>
      </div>
      <el-dialog center width="380px" custom-class="withdraw_success_dialog" :visible.sync="dialogVisible1"
        :title="$t('lang.dashboard.withdraw.successTitle')" :close-on-click-modal="false" style="padding-top: 0">
        <div>
          <div style="text-align: center; color: #000000">
            {{$t("lang.dashboard.withdraw.successMsg")}}
          </div>
          <div style="text-align: center; margin-top: 10px">
            <span style="color: #bcbcbc; cursor: pointer" @click="withdrawSuccessClose">{{ $t("lang.wallet.close")
              }}</span>
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="withdraw-history-panel">
      <div class="line"></div>
      <div style="
                  display: flex;
                  align-items: center;
                  padding: 10px 0;
                  cursor: pointer;
                " @click="showHistory()">
        <div style="flex: 1; text-align: left">
          <span>{{ $t("lang.myprofile.withdrawHistory") }}</span>
        </div>
        <div style="flex: 1; text-align: right">
          <div :class="isShowHistory ? 'common-minus' : 'common-plus'" style="float: right"></div>
        </div>
      </div>
      <div v-show="isShowHistory && historyList.length > 0" style="margin-bottom: 40px; margin-top: 10px">
        <div v-for="(item, index) in historyList" :key="index" class="history-unit" :ref="`history${item.propertyTransferId}`">
          <div class="summary">
            <div style="flex: 1"><div v-show="!item.isOpen">{{item.productName}}</div></div>
            <div style="flex: 1">
              <span style="color: #bcbcbc; margin-right: 10px"><div v-show="!item.isOpen">{{item.createTime}}</div></span>
              <!-- <a :href="item.transactionUrl" target="_blank" :class="item.nftChain==1?'eth-txid':'matic-txid'">{{
                $t("lang.myMarkPlace.view") }} TXID</a> -->
            </div>
            <div class="view-details" v-show="!item.isOpen" @click="viewDetails(item)">{{$t('lang.dashboard.withdraw.history.viewDetails')}}</div>
            <div class="view-details" v-show="item.isOpen" @click="closeDetails(item)">{{$t('lang.wallet.close')}}</div>
          </div>
          <div class="summary-info" v-show="item.isOpen">
            <div style="display: flex;">
                <div style="flex: 1;display: flex;">
                    <div style="width: 30%;cursor:pointer;" @click="gotoCabinetDetail(item)">
                      <div class="iconBox" v-if="item.type == 1">
                        <img :src="item.imgUrl"/>
                      </div>
                      <div class="iconBox" v-if="item.type == 2">
                        <video :src="item.videoUrl" muted autoplay loop="loop">
                          您的浏览器不支持视频播放。
                        </video>
                      </div>
                    </div>
                    <div style="width: 70%;padding-left:30px;">
                      <div  @click="gotoCabinetDetail(item)" style="cursor:pointer;">{{item.productName}}</div>
                      <div>{{item.artistName}}</div>
                    </div>
                </div>
                <div class="info-right">
                    <div>{{$t('lang.dashboard.withdraw.history.requestTime')}}: {{item.createTime}}</div>
                    <div>
                      <div v-show="item.bcState == 1">{{$t('lang.dashboard.withdraw.history.withdrawTime')}}: {{item.updateTime}}</div>
                      <div v-show="item.bcState == 0" style="color: #FF7A00;">
                        {{$t('lang.dashboard.withdraw.history.outgoing')}}
                      </div>
                      <div v-show="item.bcState == 0" style="margin-top:15px;"><img :src="refreshIcon" @click="refreshData(item)" class="refresh-icon"></div>
                      <div v-show="item.bcState == 2">{{$t('lang.dashboard.withdraw.history.failed')}}</div>
                    </div>
                </div>
            </div>
            <div style="display: flex;" v-show="item.isOrder == 1">
              <div style="flex: 1;">
                <div style="margin-top:30px;">{{$t('lang.dashboard.withdraw.history.paymentDetails')}}</div>
                <div style="margin-top:10px;">{{$t('lang.dashboard.withdraw.history.paymentMethod')}}: {{getPaymethod(item.payChannel)}}</div>
                <div>{{$t('lang.dashboard.withdraw.history.transactionId')}}: {{item.thirdOrderNo}}</div>
                <div>{{$t('lang.dashboard.withdraw.history.total')}}: {{item.actualFee}}</div>
              </div>
              <div class="failed-msg" v-show="item.isOrder == 1 && item.bcState == 2">
                <div style="margin-top:30px;">{{$t('lang.dashboard.withdraw.history.failedMsg1')}}</div>
                <div>{{$t('lang.dashboard.withdraw.history.failedMsg2')}}</div>
              </div>
            </div>
            <div style="display: flex;">
              <div style="flex: 1;">
                <div style="margin-top:30px;">{{$t('lang.dashboard.withdraw.history.withdrawDetails')}}</div>
                <div style="margin-top:10px;">Token ID: {{item.tokenId}}</div>
                <div>{{$t('lang.dashboard.withdraw.history.contractAddress')}}: {{item.contractAddress}}</div>
                <div>{{$t('lang.dashboard.withdraw.history.serialNo')}}: {{item.serialNo}}</div>
                <div v-show="item.txHash">{{$t('lang.dashboard.withdraw.history.txHash')}}: <a :href="item.nftUrl" target="_blank">{{item.txHash}}</a></div>
                <div>{{$t('lang.dashboard.withdraw.history.walletAddress')}}: {{item.walletAddr}}</div>
              </div>
              <div class="failed-msg" v-show="item.isOrder == 2 && item.bcState == 2">
                <div style="margin-top:30px;">{{$t('lang.dashboard.withdraw.history.failedMsg1')}}</div>
                <div>{{$t('lang.dashboard.withdraw.history.failedMsg2')}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="line grey"></div>
      </div>
      <div v-show="isShowHistory && historyList.length == 0" class="no-data">
        {{ $t("lang.myprofile.noWithdrawHistory") }}
      </div>
      <div class="line"></div>
    </div>
    <Authenticator ref="authenticator"></Authenticator>
    <!-- google 人机验证 -->
    <div id='recaptcha' style="position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);"></div>
  </div>
</template>

<script>
  import ConnectWallet from "@/components/user/ConnectWallet.vue";
  import Authenticator from "@/components/user/Authenticator.vue";
  import obj from "../../common/utils";
  export default {
    name: "WithdrawHistory",
    components: {
      ConnectWallet,
      Authenticator
    },
    data() {
      return {
        list: [],
        payment: [],
        radio: -1,
        videoHeight: 0,
        isActive: false,
        selectPayment: false,
        connectLabel: this.$t("lang.myprofile.connectWallet"),
        isShowWithdraw: true,
        isShowHistory: false,
        isNext: false,
        info: {},
        userInfo: {},
        dialogVisible1: false,
        sendCodeDisabled: false,
        sendCodeText: this.$t("lang.register.sendCode"),
        buttonType: "disabled",
        showloading: false,
        inputType: "text",
        withdrawForm: {
          walletAddr: "",
          password: "",
          verifyCode: "",
          selectItem: '',
          walletAddrError: "",
          passwordError: "",
          verifyCodeError: "",
          agree: false,
          walletAddrValid:false,
          passwordValid:false,
          verifyCodeValid:false,
          selectItemValid:false,
          agreeValid:false
        },
        historyList: [],
        refreshIcon:require('../../assets/Frontpage/refresh.png'),
        refreshFlag:false,
        needValid:false,
        hasValid:false,
        validTxt:this.$t('lang.myprofile.twofa.startValid'),
	      inter:null,
        //初始化人机验证
        captcha:obj.renderGrecaptcha()
      };
    },
    watch: {
      //验证钱包地址
      'withdrawForm.walletAddr'(newValue, oldValue) {
          try {
            obj.web3.eth
              .getCode(newValue).then((param) => {
                if (param === "0x" && 
                  this.withdrawForm["walletAddr"] != "0x539ba5d05a4a78b91cbc2a8c9a6529e7a7820e98") {
                  this.withdrawForm.walletAddrError = "";
                  this.withdrawForm.walletAddrValid = true;
                  if(this.validateForm()) {
                      this.buttonType = "active";
                  }else {
                    this.buttonType = "disabled";
                  }
                } else {
                  this.withdrawForm.walletAddrError = this.$t("lang.wallet.walletAddressError");
                  this.withdrawForm.walletAddrValid = false;
                  this.buttonType = "disabled";
                }
              });
          } catch (e) {
            console.log(e);
            this.withdrawForm.walletAddrError = this.$t("lang.wallet.walletAddressError");
            this.withdrawForm.walletAddrValid = false;
            this.buttonType = "disabled";
          }
      },
      //验证密码
      'withdrawForm.password'(newValue, oldValue) {
        if(newValue && newValue.length >= 6) {
          this.withdrawForm.passwordValid = true;
          if(this.validateForm()) {
              this.buttonType = "active";
          }else {
            this.buttonType = "disabled";
          }
        }else {
          this.withdrawForm.passwordValid = false;
          this.buttonType = "disabled";
        }
      },
      //验证验证码
      'withdrawForm.verifyCode'(newValue, oldValue) {
        if(newValue && newValue.length == 6) {
          this.withdrawForm.verifyCodeValid = true;
          if(this.validateForm()) {
              this.buttonType = "active";
          }else {
            this.buttonType = "disabled";
          }
        }else {
          this.withdrawForm.verifyCodeValid = false;
          this.buttonType = "disabled";
        }
      },
      //验证支付方式
      'withdrawForm.selectItem':{
        handler:function (newValue, oldValue) {
          if(this.info.isCommission == 1) {
              if(newValue) {
                this.withdrawForm.selectItemValid = true;
                if(this.validateForm()) {
                    this.buttonType = "active";
                }else {
                  this.buttonType = "disabled";
                }
              }else {
                this.withdrawForm.selectItemValid = false;
                this.buttonType = "disabled";
              }
          }else {
            this.withdrawForm.selectItemValid = true;
          }
        },
        immediate:true
      },
      //验证是否同意协议
      'withdrawForm.agree'(newValue, oldValue) {
        if(newValue) {
          this.withdrawForm.agreeValid = true;
          if(this.validateForm()) {
              this.buttonType = "active";
          }else {
            this.buttonType = "disabled";
          }
        }else {
          this.withdrawForm.agreeValid = false;
          this.buttonType = "disabled";
        }
      }
    },
    methods: {
      //连接钱包
      async connectWallet() {
        let _this = this;
        //先判断是否安装了metamask插件
        if (window.ethereum) {
          if (
            localStorage.getItem("from") &&
            localStorage.getItem("chainId") &&
            _this.isActive == true
          ) {
            //判断钱包是否连接
            let isUnlockResult = await obj.isWalletLocked();
            if (isUnlockResult) {
              //未锁
              localStorage.removeItem("from");
              localStorage.removeItem("chainId");
              _this.withdrawForm.walletAddr = '';
              _this.isActive = false;
              _this.connectLabel = _this.$t("lang.myprofile.connectWallet");
              _this.walletAddress = _this.$t("lang.wallet.connectWallet");
              // _this.connectWallet();
            } else {
              await obj.connectWallet();
              localStorage.removeItem("from");
              localStorage.removeItem("chainId");
              _this.isActive = false;
              _this.connectLabel = _this.$t("lang.myprofile.connectWallet");
              _this.walletAddress = _this.$t("lang.wallet.connectWallet");
            }
          } else if (
            localStorage.getItem("from") &&
            localStorage.getItem("chainId")
          ) {
            let isUnlockResult2 = await obj.isWalletLocked();
            if (isUnlockResult2) {
              localStorage.removeItem("from");
              localStorage.removeItem("chainId");
              _this.withdrawForm.walletAddr = '';
              _this.isActive = false;
              _this.connectLabel = _this.$t("lang.myprofile.connectWallet");
              _this.walletAddress = _this.$t("lang.wallet.connectWallet");
              _this.connectWallet();
            } else {
              await obj.connectWallet();
              localStorage.removeItem("from");
              localStorage.removeItem("chainId");
              _this.withdrawForm.walletAddr = '';
              _this.isActive = false;
              _this.connectLabel = _this.$t("lang.myprofile.connectWallet");
              _this.walletAddress = _this.$t("lang.wallet.connectWallet");
            }
          } else {
            //localStorage没有地址执行
            this.$refs.connectWallet.showDialog(1);
          }
        } else {
          //没有安装钱包插件,先安装钱包插件
          this.$refs.connectWallet.showDialog(2);
        }
      },
      validateForm() {
        let fields = ['walletAddrValid','passwordValid','verifyCodeValid','selectItemValid','agreeValid'];
        let flag = true,_this = this;
        if(this.needValid) {
          fields = ['walletAddrValid','verifyCodeValid','selectItemValid','agreeValid'];
        }
        fields.forEach(function(field,index) {
          if(!_this.withdrawForm[field]) {
            flag = false;
          }
        });
        if(this.needValid && !this.hasValid) {
          flag = false;
        }
        return flag;
      },
      connectSuccess() {
        this.withdrawForm.walletAddr = localStorage.getItem("from");
        this.isActive = true;
        this.connectLabel = this.$t("lang.myprofile.disconnect");
      },
      showWithdraw() {
        this.isShowWithdraw = !this.isShowWithdraw;
      },
      showHistory() {
        this.isShowHistory = !this.isShowHistory;
      },
      getTransferState(state) {
        let lang = localStorage.getItem("lang") || "en";
        return this.transferState[lang][state];
      },
      withdrawClick() {
        //获取支付渠道
        this.$http
          .post("/order/payChannel", { token: this.utils.getToken() })
          .then((res) => {
            this.showloading = false;
            if (res.code === 0) {
              this.payment = res.data.listFiat;
              let _this = this;
              let result = this.list.filter(function (item, index) {
                if (_this.radio == index) {
                  return item;
                }
              });
              this.info = result[0];
              this.countdown();
              this.isNext = true;
              document.documentElement.scrollTop = 0;
            }
          });

      },
      penddingClick() {
        const title = this.$t("lang.wallet.penddingTitle");
        const content = this.$t("lang.wallet.penddingContent");
        this.utils.alert(title, content, {
          autoClose: false,
        });
      },
      sendCode() {
        //倒计时没结束 不响应事件
        if (this.sendCodeDisabled) {
          return false;
        }
        //启动人机验证
        this.captcha.call(this,'recaptcha');
      },
      handleCaptchaCallback(captchaToken) {
        console.log(captchaToken);
        //判断是email 还是phone
        if (this.userInfo.email) {
          this.sendEmailCode(captchaToken);
        } else if (this.userInfo.phone) {
          this.sendSMSCode(captchaToken);
        } else {
          this.utils.alert("", "account error");
        }
      },
      //发送邮箱验证码
      sendEmailCode(captchaToken) {
        //点击按钮后禁用防止重复点击
        this.sendCodeDisabled = true;
        let $this = this;
        this.$http
          .post(
            "verifyCode/email",
            { email: $this.userInfo.email, emailType: 5,captchaToken:captchaToken}
          )
          .then((res) => {
            //请求成功
            if (res.code === 0) {
              let count = 59;
              sessionStorage.setItem('withdrawSendTime', new Date().getTime());
              $this.sendCodeText = count + "(s)";
              $this.initInterval(count);
              $this.utils.alert(
                $this.$t("lang.register.verCodeInfoSend"),
                $this.$t("lang.register.verCodeInfo"));
            }
            //请求失败
            else {
              $this.sendCodeDisabled = false;
              if (res.code === -3) {
                let errorList = res.msg.split(";");
                errorList.forEach(function (item, index, arr) {
                  let attr = item.split(":")[0];
                  let msg = item.split(":")[1];
                  $this.withdrawForm[attr + "Error"] = msg;
                });
              }
            }
          });
      },
      //发送短信验证码
      sendSMSCode(captchaToken) {
        let $this = this;
        //倒计时没结束 不响应事件
        if (this.sendCodeDisabled) {
          return false;
        }
        //点击按钮后禁用防止重复点击
        this.sendCodeDisabled = true;
        this.$http
          .post(
            "verifyCode/sms",
            {
              phone: $this.userInfo.phone,
              countryId: $this.userInfo.countryId,
              smsType: 5,
              captchaToken:captchaToken
            }
          )
          .then((res) => {
            //请求成功
            if (res.code === 0) {
              let count = 59;
              sessionStorage.setItem('withdrawSendTime', new Date().getTime());
              $this.sendCodeText = count + "(s)";
              $this.initInterval(count);
              $this.utils.alert(
                $this.$t("lang.register.verCodeInfoSend"),
                $this.$t("lang.register.verCodeInfo2")
              );
            }
            //请求失败
            else {
              $this.sendCodeDisabled = false;
              if (res.code === -3) {
                let errorList = res.msg.split(";");
                errorList.forEach(function (item, index, arr) {
                  let attr = item.split(":")[0];
                  let msg = item.split(":")[1];
                  $this.withdrawForm[attr + "Error"] = msg;
                });
              }
            }
          });
      },
      //刷新页面后初始化发送验证码后的倒计时
      countdown() {
        let $this = this;
        if(sessionStorage.getItem('withdrawSendTime')) {
          let sendTime = sessionStorage.getItem('withdrawSendTime');
          let count = 59 - Math.floor((new Date().getTime() - sendTime)/1000);
          if(count > 0) {
            $this.sendCodeDisabled = true;
            $this.sendCodeText = count + "(s)";
            $this.initInterval(count);
          }
        }else {
          if($this.inter) {
            clearInterval($this.inter);
          }
          $this.sendCodeDisabled = false;
          $this.sendCodeText = $this.$t("lang.register.sendCode");
        }
      },
      //发送验证码后的倒计时
      initInterval(count) {
        let $this = this;
        if($this.inter) {
          clearInterval($this.inter);
        }
        $this.inter = setInterval(function () {
          count--;
          $this.sendCodeText = count + "(s)";
          if (count == 0) {
            $this.sendCodeDisabled = false;
            sessionStorage.removeItem('withdrawSendTime');
            $this.sendCodeText = $this.$t("lang.register.sendCode");
            clearInterval($this.inter);
          }
        }, 1000);
      },
      //提交产权转移申请
      confirmWithdraw() {
        if (this.buttonType == "disabled") {
          return;
        }
        if (this.showloading) {
          return;
        }
        this.showloading = true;
        let param = { token: this.utils.getToken() };
        param.walletAddr = this.withdrawForm.walletAddr;
        param.password = this.withdrawForm.password;
        //判断是email 还是phone
        if (this.userInfo.email) {
          param.verifyCodeType = 0;
        } else if (this.userInfo.phone) {
          param.verifyCodeType = 1;
        }
        param.verifyCode = this.withdrawForm.verifyCode;
        param.propertyId = this.info.propertyId;
        //验证状态
        if(this.needValid) {
          param.isTowFA = this.hasValid;
        }else {
          param.isTowFA = false;
        }
        //判断是否需要手续费
        if (this.info.isCommission == 1) {
          param.withdrawFeeSymbol = this.info.withdrawFee;
        }
        this.$http.post("wallet/withdrawal", param).then((res) => {
          if (res.code === 0) {
             //判断是否需要手续费
            if (this.info.isCommission == 1) {
              this.paymentFee();
            } else {
              this.showloading = false;
              this.dialogVisible1 = true;
            }

          } else {
            this.showloading = false;
            const title = this.$t("lang.pay.note");
            const okText = this.$t("lang.wallet.ok");
            this.utils.alert(title, res.msg, {
              cancelButtonText: okText,
              autoClose: false,
            });
          }
        });
      },
      //支付手续费
      paymentFee() {
        let param = {};
        param.token = this.utils.getToken();
        param.propertyId = this.info.propertyId;
        param.productId = this.info.productId;
        param.totalPrice = this.info.withdrawFee;
        param.payChannel = this.withdrawForm.selectItem.id;
        param.fromWalletAddr = this.withdrawForm.walletAddr;
        this.$http
          .post("wallet/pay", param)
          .then((res) => {
            this.showloading = false;
            //请求成功
            if (res.code == 0) {
              if (param.payChannel == 1 || param.payChannel == 5) {
                window.location.href = res.data.payInfo;
              }
              if (param.payChannel == 3) {
                document.write(res.data.payInfo);
              }
            }
          });
      },
      cancel() {
        this.$parent.reload();
      },
      //跳转到忘记密码组件
      goToForgetPassword() {
        this.$router.push("/forgotPassword");
      },
      withdrawSuccessClose() {
        this.$router.push("/withdraw");
        this.$parent.reload();
        // this.dialogVisible1 = false;
      },
      gotoTC() {
        let routeData = this.$router.resolve({
          name: "Terms",
        });
        window.open(routeData.href, '_blank');
      },
      viewDetails(item) {
        item.isOpen = true;
      },
      closeDetails(item) {
        item.isOpen = false;
      },
      getPaymethod(payChannel) {
        let method = "";
        if(payChannel) {
           switch(payChannel) {
              case 1:
                method = 'Stripe';
                break;
              case 2:
                method = 'WeChat Pay';
                break;
              case 3:
                method = 'Alipay';
                break;
              case 4:
                method = 'MetaMask';
                break;
           }
        }
        return method;
      },
      gotoCabinetDetail(item) {
        this.$router.push({
          path: "/cabinetDetail",
          query: { chipCode: item.serialNo,transferId:item.propertyTransferId},
        });
      },
      resizeEvent() {
        this.$nextTick(() => {
          this.videoHeight = (this.$refs.imagePanel.clientWidth * 0.3) / 0.8;
        });
      },
      openHistory(selectedItem) {
        let _this = this;
        this.isShowHistory = true;
        setTimeout(()=> {
          if(selectedItem) {
            let selectedPanel = _this.$refs[`history${selectedItem.propertyTransferId}`][0];
            let top = selectedPanel.getBoundingClientRect().top;
            document.documentElement.scrollTop = top;
          }
        },500);
      },
      //刷新状态
      refreshData(item) {
        if(this.refreshFlag) {
          return;
        }
        this.refreshFlag = true;
        this.refreshIcon = require('../../assets/Frontpage/refresh-active.gif');
        this.$http.post(
          "wallet/findEnterStatus",
          { token: this.utils.getToken(),id:item.propertyTransferId}
        ).then((res) => {
          if (res.code === 0) {
            item.bcState = res.data.bcState;
            item.updateTime = res.data.updateTime;
            item.txHash = res.data.txId;
          }
          setTimeout(()=> {
            this.refreshIcon = require('../../assets/Frontpage/refresh.png');
            this.refreshFlag = false;
          },2000);
        });
        
      },
      startValid() {
        //调起验证码
        this.$refs.authenticator.showValid(3);
      },
      validSuccess() {
        this.validTxt = this.$t("lang.myprofile.twofa.authenticated");
        this.hasValid = true;
        //验证数据完整
        if(this.validateForm()) {
          this.buttonType = "active";
        }else {
          this.buttonType = "disabled";
        }
      },
      //绑定成功回调
      bindSuccess() {
        window.location.reload();
      }
    },
    created() {
      let _this = this;
      this.$parent.activeIndex = this.$route.meta.tab;
      //判断是否时支付回调
      if(this.$route.query.pop == 1) {
        this.dialogVisible1 = true;
      }
      //查询收藏品列表
      this.$http
        .post(
          "wallet/listNft",
          this.qs.stringify({ token: this.utils.getToken() })
        )
        .then((res) => {
          if (res.code === 0) {
            let dataList = res.data || [];
            this.list = dataList;
            //找出第一个可以withdraw的商品选中
            for (let i = 0; i < dataList.length; i++) {
              if (dataList[i].state == 1) {
                _this.radio = i;
                break;
              }
            }
          }
        });
      //查询提币记录列表
      let id = this.$route.query.transferId;
      this.$http
        .post("wallet/findByEnterAndOutCurrency", { token: this.utils.getToken(), direction: 1 })
        .then((res) => {
          if (res.code === 0) {
            let data = res.data || [];
            let selected = '';
            if(data.length > 0) {
              data.forEach((item)=> {
                if(id && id == item.propertyTransferId) {
                  item.isOpen = true;
                  selected = item;
                }else {
                  item.isOpen = false;
                }
              });
              this.historyList = data;
              this.openHistory(selected);
            }
          }
      });
      //获取用户信息
      this.$http
        .post("user/info", { token: this.utils.getToken() })
        .then((res) => {
          if (res.code === 0) {
            let data = res.data;
            this.userInfo = data;
          }
      });
       //获取是否已经绑定google身份验证
      this.$http
        .post("googleAuthenticator/isBinding", { token: this.utils.getToken() })
        .then((res) => {
          if (res.code === 0) {
            let data = res.data;
            //未绑定身份验证器
            if(data.isBinding == 1) {
              this.$refs.authenticator.showDownload();
            }
            //已绑定已开启
            else if(data.isBinding == 2 && data.isGa == 2) {
              this.needValid = true;
            }
            //已绑定未开启
            else if(data.isBinding == 2 && data.isGa == 1) {
              this.needValid = false;  
            }
          }
      });
    },
    mounted() {
      this.resizeEvent();
      //初始化底部栏位 防止上下闪跳
      this.$parent.initFooter();
      // 监听窗口变化，使得轮播图高度自适应图片高度
      window.addEventListener("resize", this.resizeEvent, false);
    },
    destroyed() {
      //解绑事件
      window.removeEventListener("resize", this.resizeEvent, false);
    }
  };
</script>
<style lang="less">
  .withdraw-panel {
    .el-dialog {
      display: flex;
      flex-direction: column;
      margin: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /*height:600px;*/
      max-height: calc(100% - 30px);
      max-width: calc(100% - 30px);
      border-radius: 10px;
    }
    .step-1 {
      .content-top {
        display: flex;
        align-items: center;
        height: 40px;
        line-height: 40px;
        border-top: 1.5px solid #000000;
        .title {
          flex: 1;
          font-size: 14px;
          line-height: 17px;
        }
      }

      .content-center {
        display: flex;
        padding-bottom: 30px;

        .left {
          flex: 1;
          margin-right: 30px;

          .remark {
            font-size: 14px;
            line-height: 17px;
            margin-top: 5px;
          }
        }

        .right {
          flex: 1;
          margin-left: 30px;
          padding-top: 5px;

          .wallet-label {
            font-size: 14px;
            line-height: 17px;
          }

          .wallet-value {
            font-size: 14px;
            line-height: 17px;
            margin-top: 5px;
          }

          .btn-panel {
            display: flex;
            justify-content: space-between;
            margin-top: 80px;

            .btn {
              background-color: #000000;
              color: #ffffff;
              height: 25px;
              line-height: 25px;
              padding: 0;
              margin: 0;
              width: 160px;
              border-radius: 13px;
            }

            .btn.default {
              background-color: #ff7a00;
            }

            .btn:disabled {
              background-color: #bcbcbc;
            }
          }

          .image-panel {
            display: flex;
            flex-wrap: wrap;

            .no-withdraw {
              font-size: 14px;
              line-height: 17px;
              color: #bcbcbc;
            }

            .image-box {
              width: 30%;
              margin-right: 2.5%;
              display: flex;
              flex-direction: column;
              margin-bottom: 20px;

              .pendding {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: #bcbcbc;
                opacity: 0.5;
                cursor: pointer;
              }

              video {
                width: 100%;
                display: block;
                object-fit: cover;
              }

              .el-radio__label {
                color: #ffffff !important;
              }
            }
          }
        }
      }
    }

    .step-2 {
      .content-top {
        display: flex;
        align-items: center;
        height: 40px;
        line-height: 40px;
        border-top: 1.5px solid #000000;

        .title {
          flex: 1;
          font-size: 14px;
          line-height: 17px;
        }
      }

      .content-center {
        padding-bottom: 30px;
        .main-image {
          width: 100%;
          display: block;
        }
        .left {
          flex: 1;
          padding-top: 15px;
          padding-right: 10px;

          video {
            width: 100%;
            display: block;
          }
        }

        .right {
          flex: 1;
          margin-left: 30px;
          padding-top: 5px;
          .el-input__icon {
            line-height: 20px;
          }

          .select-payment {
            border-bottom: 1.5px solid #BCBCBC;
            padding-bottom: 5px;
            margin-top: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
          }

          .select-box {
            width: calc(100% - 40px);
            position: absolute;
            left: 0;
            bottom: 0;
            box-shadow: 0 0 10px #BCBCBC;
            background-color: #ffffff;
            border-radius: 10px;
            padding: 20px;
            z-index: 99;

            .box-item {
              width: 100%;
              height: 40px;
              line-height: 40px;
              display: flex;
            }

            .box-item {
              border-bottom: 1.5px solid #bcbcbc;
            }
            .box-item:last-child {
              border-bottom:none;
            }
          }

          .withdraw-btn {
            width: 160px;
            height: 25px;
            line-height: 25px;
            border: none;
            padding: 0;
            color: #ffffff;
          }

          .withdraw-btn.active {
            background-color: #000000;
          }

          .withdraw-btn.disabled {
            background-color: #bcbcbc;
          }

          .withdraw-btn.is-disabled,
          .withdraw-btn.is-disabled:focus,
          .withdraw-btn.is-disabled:hover {
            color: #ffffff !important;
            background-color: #bcbcbc !important;
          }

          .el-form-item {
            margin-bottom: 15px;
          }

          .el-form-item__content {
            line-height: 25px !important;
          }

          input {
            color: #000000 !important;
          }

          .el-input input {
            width: 100%;
            height: 25px;
            line-height: 25px;
            border: none;
            outline: medium;
            border-bottom: 0.1em solid #000000;
            opacity: 1;
            border-radius: 0;
            padding: 0;
          }

          .el-input input::placeholder {
            text-align: left !important;
          }

          .send-code {
            position: absolute;
            top: -6px;
            right: 0px;
            z-index: 5;
          }

          .forget-pw {
            position: absolute;
            top: 0;
            right: 0;
            color: #bcbcbc;
            cursor: pointer;
          }

          .pw-panel {
            position: relative;
          }

          .pw-panel .el-input input {
            padding-right: 120px;
          }

          .dialog-close-btn {
            width: 200px !important;
            border: none !important;
            background-color: #ffffff !important;
            color: #bcbcbc !important;
            opacity: 1 !important;
            padding: 10px !important;
          }
        }
      }

      .cancel-btn {
        text-align: center;
        color: #BCBCBC;
        margin-bottom: 50px;
        cursor: pointer;
      }

      .btn-panel {
        margin: 40px 0 20px 0;

        .btn {
          background-color: #000000;
          color: #ffffff;
          height: 25px;
          line-height: 25px;
          padding: 0;
          margin: 0;
          width: 160px;
          border-radius: 13px;
        }

        .btn.default {
          background-color: #ff7a00;
        }

        .btn:disabled {
          background-color: #bcbcbc;
        }
      }
    }
  }

  .withdraw-history-panel {
    padding-top: 20px;
    .no-data {
      color: #bcbcbc;
      font-size: 14px;
      padding: 10px 0 30px 0;
    }
    .history-unit {
      .summary {
        display: flex;
        height: 40px;
        line-height: 40px;
        border-top: 1.5px solid #BCBCBC;
        .view-details {
          width: 100px;
          cursor: pointer;
          text-align:right;
        }
      }
      .summary-info {
        padding-bottom:20px;
        .refresh-icon {
          width:16px;
          vertical-align:middle;
          cursor:pointer;
        }
        .info-right {
          flex: 1;
          color: #BCBCBC;
          text-align: right;
        }
      }
      .failed-msg {
        flex: 1;
        color:#BCBCBC;
        text-align: right;
      }
    }
    .line {
      border: none;
      border-top: 1.5px solid #000000;
      width: 100%;
    }
    .line.grey {
      border-top: 1.5px solid #BCBCBC !important;
    }
    .iconBox video,
    .iconBox img {
      width: 100%;
    }
  }

  .iconBox video {
    margin-bottom: 0px !important;
  }

  .el-dialog__headerbtn {
    display: none;
  }
</style>