<template>
  <el-select
    class="area-select"
    v-model="countryId"
    filterable
    @change="areaChange"
    placeholder=" "
    :no-match-text="$t('lang.other.noAreaText')"
    :disabled="!disabled"
  >
    <el-option
      v-for="item in countryList"
      :key="item.id"
      :label="item.areaCode"
      :value="item.id"
    >
      <span style="float: left">{{ item.countryName }}</span>
      <span style="float: right; margin-left: 50px">{{ item.areaCode }}</span>
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "AreaSelect",
  data() {
    return {
      countryId: "",
      countryList: [],
    };
  },
  props: ["disabled", "selectedId"],
  watch: {},
  methods: {
    areaChange(value) {
      let country = this.countryList.filter(function (obj, index) {
        return obj.id == value;
      });
      this.$emit("countryChange", country[0]);
    },
  },
  created() {
    this.$http.post("common/countryList").then((res) => {
      if (res.code === 0) {
        let list = res.data,
          countryId = 39;
        //缓存中是第二优先级
        if (localStorage.getItem("areaCode")) {
          countryId = parseInt(localStorage.getItem("areaCode"));
        }
        //如果有传selectedId优先级最高
        if (this.selectedId) {
          countryId = parseInt(this.selectedId);
        }
        this.countryId = countryId;
        let country = list.filter(function (obj) {
          return obj.id == countryId;
        });
        this.$emit("countryChange", country[0]);
        this.countryList = list;
      }
    });
  },
};
</script>
<style lang="less">
.el-select.area-select input {
  text-align: center !important;
}
.el-select.area-select input::placeholder {
  text-align: center !important;
}
.el-select.area-select .el-input__suffix {
  display: none;
}
.el-select.area-select .el-input.is-active .el-input__inner,
.el-select.area-select .el-input__inner:focus,
.el-select.area-select .el-select .el-input__inner:focus {
  border-bottom-color: #000000 !important;
}
.el-select-dropdown__empty {
  padding: 10px !important;
}
</style>