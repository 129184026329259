export const lang = {
    navigator: {
        technology: 'Technology',
        experience: 'Experience',
        editorial: 'Editorial',
        clientServices: 'Client Services',
        register: 'Register',
        markPlace: 'Marketplace',
        login: 'LOGIN',
        language: 'EN'
    },
    profile: {
        login: 'Login',
        register: 'Sign Up',
        password: 'Password',
        forgetPassword: 'Forgot Password',
        loginOut: 'Log out',
        transfer: 'OWNERSHIP TRANSFERS',
        confirmDialog: {
            title: 'Confirm Ownership Transfer',
            desc1: 'You have received an ownership transfer request. To accept ownership of this edition, please click Confirm Transfer below.',
            desc2: 'If you do not recognize this transfer please do not accept — Cancel the transaction and immediately contact us at support@zhen.io.',
            confirm: 'Confirm Transfer'
        },
        required:'required'

    },
    login: {
        title: 'LOGIN',
        email: 'Email',
        yourEmail: 'Your Email',
        editPassword: 'EDIT PASSWORD',
        currentPassword: 'Current Password',
        yourPassword: 'Your Password',
        newPassword: 'New Password',
        confirm: 'Confirm',
        passwordInfo: 'Please enter a minimum of 8 characters',
        cancel: 'Cancel',
        loginInfo: 'Your password has been reset, please login.',
        forgetPassword: 'Forgot Password',
        phone: 'Phone',
        yourPhone: 'Your Phone',
        password: 'Password',
        remember: 'Remember',
        errorInfo: 'Invalid country/region code',
        content: 'By using our services, you agree to ZHEN.’s  <span style="color:#FF7A00;">Terms & Conditions </span> and <span style="color:#FF7A00;">Privacy Policy.</span>',
        content2: 'Required field.',
        content3: 'Don’t have an account yet？',
        content4: 'Sign up here.',
        updatePWTitle: 'UPDATE YOUR PASSWORD',
        updatePWContent: 'Update the password received by ZHEN. with a personal pasword to make the login easier.',
        updatePWBtn: 'Reset password'
    },
    register: {
        title: 'SIGN UP',
        title2: 'Sign up',
        email: 'Email',
        yourEmail: 'Your Email',
        phone: 'Phone',
        yourPhone: 'Your Phone',
        fullName: 'Your Full Name',
        emailVer: 'Email Verification',
        yourEmailVer: 'Please enter your email verification code here',
        sMSVerification: 'SMS Verification',
        yourSmsVer: 'Please enter your SMS verification code here',
        setPassword: 'Set Password',
        youSetPassword: 'Your Password',
        sendCode: 'Send Code',
        verCodeInfoSend: 'Verification Code Sent',
        verCodeInfo: 'Your verification code has been sent to the email address provided. If you don’t receive your code within 5 minutes, please check your spam folder or request again.',
        verCodeInfo2: 'Your verification code has been sent to the phone number provided. If you don’t receive your code within 5 minutes, please request a new code.',
        note: 'Note',
        registerTitle: 'SIGNUP SUCCESSFUL',
        registerInfo: 'Please log in to continue.',
        errorInfo: 'Invalid country/region code',
        content: 'By using our services, you agree to ZHEN.’s  <span style="color:#FF7A00;">Terms & Conditions </span> and <span style="color:#FF7A00;">Privacy Policy.</span>',
        content2: 'Already have an account?',
        content3: 'Log in here'
    },
    cabinet: {
        title: 'MY CABINET',
        title2: 'My Cabinet',
        all: 'ALL',
        edition: 'EDITION',
        editions: 'Editions',
        nftPremium: 'NFT PREMIUM',
        nft: 'NFT',
        noText: 'Your cabinet is empty!<br>Start adding editions to your cabinet by browsing our<br>Marketplace or downloading the ZHEN. app.',
        dialogTitle: 'YOUR CABINET IS EMPTY!',
        dialogContent: 'Treat yourself to a ZHEN. -authenticated edition now via the ZHEN. app or in our marketplace.',
        dialogBtn1: 'Download',
        dialogBtn2: 'Marketplace',
        detail: {
            description: 'DESCRIPTION',
            conentText: ' WHAT IS ',
            conentText2: 'Purchased on',
            conentText3: 'Transfer ownership',
            artist: 'THE ARTIST',
            currentOWner: 'CURRENT OWNER',
            productInfo: 'PRODUCT INFO',
            publishedBy: 'Published by',
            producedBy: 'Produced by',
            material: 'Material',
            color: 'Color',
            editionOf: 'Edition of',
            measurements: 'Measurements',
            weight: 'Weight',
            manufacturingYear: 'Manufacturing Year',
            editionInfo: 'EDITION INFO',
            editionNo: 'Edition No',
            serialNo: 'Serial No',
            ownershipInfo: 'OWNERSHIP INFO',
            currentOwner: 'Current Owner:',
            accountNumber: 'Account Number',
            purchaseDate: 'Purchase Date',
            ownershipTransfer: 'Ownership Transfer',
            txid: 'TXID',
        },
        dialog: {
            title: 'TRANSFER OWNERSHIP',
            desc: 'To keep your ownership secure, ZHEN. needs to make sure it’s you. Please provide the details below to continue your transfer.',
            userId: 'ZHEN. ID of the New Owner',
            password: 'Your Password',
            code: 'Code',
            codeHolder: 'Enter your verification code here',
            success: 'SUCCESS!',
            tip: 'Congratulations! You have successfully transferred your ownership of #username #workname.',
            backCabinet: 'Back to cabinet'
        }
    },
    myMarkPlace: {
        shopNow: 'Shop now',
        dropsCalendar: 'DROP CALENDAR',
        notifyMe: ' Notify Me',
        artist: 'THE ARTIST',
        description: 'EDITOR’S NOTE',
        conentText: ' WHAT IS PREMUM NFT?',
        productDetail: 'PRODUCT DETAILS',
        productDetail2: 'PRODUCT DETAILS',
        history: 'HISTORY',
        view: 'View',
        content: 'To complete your order, you will need to grant ZHEN. access to your MetaMask wallet. Please select “Connect Wallet” to continue.',
        digitalWallet: 'Digital Wallet',
        connectwallet: 'Connect Wallet',
        day: 'Day',
        cancel: 'Cancel',
        title: 'ENTERED',
        installText: 'Install',
        installText1: 'INSTALL',
        dialogTitle1: 'CONNECT YOUR WALLET',
        label1: 'Terms & Conditions',
        label2: 'Privacy Policy.',
        label3: 'and',
        conentText2: 'Thank you for entering the raffle. You will be notified of the results soon, so make sure to turn on your notifications in the ZHEN. app. Good luck',
        contentText3: 'By using our services, you agree to ZHEN.’s  ',
        contentText4: 'Install MetaMask add-on to connect to ZHEN. and continue with your purchase.',
        checkOut: 'Check out',
        alertText1: "Thanks for subscribing, we'll be in touch on time.",
        alertText2: "You haven't filled in your email address, please complete you information on profile page.",
        alertTitle: 'NOT ENOUGH ETH TO COMPLETE PURCHASE',
        alertText3: 'Please ensure that you have enough main network ETH on your wallet to continue the purchase.',
        alertBtn: 'See Tutorial',
        comingsoon: 'Coming Soon',
        raffleRule:'RAFFLE DETAILS',
        event:'EVENT '
    },
    myprofile: {
        title: 'PROFILE',
        title2: 'Profile',
        detail: 'PERSONAL DETAILS',
        userId: 'User ID',
        yourWallet: 'Your Wallet',
        connect: 'No wallet is connected.',
        connectWallet: 'Connect Wallet',
        disconnect: 'Disconnect',
        editImg: 'Edit Image',
        editName: 'EDIT NAME',
        confirm: 'Confirm',
        cancel: 'Cancel',
        fullName: ' Full legal name',
        youNewName: 'Your New Name',
        editPhone: 'EDIT PHONE',
        phone: 'Phone',
        newPhone: 'New Phone',
        youNewPhone: 'Your New Phone',
        email: 'Email',
        newEmail: 'New Email',
        youEmail: 'Your New Email',
        editPassword: 'EDIT PASSWORD',
        cureentPassword: 'Current Password',
        password: 'Password',
        youPassword: 'Your Password',
        newPassword: 'New Password',
        enterPassword: 'Enter your password here',
        edit: 'Edit',
        sMSVerification: 'SMS Verification',
        smsVerCode: 'Enter your verification code here',
        sendCode: 'Send Code',
        accountPassword: 'ZHEN. Account Password',
        editEmail: 'EDIT EMAIL',
        emailVerification: 'Email Verification',
        errorInfo: 'Invalid country/region code',
        verificationCodeSent: 'Verification Code Sent',
        verCodeInfo: 'Your verification code has been sent to the email address provided. If you don’t receive your code within 5 minutes, please check your spam folder or request again.',
        verCodeInfo2: 'Your verification code has been sent to the phone number provided. If you don’t receive your code within 5 minutes, please request a new code.',
        note: 'Note',
        uploadInfo: 'Uploaded file is not a valid image. Only JPG, JPEG and PNG files are allowed.',
        orderHistory: {
            title: 'ORDER HISTORY',
            viewOrder: 'View Order',
            order: 'ORDER',
            productOverView: 'PRODUCT OVERVIEW',
            deliveryFee: 'Delivery fee',
            total: 'Total',
            deliveryaddr: 'DELIVERY ADDRESS',
            parcels: 'PARCELS',
            contactUs: 'Contact us',
            nodata: "The carrier hasn't provided shipping information, please check later."
        },
        noWithdrawHistory: 'You have no withdrawal history.',
        withdrawHistory: 'WITHDRAW HISTORY',
        noTransfer: 'No ownership transfers.',
        noOrder: 'You don’t have any order yet.',
        twofa: {
            title:'ENABLE TWO-FACTOR AUTHENTICATION',
            authenticator:'Google Authenticator',
            bind:'Enable',
            unbind:'Disable',
            downDialogTitle:'SECURITY ALERT',
            downDialogContent:'ZHEN. recommends protecting your account by enabling two-factor authentication. Enable Google Authenticator for this additional protection.',
            downDialogBtn1:'Enable 2FA',
            downDialogBtn2:'Not now',
            bindTitle:'Enable 2FA with Google Authenticator',
            bindRemark:'You will need the Google Authenticator mobile app to continue. Install Google Authenticator on your mobile device to complete the next step.',
            bindRemark1:'Open the Google Authenticator app on your mobile device. Click the + button and scan the QR code below, or input the string of text provided below and click Next.',
            bindRemark2:'Do not share these authentication details with anyone. Please contact us at support@zhen.io with questions or concerns about the security of your account.',
            continue:'Next',
            enterCode:'Enter the six digit code from your Google Authenticator app.',
            confirm:'Confirm',
            copySuccessTitle:'key copied!',
            copySuccess: 'Your key has been copied to your Clipboard.',
            unbindTitle:'Disable?',
            unbindContent:'Disabling Google Authenticator will remove the extra security on your account.',
            unbindAlertBtn:'Disable Anyway',
            validText:'2-STEP AUTHENTICATION',
            startValid:'Start Authentication',
            authenticated:'Authenticated',
            errorMsg:'Incorrect Code'
        }
    },
    forgetPassword: {
        title: 'FORGOT PASSWORD',
        title2: 'Reset password',
        email: 'Email',
        yourEmail: 'Your Email',
        phone: 'Phone',
        yourPhone: 'Your Phone',
        emailVer: 'Email Verification',
        smsVer: 'SMS Verification',
        yourEmailVer: 'Please enter your email verification code here',
        yourPhoneVer: 'Please enter your SMS verification code here',
        setPassword: 'Set Password',
        youSetPassword: 'Your Password',
        remember: 'Remember',
        sendCode: 'Send Code',
        content: 'Required field.'
    },
    home: {
        reimagine: 'REIMAGINE OWNERSHIP',
        systemRemark: 'The world’s most secure art ownership authentication system, <br />all in the palm of your hand.',
        shopNow: 'Shop now',
        technologyText: 'OUR TECHNOLOGY',
        technologyRemark: 'AUTHENTICATES <br>ARTWORK VIA NFTS,<br> POWERED BY BLOCKCHAIN.',
        nftText: 'IS BUILT ON ETHEREUM (ERC-721).',
        nftFeature: 'Decentralized.<br>Immutable.<br>Highly secure.<br>Transparent.',
        nftAuthenticity: 'Ownership and authenticity, protected by blockchain.',
        tapText: '1-TAP<br>AUTHENTICATION',
        tapFeature: 'Intuitive and frictionless.<br>Authentication made radically simple. <br>All in the palm of your hand.',
        experienceText: 'THE EXPERIENCE',
        ownershipText: 'A NEW OWNERSHIP<br>EXPERIENCE AWAITS YOU.',
        ownershipRemark: 'The quickest way to ascertain ownership.<br>Authenticity and provenance, wherever you need it.',
        artText: 'YOUR ENTIRE ART COLLECTION',
        artText1: 'IN YOUR POCKET.',
        artRemark: 'An unprecedented way to display your physical and digital collection.<br>Ownership has never looked like this before.',
        appText: 'EVERYTHING YOU’LL<br> NEED IN ONE APP.',
        authMethod: 'Scan your ZHEN. -authenticated edition seamlessly and effortlessly to register ownership and ascertain authenticity.',
        productText: 'PROVENANCE AND HISTORY',
        productOwnership: 'Get details on where your edition came from, and become part of that journey by certifying your ownership.',
        collectorsText: 'COLLECTORS’ CABINET',
        collectorsRemark: 'Enjoy a hybrid view of your favorite physical and digital pieces together.',
        contentText: 'EXCLUSIVE CONTENT',
        contentRemark: 'Dive into the world of art tech through our original editorial content.',
        shopText: 'SHOP AND DISCOVER',
        shopRemark: 'Explore curated drops and raffles, and check out our Edition Archive.',
        nftDrops: 'NFT DROPS, LIKE YOU’VE NEVER<br>SEEN BEFORE.',
        nftDropsRemark: 'We’ve reimagined the NFT drop by bridging the gap between the digital and the physical.',
        best: 'It’s what we do best',
        artistTitle: 'OUR ARTIST NETWORK',
        artistRemark: 'has proudly authenticated artworks from the world’s most influential artists.',
        miss: 'Don’t miss out.',
        joinUs: 'Join us on the new frontier.',
        holder: 'Enter your email',
        holder2: 'Email can not be empty',
        holder3: 'Please enter the correct email format',
        holder4: "Thanks for subscribing.",
        note: 'Note',
        confirm: 'Confirm',
        cancel: 'Cancel',
        holder5: 'Your email',
        runningText1:'THE HAJIME SORAYAMA NFT HAS DROPPED',
        runningText2:'ENTER THE RAFFLE NOW',
        runningText3:'UNTITLED_SHARK ROBOT NFT IS HERE',
    },
    manual: {
        youGuideTo: 'YOUR GUIDE TO',
        ownerShipTransfer: 'OWNERSHIP TRANSFER',
        ask: 'Is your ZHEN. -authenticated product headed for a new home?',
        que: 'With a few simple taps, you can safely transfer the digital ownership of your<br> edition to a new owner.',
        initiation: 'INITIATION',
        content: 'To initiate the ownership transfer, you must be the registered owner of the edition. Please note that you have to be logged in to ZHEN. throughout the transfer.',
        contentList: [
            {
                list: '1 &nbsp;&nbsp;&nbsp;&nbsp   Access the product again by scanning with the ZHEN. app, or finding it within your collector’s cabinet within the app.'
            },
            {
                list: '2 &nbsp;&nbsp;&nbsp;&nbsp  On the product authentication page, a “Transfer Ownership” button will appear.'
            },
            {
                list: '3 &nbsp;&nbsp;&nbsp;&nbsp   Tap that button to continue.'
            }
        ],
        information: {
            title: 'INFORMATION',
            content: 'The buyer (future owner) will receive an email indicating<br> that someone has requested to transfer ownership of a<br> ZHEN. -authenticated product to them.'
        },
        informations: {
            list: [
                {
                    ask: '1 &nbsp;&nbsp;&nbsp;&nbsp  FUTURE OWNER ZHEN. ID',
                    que: 'Add the associated ZHEN. ID of the future owner<br> in the first field. Note that this future owner must<br> also be a ZHEN. user for this ownership transfer<br> to work.'
                },
                {
                    ask: '2&nbsp;&nbsp;&nbsp;&nbsp  YOUR ZHEN. PASSWORD',
                    que: "Insert the password connected to your ZHEN.<br> account (the account of the current owner).<br><br><span style='font-size:10px;'>Each account’s unique ZHEN. ID can be found in the User<br> Profile.</span>"
                },
                {
                    ask: '3&nbsp;&nbsp;&nbsp;&nbsp  VERIFICATION CODE REQUEST',
                    que: 'Hit “Send Code” to receive a unique code by text<br> or email to verify your identity. (The code will be<br> delivered to the phone number or email address<br> associated with your account.)'
                },
                {
                    ask: '4&nbsp;&nbsp;&nbsp;&nbsp  INSERT VERIFICATION CODE',
                    que: 'After receiving the verification code via SMS or<br> email, enter that code in the verification field.'
                },
                {
                    ask: '5&nbsp;&nbsp;&nbsp;&nbsp  SUCCESSFUL INITIATION',
                    que: "After filling out the form, the button “Transfer<br> Ownership” will be clickable, and you’ll be able to<br> start the transfer.<br>Upon successful initiation, a pop-up notification<br> will appear to confirm the initiation."
                },
                {
                    ask: '6&nbsp;&nbsp;&nbsp;&nbsp  TRANSFER REQUEST RECEIVED',
                    que: "You will receive an email stating that ZHEN. has received the ownership transfer.<br><br>Ownership transfer is now pending."
                }
            ]
        },
        transfer: {
            title: 'TRANSFER',
            content: 'The buyer (future owner) will receive an email indicating<br> that someone has requested to transfer ownership of a<br> ZHEN. -authenticated product to them.',
        },
        transfers: {
            list: [
                {
                    ask: '1.&nbsp;&nbsp;&nbsp;&nbsp  CONFIRMATION EMAIL',
                    que: 'Upon successful initiation from the current<br> owner, the future owner will receive an email<br> notification and must now log in to<br> ZHEN. to accept the transfer.'
                },
                {
                    ask: '2.&nbsp;&nbsp;&nbsp;&nbsp  TRANSFER COMPLETED',
                    que: 'Upon accepting, the transfer will be completed.<br> Each transfer request is verified by ZHEN. and<br> checked against the blockchain to ensure a valid<br> transaction, and may take several business days<br> to complete.'
                },
            ]
        },
        help: "Need help? We're here for you."
    },
    download: {
        title: 'DOWNLOAD NOW',
        content: 'DOWNLOAD ZHEN. NOW,<br>AVAILABLE ON ALL APP STORES GLOBALLY.',
        content2: '<span style="font-size:21px;">The power of NFTs, and radically simple art authentication<br> — all in the palm of your hand.</span>',
        ios: 'iOS',
        android: 'Android',
        contentList: {
            content: 'The anchor of your new ownership experience.<br>With ZHEN. you can:',
            list: [
                {
                    ask: 'AUTHENTICATE',
                    que: 'Register ownership of your favorite authentic editions.'
                },
                {
                    ask: 'DISCOVER',
                    que: 'Explore the finest ZHEN. -authenticated editions.'
                },
                {
                    ask: 'COLLECT',
                    que: 'Show off your physical and digital collections, all in one<br> place, for the first time ever.'
                },
                {
                    ask: 'EDUCATE',
                    que: 'Get to know your favorite artists, and read exclusive<br> stories around blockchain and art.'
                },
            ]
        }
    },
    footer: {
        legal: 'LEGAL',
        // carreers: 'Carreers',
        // company: 'Company',
        //data: 'Data'
        warranty: {
            title: "Warranty"
        },
        support: {
            title: 'THE SUPPORT YOU NEED,<br>ALL IN ONE PLACE.',
            menus: 'Support',
            menuContext: [
                {
                    title: 'OWNERSHIP + AUTHENTICATION<br>',
                    content: 'Everything you need to know about<br> your new collectibles',
                    footerContent: 'View Manuals'
                },
                {
                    title: 'FAQs<br>',
                    content: "Answers to some of ZHEN.'s most<br> pressing questions.",
                    footerContent: 'See more'
                },
                {
                    title: 'CONTACT US<br>',
                    content: "Need something else? Drop our<br> support team a line.",
                    footerContent: 'Get in touch'
                }
            ]
        },
        faq: {

            title: 'FAQ',
            menus: ['General', 'AUTHENTICATION', 'Logistics', 'Data and Privacy', 'Bugs/Troubleshooting', 'NFT Withdrawal and Deposit NFT', 'HOW TO PURCHASE NFT', 'HOW TO TRANSFER NFT OWNERSHIP TO A ZHEN. USER', 'HOW TO WITHDRAWAL ZHEN. NFT TO WALLET', 'Augmented Reality (AR)'],
            menuContext: [
                {
                    title: "GENERAL",
                    faq: [
                        {
                            ask: "What is ZHEN. ?",
                            que: "ZHEN. is an art tech company that leverages the transparency and security of blockchain into authentication. Using ZHEN.’s native mobile app (iOS and Android) and blockchain technology (Ethereum and Polygon), collectors can authenticate and experience art via NFTs.<br /><br />"
                        },
                        {
                            ask: "How does ZHEN. work?",
                            que: "We’re building an ecosystem of artwork authentication. With NFC chips implanted inside each artwork, and an ownership verification system powered by blockchain, we’re able to guarantee that each ZHEN. -authenticated artwork is the original edition from the artist, and provide provenance for digital ownership.<br /><br />"
                        },
                        {
                            ask: "Are you looking to collaborate with anyone right now?",
                            que: "Yes! We are always looking for new artists and collaborators to work with us. Please reach out by sending us your ideas to <a class=\"email2\" href=\"mailto:partnerships@zhen.io\">partnerships@zhen.io</a>.<br /><br />"
                        },
                        {
                            ask: "What does ZHEN. mean?",
                            que: "In Chinese, ZHEN. means precious or rare (珍); real and genuine (真); and perfection (臻) — all of our values, summarized into one. <br><br> For us, ZHEN. signifies a reimagining, a new generation of collectors and a new beginning in art. The word is pronounced like the “gen” in “generation” or “genesis”.<br /><br />"
                        }
                    ]
                },
                {
                    title: "AUTHENTICATION",
                    faq: [
                        {
                            ask: "What does it mean when you say “blockchain authentication”? How do you authenticate artwork?",
                            que: "With a security chip embedded in the collectible, ZHEN. is already taking the authentication process to the next level. However, we don’t just stop here; we believe the authentication process should NOT be confined to just the physical form, and that digital ownership is as important as physical ownership.<br><br>" +
                                "With ZHEN., digital ownership takes the form of a non-fungible token (“NFT”) on blockchain, which is an open, immutable ledger for recording transactions. Each NFT corresponds to one unique item and stores redacted information on digital ownership, ID serial numbers, item descriptions, and other product information. (For a full list, please refer to the “Data and Privacy” section in this FAQ.)<br><br>" +
                                "When you scan the collectible that is authenticated by us, ZHEN. will check all these for you, verifying with the data on blockchain. It’s simple, safe, and accurate, and we’re confident that it will revolutionize the way art is authenticated.<br><br>"
                        },
                        {
                            ask: "How do I authenticate and register ownership of a product?",
                            que: "In most instances, ZHEN.-authenticated collectibles are authenticated by logging into your ZHEN. app and pressing the authentication icon [insert icon]. From here, follow the instructions on the screen.  Every ZHEN.-authenticated collectible comes with an illustrated booklet (owner’s manual), instruction manual or card, or animated lenticular card that indicates clearly where the NFC chip (located within the ZHEN. logo) is located within each specific item with a dedicated verification code attached. Please refer to these instructions for additional help.<br><br>" +
                                "If you have purchased the collectible on the secondary market and it does not come with an owner’s manual, or if you need any additional help, email <a class=\"email2\" href=\"mailto:support@zhen.io\">support@zhen.io</a> with your product info and ZHEN. ID.<br /><br />"
                        },
                        {
                            ask: "How is ownership stored and transferred?",
                            que: "Each collectible is embedded with a ZHEN. NFC chip that corresponds with a unique Ethereum or Polygon NFT, which can be searched on Etherscan or Polygonscan with the associated TXID. Each of these NFTs stores product information and chip information.<br><br>" +
                                "To further safeguard the full collecting experience, ZHEN. enables end-to-end ownership transfer within the app. The seller initiates the process by physically scanning the product embedded with the ZHEN. NFC chip and tapping a button within the app that begins the process of transferring the digital ownership to the buyer. The request is then sent to our backend for confirmation.<br /><br />" + 
                                "Once confirmed by our team as a valid transfer, the transfer will go through the smart contract, and the ownership transfer is registered on the blockchain, where a unique TXID is generated to confirm the transfer.<br /><br />"
                        },
                        {
                            ask: "I’m not able to scan my product / I can’t find the chip. Can you help?",
                            que: "ZHEN.-authenticated editions include an owner’s manual in the packaging. The manual’s animation or illustration will show you where the chip is located within your artwork. Be sure to also hold your phone in place for up to 5 seconds until the scanning is complete.<br><br>" +
                                "Please also check that the NFC functionality on your phone is enabled and hold your phone as close to the product as you can.<br /><br />" + 
                                "If you are still having trouble scanning your product, please contact us — <a class=\"email2\" href=\"mailto:support@zhen.io\">support@zhen.io</a><br /><br />"
                        }
                    ]
                },
                {
                    title: "LOGISTICS",
                    faq: [
                        {
                            ask: "Do you ship worldwide?",
                            que: "Each product may have different availability by territory, depending on the edition and release. To inquire about a particular product, please contact us at <a class=\"email2\" href=\"mailto:support@zhen.io\">support@zhen.io</a><br><br>"
                        },
                        {
                            ask: "Where do you ship from?",
                            que: "This information is dependent on each order. Please contact us at <a class=\"email2\" href=\"mailto:support@zhen.io\">support@zhen.io</a>. Thank you for your patience!<br><br>"
                        },
                        {
                            ask: "How much is shipping?",
                            que: "Shipping is calculated based on the product’s final destination, as well as the availability by territory, for each product. Therefore, depending on each edition or release, shipping costs may be different. To inquire about a particular product, please contact us at <a class=\"email2\" href=\"mailto:support@zhen.io\">support@zhen.io</a><br><br>"
                        }
                    ]
                },
                {
                    title: "DATA AND PRIVACY",
                    faq: [
                        {
                            ask: "How will my data be stored with ZHEN. ?",
                            que: "Your privacy and security is ZHEN.’s number one priority. Your data is encrypted with MD5 Message-Digest Algorithm and stored in our secured server. We are committed to protecting your data and are continuously updating our internal practices to stay abreast of the latest security breakthroughs and developments.<br><br>"
                        },
                        {
                            ask: "Where are your servers located?",
                            que: "Our data is stored in Amazon Cloud servers located in Singapore.<br><br>"
                        },
                        {
                            ask: "What information will be viewable on the blockchain?",
                            que: "Currently, the following information is viewable on the blockchain:<br>" +
                                "- Product information: Product name, artist name, publisher, manufacturer, edition number, serial number<br>" +
                                "- Material, color, measurements, weight, manufacturing year<br>" +
                                "- Chip information: Token version number, Chip Unique Identifier (= Product Serial Number), image URL<br>" +
                                "- User ID<br><br>" +
                                "The account ID will be the identifier for any prospective buyer to \"know\" via ZHEN. whether the purported seller has the current ownership of the piece or not. That’s why we encourage collectors to change ownership via ZHEN., so that we can verify the seller’s identity and ensure that you are purchasing the genuine product from the genuine seller.\n" +
                                "<br><br>" +
                                "None of this information will be personally identifiable, so rest assured that your privacy is protected.<br><br>"
                        },
                        {
                            ask: "Why does ZHEN. need all this personal information?",
                            que: "We take our inspiration from traditional auction houses, where the industry standard is to be able to point to the provenance for any work of art. This standardization of recording everything on ZHEN. benefits all stakeholders in the market: the artist, the gallery, the collector, and the auction house.\n" +
                                "<br><br>" +
                                "With that being said, data privacy is, and always will be, the top priority for ZHEN. You can be assured that the identities of owners and collectors will stay anonymous in the public domain and on blockchain, and we work actively every day to uphold the highest security and privacy standards possible.<br><br>"
                        },
                    ]
                },
                {
                    title: "BUGS/TROUBLESHOOTING",
                    faq: [
                        {
                            ask: "The app doesn’t work on my phone. Can you help?",
                            que: "We’ve done our best to ensure that as many people as possible can enjoy the full ZHEN. app experience, but operating system and phone hardware issues are two common reasons why the ZHEN. app may not be working as intended on your phone. Please send us an email with more information, including your current operating system and phone model — <a class=\"email2\" href=\"mailto:support@zhen.io\">support@zhen.io</a><br><br>"
                        },
                        {
                            ask: "I received a damaged collectible. How can you help with this?",
                            que: "We apologize that your collectible was damaged when you received it. We always work with suppliers and dispatchers to make sure that all of our products are in good condition before packing and shipping them to you. Unfortunately, once a package leaves our distribution center we no longer have authority over the condition of the product. We are only able to handle requests if the defect occurred during production. Please reach out with images and a description of the damaged product to <a class=\"email2\" href=\"mailto:support@zhen.io\">support@zhen.io</a> and familiarize yourself with our  <a class=\"email2\" href=\"/warranty\">Warranty</a> here.<br><br>"
                        }
                    ]
                },
                {
                    title: "NFT WITHDRAWAL AND DEPOSIT NFT",
                    faq: [
                        {
                            ask: "After the withdrawal is completed, where can I see my NFT?",
                            que: "When your withdrawal request is successful, your NFT will be viewable on a decentralized platform (such as OpenSea) when you log in via the crypto wallet you configured during the withdrawal process.<br><br>"
                        },
                        {
                            ask: "To be deposited, the NFT has to be in your wallet. How do I add an NFT to my crypto wallet?",
                            que: "When an NFT is in your wallet, you must take additional steps to be able to view it in your wallet. You’ll need your NFT’s token ID and contract address, which can be found in the NFT’s details on its decentralized platform (such as OpenSea). Ethereum has a simple tutorial described <a href='https://ethereum.org/de/developers/tutorials/how-to-view-nft-in-metamask/' target='_blank'>here</a>.<br><br>"
                        },
                        {
                            ask: "Where can I find my NFT’s contract address?",
                            que: "If your NFT is stored in your ZHEN. cabinet, you can see your NFT’s contract address in the  METADATA section on the NFT details page of your Dashboard.<br><br>"+
                                "For NFTs deposited into the ZHEN. platform from a secondary marketplace, you can view the contract address in the <a href='/deposit'>deposit history records</a>.<br><br>"+
                                "For NFTs withdrawn from the ZHEN. platform, you can view the contract address in the <a href='/withdraw'>withdrawal history records</a>.<br><br>"+
                                "If your NFT is not currently on the ZHEN. platform, you can see its contract address in the NFT details page of the decentralized platform you are using (such as OpenSea).<br><br>"
                        },
                        {
                            ask: "Can I see non-ZHEN. NFTs that I own in my ZHEN. cabinet?",
                            que: "ZHEN. only supports the viewing of NFTs created by ZHEN.; other non-ZHEN. NFTs may not function correctly when deposited.<br><br>"
                        },
                        {
                            ask: "Will ZHEN. be responsible if anything happens to my NFT after withdrawing it?",
                            que: "We are not responsible for your NFT once you choose to withdraw it from your ZHEN. cabinet, nor are we liable in the event that something happens to your NFT outside of our custody. Do not share your crypto wallet password or seed phrase with anyone.<br><br>"
                        }
                    ]
                },
                {
                    title: "HOW TO PURCHASE NFT",
                    faq: [
                        {
                            ask: "How do I register for an NFT from ZHEN.?",
                            que: "ZHEN. will release NFTs through the ZHEN. marketplace. You will need an activated ZHEN. account, which you can register for using either your cell phone number or your email address. Once logged in to your ZHEN. account, you will be able to register for the NFT drops.<br><br>"
                        },
                        {
                            ask: "How do I purchase an NFT from ZHEN.?",
                            que: "Once raffle winners are picked and notified, either through SMS or email, you will have a specified amount of time to buy your NFT. You can purchase your NFT by completing your transaction on the ZHEN. marketplace with fiat, using either Stripe or Alipay.<br><br>"
                        },
                        {
                            ask: "Do I need to have a crypto wallet to purchase my NFT? ",
                            que: "No, at this time you do not need a crypto wallet to purchase anything from ZHEN. <br><br>"
                        },
                        {
                            ask: "I purchased my NFT, where can I view it?",
                            que: "Once your payment is completed and confirmed, your NFT will show up in your ZHEN. Cabinet. Access to the ZHEN. cabinet can be reached through the ZHEN. app or through logging into the ZHEN. website and viewing it under “my cabinet”.<br><br>"
                        },
                        {
                            ask: "Why isn’t my NFT showing up in my ZHEN. cabinet?",
                            que: "If your purchase shows up in your order history you can rest assured that your NFT transaction has gone through and your NFT will display in your cabinet soon. It may take minutes or a few hours to appear in your ZHEN. cabinet. If it does not appear after 48 hours, please reach out to <a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a><br><br>"
                        }
                    ]  
                },
                {
                    title: "HOW TO TRANSFER NFT OWNERSHIP TO A ZHEN. USER",
                    faq: [
                        {
                            ask: "Can I sell my NFT to another ZHEN. user?",
                            que: "Through ZHEN. this is currently not possible. Our NFTs are compatible, however, with OpenSea, so you may use their platform for secondary market transactions. We hope to include this functionality very soon natively within ZHEN.<br><br>"
                        },
                        {
                            ask: "How do I transfer my NFT to another ZHEN. user?",
                            que: "Through ZHEN. this is currently not possible. This functionality is coming soon.<br><br>"
                        }
                    ]  
                },
                {
                    title: "HOW TO WITHDRAWAL ZHEN. NFT TO WALLET",
                    faq: [
                        {
                            ask: "What crypto wallets can I connect to my ZHEN. account? ",
                            que: "On your desktop browser, the ZHEN. website supports MetaMask. Currently, a user can only connect their wallet on desktop browsers but we are looking to extend the functionality to our mobile app.<br><br>"
                        },
                        {
                            ask: "What information does ZHEN. need to connect my crypto wallet?",
                            que: "You will need to connect your crypto wallet to your ZHEN. account first by logging into your ZHEN. account through the website. This step cannot be completed in the ZHEN. app. Once logged in, you will go to the “wallet” tab and hit the “connect wallet” button which will prompt you to connect your wallet. A wallet extension must be running on your browser first. ZHEN. will never ask you for your wallet password or your Secret Recovery Phrase.<br><br>"
                        },
                        {
                            ask: "How do I move my ZHEN. NFT to my crypto wallet?",
                            que: "To move your ZHEN. NFT to your wallet, you need to hit the “withdraw” button under the “Withdrawal” section, pick the NFT you want to withdraw, and manually enter your wallet address. You will be asked to put in your account password and a security code that will be sent to either your email or SMS depending on what you used to sign-up for your account. You will be asked to put in your ZHEN. password and a security code.<br><br>"
                        },
                        {
                            ask: "If there is an airdrop or future utility associated with a ZHEN. NFT, does it have to be in my ZHEN. cabinet for me to receive it?",
                            que: "Currently, yes. If you would like to experience any additional benefits associated with your NFT, it must remain in your ZHEN. cabinet. We will announce any changes to this function should any changes occur in the future.<br><br>"
                        }
                    ]  
                },
                {
                    title: "AUGMENTED REALITY (AR)",
                    faq: [
                        {
                            ask: "What is Augmented Reality?",
                            que: "Augmented Reality or AR as it is commonly known as is an altered version of reality where data or 3D images of objects are overlaid over the real world. In other words, AR is a combination of the real and digital worlds where a user can interact with the enhanced environment.<br><br>"
                        },
                        {
                            ask: "How does Augmented Reality work?",
                            que: "There are many different types of Augmented Reality filters or effects, but all of them involve merging the real and virtual worlds to create a unique kind of experience. Some AR effects that ZHEN. supports are:<br><br>" + 
                            "- Markered: An effect that overlays a specific marker, triggered only when that marker is in view.<br>" + 
                            "Using image recognition and mapping technology, the AR experience plays on top of the designated image marker, or elsewhere after the initial marker has been recognized. The resulting effect can be in 2D or 3D.<br>" + 
                            "- Markerless: With this effect, there is nothing to anchor to specifically, making it possible to place your AR sculpture or artwork whenever you are, interacting with your surroundings. You can place AR objects on your coffee table, or view a life-sized Tyrannosaurus Rex while walking around a train station terminal — the possibilities are endless.<br>" + 
                            "- Face tracking: This is an interactive effect that responds to your face. With facial recognition and mapping technology, our face tracker uses the front-facing camera to detect the position and orientation of the face using the effect.<br><br>" + 
                            "Using our AR functionalities means that our app has to access your front and rear cameras and scan your surroundings and/or face. We take our collectors’ privacy very seriously and any visual information received or transmitted during the experience will not be used for non-AR purposes. You can review our <a class=\"email2\" href=\"/privacy\">Privacy Policy</a> here."
                        },
                        {
                            ask: "How is Augmented Reality (AR) different from Virtual Reality (VR)?",
                            que: "Virtual Reality or VR provides a completely immersive experience shutting out the physical world using sensory devices like headsets whereas Augmented Reality (AR) creates a perfect blend of the real and digital worlds by overlaying digital components over the real world. At ZHEN., our focus currently remains on bringing art experiences into AR as part of our authentication and ownership experience.<br><br>"
                        },
                        {
                            ask: "How do I experience Augmented Reality?",
                            que: "Augmented Reality can be experienced on smartphones, tablets, via smart glasses, but also via regular desktop computers. Via ZHEN.’s app on a smartphone, users can experience AR based on image recognition, object recognition, and geo-position. Things like movements, audio, thermography, and color recognition can also be triggers for AR.<br><br>" + 
                            "AR can also be spatially positioned as soon as the device “understands” the environment.<br><br>"
                        },
                        {
                            ask: "How can I activate my AR experience?",
                            que: "Generally speaking, ZHEN. AR can be experienced by following the steps below: <br><br>" + 
                            "&nbsp&nbsp&nbsp&nbsp- Open the ZHEN. app<br>" + 
                            "&nbsp&nbsp&nbsp&nbsp- If the Augmented Reality experience is tied to a physical edition, first authenticate the artwork by scanning the NFC chip and be sure the work is viewable in your ZHEN. cabinet.<br>" + 
                            "&nbsp&nbsp&nbsp&nbsp- Once the work is available in your ZHEN. cabinet, tap the AR icon on the top right corner of the product<br>" + 
                            "&nbsp&nbsp&nbsp&nbsp- Follow the on-screen instructions<br>" + 
                            "&nbsp&nbsp&nbsp&nbsp- The AR experience will be activated and presented accordingly.<br><br>" + 
                            "Depending on your internet connection and the make and model of your phone, the effect may take longer to load.<br><br>"
                        },
                        {
                            ask: "Can I only experience the AR effect through my ZHEN. app?",
                            que: "AR experiences that come exclusively with a ZHEN.-authenticated artwork and/or NFT are accessible only via the ZHEN. app; this is to ensure that only you, the rightful owner, have exclusive access to these owner-only experiences.<br><br>"
                        },
                        {
                            ask: "I am not able to load my ZHEN. exclusive AR effect.  Any tips?",
                            que: "It is highly recommended to connect to a stable WiFi network to experience the Augmented Reality provided by ZHEN. Additionally, a well-lit space that is free from too many objects or clutter can provide a better AR experience.<br><br>"
                        },
                        {
                            ask: "Why is ZHEN. creating Augmented Reality experiences?",
                            que: "Augmented Reality or AR is in step with our ongoing mission of reimagining ownership. With ZHEN., collectors not only own a physical or digital edition, but also an experience that is unlocked through the connected NFT, elevating ownership through technology. The possibilities with AR to create unique and special moments for collectors are limitless. With AR bridging the digital and physical realms, we are forging a new path forward, exploring a brand new concept of ownership and providing a more meaningful experience for both artists and collectors.<br><br>"
                        }
                    ]  
                }
            ],
        },
        contactUs: 'Work With Us',
        downloadApp: 'Download Now',
        privacyPolicy: {
            title: 'Privacy Policy'
        },
        termsAndConditions: 'Terms and Conditions',
        tc: {
            title: 'T&C'
        },
    },
    pay: {
        checkOut: 'CHECK OUT',
        product: '1. PRODUCT',
        shipping: '2. SHIPPING',
        payMent: '3. PAYMENT AND CONFIRMATION',
        payMent1: '2. PAYMENT AND CONFIRMATION',
        productOverView: 'Product overview',
        subTotal: 'Subtotal',
        content: 'Delivery fee will be calculated in the next section.',
        next: 'next >',
        payMethod: 'Payment Method',
        digitalWallet: 'Digital Wallet',
        other: 'Online Payment',
        shipTo: 'Ship to',
        deliveryFee: 'Delivery fee',
        total: 'Total',
        back: 'back',
        confirmPayMent: 'Confirm Payment',
        content5: 'YOUR  ORDER IS COMPLETE!',
        content2: 'An email confirmation with your order details has been sent to your address on file!',
        content3: 'Continue Browsing',
        firstName: 'First Name',
        lastName: 'Last Name',
        address: 'Address',
        phoneNumber: 'Phone Number',
        select1: 'State/ Province/ Region',
        city: 'City',
        email: 'Email',
        select2: 'ZIP/ Postal Code',
        country: 'Country/ Region',
        next2: 'next',
        content4: 'required field',
        phoneHolder: 'Your Phone',
        firstNameHolder: 'Your First Name',
        lastNameHolder: 'Your Last Name',
        addressHolder: '15 Hollywood Street, Apartment 16G',
        stateHolder: 'California',
        cityHolder: 'Los Angeles',
        emailHolder: 'youremail@gmail.com',
        codeHolder: '90039',
        countryHolder: 'USA',
        note: 'Note',
        confirm: 'Confirm',
        cancel: 'Cancel',
        back2: 'Back',
        content6: 'OH NO! We‘re sorry, but your selection is no longer available. Please try again, or select another product.',
        content7: 'PRICE CHANGE!<br />Due to currency changes, your payment total has changed and has been automatically updated. Please continue to check out to finish your payment process.',
        content8: 'YOUR  ORDER IS CANCEL!',
        notComplete:'YOUR ORDER WAS NOT COMPLETED'
    },
    other: {
        title: 'YOU ARE NOT LOGGED IN',
        content: 'Log in or sign up now to fully experience the ZHEN. marketplace and receive updates.',
        login: 'Log in',
        register: 'Sign up',
        continue: 'Continue browsing',
        cookiesText: 'This website uses cookies to improve your experience.',
        readMore: 'Read More',
        acceptAll: 'Accept all',
        noAreaText:'No matched Country/ Region',
    },
    notFind: {
        title: 'PAGE NOT FOUND',
        content1: 'Oops, we can’t verify the existence of this page.',
        content2: 'Don’t worry, though — we’ve authenticated (almost) everything else around here.'
    },
    wallet: {
        wallet: 'Wallet',
        error: 'Error',
        confirm: 'Confirm',
        cancel: 'Cancel',
        connectTitle: 'CONNECT WALLET',
        connectRemark: 'This is your Metamask wallet, from which your digital payments in ETH are withdrawn.',
        walletLabel: 'Your verified wallet address:',
        connectWallet: '<span style="color:#BCBCBC;">You do not have a wallet connected to ZHEN. Connect your wallet to continue.</span>',
        copyLabel: 'Copy',
        depositTitle: 'DEPOSIT WALLET',
        depositWalletLabel: 'Your Deposit wallet address:',
        depositRemark1: 'Send only ZHEN.-issued NFTs to this address. Sending any other NFTs may result in permanent loss.',
        depositRemark2: 'Non-ZHEN.-issued NFTs are currently not compatible with the ZHEN. platform and will not render or display correctly in your ZHEN. cabinet or other parts of the site. Proceed with extreme caution.',
        scanLabel: 'Scan',
        withdrawTitle: 'WITHDRAWAL',
        withdrawDialogTitle:'NFT WITHDRAWAL',
        withdrawRemark1: 'The following ZHEN. NFTs associated with your account are eligible for withdrawal.',
        withdrawRemark2: 'Please note that withdrawing your NFT will result in your edition no longer appearing in your ZHEN. cabinet. Re-depositing your ZHEN.-issued NFT will reinstate your edition in your cabinet.',
        withdrawBtn: 'Withdraw',
        copySuccessTitle: 'connect wallet copied!',
        copySuccessContent: 'Your Connect Wallet has been copied to your Clipboard.',
        copySuccessTitle1: 'Deposit wallet copied!',
        copySuccessContent1: 'Your Deposit Wallet has been copied to your Clipboard.',
        walletAddress: 'Your #nftChain Wallet Address',
        walletAddressError:'Please enter the correct blockchain wallet address！',
        submit: 'Submit',
        close: 'Close',
        ok: 'OK',
        successTitle: 'Request successful!',
        successMsg: 'Your withdrawal request is submitted. The withdrawal process can take up to 3 business days.',
        noWithdraw: 'You do not currently have any NFTs associated with your ZHEN. account.',
        penddingTitle: 'Request under review',
        penddingContent: 'This request is under review now. We will process your request in 3 businesse days,  Thank you for waiting!'
    },
    surpsise: {
        title:'ENTER USING SURPRISE PASSWORD',
        remark:'To visit the product page and purchase the first ever NFT drop from Hajime<br> Sorayama please enter your unique secret code in the field below:',
        inputLabel:'Surprise Password',
        inputHolder:'Enter your code here',
        btnText:'Enter'
    },
    productType: {
        1: {
            title: "EDITION",
            whatTxt:'AN EDITION',
            hover:"A ZHEN.-authenticated edition certifies physical ownership with digital provenance.",
            remark:"A ZHEN.-authenticated edition: the most secure way to own art. ZHEN. certifies your physical editions and collectibles with digital ownership through our NFC security chips, each tied to a unique NFT on the blockchain. By combining the immutability and security of blockchain with inseparable authenticity and provenance, ZHEN. powers a radically new art ownership and collecting experience.",
        },
        2: {
            title: "PREMIUM NFT",
            whatTxt:'A PREMIUM NFT',
            hover:"A ZHEN. Premium NFT offers a hybrid physical and digital ownership experience.",
            remark:"A ZHEN. Premium NFT bridges the physical and digital worlds, reimagining and redefining what ownership of a digital artwork means. More than just a digital artwork existing on the blockchain, premium NFTs are a hybrid ownership experience, coming with a physical counterpart (such as an artwork, collectible, or other tangible item), and occasionally together with an augmented reality experience. With premium NFTs on ZHEN., get ready to explore all the possibilities of ownership across every dimension.",
        },
        3: {
            title: "NFT",
            whatTxt:'AN NFT',
            hover:"A ZHEN. NFT brings your favorite artists’ work into the digital realm.",
            remark:"A ZHEN. NFT brings the traditional art ownership experience into the digital world. Get to know your favorite artists’ work in a whole new dimension through faithful recreations as animations or groundbreaking reimagination in augmented or virtual reality. More than your average NFT drop, a ZHEN. NFT pushes the boundaries of what digital art ownership means.",
        }
    },
    dashboard: {
        title:"Dashboard",
        orders:"ORDERS",
        transfer:"OWNERSHIP TRANSFER",
        withdraw:{
            title:"WITHDRAW",
            eligible:'ELIGIBLE FOR WITHDRAWAL',
            please:'Please provide the details below to continue your withdrawal.',
            selectPayment:'SELECT PAYMENT',
            selectPaymentHolder:'Select Payment',
            payRemark1:'We charge a fee for every transaction to cover gas and other incurred costs. If the withdrawal is canceled or otherwise declined, the hold amount will be refunded. ',
            payRemark2:'Withdrawing your NFT will result in your edition no longer appearing in your ZHEN. cabinet. Re-depositing your ZHEN.-issued NFT will reinstate your edition in your cabinet.',
            agree:'I agree to ZHEN.’s updated',
            transactionFee:'Transaction Fee',
            noTransactionFee:'As a thank you for purchasing your NFT through ZHEN., we will waive your transaction fee for your first withdrawal request.',
            successTitle: 'WITHDRAW REQUEST SUBMITTED',
            successMsg: 'Your request will be processed in 3 business days. Confirmation details will be sent via your registered communication method. Active transfers can be viewed in your WITHDRAW HISTORY.',
            withdrawn:'withdrawn',
            history: {
                viewDetails:'View Details',
                requestTime:'Request time',
                withdrawTime:'Withdraw time',
                paymentDetails:'PAYMENT DETAILS',
                paymentMethod:'Transaction fee payment method',
                transactionId:'Transaction fee ID number',
                total:'Transaction fee total',
                withdrawDetails:'WITHDRAW DETAILS',
                contractAddress:'Contract Address',
                serialNo:'Serial No',
                txHash:'TxHash',
                walletAddress:'Wallet Address',
                outgoing:'Outgoing',
                failed:'Failed',
                failedMsg1:'Your NFT withdrawal request was not successful.',
                failedMsg2:'Please try again.'
            }
        },
        deposit:{
            title:"DEPOSIT",
            notConnect:'No wallet is connected, you need to connect to deposit.',
            depositRemark1:'Only ZHEN. NFTs can be deposited.',
            depositRemark2:'To check if your NFT is eligible for deposit click',
            depositRemark3:'You must have a wallet connected to ZHEN. to complete deposits. Connect your wallet to continue.',
            here:'here.',
            depositBtnTxt:'Deposit',
            depositNFT:'DEPOSIT NFT',
            walletAddress:'Wallet address: ',
            depositNFTLabel:'Only ZHEN. NFTs can be deposited. Below are the supported NFTs we found.',
            contractAddress:'Contract Address',
            contractAddressError:'Please enter the correct blockchain contract address！',
            checkTitle:'CHECK NFT COMPATIBILITY',
            checkRemarK:'Only ZHEN. NFTs are compatible for deposit. To check if your NFT is eligible for deposit, enter the NFT contract address in the space below.',
            contractLabel:'NFT Contract Address',
            contractInputHolder:'Your NFT Contract',
            checkBtnText:'Check compatibility',
            compatible:'YOUR NFT IS COMPATIBLE',
            compatibleRemark:'Your NFT is eligible for deposit into ZHEN. You can initiate the deposit process from the Deposit section of your Dashboard.',
            notCompatible:'NFT NOT COMPATIBLE',
            notCompatibleRemark:'Your NFT is not eligible for deposit into ZHEN. ',
            coming:'Incoming',
            submitTitle:'DEPOSIT REQUEST SUBMITTED',
            submitRemark:'Deposit time can vary depending on the blockchain status. You can check the status of your request in the Deposit History section of your dashboard.',
            submitRemark1:'We recommend you do not make any changes while the deposit is processing, such as manually speeding up the process on your wallet after submitting a deposit request, as this can affect the deposit’s success.',
            checkStatus:'Check status',
            helpMsg:'Where can I find my NFT’s contract address?',
            notFound:'NO NFT FOUND',
            notFoundRemark:'We didn’t find any ZHEN.-compatible NFTs in your wallet. If you have recently added an NFT to your wallet, it may take a few minutes to appear here. If you believe you own a compatible NFT, please check your wallet to ensure you have the correct wallet and network for that NFT configured.',
            helpDeposit:'To be deposited, the NFT has to be viewable in your wallet. How do I view an NFT in my crypto wallet?',
            depositDetails:'DEPOSIT DETAILS',
            depositTime:'Deposit time',
            depositHistory:'DEPOSIT HISTORY',
            failedMsg1:'Your deposit request was not successful.',
            noDepositHistory: 'You have no deposit history.'
        },
        transferHistory: {
            transferState:{
                0: { text: "Pending", className: "pending" },
                1: { text: "Confirm"},
                2: { text: "Approved", className: "approved" },
                3: { text: "Rejected", className: "rejected" },
                4: { text: "Canceled", className: "canceled" },
            }
        }
    }

}