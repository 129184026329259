<template>
    <div class="order-cancel">
      <!-- <div class="order-cancel_box">
        <div class="order-cancel_top" style="border-bottom:0.1em solid #000000;">
          <AppNavigator/>
        </div>
        <div class="order_gap"> </div>
        <div class="order-cancel_main">
            <div class="orderComplete_content">
                <h1 style="margin-bottom:25px;color: #000000;"> {{$t('lang.pay.content8')}}</h1>
                <div style="margin-bottom:25px;color: #000000;">{{$t('lang.pay.content2')}}</div>
            </div>
        </div>
        <div class="order-cancel_footer">
          <AppFooter />
        </div>
      </div> -->
    </div>
  </template>
  
  <script>
  import AppNavigator from "@/components/common/AppNavigator.vue"
  import AppFooter from "@/components/common/AppFooter.vue"
  export default {
    name: "OrderComplete",
    components: {
      AppNavigator,
      AppFooter,
    },
    data(){
      return {
      }
    },
    methods: {
    },
    mounted() {
      this.$router.push({ path: "/confirmOrder" });
      
      // let orderId = localStorage.getItem('orderId');
      // this.$http.post('order/cancel', { orderId: orderId, token: this.utils.getToken() }).then(res => {
      //     console.log(res)
      //     if (res.code === 0) {
      //     }
      // })
    }
  };
  </script>
  
  <style lang="less" scoped>
  .order-cancel {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .order-cancel_box  {
      .order-cancel_top {
        width: 100%;
        box-sizing: border-box;
        position: fixed;
        top: 0px;
        z-index: 999;
      }
      .order-cancel_main {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        margin-top: 120px;
        padding-bottom: 200px;
        .orderComplete_content {
            width: 70%;
            margin: 0 auto;
        }
      }
      .order-cancel_footer {
        width: 100%;
        box-sizing: border-box;
        position: fixed;
        bottom: 0px;
      }
    }
  }
  </style>
  