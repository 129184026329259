import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Links from '../views/Links.vue'
import MarketPlace from '../views/MarketPlace.vue'
import Login from '../components/user/Login.vue'
import ForgotPassword from '../components/user/ForgotPassword.vue'
import Register from '../components/user/Register.vue'
import MyCabinet from '../components/user/MyCabinet.vue'
import CabinetDetail from '../components/user/CabinetDetail.vue'
import EditionDetail from '../components/user/EditionDetail.vue'
import Profile from '../components/user/Profile.vue'
import Dashboard from '../components/user/Dashboard.vue'
import Collection from '../components/marketPlace/Collection.vue'
import Product from '../components/marketPlace/Product.vue'
import Surprise from '../components/marketPlace/Surprise.vue'
import CheckOut from '../components/checkOut/CheckOut.vue'
import Shipping from '../components/checkOut/Shipping.vue'
import ConfirmOrder from '../components/checkOut/ConfirmOrder.vue'
import OrderComplete from '../components/checkOut/OrderComplete.vue'
import OrderCapture from '../components/checkOut/OrderCapture.vue'
import OrderNotComplete from '../components/checkOut/OrderNotComplete.vue'
import OrderCancel from '../components/checkOut/OrderCancel.vue'
import Faq from '../components/copyWriting/Faq.vue'
import Privacy from '../components/copyWriting/Privacy.vue'
import Terms from '../components/copyWriting/Terms.vue'
import Warranty from '../components/copyWriting/warranty.vue'
import Support from '../components/copyWriting/Support.vue'
import Download from '../components/copyWriting/Download.vue'
import Manual from '../components/copyWriting/Manual.vue'
import NotFind from '../views/NotFind.vue'
import OrdersHistory from "../components/user/OrdersHistory.vue";
import OwnershipHistory from "../components/user/OwnershipHistory.vue";
import WithdrawHistory from "../components/user/WithdrawHistory.vue";
import DepositHistory from "../components/user/DepositHistory.vue";

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/ownership-transfer',
    name: 'Manual',
    component: Manual,
    meta: { needLogin: false }
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
    meta: { needLogin: false }
  },
  {
    path: '/app',
    name: 'Download',
    component: Download,
    meta: { needLogin: false }
  },
  {
    path:'/share/:id',
    name:'Download',
    component:Download,
    meta: { needLogin: false }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: { needLogin: false }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: { needLogin: false }
  },
  {
    path: '/warranty',
    name: 'Warranty',
    component: Warranty,
    meta: { needLogin: false }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { needLogin: false }
  },
  {
    path: '/links',
    name: 'Links',
    component: Links,
    meta: { needLogin: false }
  },
  // {
  //   path: '/nft',
  //   name: 'Links',
  //   component: Links,
  //   meta: { needLogin: false }
  // },
  {
    path: '/collection',
    name: 'Collection',
    component: Collection,
    meta: { needLogin: false }
  },
  {
    path: '/checkOut',
    name: 'CheckOut',
    component: CheckOut,
    meta: { needLogin: true }
  },
  {
    path: '/shipping',
    name: 'Shipping',
    component: Shipping,
    meta: { needLogin: true }
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
    meta: { needLogin: false }
  },
  {
    path: '/surprise',
    name: 'Surprise',
    component: Surprise,
    meta: { needLogin: false }
  },
  {
    path: '/marketPlace',
    name: 'MarketPlace',
    component: MarketPlace,
    meta: { needLogin: false }
  },
  {
    path: '/nft',
    name: 'MarketPlace',
    component: MarketPlace,
    meta: { needLogin: false }
  },
  {
    path: '/confirmOrder',
    name: 'ConfirmOrder',
    component: ConfirmOrder,
    meta: { needLogin: true }
  },
  {
    path: '/orderComplete',
    name: 'OrderComplete',
    component: OrderComplete,
    meta: { needLogin: true }
  },
  {
    path: '/orderCapture',
    name: 'OrderCapture',
    component: OrderCapture,
    meta: { needLogin: true }
  },
  {
    path: '/orderNotComplete',
    name: 'OrderNotComplete',
    component: OrderNotComplete,
    meta: {needLogin: true}
  },
  {
    path: '/orderCancel',
    name: 'OrderCancel',
    component: OrderCancel,
    meta: { needLogin: true }
  },
  {
    path: '/myCabinet',
    name: 'MyCabinet',
    component: MyCabinet,
    meta: { needLogin: true }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {needLogin: true}
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { needLogin: true },
    children:[{
        path: '/orders',
        name: 'OrdersHistory',
        meta: {needLogin: true,tab:0},
        component: OrdersHistory
      },
      {
        path: '/ownershipTransfer',
        name: 'OwnershipHistory',
        meta: {needLogin: true,tab:1},
        component: OwnershipHistory
      },{
        path: '/withdraw',
        name: 'WithdrawHistory',
        meta: {needLogin: true,tab:2},
        component: WithdrawHistory
      },{
        path: '/deposit',
        name: 'DepositHistory',
        meta: {needLogin: true,tab:3},
        component: DepositHistory
      }
    ]
  },
  {
    path: '/cabinetDetail',
    name: 'CabinetDetail',
    component: CabinetDetail,
    meta: { needLogin: true }
  },
  {
    path: '/editionDetail',
    name: 'EditionDetail',
    component: EditionDetail,
    meta: { needLogin: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { needLogin: false }
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: { needLogin: false }
  },
  {
    path: '/register',
    name: Register,
    component: Register,
    meta: { needLogin: false }
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
    meta: { needLogin: false }
  },
  {
    path: '/notFind',
    name: 'NotFind',
    component: NotFind,
    meta: { needLogin: false }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    // 解决路由跳转后 会滚动到底部
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
