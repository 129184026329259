<template>
  <div class="surprise">
    <div class="surprise_box">
      <div class="surprise_top bg-white">
        <AppNavigator />
      </div>
      <div class="surprise_main">
          <div style="font-size:21px;">{{$t("lang.surpsise.title")}}</div>
          <div style="font-size:14px;line-height:17px;margin-top:15px;" v-html="$t('lang.surpsise.remark')"></div>
          <div style="font-size:14px;line-height:17px;margin-top:15px;"><div style="width:160px;margin:auto;text-align:left;">{{$t("lang.surpsise.inputLabel")}}</div></div>
          <div>
            <input type="text" v-model="code" :placeholder="$t('lang.surpsise.inputHolder')" maxlength="6"/>
          </div>
          <div style="margin-top:20px;">
            <el-button :class="valid?'active':''" :showloading="showloading" @click="commitCode">{{$t("lang.surpsise.btnText")}}</el-button>
          </div>
      </div>
    </div>
    <div class="surprise_footer">
      <AppFooter />
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";

export default {
  name: "Surprise",
  components: {
    AppNavigator,
    AppFooter,
  },
  data() {
    return {
      code:'',
      valid:false,
      showloading: false,
      waresId:''
    };
  },
  watch: {
    code(val, oldVal) {
      if(this.code.length == 6) {
        sessionStorage.setItem('tempCode',this.code);
        this.valid = true;
      }else {
        this.valid = false;
      }
    }
  },
  methods: {
    commitCode() {
      this.$http
        .post("wares/detail/jdugeSurpriseCode",{
            token: this.utils.getToken(),
            waresId: this.waresId,
            surpriseCode: this.code,
          }
        )
        .then((res) => {
            if (res.code === 0) {
              sessionStorage.removeItem('tempCode');
              sessionStorage.setItem('surpriseCode',this.code);
              this.$router.back(-1);
            }else {
              const title = this.$t("lang.pay.note");
              this.utils.alert(title, res.msg, {
                autoClose: false,
              });
            }
        });
    }
  },
  created() {
    this.waresId = this.$route.query.waresId;
    this.code = sessionStorage.getItem('tempCode')||'';
  }
};
</script>

<style lang="less">
.surprise {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0.5;
  .surprise_box {
    width: 100%;
    .surprise_top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      // backdrop-filter: saturate(180%) blur(30px) opacity(80%);
      border-bottom: 1px solid #000000;
    }
    .surprise_main {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      margin-top: 40px;
      padding-top: 50px;
      color: #000000;
      text-align: center;
      input {
        width: 160px;
        padding: 0 0 3px 0;
        border:none;
        border-bottom:1.5px solid #000000;
      }
      input:focus-visible {
        outline: none !important;
      }
      input::placeholder {
        font-size: 14px;
        color: #BCBCBC;
      }
      button {
        width: 160px;
        height: 25px;
        line-height: 25px;
        background-color: #BCBCBC;
        color: #FFFFFF;
        border-radius: 13px;
        padding: 0 !important;
        cursor: not-allowed;
      }
      button.active {
        background-color: #000000;
        cursor: pointer;
      }
    }
  }
  .surprise_footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }

}
</style>