<template>
  <div class="privacy">
    <div class="box">
      <div class="top bg-white">
        <AppNavigator />
      </div>
      <div class="main">
        <div class="left_content">
          <div style="position: fixed; top: 8rem; left: 7%">
            <div class="left_title">{{ $t("lang.footer.legal") }}</div>
            <div class="left_title2">
              <el-button
                type="text"
                mini="mini"
                style="font-size: 16px; opacity: 1; padding: 0 !important"
                class="active"
              >
                {{ $t("lang.footer.privacyPolicy.title") }}
              </el-button>
            </div>
            <div class="left_title3" @click="gotoTerms">
              <el-button
                type="text"
                mini="mini"
                style="font-size: 16px; opacity: 1; padding: 0 !important"
              >
                {{ $t("lang.footer.termsAndConditions") }}
              </el-button>
            </div>
            <div class="left_title4" @click="gotoWarranty">
              <el-button
                type="text"
                mini="mini"
                style="font-size: 16px; opacity: 1; padding: 0 !important"
              >
                {{ $t("lang.footer.warranty.title") }}
              </el-button>
            </div>
          </div>
        </div>
        <div class="right_content">
            <PrivacyEn v-if="lang == 'en'"></PrivacyEn>
            <PrivacyCn v-if="lang == 'cn'"></PrivacyCn>
            <PrivacyTw v-if="lang == 'tw'"></PrivacyTw>
        </div>
      </div>
      <div class="footer">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
import PrivacyEn from "@/components/legal/privacyEn.vue";
import PrivacyCn from "@/components/legal/privacyCn.vue";
import PrivacyTw from "@/components/legal/privacyTw.vue";
export default {
  name: "Privacy",
  components: {
    AppNavigator,
    AppFooter,
    PrivacyEn,
    PrivacyCn,
    PrivacyTw
  },
  data() {
    return {
      lang:'en'
    };
  },
  methods: {
    gotoTerms() {
      this.$router.push("/terms");
    },
    gotoWarranty() {
      this.$router.push("/warranty");
    }
  },
  created() {
    const lang = localStorage.getItem("lang") || "en";
    this.lang = lang;
  },
};
</script>
<style lang="less" scoped>
.privacy {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: #000000;
  .box {
    height: 100%;
    .setColor {
      color: #bcbcbc;
    }
    .active {
      font-weight: bold !important;
      color: #000000 !important;
    }
    .top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      color: #000000;
      // backdrop-filter: saturate(180%) blur(30px) opacity(80%);
      border-bottom: 1px solid #000000;
    }
    .main {
      width: 86%;
      height: 100%;
      box-sizing: border-box;
      margin: 0 auto;
      margin-top: 8rem;
      margin-bottom: 280px;
      display: flex;
      justify-content: space-between;
      .left_content {
        flex: 1;
        width: 70%;
        margin: 0 auto;
        .el-button--text {
          color: #bcbcbc;
        }
        .left_title {
          font-family: Helvetica;
          color: #000000;
          font-size: 28px;
        }
        .left_title2 {
          font-family: Helvetica;
          color: #bcbcbc;
          margin-top: 40px;
          margin-bottom: 10px;
        }
        .left_title3 {
          font-family: Helvetica;
          color: #bcbcbc;
        }
        .left_title4 {
          font-family: Helvetica;
          color: #bcbcbc;
          margin-top: 10px;
        }
      }
      .right_content {
        flex: 2;
        height: 100%;
      }
    }
    .footer {
      width: 100%;
      box-sizing: border-box;
      //position: fixed;
      bottom: 0px;
    }
  }
}
</style>