<template>
  <div class="appNavigator">
    <!-- <div class="second-nav" v-show="isShowSecomdNav"><SecondNavigator></SecondNavigator></div> -->
    <div style="width: 86%;  margin: 0 auto">
      <div
        style="
          width: 100%;
          height:40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div @click="goToFirstWeb" style="flex: 1;height:40px;">
          <el-button
            type="text"
            class="logo_btn"></el-button>
        </div>
        <div
          style="
            flex: 1;
            height:40px;
            display: flex;
            align-items:center;
          ">
          <div style="flex:1;text-align:right;padding-right:40px;">
            <el-button type="text" class="mark_place" :style="{fontWeight:fontWeight+' !important'}" @click="goToMarket" v-if="$store.state.showMarketplace">{{ $t("lang.navigator.markPlace") }}</el-button>
            <el-button type="text" class="mark_place" style="color:#FF7A00 !important;margin-left: 40px;" @click="goToLogin" v-if="!userImg">{{ $t("lang.navigator.login") }}</el-button>
            <img :src="userImg" style="width:30px;height:30px;border-radius:100%;margin-left: 40px;cursor: pointer;vertical-align: middle;" v-if="userImg" @click="goToProfile()">
          </div>
          <div>
            <el-dropdown @command="handleCommand" trigger="click"  placement="bottom">
              <el-button type="text" class="el-dropdown-link mark_place" style="color:#bcbcbc;">
                {{langText}}
              </el-button>
              <el-dropdown-menu slot="dropdown" style="padding:10px 25px;color:#000000;border-radius:10px;font-size:14px;text-align:center;">
                <el-dropdown-item command="a" class="dropdown_item" style="font-family:NeueHaasUnicaW1G-Regular,NeueHaasUnicaW1G;">{{ english }}</el-dropdown-item>
                <el-dropdown-item command="c" class="dropdown_item" style="font-family:Sans;">{{ sChinese }}</el-dropdown-item>
                <el-dropdown-item command="b" class="dropdown_item"  style="font-family:Sans;">{{ chinese }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SecondNavigator from "@/components/common/SecondNavigator.vue";
export default {
  name: "appNavigator",
  components: {
    SecondNavigator
  },
  data() {
    return {
      fontWeight:'normal',
      userImg:'',
      langText:'EN',
      isShowSecomdNav:false,
      list: [
        { title: "ZHEN." },
        { title: this.$t("lang.navigator.technology") },
        { title: this.$t("lang.navigator.experience") },
        { title: this.$t("lang.navigator.editorial") },
        { title: this.$t("lang.navigator.clientServices") },
      ],
      english: "EN",
      chinese: "简",
      sChinese: "繁",
      msg: this.$store.state.msg,
    };
  },
  props:["parentData"],
  watch:{
      parentData(n,o){ //n为新值,o为旧值;
        this.userImg = n;
      }
  },
  methods: {
    //跳转到markPlace
    goToMarket() {
      this.$router.push("/marketPlace");
    },
    //跳转到登录页
    goToLogin() {
      this.$router.push("/login");
    },
    //跳转到个人中心
    goToProfile() {
      this.$router.push("/myCabinet");
      // let routeName = this.$route.name;
      // if(this.$route.name) {
        
      // }
      // this.isShowSecomdNav = !this.isShowSecomdNav;
    },
    //跳转到首页
    goToFirstWeb() {
      this.$router.push("/");
    },
  
    handleCommand(command) {
      let lang = "";
      if (command == "a") {
        lang = "en";
      } else if (command == "b") {
        lang = "cn";
      } else if (command == "c") {
        lang = "tw";
      } 
      if(this.utils.getToken()) {
        //上传给服务器
        this.$http.post('setting/changeLanguage', this.qs.stringify({ token: this.utils.getToken(),language:lang})).then(res => {
          this.$store.commit("setLang", lang);
          location.reload();
        })
      }else {
        this.$store.commit("setLang", lang);
        location.reload();
      }
    },
  },
  created() {
    if (localStorage.getItem("from")) {
      this.msg = localStorage.getItem("from");
      this.msg = this.msg.substring(0, 8) + "......";
    } else {
      this.msg = this.$store.state.msg;
    }
    //初始化国际化
    let lang = localStorage.getItem('lang');
    if (lang == "en") {
      this.langText = 'EN';
    } else if (lang == "cn") {
      this.langText = '简';
    } else if (lang == "tw") {
      this.langText = '繁';
    }
    //初始化用户头像
    let token = this.utils.getToken();
    let profilePhoto = this.utils.getProfilePhoto();
    if(token) {
      this.userImg = profilePhoto;
    }
    if(this.$router.currentRoute.name == "MarketPlace") {
        this.fontWeight = 'bold';
    }
  }
};
</script>
<style lang="less" scoped>
.el-popper {
  margin-top: 0px !important;
}
/deep/ .popper__arrow {
  display: none !important;
}
.appNavigator {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  backdrop-filter: saturate(180%) blur(30px);
}
.second-nav {
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: absolute;
  top: 41.5px;
  left: 0;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.dropdown_item {
  padding: 0 10px;
  color: #000000;
  border-bottom:1px solid #bcbcbc;
}
.dropdown_item:nth-child(3) {
  border-bottom: none;
}
.dropdown_item:hover {
  background-color: #fff !important;
  color: #FF7A00 !important;
}
.el-button,
.el-button--text {
  border: none !important;
  padding: 10px !important;
}
.logo_btn {
  width:80px;
  font-weight: bold !important;
  height: 40px !important;
  padding: 0 !important;
}
.mark_place {
  font-weight: 400 !important;
  font-size: 14px !important;
  padding: 0 !important;
}
#dropdown-menu-2709 {
  border: none !important;
}
.navBox {
  width: 70%;
  margin: 0 auto;
  color: #333;
  text-align: center;
  line-height: 40px;
  box-sizing: border-box;
  .box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    box-sizing: border-box;
    .leftBox {
      width: 70%;
      .leftBox_aside {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
    .rightBox {
      width: 20%;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
}
</style>
