import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Vuex from 'vuex'
import service from './axios/api'
import VueI18n from 'vue-i18n'
import './assets/fonts/font.css'
import './assets/iconfont/iconfont.css'

import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
import utils from './common/utils'
import VueCropper from 'vue-cropper'
import qs from 'qs'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueDirectiveImagePreviewer,{
  previewSize: 10,
  zIndex: 999
})
Vue.use(ElementUI);
Vue.use(Vuex);
Vue.use(VueCropper);
Vue.use(VueClipboard);
//通过插件的形式挂载，通过全局方法 Vue.use()
Vue.use(VueI18n);

let i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en',   // 语言标识 //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'cn': require('./lang/cn'),   // 简体中文语言包
    'en': require('./lang/en'),    // 英文语言包
    'tw': require('./lang/tw'),     //繁体中文语言包
  }
})

Vue.prototype.qs = qs;
Vue.http = Vue.prototype.$http = service;
Vue.prototype.utils = utils;
Vue.config.productionTip = false;




/*
* 路由守卫,路由拦截器
* to: Route: 即将要进入的目标路由对象 
* from: Route: 当前导航正要离开的路由 
* next: Function: 一定要调用该方法来resolve这个钩子，执行效果依赖 next 方法的调用参数。
*/
router.beforeEach((to, from, next) => {
  // console.log(to);
  // console.log(from);
  //防止多个loading出现
  utils.closeLoading();
  utils.openLoading();
  //判断是否有token
  if (utils.getToken()) { 
    //如果有token直接访问/login 
    if(to.path == '/login') {
      next({
        path: (from.fullPath || '/')
      });
    }
    //如果非/login 并且路由有定义
    else if(to.path != '/login' && to.name) {
      next();
    }
    //如果路由没有定义 
    else {
      next({
        path: '/notFind'
      });
    }
  }else {
    //判断是否需要登录
    if(to.meta.needLogin) {
      next({
        path: '/login'
      })
    }else if(to.name){
      next();
    }
    //如果路由没有定义 
    else {
      next({
        path: '/notFind'
      });
    }
  }
});

router.afterEach((to, from) => {
  //关闭遮罩
  utils.closeLoading();
})

let vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

export default vue;
