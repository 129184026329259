export const lang = {
    navigator:{
        technology: '技术',
        experience: '经验',
        editorial: '社区' ,
        clientServices: '客户服务',
        login: '登入',
        markPlace: '交易市场',
        language: '简'
    },
    profile: {
        login: '登入',
        register: '注册',
        password: '密码',
        forgotPassword: '忘记密码',
        loginOut: '登出',
        transfer:'产权转移',
        confirmDialog: {
            title:'产权转移确认',
            desc1:'您收到一份产权转移请求。若接受此臻品的产权转移，请点击下面的确认转移按钮。',
            desc2:'若此次转移未经过协商或您不清楚此次转移的情况，请点击取消按钮，并发邮件至support@zhen.io与我们联系。',
            confirm:'确认转移'
        },
        required:'必填项'
    },
    login: {
        title: '登入',
        email: '邮箱',
        yourEmail:'请输入你的邮箱',
        editPassword: '编辑密码',
        currentPassword: '你的密码',
        yourPassword: '你的密码',
        newPassword: '新的密码',
        confirm: '确认',
        passwordInfo: '请输入至少8个字符',
        cancel: '取消',
        loginInfo: '您的密码已重置,请登录.',
        forgetPassword: '忘记密码',
        phone: '手机号码',
        yourPhone:'请输入你的手机号码',
        password: '密码',
        yourPassword:'请输入你的密码',
        remember: '记住密码',
        errorInfo: '国家/地区代码无效',
        content: '在使用我们的服务过程中，您需要同意至臻的<span style="color:#FF7A00;">条件条款</sapn>和<span style="color:#FF7A00;">隐私协议</span>.',
        content2: '必填项.',
        content3: '还没有账号？',
        content4:'点击此处进行注册.',
        updatePWTitle:'更新密码',
        updatePWContent:'为了保证您的账户安全，请重置系统默认发送给您的密码。',
        updatePWBtn:'重置密码'
    },
    register:{
        title: '注册',
        title2: '注册',
        email: '邮箱',
        yourEmail:'请输入你的邮箱',
        phone: '手机号码',
        yourPhone:'请输入你的手机号码',
        emailVer: '邮箱验证',
        fullName:'姓名',
        yourEmailVer:'请输入验证码',
        sMSVerification: '短信验证码',
        yourSmsVer: '请输入短信验证码',
        setPassword: '设置密码',
        youSetPassword: '请输入你的密码',
        sendCode: '发送验证码',
        verCodeInfoSend: '验证码已发送',
        verCodeInfo: '您的验证码已发送到提供的电子邮件地址。如果您在 5 分钟内没有收到您的代码，请检查您的垃圾邮件文件夹或再次请求.',
        verCodeInfo2: '您的验证码已发送至提供的电话号码。如果您在 5 分钟内没有收到您的代码，请申请一个新代码.',
        note: '注意',
        registerTitle: '注册成功',
        registerInfo: '请您登录以继续',
        errorInfo: '国家/地区代码无效',
        content: '在使用我们的服务过程中，您需要同意至臻的<span style="color:#FF7A00;">条件条款</span>和<span style="color:#FF7A00;">隐私协议</span>.',
        content2: '已经有账号了?',
        content3: '点击此处进行登录 '
    },
    cabinet: {
        title: '我的橱窗',
        title2: '我的橱窗',
        all: '全部',
        edition: '臻品',
        editions:'臻品',
        nftPremium: 'NFT优质版',
        nft: 'NFT',
        noText:'您的橱窗暂时还没有任何收藏品喔！<br>可以通过浏览交易市场或者下载ZHEN. app添加您的臻品',
        dialogTitle:'您的橱窗暂时还没有商品喔！',
        dialogContent:'可以前往ZHEN. app或者在交易市场中发现属于您的臻品。',
        dialogBtn1:'下载',
        dialogBtn2:'交易市场',
        detail: {
            description: '描述',
            conentText: '什么是',
            conentText2: '购买时间',
            conentText3: '转让所有权',
            artist: '艺术家',
            currentOWner:'当前产权方',
            productInfo:'产品信息',
            publishedBy:'出版商:',
            producedBy:'制造商:',
            material:'材料',
            color:'颜色',
            editionOf:'版数',
            measurements:'尺寸',
            weight:'重量',
            manufacturingYear:'制作年份',
            editionInfo:'版本信息',
            editionNo:'版号',
            serialNo:'序列号',
            ownershipInfo:'产权信息',
            currentOwner:'所有者:',
            accountNumber:'账号',
            purchaseDate:'购买日期',
            ownershipTransfer:'产权转移',
            txid:'交易哈希',
        },
        dialog: {
            title:'产权转移',
            desc:'为了维护所有权的安全性，ZHEN. 需要确保当前进行操作的是您本人。请提供以下信息以继续产权转移操作。',
            userId:'请输转入用户的ZHEN. ID',
            password:'密码',
            code:'验证码',
            codeHolder:'请输入验证码',
            success:'操作成功！',
            tip:'恭喜！已成功转移您的#username #workname所有权。',
            backCabinet:'返回橱窗'
        }
    },
    myMarkPlace: {
        shopNow: '立即购买',
        dropsCalendar: '投放預告',
        notifyMe: '通知我',
        artist: '艺术家',
        description: '编辑寄语',
        conentText: ' 什么是优质版NFT?',
        productDetail:'商品信息',
        productDetail2:'商品信息',
        history:'交易记录',
        view:'查看',
        content: '要完成您的订单，您需要授予 ZHEN。访问您的 MetaMask 钱包。请选择“连接钱包”以继续.',
        digitalWallet: '电子钱包',
        connectwallet: '连接钱包',
        day: '天',
        cancel: '取消',
        title: '输入',
        installText:'安装',
        installText1:'安装',
        dialogTitle1:'连接钱包',
        label1:'用户协议',
        label2:'隐私条款。',
        label3:'和',
        conentText2: '感谢您参与抽奖。您将很快收到结果通知，因此请务必在ZHEN应用程序中打开通知。应用程序。祝你好运！',
        contentText3:'在使用我们的服务过程中，您需要同意至臻的',
        contentText4:'安装 MetaMask 插件，再将其与ZEHN. 进行链接，之后继续进行支付。',
        checkOut:'结算',
        alertText1:"感谢订阅，我们会按时通知您。",
        alertText2:"您还没有填写邮箱信息，请前往个人资料页面完善。",
        alertTitle:'您的ETH币不足以完成这次支付',
        alertText3:'请确保您的钱包里有足够的主网络ETH以继续支付。',
        alertBtn:'查看帮助文档',
        comingsoon:'即将开放',
        raffleRule:'抽签详情',
        event:'活动'
    },
    myprofile: {
        title: '个人资料',
        title2: '个人资料',
        detail: '个人资料',
        userId: '用户ID',
        yourWallet: '你的钱包',
        connect: '未连接.',
        connectWallet: '连接钱包',
        disconnect: '断开',
        editImg: '编辑头像',
        editName: '编辑姓名',
        confirm: '确认',
        cancel: '取消',
        fullName: '姓名',
        youNewName: '请输入你的姓名',
        editPhone: '编辑手机号码',
        phone: '手机号码',
        newPhone: '手机号码',
        youNewPhone: '请输入你的手机号码',
        email: '邮箱',
        newEmail: '新的邮箱',
        youEmail: '请输入你的邮箱',
        editPassword: '编辑密码',
        cureentPassword: '你的密码',
        password: '密码',
        youPassword: '请输入你的密码',
        newPassword: '请输入新的密码',
        enterPassword: '请输入你的密码',
        edit: '编辑',    
        sMSVerification: '短信验证码',
        smsVerCode: '请输入验证码',
        sendCode: '发送验证码',
        accountPassword: '账户密码',
        editEmail: '编辑邮箱',
        emailVerification: '邮箱验证码',
        errorInfo: '国家/地区代码无效',
        verificationCodeSent: '验证码已发送',
        verCodeInfo: '您的验证码已发送到提供的电子邮件地址。如果您在 5 分钟内没有收到您的代码，请检查您的垃圾邮件文件夹或再次请求.',
        verCodeInfo2: '您的验证码已发送至提供的电话号码。如果您在 5 分钟内没有收到您的代码，请申请一个新代码.',
        note: '注意',
        uploadInfo: '上传的文件不是有效的图像。仅允许使用JPG、JPEG和PNG文件.',
        orderHistory: {
            title: '订单历史',
            viewOrder: '查看订单',
            order: '订单',
            productOverView: '商品信息',
            deliveryFee: '运费',
            total: '总计',
            deliveryaddr: '收件地址',
            parcels: '包裹',
            contactUs: '联系我们',
            nodata:"承运商未提供货运信息，请稍后访问。"
        },
        noWithdrawHistory:'您没有提取历史。',
        withdrawHistory:'提取历史',
        noTransfer:'暂无产权转移记录。',
        noOrder:'暂无订单记录。',
        twofa: {
            title:'开启两步身份验证',
            authenticator:'谷歌身份验证器',
            bind:'去绑定',
            unbind:'解除绑定',
            downDialogTitle:'安全提醒',
            downDialogContent:'ZHEN. 建议您通过启用双重认证来保护您的帐户。使用谷歌身份验证器以获得额外的保护。',
            downDialogBtn1:'启用双重认证',
            downDialogBtn2:'暂不启用',
            bindTitle:'使用谷歌身份验证器并启用双重认证',
            bindRemark:'您将需要安装谷歌身份验证器继续操作。在您的移动设备上安装谷歌身份验证器，以完成下一步。',
            bindRemark1:'在您的移动设备上打开谷歌身份验证器应用程序。点击 "+"按钮并扫描下面的二维码，或输入下面提供的一串文字，然后弹窗内的继续按钮。',
            bindRemark2:'请不要与任何人分享这些认证细节。如果对您的账户安全有疑问或担心，请联系我们：support@zhen.io。',
            continue:'下一步',
            enterCode:'输入六位谷歌身份验证器提供的数字验证码。',
            confirm:'确认',
            copySuccessTitle:'密钥已复制',
            copySuccess: '您的密钥已经复制到您的剪贴板上。',
            unbindTitle:'禁用？',
            unbindContent:'禁用谷歌验证器将会移除您账户的额外安全保障。',
            unbindAlertBtn:'确认禁用',
            validText:'两步身份验证',
            startValid:'开始身份验证',
            authenticated:'已验证',
            errorMsg:'错误的验证码'
        }
    },
    forgetPassword: {
        title: '忘记密码',
        title2: '重置密码',
        email: '邮箱',
        yourEmail:'请输入你的邮箱',
        phone: '手机号码',
        yourPhone:'请输入你的手机号码',
        emailVer: '邮箱验证',
        smsVer: '短信验证码',
        yourEmailVer:'请输入邮箱验证码',
        yourPhoneVer:'请输入短信验证码',
        setPassword: '设置密码',
        youSetPassword: '请输入你的密码',
        remember: '记住密码',
        sendCode: '发送验证码',
        content: '必填项.'
    },
    home: {
        reimagine:'重新构想所有权',
        systemRemark:'全世界最具安全性的艺术品所有权鉴别系统，一切尽在你的掌控之中。',
        shopNow:'立即购买',
        technologyText:'我们使用的技术',
        technologyRemark:'通过由区块链赋能的NFT鉴别艺术品。',
        nftText:'的开发基于以太坊（ERC-721）具有以下特点',
        nftFeature:'去中心化<br>不可篡改<br>高度安全<br>公开透明',
        nftAuthenticity:'所有权及真实性受区块链保护',
        tapText:'一键式鉴别',
        tapFeature:'直观且毫不费力<br>鉴别真的很简单 <br>一切尽在你的掌控之中',
        experienceText:'体验',
        ownershipText:'全新的所有权<br>体验等待着您',
        ownershipRemark:'确定所有权最快捷的方法<br>无论在哪，您都可以查验产品真实性和出处。',
        artText:'你所有的艺术藏品',
        artText1:'全部都收纳在自己的口袋里。',
        artRemark:'一种前所未有的方式来展示你的实体和数字收藏。<br>收藏不止于此。',
        appText:'你所需要的一切都在一款APP中',
        authMethod:'扫描您的ZHEN.-不费吹灰之力就能登记产权和确定真伪。',
        productText:'产品出处及历史记录',
        productOwnership:'获得关于您的藏品编号来自何处的详细信息，并通过认证您的所有权，为藏品留下您的专属记号。',
        collectorsText:'收藏者橱窗',
        collectorsRemark:'同时欣赏你所感兴趣的的实物作品和数码作品。',
        contentText:'独家内容',
        contentRemark:'通过我们的原创内容带你畅游艺术科技的世界。',
        shopText:'商城与发现',
        shopRemark:'探索由至臻精心策划的投放及抽签活动，还可以逛一逛我们的臻选区。',
        nftDrops:'NFT投放，前所未见的形式',
        nftDropsRemark:'通过建立数字与实体之间的纽带，我们已经重新构想了NFT。',
        best:'这就是我们最擅长的事情。',
        artistTitle:'我们合作的艺术家',
        artistRemark:'很荣幸获得了世界各地有影响力的一些艺术家的授权。',
        miss:'不要错过',
        joinUs:'快来订阅我们的最新资讯吧。',
        holder:'请输入邮箱',
        holder2:'邮箱不能为空',
        holder3:'请输入正确的邮箱格式',
        holder4: '感谢订阅.',
        note:'提示',
        confirm: '确认',
        cancel: '取消',
        holder5: '你的邮箱',
        runningText1:'空山基的首个NFT作品已被投放',
        runningText2:'快来加入抽签吧',
        runningText3:'点击这里立即参与',
    },
    manual: {
        youGuideTo: '使用',
        ownerShipTransfer: '产权转移功能指南',
        ask: '要将你的臻品转让给别人?',
        que: '只需简单的几个步骤, 你就可以安全地将臻品的数字产权转移给新的<br>所有者.',
        initiation: '启用功能',
        content: '在发起产权转移前,你需要重新识别产品进入产权转移流程。在此过程<br>中请确保当前为已登录的状态.',
        contentList: [
            {
                list: '1 &nbsp;&nbsp;&nbsp;&nbsp  在至臻APP内再次识别您的产品.'
            },
            {
                list: '2 &nbsp;&nbsp;&nbsp;&nbsp  在鉴别页面会显示“产权转移”按钮.'
            },
            {
                list: '3 &nbsp;&nbsp;&nbsp;&nbsp 点击按钮继续完成产权转移流程.'
            }
        ],
        information: 
        {
            title: '提示信息',
            content: '买方 (未来的所有者) 将会收到一封产权转移请求邮件，将自己的臻品转移至对方名下.'
        },
        informations:{
            list: [
                {
                    ask: '1 &nbsp;&nbsp;&nbsp;&nbsp 未来所有者ZHEN. ID',
                    que: '在第一行添加关联的未来所有者 ZHEN. ID 。注意：为了保证产权转移流程顺利进行,未来所有者也必须是至臻的用户.'
                },
                {
                    ask: '2 &nbsp;&nbsp;&nbsp;&nbsp 你的ZHEN.密码',
                    que: "输入密码连接你的 ZHEN. 账号 (当前所有者的账号)。每个账号唯一的 ZHEN. ID可以再个人资料页面中找到." 
                },
                {
                    ask: '3 &nbsp;&nbsp;&nbsp;&nbsp 请求验证码',
                    que: '点击“发送验证码” 通过邮件或短信收取唯一的验证码以验证您的身份(验证码将发送至与账号相关联的邮箱或手机号中.)'
                },
                {
                    ask: '4 &nbsp;&nbsp;&nbsp;&nbsp 输入验证码',
                    que: '收到验证码后请输入至验证码填写区域.'
                },
                {
                    ask: '5 &nbsp;&nbsp;&nbsp;&nbsp 成功发起请求',
                    que: "信息填写完成后,“产权转移”按钮会变为可点击的状态,你可以点击此按钮开始进行产权转移。成功发起申请后,会出现弹框提示确认此次申请操作."    
                },
                {
                    ask: '6 &nbsp;&nbsp;&nbsp;&nbsp 收到产权转移请求',
                    que: " 你将会收到一封邮件，内容为ZHEN已收到你的产权转移请求。此时您的产权转移请求正在处理中." 
                }
            ]
        },
        transfer: {
            title: '转移',
            content: '买方(未来所有者) 将会收到一封邮件,内容为有人向您发起了臻品的产权转移请求.',
        },
        transfers: {
            list: [
                {
                    ask: '1. &nbsp;&nbsp;&nbsp;&nbsp  确认邮件',
                    que: '当前所有者成功发起转移申请后, 未来所有者将会收到一封邮件通知，并且需要立即登录同意该申请.'
                },
                {
                    ask: '2.  &nbsp;&nbsp;&nbsp;&nbsp  转移完成',
                    que: '接受后, 产权转移流程已全部完成。每条转移请求都会经过至臻的验证，并且通过区块链确保为有效交易，此过程可能需要几个工作日才可以完成.'
                },
            ]
        },
        help: "需要帮助?我们就在这里."
    },
    download: {
        title: '立即下载',
        content: '<span style="font-size:50px;font-weight:400;">现在下载ZHEN. ，<br>目前已支持全球所有的应用商店。</span>',
        content2: '<span style="font-size:21px;">让你感受到NFT的力量, 使得艺术品鉴别如此简单 — 一切都在你的掌控中。</span>',
        ios: 'ios',
        android: '安卓',
        contentList: {
            content: '迅速获取你的最新所有权体验权限。通过至臻，你可以体验:',
            list:[
                {
                    ask: '艺术品鉴别',
                    que: '臻品产权登记.'
                },
                {
                    ask: '发现',
                    que: '遇见最好的至臻--探索臻品.'
                },
                {
                    ask: '收藏',
                    que: '在一处展示你的实物及数码藏品，前所未有的体验.'
                },
                {
                    ask: '收获',
                    que: '了解你最喜欢的艺术家, 还可以获取有关区块链以及艺术相关的独家资讯.'
                },
        ]
        }
    },
    footer:{
        legal: '法律条款',
        // carreers: '职业者',
        // company: '公司',        
        // data: '数据',
        warranty: {
            title: "质保条款"
        },
        support:{
            title: '你所需要的支持,<br>全部都在这里。',
            menus: '支持',
            menuContext: [
                {
                    title: '所有权+鉴别<br>' ,
                    content: '新入手的藏品可以在这里获取所有信息',
                    footerContent: '查看手册'
                },
                {
                    title: '常见问题<br>' ,
                    content: "至臻一些紧急问题的答案",
                    footerContent: '查看更多'
                },
                {
                    title: '联系我们<br>' ,
                    content: "还需要其他帮助？可以线下咨询我们的支持团队",
                    footerContent: '取得联系'
                }
                ]
        }, 
        faq: {
            title: '常见问题',
            menus: ['General', 'AUTHENTICATION', 'Logistics', 'Data and Privacy', 'Bugs/Troubleshooting', 'NFT Withdrawal and Deposit NFT', 'HOW TO PURCHASE NFT', 'HOW TO TRANSFER NFT OWNERSHIP TO A ZHEN. USER', 'HOW TO WITHDRAWAL ZHEN. NFT TO WALLET', 'Augmented Reality (AR)'],
            menuContext: [
                {
                    title:"常见问题",
                    faq:[
                        {
                            ask:"ZHEN. 是什么?",
                            que:"ZHEN. 是一家利用区块链的透明度和安全性进行艺术认证的科技公司。使用 ZHEN.手机应用程序 (iOS 和 Android) 和区块链技术 (Ethereum 和 Polygon)，收藏家可以通过 NFT 认证和体验艺术品。<br /><br />"
                        },
                        {
                            ask:"ZHEN. 是怎样运作?",
                            que:"我们正在建立一个艺术品认证的生态系统。通过在艺术品中植入 NFC芯片和由区块链驱动的拥有权验证系统，我们能够保证每件经 ZHEN. 认证的艺术品是正品，并提供数字拥有权的出处。<br /><br />"
                        },
                        {
                            ask:"你现在想与任何人合作吗？",
                            que:"当然！我们一直在寻找新的艺术家和单位与我们合作。请将您的想法发送至<a class=\"email2\" href=\"mailto:partnerships@zhen.io\">partnerships@zhen.io</a> 与我们联系。<br /><br />"
                        },
                        {
                            ask:"ZHEN. 是什么意思?",
                            que:"ZHEN.取自中文拼音，融合真品、珍藏、至臻之意 。单音节的ZHEN.，体现了我们全部的价值追求。<br>同时，ZHEN. 的发音近似英文“generation (世代)”及“genesis(创世纪)”的开头“gen”，对我们来说，ZHEN. 也意味着重新构想、新世代收藏家和艺术的开端。<br /><br />"
                        }
                    ]
                },
                {
                    title:"区块链认证",
                    faq:[
                        {
                            ask:"当你们说“区块链认证”时，是什么意思？你们如何鉴定艺术品?",
                            que:"艺术品中嵌入了安全芯片成功地把认证过程提升到了一个新水平。但是，我们不止步于此；亦认为艺术认证过程不应仅限于实物形式，而数字拥有权与实物拥有权一样重要。<br><br>" +
                                "ZHEN., 数字拥有权采用区块链上非同质化代币（“NFT”）的形式，这是一个用于记录交易的开放、不可变的分类帐。每个 NFT 对应一个唯一的项目，并存储有关数字拥有权、ID 序列号、项目描述和其他产品信息的编辑信息。 (有关完整列表，请参阅此常见问题解答中的“数据和隐私”部分。)<br><br>" +
                                "当您扫描我们认证的收藏品时，ZHEN. 将为您检查并使用区块链上的数据进行认证。这个过程非常简单，安全和准确。我们相信将彻底改变艺术品的鉴定方式。<br><br>"
                        },
                        {
                            ask: "如何认证和注册产品的拥有权?",
                            que: "在大多情况下，请登录ZHEN. APP并按下认证图标[插入图标]进行艺术品认证，按照屏幕上的说明进行操作。每个由ZHEN.认证的艺术品都配有插图手册（用户说明手册或卡片），通过手册标注的ZHEN. LOGO清楚表明 NFC芯片特定位置，并附上验证码。请参阅说明手册以获得更多帮助。<br><br>" +
                                "如果您在二手市场上购买的艺术藏品没有附带用户手册，或需要任何其他帮助，请发送电子邮件至 <a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a> 并提供您的产品信息和 ZHEN. ID。<br><br>"
                        },
                        {
                            ask: "如何存储和转让拥有权?",
                            que: "每个收藏品都嵌入了一个 ZHEN. NFC 芯片，与唯一的 Ethereum 或 Polygon NFT对应，可以在 Etherscan 或 Polygonscan 上使用关联的 TXID 进行搜索。每一个NFT 中都存储产品信息和芯片信息。<br><br>" +
                                "为了进一步保障完整的收藏体验，可启用ZHEN.APP内的拥有权转移。卖家通過扫描嵌入的 NFC 芯片并点击APP按钮来启动程序，将数字拥有权转移给买方。转移请求将发到我们的后端进行确认。<br><br>" + 
                                "一旦我们的团队确认为有效转移，将通过智能合约把拥有权转让在区块链上注册，并生成唯一的TXID来确认。<br><br>"
                        },
                        {
                            ask: "我无法扫描我的产品/找不到芯片。你们能帮忙吗?",
                            que: "ZHEN. 认证的艺术品包装内附有用户手册。手册的动画或插图将向您展示芯片的位置。在扫描完成之前，请务必将手机保持在原位5秒。<br><br>" +
                                "请检查手机上的 NFC 功能是否已启用，并将手机尽可能靠近产品。<br><br>" + 
                                "如您仍然无法扫描产品，请联系我们 — <a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a><br><br>"
                        }
                    ]
                },
                {
                    title:"物流运输",
                    faq:[
                        {
                            ask:"你们在全球范围内发货吗?",
                            que:"每种产品的供应可能因地区而异，具体取决于版本和发行版。如需咨询特定产品，请通过 <a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a> 联系我们。<br><br>"
                        },
                        {
                            ask:"你们从哪里发货?",
                            que:"这取决于每笔订单，因产品而异。请通过<a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a>联系我们。感谢您的耐心等待！<br><br>"
                        },
                        {
                            ask:"运费是多少?",
                            que:"运费是根据产品的最终目的地以及每个地区计算。因此，每个产品的运费可能有所不同。如需咨询特定产品，请通过 <a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a> 联系我们。<br><br>"
                        }
                    ]
                },
                {
                    title:"数据和隐私",
                    faq:[
                        {
                            ask:"ZHEN. 将如何存储我的数据?",
                            que:"您的隐私和安全是 ZHEN. 的优先事项。您的数据使用 MD5 消息摘要算法加密并存储在安全服务器中。我们致力于保护您的数据，并不断更新我们的内部实践，以跟上最新的保障和发展。<br><br>"
                        },
                        {
                            ask:"你们的服务器在哪里?",
                            que:"我们的数据存储在位于新加坡的亚马逊云服务器中。<br><br>"
                        },
                        {
                            ask:"在区块链上可以查看哪些信息?",
                            que:"目前在区块链上可以看到以下信息：<br>" +
                                " -	产品信息：产品名称、艺术家姓名、出版商、制造商、版本号、序列号<br>" +
                                " -	材料, 颜色, 尺寸, 重量, 制造年份<br>" +
                                " -	芯片信息：令牌版本号、芯片唯一标识符（=产品序列号）、图像 URL<br>" +
                                "-	用戶身份<br>" +
                                " <br><br>"+
                                "用户 ID 将是任何买家及卖家的身份识别，并能确认卖家是否拥有该艺术品当前的拥有权。因此我们鼓励买家通过 ZHEN.转移拥有权，这样我们可以验证卖家的身份，确保您购买的是正品。" +
                                "<br><br>" +
                                "请放心这些信息不涉及个人信息，您的隐私受到了保护。<br><br>"
                        },
                        {
                            ask:"为什么ZHEN. 需要这些个人信息?",
                            que:"我们从传统拍卖行中汲取灵感，行业标准是能够指出任何艺术品的出处。这种在ZHEN.上记录一切的规范化，使市场中的所有利益相关者受益，包括艺术家、画廊、收藏家和拍卖行。" +
                                "<br><br>" +
                                "话虽如此，数据隐私始终是 ZHEN. 的首要任务。请放心，所有者和收藏者的身份将在公共领域和区块链上保持匿名，我们以维护高水平的安全和隐私标准积极工作。<br><br>"
                        },
                    ]
                },
                {
                    title:"错误/疑难排解",
                    faq:[
                        {
                            ask:"APP无法在我的手机上运行，你们能帮忙吗?",
                            que:"我们已尽最大努力确保用户能够享受到完整的 ZHEN. APP体验，但导致ZHEN. APP无法在您手机上正常运行的两个常见原因是操作系统和手机硬件问题。您可向我们发送电子邮件，以提供更多信息，包括当前的操作系统和手机型号 — <a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a><br><br>"
                        },
                        {
                            ask:"我收到了损坏的艺术品。你们能帮忙吗?",
                            que:"我们很抱歉您收到损坏的艺术品。我们一直与供应商和快递公司配合，以确保我们所有的产品在包装和送达之前都处于良好状态。不幸的是，一旦包裹离开我们的配送中心，我们就不能控制产品的状况。我们将会处理在生产过程中产生损坏的产品。请提供损坏产品的图片和信息，联系  <a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a> 并在此处熟读我们的<a class=\"email2\" href=\"/warranty\">保养条文</a>。<br><br>"
                        }
                    ]
                },
                {
                    title:"NFT 提现和存入",
                    faq:[
                        {
                            ask:"提现完成后，我在哪里可以看到我的NFT?",
                            que:"当您的提现申请成功后，您的 NFT 将在去中心化平台（如 OpenSea）上通过您配置的加密钱包登录可见。<br><br>"
                        },
                        {
                            ask:"要存入，NFT 必须在钱包中。如何将 NFT 添加到我的加密钱包?",
                            que: "当 NFT 在您的钱包中时，您必须采取额外的步骤才能在您的钱包中查看。你可以在去中心化平台（如 OpenSea）的详细信息中找到 NFT 的代币 ID 和合约地址。以太坊有一个简单的教程描述<a href='https://ethereum.org/de/developers/tutorials/how-to-view-nft-in-metamask/' target='_blank'>在此</a>。<br><br>"
                        },
                        {
                            ask: "我在哪里可以找到我的 NFT 的合约地址?",
                            que: "如果你的 NFT 存储在你的 ZHEN. 上。您可以在 Dashboard 的信息页面中 METADATA 部分看到您的 NFT 合约地址。<br><br>"+
                                "对于从二级市场存入 ZHEN. 的 NFT，您可以在<a href='/deposit'>存入历史记录中</a>，查看到合约地址。<br><br>"+
                                "对于从 ZHEN. 平台中提现的 NFT，您可以在 ZHEN. 平台<a href='/withdraw'>提现历史记录中</a>，查看到合约地址。<br><br>"+
                                "如果你的 NFT 目前不在 ZHEN. 上，你可以在正在使用的去中心化平台的详情页面看到它的合约地址(如 OpenSea)。<br><br>"
                        },
                        {
                            ask: "我在哪里可以找到我的 NFT 的合约地址?",
                            que: "如果你的 NFT 存储在你的 ZHEN. 上。您可以在 Dashboard 的信息页面中 METADATA 部分看到您的 NFT 合约地址。<br><br>"+
                                "对于从二级市场存入 ZHEN. 的 NFT，您可以在<a href='/deposit'>存入历史记录中</a>，查看到合约地址。<br><br>"+
                                "对于从 ZHEN. 平台中提现的 NFT，您可以在 ZHEN. 平台<a href='/withdraw'>提现历史记录中</a>，查看到合约地址。<br><br>"+
                                "如果你的 NFT 目前不在 ZHEN. 上，你可以在正在使用的去中心化平台的详情页面看到它的合约地址(如 OpenSea)。<br><br>"
                        },
                        {
                            ask: "我可以在 ZHEN. 橱窗上看到我拥有的非 ZHEN. NFT吗?",
                            que: "ZHEN. 仅支持查看 ZHEN. 创建的 NFT，其他的NFT 在存入时可能无法正常运行。<br><br>"
                        },
                        {
                            ask: "如我的 NFT 在提现后发生任何事情，ZHEN. 会否负责？",
                            que: "一旦您选择从ZHEN. 中提现 NFT，我们将不对您的 NFT 负责。如果您的 NFT 在我们监管范围之外发生任何事情，我们也不承担任何责任。千万不要与任何人分享您的加密钱包密码或助记词。<br><br>"
                        }
                    ]
                },
                {
                    title: "如何购买 NFT",
                    faq: [
                        {
                            ask: "如何从 ZHEN. 注册 NFT?",
                            que: "ZHEN. 将通过其市场平台发布 NFT。您将需要一个有效的 ZHEN. 帐户，通过手机号码或电子邮件地址进行注册。登录到您的 ZHEN. 帐户后，您将能够参与 NFT 发售活动。<br><br>"
                        },
                        {
                            ask: "如何从 ZHEN. 购买 NFT?",
                            que: "中签者被抽签结果选中后将会收到短信或电子邮件通知，并在其指定的时间内购买 NFT。您可以通过在 ZHEN. 市场平台上使用法币通过 Stripe 或支付宝完成交易来购买您的 NFT。<br><br>"
                        },
                        {
                            ask: "我需要一个加密钱包来购买 NFT 吗?",
                            que: "否，目前不需要加密钱包从 ZHEN. 上购买任何东西。<br><br>"
                        },
                        {
                            ask: "我购买了 NFT，请问在哪里可以查看?",
                            que: "一旦您的付款完成并确认，您的 NFT 将显示在您的 ZHEN. 橱窗上。通过ZHEN. APP或登录 ZHEN. 网站在“我的橱窗”查看。<br><br>"
                        },
                        {
                            ask: "为什么我的 NFT 没有出现在我的 ZHEN. 橱窗上?",
                            que: "如您的交易出现在订单历史记录中，请放心，您的 NFT 交易已经完成并将显示在您的橱窗上。根据不同情况，可能需要几分钟或几个小时显示。如 48 小时后仍未显示，请联系<a class=\"email2\" href='mailto:support@zhen.io'>support@zhen.io</a><br><br>"
                        }
                    ]  
                },
                {
                    title: "如何将 NFT 拥有权转让给 ZHEN. 用户",
                    faq: [
                        {
                            ask: "我可以将我的 NFT 卖给另一位 ZHEN. 用户吗?",
                            que: "目前这是不可能的。但是，我们的 NFT 与 OpenSea 兼容，因此您可以使用他们的平台进行二手市场交易。我们亦希望尽快实现该功能。<br><br>"
                        },
                        {
                            ask: "我如何将我的 NFT 转移到另一个 ZHEN. 用户?",
                            que: "目前这是不可能的。此功能即将在 ZHEN.推出。<br><br>"
                        }
                    ]  
                },
                {
                    title: "如何提现 ZHEN. NFT 到钱包",
                    faq: [
                        {
                            ask: "我的 ZHEN. 帐户可以连接哪些加密钱包?",
                            que: "在桌面浏览器上，ZHEN. 的网站支持 MetaMask。目前，用户只能在桌面浏览器上连接他们的钱包，但我们希望将该功能扩展到我们的手机APP。<br><br>"
                        },
                        {
                            ask: "ZHEN. 需要什么资料连接我的加密钱包?",
                            que: "您需要将您的加密钱包连接到您的 ZHEN. 首先在网站上登录您的 ZHEN. 帐户，这一步不能在ZHEN. APP上进行。登录后，请浏览“钱包”选项并点击“连接钱包”按钮，该按钮将提示连接您的钱包。钱包扩展程序必须在您的桌面浏览器上运行。 ZHEN. 永远不会要求您提供您的钱包密码或您的私钥助记词。<br><br>"
                        },
                        {
                            ask: "如何转移我的 ZHEN. NFT 到我的加密钱包？",
                            que: "转移你的 ZHEN. NFT 到你的钱包，你需要点击“提现”选项下的“提现”按钮，选择你要提现的 NFT，然后手动输入你的钱包地址。您将被要求输入您的 ZHEN. 帐户密码和一个安全代码，该代码将发送到您的电子邮件或手机短信，这取决于您注册帐户的方式。<br><br>"
                        },
                        {
                            ask: "如有与 ZHEN. NFT 相关的空投或未来实用功能, 该 NFT 是不是一定要在我的ZHEN. 橱窗上才可接收?",
                            que: "是的。如希望体验与您的 NFT 相关的任何额外功能，它必须保留在您的 ZHEN. 橱窗上。如将来发生任何更改，我们将对外宣布。<br><br>"
                        }
                    ]  
                },
                {
                    title: "增强现实",
                    faq: [
                        {
                            ask: "什么是AR?",
                            que: "增强现实简称AR，通常被称为是现实的改变版本，即数据或物体的3D图像覆盖在现实世界上。换句话说，AR是真实世界和数字世界的结合，用户可以与增强后的环境进行交互。<br><br>"
                        },
                        {
                            ask: "AR如何实现?",
                            que: "许多不同类型的AR滤镜或效果，但它们都涉及融合现实世界和虚拟世界以创造一种独特的体验。 ZHEN. 支持的一些AR效果是：<br><br>" + 
                            "- 标记：覆盖特定标记的效果，仅当该标记在视野中时触发。<br>" + 
                            "使用图像识别和映射技术，AR 动画可以在指定的图像标记上播放，或者在初始标记被识别后的其他地方播放。生成的效果可以是 2D 或 3D。<br>" + 
                            "- 非标记：这种效果没有什么特别锚定的，让您可以随时随地放置您的 AR 雕塑或艺术品与四周的环境互动。<br>您可以将 AR 对象放在咖啡桌上，或者在火车站候大楼附近走动时观看真人大小的霸王龙——可能性是无穷无尽。<br>" + 
                            "- 面部追踪：这是响应您的面部的交互式效果。通过面部识别和映射技术，面部跟踪器使用前置摄像头检测面部的位置和方向播放效果。<br><br>" + 
                            "使用我们的 AR 功能意味着我们的APP必须访问您的前后摄像头并扫描您的周围环境和/或面部。我们非常重视用户的隐私，在体验过程中接收或传输的任何视觉信息都不会用于非 AR 目的上。您可以在此处查看我们的<a class=\"email2\" href=\"/privacy\">隐私政策</a> 。"
                        },
                        {
                            ask: "增强现实 (AR) 与虚拟现实 (VR) 有何不同?",
                            que: "虚拟现实 (VR) 提供完全身临其境的体验，使用耳机等感官设备将现实世界拒之门外，而增强现实 (AR) 通过将数字组件覆盖在现实世界上，创造了现实世界和数字世界的完美融合。在 ZHEN. 我们目前的重点仍然是将艺术体验带入 AR，作为我们认证和拥有权体验的一部分。<br><br>"
                        },
                        {
                            ask: "如何体验AR?",
                            que: "AR可以在智能手机、平板电脑、智能眼镜上体验，也可以通过普通桌面电脑体验。通过 ZHEN. APP，用户可以体验基于图像识别、物体识别和地理位置的 AR。动作、音频、热成像和颜色识别等事物也可以触发 AR。<br><br>" + 
                            "当设备“了解”环境，AR 也可以在空间上定位。<br><br>"
                        },
                        {
                            ask: "如何激活我的 AR 体验？",
                            que: "一般来说，ZHEN. 可以按照以下步骤体验 AR： <br><br>" + 
                            "- 打开 ZHEN. APP<br>" + 
                            "- 如果AR体验与实体艺术品相关联，首先请通过扫描 NFC 芯片对艺术品进行认证，并确保在您的 ZHEN. 橱窗中可以看到该艺术品。<br>" + 
                            "- 当艺术品在你的 ZHEN. 橱窗上可见，点击其右上角的 AR 图标<br>" + 
                            "- 按照屏幕上的说明进行操作<br>" + 
                            "- AR 体验将被激活并相应地呈现<br><br>" + 
                            "依照您的互联网连接以及手机的品牌和型号，加载效果可能需要更长的时间。<br><br>"
                        },
                        {
                            ask: "我只能通过我的 ZHEN. APP 体验 AR 效果吗?",
                            que: "由 ZHEN. 认证的艺术品和/或 NFT 提供的 AR 体验只能通过 ZHEN. APP呈现。这是为了确保只有您，即合法所有者才能独家享受的体验。<br><br>"
                        },
                        {
                            ask: "我无法加载我的 ZHEN. 独家 AR 效果。可以帮忙吗?",
                            que: "建议连接稳定的 WiFi 网络来体验 ZHEN. 提供的AR效果。此外，在一个没有太多物体或杂物且光线充足的空间可以提供更好的 AR 体验。<br><br>"
                        },
                        {
                            ask: "为什么 ZHEN. 一直在创建AR体验?",
                            que: "AR 与我们正在践行的重新构想拥有权的使命一致。因为 ZHEN. ，用户不仅拥有实体或数字版的艺术品，还拥有连接 NFT 解锁的体验，通过技术提升拥有权的重要性。使用 AR 为收藏家创造独特和特别时刻的可能性是无限的。随着 AR 连接数字和现实领域，我们正在开辟一条新的道路，探索全新的拥有权概念，并为艺术家和收藏家提供更有意义的体验。<br><br>"
                        }
                    ]  
                }
            ],
        },
        contactUs: '加入我们',
        downloadApp: '立即下载',
        privacyPolicy: {
            title: '隐私条款'
        },
        termsAndConditions: '用户协议',
        tc: {
            title: '用户协议'
        },
    },
    pay: {
        checkOut: '结算',
        product: '1.商品',
        shipping: '2.收货信息',
        payMent: '3.确认与结算',
        payMent1: '2.确认与结算',
        productOverView: '商品概览',
        subTotal:'小计',
        content: '运费计入下一步骤中.',
        next: '下一步 >',
        payMethod: '支付方式',
        digitalWallet: '电子钱包',
        other: '在线支付',
        shipTo: '收货信息',
        deliveryFee: '运费',
        total: '总计',
        back: '返回',
        confirmPayMent: '确认付款',
        content5: '您的订单已完成！',
        content2: '确认邮件已发送至您的邮箱，请您确认!',
        content3: '继续浏览',
        firstName: '名字',
        lastName: '姓氏',
        address: '地址',
        phoneNumber: '手机号码',
        select1: '州/ 省/ 地区',
        city: '城市',
        email: '邮箱',
        select2: '邮编/邮政编码',
        country: '国家/地区',
        next2: '下一步',
        content4: '必填项目',
        phoneHolder:'',
        firstNameHolder:'',
        lastNameHolder:'',
        addressHolder:'',
        stateHolder:'',
        cityHolder:'',
        emailHolder:'',
        codeHolder:'',
        countryHolder:'',
        note: '提示',
        confirm: '确认',
        cancel: '取消',
        back2: '返回',
        content6: '哎呀！很抱歉，你所选择的商品已售罄。请继续浏览其他商品。',
        content7: '价格变动通知!<br />由于汇率变动，您的支付金额已发生变化并自动更新。请您继续完成支付。',
        content8: '您的订单已取消！',
        notComplete:'您的订单未完成'
    },
    other: {
        title: '你没有登录',
        content: '立即登录或注册以全面体验ZHEN.市场并接收更新.',
        login: '登录',
        register: '注册',
        continue: '继续浏览',
        cookiesText:'该网站期望获得cookies使用权限来提升您的用户体验。',
        readMore:'了解更多',
        acceptAll:'全部接受',
        noAreaText:'无匹配的国家或地区'
    },
    notFind: {
        title:'页面找不到了',
        content1:'糟糕，我们无法鉴别此页面是否存在。',
        content2:'但是，请放心，我们这里所有的商品都是经过鉴别的。'
    },
    wallet: {
        wallet: '钱包',
        error:  '错误',
        confirm : '确认',
        cancel: '取消',
        connectTitle:'链接钱包',
        connectRemark:'这是您的数字钱包，您将使用该钱包进行ETH数字货币支付。',
        walletLabel:'您认证的钱包地址:',
        connectWallet:'ZHEN.没有连接到您的钱包。请连接您的钱包以继续。',
        copyLabel:'复制',
        depositTitle:'商城钱包',
        depositWalletLabel:'商城的钱包地址:',
        depositRemark1:'只可将ZHEN.发行的NFT发送至此地址。',
        depositRemark2:'发送任何其他NFT可能会导致其永久损毁。非ZHEN.发行的NFT目前与ZHEN.平台不兼容，不会在您的ZHEN.橱窗或网站的其他任意部分正确显示或呈现。请谨慎操作。',
        scanLabel:'扫码',
        withdrawTitle:'提出艺术品',
        withdrawDialogTitle:'NFT 提取',
        withdrawRemark1:'您账户里的这些NFT可以被提取。',
        withdrawRemark2:'请注意，提取成功后，被提取的NFT将不会再出现在您的ZHEN.橱窗中。不过您随时可以将它重新转入，转入成功后，它将重新出现在您的ZHEN.橱窗中。',
        withdrawBtn:'提出艺术品',
        copySuccessTitle:'已复制您的钱包地址',
        copySuccessContent:'您的钱包地址已经复制到您的粘贴板上。',
        copySuccessTitle1:'已复制ZHEN.的存储地址',
        copySuccessContent1:'ZHEN.的储存地址已经复制到您的粘贴板上.',
        walletAddress:'您的#nftChain钱包地址',
        walletAddressError:'请输入正确的区块链钱包地址！',
        submit:'提交',
        close:'关闭',
        ok:'好的',
        successTitle:'请求成功',
        successMsg:'您的提取请求已经被受理，该提取流程将至多花费三个工作日处理完。',
        noWithdraw:'目前您没有与您ZHEN.账户相关联的NFTs。',
        penddingTitle:'正在审核您的请求',
        penddingContent:'该请求正在审核中，我们预计将在三个工作日内处理完毕，感谢您的配合。'
    },
    surpsise: {
        title:'使用惊喜密码进入',
        remark:'要访问产品页面并购买空山基的首款NFT产品，请在下面的字段中输入您的秘密邀请码。',
        inputLabel:'惊喜邀请码',
        inputHolder:'在此输入您的邀请码',
        btnText:'输入'
    },
    productType: {
        1: {
            title: "臻品",
            hover:"ZHEN,认证的臻选商品声明了具有数字证明的物理所有权。",
            remark:"一个经过ZHEN.认证的臻选产品：即以最安全方式拥有艺术品。用户通过扫描我们的NFC安全芯片，为您的实体艺术品和收藏品提供数字所有权进行认证，每个芯片都与区块链上的唯一NFT绑定。结合区块链的不变性，安全性，不可分割性，真实性的出处等特性相结合，给ZHEN.提供全新的艺术品所有权和收藏体验的驱动力。",
        },
        2: {
            title: "NFT优质版",
            hover:"ZHEN. 优质NFT提供实物和数字产权的混合体验。",
            remark:"ZHEN. 优质的NFT产品打通了物理世界和数字世界的界限，重新想象和定义了数字艺术品所有权的含义。不仅仅是存在于区块链上的数字艺术品体验，优质NFT会与实物对应（如艺术品本身、收藏品或其他现实存在的物品），有时也会与增强现实体验紧密相连。有了ZHEN.上的优质NFT，您就可完备地探索所有权在各个维度上的可能性了。",
        },
        3: {
            title: "NFT",
            hover:"ZHEN. NFT 将您最喜爱的艺术家作品带入数字领域。",
            remark:"一个 ZHEN.的 NFT将传统的艺术所有权体验领入了数字世界。通过对三维动画动的忠实再现和在增强虚拟现实中的突破性重塑，在一个全新的维度上展示您最喜爱的艺术家的作品。ZHEN.的NFT不仅仅是普通的NFT作品，它更多的是一种新技术推动了数字艺术所有权的界限。",
        },
    },
    dashboard: {
        title:"交易看板",
        orders:"订单历史",
        transfer:"产权转移历史",
        withdraw:{
            title:"提取",
            eligible:'可提取的NFT',
            please:'为了提起申请，请您填写以下信息。',
            selectPayment:'选择支付方式',
            selectPaymentHolder:'选择支付方式',
            payRemark1:'由于提取操作将需要矿工费等费用，我们对每笔交易收取相应的费用。若出现提取未完成的特殊情况，我们将退还已收取的这笔费用。',
            payRemark2:'当提取完成后，您的ZHEN.橱窗将不再展示这个NFT。但若您之后进行转入操作，则转入成功后，橱窗将恢复该NFT的展示。 ',
            agree:'已阅读并同意',
            transactionFee:'手续费',
            noTransactionFee:'这是您购买此NFT之后的首次提取操作。为感谢您选择ZHEN.，本次我们将免费为您服务，不收取任何费用。',
            successTitle: '提取申请已提交',
            successMsg: '提取将在3个工作日内完成。相关详情将发送到您账号预留的联系方式上。您也可在提取记录中看到您的本次申请。',
            withdrawn:'已提取',
            history: {
                viewDetails:'查看详情',
                requestTime:'申请时间',
                withdrawTime:'完成时间',
                paymentDetails:'手续费详情',
                paymentMethod:'支付方式',
                transactionId:'交易ID',
                total:'总金额',
                withdrawDetails:'提取详情',
                contractAddress:'合约地址',
                serialNo:'序列号',
                txHash:'哈希',
                walletAddress:'钱包地址',
                outgoing:'提取中',
                failed:'提取失败',
                failedMsg1:'您的提取申请没有成功。',
                failedMsg2:'若您有需要，请再试一次。'
            }
        },
        deposit:{
            title:"转入",
            notConnect:'请先连接钱包，方可进行转入操作。',
            depositRemark1:'仅ZHEN.发行的NFT可转入。',
            depositRemark2:'若要查询您的NFT是否可被转入，请点击',
            depositRemark3:'进行转入操作前，请先连接您的钱包。',
            here:'此处。',
            depositBtnTxt:'转入',
            depositNFT:'转入NFT',
            walletAddress:'钱包地址: ',
            depositNFTLabel:'仅ZHEN.发行的NFT可转入。我们在您的钱包中找到了这些可以转入的NFT。',
            contractAddress:'合约地址',
            contractAddressError:'请输入正确的区块链合约地址！',
            checkTitle:'查询',
            checkRemarK:'仅ZHEN.发行的NFT可转入。您于下方输入您NFT的合约地址，查询您的NFT是否可被转入。',
            contractLabel:'NFT合约地址',
            contractInputHolder:'你的NFT合约地址',
            checkBtnText:'查询',
            compatible:'您的NFT可以转入',
            compatibleRemark:'您的NFT可以转入ZHEN.。您可以于转入面板中进行转入操作。',
            notCompatible:'不可转入',
            notCompatibleRemark:'此NFT不可转入。',
            coming:'转入中',
            submitTitle:'转入申请已提交',
            submitRemark:'转入的完成时间受链的当前状态影响，因此我们不确定将于何时完成。但您可以随时在转入历史中查看进展。',
            submitRemark1:'我们建议您不要再进行任何手工加速操作，因为这可能导致订单发生变化，从而导致我们找不到您的转入记录。',
            checkStatus:'查看进展',
            helpMsg:'怎麽找NFT的合约地址？ ',
            notFound:'没有发现NFT',
            notFoundRemark:'未找到可转入的NFT。如果您刚刚将NFT转入钱包，可能需要等待几分钟，它才会出现。另外，如果您非常确定您拥有可转入的NFT，那么请检查您的钱包，看看当前是否选择了正确的账户和网络。',
            helpDeposit:'进行转入操作前，您需要先将NFT添加显示于您的钱包。如何才能在钱包裡显示我的NFT？',
            depositDetails:'转入详情',
            depositTime:'完成时间',
            depositHistory:'转入历史',
            failedMsg1:'您的转入申请没有成功。',
            noDepositHistory: '您没有转入历史。'
        },
        transferHistory: {
            transferState:{
                0: { text: "进行中", className: "pending" },
                1: { text: "待确认"},
                2: { text: "审核通过", className: "approved" },
                3: { text: "审核驳回", className: "rejected" },
                4: { text: "取消", className: "canceled" },
            }
        }
    }

}