<template>
  <div>
    <div class="login">
      <div class="login_box">
        <div class="login_top bg-white">
          <AppNavigator />
        </div>
        <div style="width: 100%; height: 79px; margin-top: 40px"></div>
        <div class="login_main" :style="{ minHeight: mainHeight + 'px' }">
          <div style="width: 86%; margin: 0 auto">
            <div style="display: flex; justify-content: space-between">
              <div style="width: calc(calc(100% - 80px) / 3)">
                <div style="font-size: 21px; color: #000000">
                  {{ $t("lang.login.title") }}
                </div>
              </div>
              <div style="flex: 1; margin-left: 40px">
                <el-form>
                  <div style="display: flex">
                    <div style="flex: 1; height: 80px; line-height: 40px">
                      <div>
                        <el-radio
                          v-model="radio"
                          label="1"
                          @change="checkRadio"
                          name="username"
                          >{{ $t("lang.login.email") }}*</el-radio
                        >
                      </div>
                      <div>
                        <el-form-item label="" prop="email">
                          <el-input
                            type="text"
                            v-model="email"
                            :placeholder="emailHolder"
                            :disabled="radio == 2"
                            autocomplete="off"
                            maxlength="128"
                            :class="emailError ? 'error_border' : ''"
                          />
                          <div class="el-form-item__error">
                            {{ emailError }}
                          </div>
                        </el-form-item>
                      </div>
                    </div>
                    <div
                      style="
                        flex: 1;
                        margin-left: 40px;
                        height: 80px;
                        line-height: 40px;
                      "
                    >
                      <div>
                        <el-radio
                          v-model="radio"
                          label="2"
                          @change="checkRadio"
                          name="username"
                          >{{ $t("lang.login.phone") }}*</el-radio
                        >
                      </div>
                      <div>
                        <el-form-item label="" prop="phone">
                          <div style="display: flex">
                            <div
                              :style="
                                radio == 1
                                  ? 'color: #BCBCBC;'
                                  : 'color: #000000;'
                              "
                            >
                              +
                            </div>
                            <div style="width: 60px; margin-right: 10px">
                                <AreaSelect :disabled="isPhone"  @countryChange="countryChange($event)"/>
                            </div>
                            <div style="flex: 1">
                              <el-input
                                type="text"
                                v-model="phone"
                                :placeholder="phoneHolder"
                                :disabled="radio == 1"
                                autocomplete="off"
                                maxlength="64"
                                :class="phoneError ? 'error_border' : ''"
                              />
                            </div>
                          </div>
                          <div class="el-form-item__error">
                            {{ phoneError }}
                          </div>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div style="height: 80px; line-height: 40px">
                    <div style="color: #000000">
                      {{ $t("lang.login.password") }}*
                    </div>
                    <div>
                      <el-form-item label="" prop="password">
                        <el-input
                          type="text"
                          v-model="password"
                          :placeholder="$t('lang.login.yourPassword')"
                          :show-password='ispassword'
                          maxlength="64"
                          autocomplete="off"
                          @focus="ispassword = true"
                          :class="passwordError ? 'error_border' : ''"/>
                        <div class="el-form-item__error">
                          {{ passwordError }}
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                  <div
                    style="
                      margin-top: 30px;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <div>
                      <el-checkbox
                        v-model="check"
                        :label="$t('lang.login.remember')"
                      ></el-checkbox>
                    </div>
                    <div
                      style="
                        font-family: Helvetica;
                        color: #ff7a00;
                        cursor: pointer;
                      "
                      @click="goToForgetPassword()"
                    >
                      {{ $t("lang.login.forgetPassword") }}?
                    </div>
                  </div>
                  <div
                    style="
                      margin-top: 15px;
                      font-size: 12px;
                      font-family: Helvetica;
                      color: #bcbcbc;
                    "
                  >
                    {{ $t("lang.myMarkPlace.contentText3") }}
                    <span
                      @click="goToLegal(2)"
                      style="color: #ff7a00; cursor: pointer"
                      >{{ $t("lang.myMarkPlace.label1") }}
                    </span>
                    {{ $t("lang.myMarkPlace.label3") }}
                    <span
                      @click="goToLegal(1)"
                      style="color: #ff7a00; cursor: pointer"
                      >{{ $t("lang.myMarkPlace.label2") }}</span
                    >
                  </div>
                  <div style="margin-top: 20px; width: 100%">
                    <el-button
                      :type="buttonType"
                      round
                      style="
                        width: 100%;
                        color: #ffffff;
                        height: 25px;
                        padding: 0;
                        border-radius: 13px;
                      "
                      @click="submitForm()"
                      :showloading="showloading"
                      >{{ $t("lang.profile.login") }}</el-button
                    >
                  </div>
                  <div
                    style="
                      margin-top: 40px;
                      margin-bottom: 50px;
                      color: #000000;
                    "
                  >
                    * {{ $t("lang.login.content2") }}
                  </div>
                </el-form>
              </div>
            </div>
          </div>
          <div
            style="
              font-size: 14px;
              width: 100%;
              border-top: 0.1em solid #000000;
              padding-top: 25px;
              text-align: center;
              color: #000000;
              margin-top: 200px;
            "
          >
            {{ $t("lang.login.content3")
            }}<el-button
              class="sign_btn"
              round
              style="color: #ff7a00; border: none; padding: 0"
              @click="goToSignup()"
              >{{ $t("lang.login.content4") }}</el-button
            >
          </div>
        </div>

        <div class="login_footer">
          <AppFooter />
        </div>
      </div>
      <!-- 强制修改密码提示框 -->
      <el-dialog
        center
        width="380px"
        :show-close="false"
        custom-class="update_dialog"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :title="$t('lang.login.updatePWTitle')"
      >
        <div style="padding: 0 30px">
          <div style="text-align: center; color: #000000">
            {{ $t("lang.login.updatePWContent") }}
          </div>
          <div style="text-align: center; margin-top: 20px">
            <button class="showupdate_btn" @click="showUpdateDialog()">
              {{ $t("lang.login.updatePWBtn") }}
            </button>
          </div>
        </div>
      </el-dialog>
      <!-- 强制修改密码框 -->
      <el-dialog
        center
        width="380px"
        :title="$t('lang.login.editPassword')"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
        :show-close="false"
      >
        <el-form :model="passwordForm">
          <div>{{ $t("lang.login.currentPassword") }}</div>
          <el-form-item>
            <div style="margin-top: 5px">
              <el-input
                type="text"
                v-model="passwordForm.quondam"
                autocomplete="off"
                :placeholder="$t('lang.login.yourPassword')"
                :show-password="ispassword1"
                @focus="ispassword1 = true"
                :class="passwordForm.quondamError ? 'error_border' : ''"
              />
              <div class="el-form-item__error">
                {{ passwordForm.quondamError }}
              </div>
            </div>
          </el-form-item>
          <div>{{ $t("lang.login.newPassword") }}</div>
          <el-form-item>
            <div style="margin-top: 5px">
              <el-input
                type="text"
                v-model="passwordForm.pristine"
                :placeholder="$t('lang.login.passwordInfo')"
                :show-password="ispassword2"
                @focus="ispassword2 = true"
                autocomplete="off"
                :class="passwordForm.pristineError ? 'error_border' : ''"
              />
              <div class="el-form-item__error">
                {{ passwordForm.pristineError }}
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            :type="passwordForm.buttonType"
            @click="updatePwd"
            :disabled="passwordForm.buttonType == 'info'"
            round
            size="mini"
            class="confirm_btn"
            >{{ $t("lang.login.confirm") }}</el-button
          >
        </div>
        <div slot="footer" class="dialog-footer" style="margin-top: 10px">
          <el-button
            @click="dialogFormVisible = false"
            round
            style="
              width: 200px;
              border: none;
              background-color: #ffffff;
              color: #6d7278;
              opacity: 1;
              padding: 10px !important;
            "
            >{{ $t("lang.login.cancel") }}</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
import AreaSelect from "@/components/user/AreaSelect.vue";
export default {
  name: "Login",
  components: {
    AppNavigator,
    AppFooter,
    AreaSelect
  },
  data() {
    return {
      showloading: false,
      dialogVisible: false,
      radio: "1",
      check: false,
      email: "",
      password: "",
      phone: "",
      emailError: "",
      phoneError: "",
      passwordError: "",
      buttonType: "info",
      timeout: "",
      countryId: "",
      token: "",
      emailHolder: this.$t("lang.login.yourEmail"),
      phoneHolder: "",
      dialogFormVisible: false,
      mainHeight: 0,
      passwordForm: {
        quondam: "",
        pristine: "",
        quondamError: "",
        pristineError: "",
        buttonType: "",
      },
      isBack: "",
      isPhone:false,
      ispassword:false,
      ispassword1:false,
      ispassword2:false
    };
  },
  watch: {
    //监听表单内容变化
    phone(val, oldVal) {
      if (
        this.radio == 2 &&
        this.phone &&
        this.password &&
        this.password.length >= 6 &&
        this.countryId
      ) {
        this.buttonType = "primary";
      } else {
        this.buttonType = "info";
      }
    },
    state(val, oldVal) {
      if (
        this.radio == 2 &&
        this.phone &&
        this.password &&
        this.password.length >= 6 &&
        this.countryId
      ) {
        this.buttonType = "primary";
      } else {
        this.buttonType = "info";
      }
    },
    email(val, oldVal) {
      if (
        this.radio == 1 &&
        this.email &&
        this.password &&
        this.password.length >= 6
      ) {
        this.buttonType = "primary";
      } else {
        this.buttonType = "info";
      }
    },
    password(val, oldVal) {
      if (
        (this.radio == 1 &&
          this.email &&
          this.password &&
          this.password.length >= 6) ||
        (this.radio == 2 &&
          this.phone &&
          this.password &&
          this.countryId &&
          this.password.length >= 6)
      ) {
        this.buttonType = "primary";
      } else {
        this.buttonType = "info";
      }
    },
    //监听表单内容变化 控制注册按钮是否可用
    passwordForm: {
      handler(val, oldVal) {
        let $this = this;
        let flag = true;
        let attr = ["quondam", "pristine"];
        attr.forEach(function (prop, index) {
          if (!$this.passwordForm[prop]) {
            flag = false;
          }
        });
        if (flag) {
          $this.passwordForm.buttonType = "primary";
        } else {
          $this.passwordForm.buttonType = "info";
        }
      },
      deep: true,
    },
  },
  methods: {
    checkRadio() {
      if (this.radio == 1) {
        this.isPhone = false;
        this.phoneHolder = "";
        this.phoneError = "";
        this.emailHolder = this.$t("lang.login.yourEmail");
        this.phone = "";
      } else {
        this.isPhone = true;
        this.emailHolder = "";
        this.emailError = "";
        this.phoneHolder = this.$t("lang.login.yourPhone");
        this.email = "";
      }
      this.password = "";
    },
    countryChange(param) {
      this.countryId = param.id;
    },
    goToLegal(index) {
      let param = {
        name: "Privacy",
      };
      if (index == 2) {
        param = {
          name: "Terms",
        };
      }
      const { href } = this.$router.resolve(param);
      window.open(href, "_blank");
    },
    //点击login触发
    submitForm() {
      let $this = this;
      let param = { password: $this.password };
      let reqUrl = "user/login/email";
      //清除错误信息
      $this.resetError();
      //页面表单没有填写完 不处理
      if ($this.buttonType == "info") {
        return false;
      }
      //邮箱登录请求参数
      if ($this.radio == 1) {
        param.email = $this.email;
      }
      //手机号登录请求参数
      if ($this.radio == 2) {
        if (!$this.countryId) {
          $this.phoneError = this.$t("lang.register.errorInfo");
          return false;
        }
        param.countryId = $this.countryId;
        param.phone = $this.phone;
        reqUrl = "user/login/phone";
      }
      //显示按钮上的loading
      $this.showloading = true;
      //发送请求
      $this.$http.post(reqUrl, this.qs.stringify(param)).then((res) => {
        //显示按钮上的loading
        $this.showloading = false;
        //请求成功
        if (res.code === 0) {
          localStorage.setItem('areaCode',$this.countryId);
          $this.utils.logEvent("loginTimeEvent", { loginDate: new Date() });
          if (res.data.needChangePassword) {
            $this.token = res.data.token;
            this.dialogVisible = true;
            return false;
          }
          //是否记住密码
          if ($this.check) {
            localStorage.setItem("token", res.data.token || "");
          } else {
            sessionStorage.setItem("token", res.data.token || "");
          }
          localStorage.setItem("profilePhoto", res.data.profilePhoto || "");
          if ($this.isBack == 3) {
            $this.$router.back(-1);
          } else if ($this.isBack == 1){
            $this.$router.push("/");
          } else if ($this.isBack == 2){
            $this.$router.push("/marketPlace");
          }
        }
        //请求失败
        else {
          if (res.code === -3) {
            let errorList = res.msg.split(";");
            errorList.forEach(function (item, index, arr) {
              let attr = item.split(":")[0];
              let msg = item.split(":")[1];
              $this[attr + "Error"] = msg;
            });
          }
        }
      });
    },
    showUpdateDialog() {
      this.dialogVisible = false;
      this.dialogFormVisible = true;
    },
    //修改密码
    updatePwd() {
      this.passwordForm.quondamError = "";
      this.passwordForm.pristineError = "";
      let $this = this;
      this.$http
        .post(
          "user/password/modify",
          this.qs.stringify({
            quondam: this.passwordForm.quondam,
            pristine: this.passwordForm.pristine,
            token: this.token,
          })
        )
        .then((res) => {
          if (res.code === 0) {
            this.dialogFormVisible = false;
            $this.utils.alert("", this.$t("lang.login.loginInfo")).then(() => {
              location.reload();
            });
          } else {
            if (res.code === -3) {
              let errorList = res.msg.split(";");
              errorList.forEach(function (item, index, arr) {
                let attr = item.split(":")[0];
                let msg = item.split(":")[1];
                $this.passwordForm[attr + "Error"] = msg;
              });
            }
          }
        });
    },
    //跳转到注册组件
    goToSignup() {
      this.$router.push("/register");
    },
    resetError() {
      this.emailError = "";
      this.phoneError = "";
      this.passwordError = "";
    },
    //跳转到忘记密码组件
    goToForgetPassword() {
      this.$router.push("/forgotPassword");
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //判断如果是注册 或者 忘记密码页面跳转过来的登录成功后返回首页。
      if (from.path == "/register" || from.path == "/forgotPassword") {
        vm.isBack = 1;
      } else if(from.path == "/checkOut" || from.path == "/shipping" || from.path == "/confirmOrder") {
        vm.isBack = 2;
      }else {
        vm.isBack = 3;
      }
    });
  },
  created() {
  },
  mounted() {
    this.mainHeight = window.screen.availHeight - 355;
  },
};
</script>

<style lang="less">
.login {
  width: 100%;
  height: 100%;
  font: 400 14px NeueHaasUnicaW1G !important;
  .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*height:600px;*/
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
    border-radius: 10px;
  }
  .update_dialog {
    border-radius: 10px;
  }
  .update_dialog .el-dialog__body {
    padding-top: 0 !important;
  }
  .el-button--info {
    background-color: #bcbcbc !important;
    border-color: #bcbcbc !important;
  }
  .confirm_btn {
    width: 150px;
    background: #bcbcbc;
    color: #fff;
  }
  .showupdate_btn {
    width: auto;
    padding: 6px 60px 6px 40px;
    text-align: center;
    border-radius: 15px;
    border: none;
    color: #fff;
    background-color: #000000;
    margin-top: 10px;
    cursor: pointer;
  }
  .el-autocomplete input {
    text-align: center !important;
  }
  .el-autocomplete input::placeholder {
    text-align: center !important;
  }
  .input-search {
    width: 250px !important;
  }
  .sign_btn:hover,
  .sign_btn:focus {
    background-color: #ffffff !important;
  }
  input {
    color: #000000 !important;
    font: 400 14px NeueHaasUnicaW1G !important;
  }
  .el-input input {
    width: 100%;
    line-height: 25px;
    height: 25px;
    border: none;
    outline: medium;
    border-bottom: 0.1em solid #000000;
    opacity: 1;
    border-radius: 0;
    padding: 0 !important;
  }
  .el-form-item__content {
    line-height: 30px !important;
  }
  .el-input input:disabled {
    background-color: #ffffff !important;
    cursor: not-allowed;
  }

  /* 添加颜色类 */
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #000000 !important;
    border-color: #000000 !important;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #000000;
    font: 400 14px NeueHaasUnicaW1G !important;
  }

  .el-checkbox__input.is-focus .el-checkbox__inner,
  .el-checkbox__input:hover .el-checkbox__inner {
    border-color: #000000 !important;
  }
  .el-checkbox__label,
  .el-radio__label {
    font: 400 14px NeueHaasUnicaW1G !important;
  }
  .el-checkbox__inner {
    border-radius: 8px !important;
  }

  input::placeholder {
    color: #bcbcbc !important;
    text-align: left !important;
  }

  .login_box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .login_top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      border-bottom: 1px solid #000000;
      // backdrop-filter: saturate(180%) blur(30px);
    }

    .login_main {
      width: 100%;
      box-sizing: border-box;
      margin: 0 auto;
      padding-bottom: 100px;
    }

    // .login_footer {
    //   width: 100%;
    //   box-sizing: border-box;
    //   position: fixed;
    //   bottom: 0px;
    // }
  }
}
</style>