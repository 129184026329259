<template>
  <div class="product_info">
    <div class="product_info_box">
      <div class="product_info_top bg-white">
        <AppNavigator />
      </div>
      <div class="product_info_main">
        <div style="width: 100%">
          <div class="main-content">
            <div class="content-left">
              <div class="product-left" v-show="isBottom">
                <div style="font-size: 14px">
                  <el-tooltip
                    class="item"
                    effect="light"
                    visible-arrow
                    :content="$t('lang.productType')[info.category]?$t('lang.productType')[info.category].hover:''"
                    placement="right-end"
                  >
                    <el-button class="category_btn"
                      >{{$t("lang.productType")[info.category]?$t("lang.productType")[info.category].title:''}}<i class="el-icon-warning-outline"></i
                    ></el-button>
                  </el-tooltip>
                </div>
                <div class="artist-name">{{ info.artistName }}</div>
                <div class="wares-name">{{ info.waresName }}</div>
              </div>
            </div>
            <div class="content-center">
              <!-- <div class="iconBox" v-if="info.videoUrl">
                <video
                  :src="info.videoUrl"
                  autoplay
                  muted
                  loop="loop"
                  :height="imgHeight + 'px'"
                >
                  您的浏览器不支持视频播放。
                </video>
                <div class="iconClass">
                                            <img
                    v-if="info.nftChain == 1"
                    src="../..//assets/Frontpage/ETH.png"
                  />
                                            <img
                    v-else-if="info.nftChain == 2"
                    src="../../assets/Frontpage/matic.png"
                  />
                </div>
                            
              </div> -->
              <!-- v-if="!info.videoUrl"  -->
              <div style="text-align: center">
                <el-carousel
                  arrow="never"
                  indicator-position="outside"
                  :autoplay="false"
                  :height="imgHeight + 'px'"
                  @click.native="onPreview()"
                  ref="carousel"
                  style="cursor: pointer"
                >
                  <el-carousel-item
                    v-for="(url, index) in info.waresListImgDtos"
                    :key="index"
                  >
                    <div class="iconBox">
                      <el-image
                        v-if="url.type == 1"
                        :src="url.url"
                        ref="image"
                        style="width: 100%; height: 100%"
                      ></el-image>

                      <video
                        v-if="url.type == 2"
                        :src="url.url"
                        autoplay
                        muted
                        loop="loop"
                        :height="imgHeight + 'px'"
                      >
                        您的浏览器不支持视频播放。
                      </video>

                      <div class="iconClass" v-if="false">
                                                  <img
                          v-if="info.nftChain == 1"
                          src="../..//assets/Frontpage/ETH.png"
                        />
                                                  <img
                          v-else-if="info.nftChain == 2"
                          src="../../assets/Frontpage/matic.png"
                        />
                      </div>
                                  
                    </div>
                  </el-carousel-item>
                  <el-image-viewer
                    v-if="showViewer"
                    :on-close="closeViewer"
                    :url-list="viewerImgList"
                    :z-index="3000"
                  />
                </el-carousel>
              </div>
              <div  class="small-image-panel">
                <div
                  v-for="(item, index) in info.waresListImgDtos"
                  :key="index"
                  class="min_img_box">
                  <img
                    ref="images"
                    v-if="item.type == 1"
                    :src="item.url"
                    :height="imgMinHeight"
                    class="small-image"
                    @click="setActiveItem(index)"/>
                    <video
                        v-if="item.type == 2"
                        :src="item.url"
                        autoplay
                        muted
                        :height="imgMinHeight"
                        @click="setActiveItem(index)"
                        loop="loop">
                        您的浏览器不支持视频播放。
                      </video>
                </div>
              </div>
              <div class="description">
                {{ $t("lang.myMarkPlace.description") }}
              </div>
              <div style="margin-bottom: 25px;font-size: 14px;line-height:17px;color: #000000;">
                  {{ info.productIntro }}
              </div>
              <div class="nft-remark-panel">
                <div class="nft-remark-content" @click="showRemark()">
                  <div class="nft-remark-content-txt">
                    {{ $t("lang.cabinet.detail.conentText") }}{{$t("lang.productType")[info.category]?$t("lang.productType")[info.category].whatTxt:''}}?
                  </div>
                  <div>
                    <div
                      :class="isShowRemark ? 'common-minus' : 'common-plus'"
                      style="float: right"
                    ></div>
                  </div>
                </div>
                <div v-show="isShowRemark" class="remark-display-panel">
                  {{$t("lang.productType")[info.category]?$t("lang.productType")[info.category].remark:''}}
                </div>
              </div>
              <div class="product-details-panel">
                <div class="product-details-content" @click="showDetail">
                  <div class="product-details-content-txt">
                    {{ $t("lang.myMarkPlace.productDetail2") }}
                  </div>
                  <div>
                    <div
                      :class="isShowDetail ? 'common-minus' : 'common-plus'"
                      style="float: right"
                    ></div>
                  </div>
                </div>
                <div v-show="isShowDetail" class="details-display-panel">
                  <div style="display: flex">
                    <div
                      style="flex: 1; line-height: 18px"
                      v-html="info.digitalInfo"
                      v-show="info.category == 2 || info.category == 3"
                    ></div>
                    <div
                      style="flex: 1; line-height: 18px"
                      v-html="info.physicalInfo"
                      v-show="info.category == 2 || info.category == 1"
                    ></div>
                  </div>
                </div>
              </div>
              <div style="border-bottom: 0.1em solid #000000; padding: 8px 0">
                <div
                  style="display: flex; align-items: center; cursor: pointer"
                  @click="showHistory"
                >
                  <div style="flex: 1; font-size: 14px; color: #000000">
                    {{ $t("lang.myMarkPlace.history") }}
                  </div>
                  <div>
                    <div
                      :class="isShowHistory ? 'common-minus' : 'common-plus'"
                      style="float: right"
                    ></div>
                  </div>
                </div>
                <div
                  v-show="isShowHistory"
                  style="color: #000000; font-size: 14px; padding: 20px 0 0 0"
                >
                  <div
                    v-for="(log, index) in info.listTransactionLog"
                    :key="index"
                    style="
                      display: flex;
                      border-top: 1px solid #bcbcbc;
                      padding: 10px 0;
                    "
                  >
                    <div style="flex: 1">
                      <div
                        :style="{ color: log.infoColor ? log.infoColor : '' }"
                      >
                        {{ log.info }}
                      </div>
                      <div style="font-size: 12px; color: #bcbcbc">
                        {{ log.createTime }}
                        <a
                          :href="log.transactionUrl"
                          v-show="log.transactionUrl"
                          target="_blank"
                          :class="log.nftChain == 1 ? 'eth-txid' : 'matic-txid'"
                          >{{ $t("lang.myMarkPlace.view") }} TXID</a
                        >
                      </div>
                    </div>
                    <div style="text-align: right">
                      <div>{{ log.usdPrice }}</div>
                      <div
                        style="font-size: 12px; color: #bcbcbc"
                        v-show="log.ethPrice"
                      >
                        {{ log.ethPrice }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="raffle-rules-panel" v-if="info.lotRule">
                  <div class="raffle-rules-content" @click="showRaffleRules()">
                    <div class="raffle-rules-content-txt">
                      {{ $t("lang.myMarkPlace.raffleRule")}}
                    </div>
                    <div>
                      <div :class="isShowRaffleRules ? 'common-minus' : 'common-plus'" style="float: right"></div>
                    </div>
                  </div>
                  <div v-show="isShowRaffleRules" class="raffle-rules-display-panel" v-html="info.lotRule.replace(/\r\n/g,'<br/>')">
                  </div>
              </div>
              <!-- <div class="metadata-panel">
                <div class="metadata-content" @click="showMetadata()">
                  <div class="metadata-content-txt">
                    METADATA
                  </div>
                  <div>
                    <div :class="isShowMetadata?'common-minus':'common-plus'" style="float: right;"></div>
                  </div>
                </div>
                <div v-show="isShowMetadata" class="metadata-display-panel">
                    <el-button class="metadata-btn" style="margin-right:5%;">View on IPFS</el-button>
                    <el-button class="metadata-btn" style="margin-left:5%;">View on Etherscan</el-button>
                    <el-button class="metadata-btn">View IPFS Metadata</el-button>
                </div>
              </div> -->
            </div>
            <div class="content-right">
              <div class="product-right" v-show="isBottom">
                <div class="product-price">{{ info.price }}</div>
                <div class="btn-panel">
                  {{ info.state }}
                  <span v-show="info.lastSecond > 0 && timeObj.day > 0"
                    >{{ timeObj.day }} {{ $t("lang.myMarkPlace.day")
                    }}<span
                      v-if="
                        timeObj.day > 1 && $t('lang.myMarkPlace.day') == 'Day'
                      "
                      >s</span
                    ></span
                  >&nbsp;
                  <span
                    v-show="
                      info.lastSecond > 0 &&
                      (timeObj.day > 0 ||
                        timeObj.hour > 0 ||
                        timeObj.minute > 0 ||
                        timeObj.second > 0)
                    "
                    >{{ timeObj.hour > 9 ? timeObj.hour : "0" + timeObj.hour }}
                    :
                  </span>
                  <span
                    v-show="
                      info.lastSecond > 0 &&
                      (timeObj.day > 0 ||
                        timeObj.hour > 0 ||
                        timeObj.minute > 0 ||
                        timeObj.second > 0)
                    "
                    >{{
                      timeObj.minute > 9 ? timeObj.minute : "0" + timeObj.minute
                    }}
                    :
                  </span>
                  <span
                    v-show="
                      info.lastSecond > 0 &&
                      (timeObj.day > 0 ||
                        timeObj.hour > 0 ||
                        timeObj.minute > 0 ||
                        timeObj.second > 0)
                    "
                    >{{
                      timeObj.second > 9 ? timeObj.second : "0" + timeObj.second
                    }}</span
                  >
                </div>
                <el-button
                  round
                  size="mini"
                  v-show="info.buttonType != 0"
                  class="common_btn"
                  :disabled="info.buttonType == 2"
                  @click="shopNow(info.buttonAction)"
                  :showloading="showloading"
                >
                  {{ info.buttonWord }}</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="split-line" ref="bottom"></div>
        <div class="artist-panel">
          <div class="artist-remark">{{ $t("lang.myMarkPlace.artist") }}</div>
          <div style="text-align: center">
            <div>
              <img :src="info.artistImg" style="width: 360px" />
            </div>
            <div class="artist-name-txt">{{ info.artistName }}</div>
            <div class="artist-intro">
              <span class="artist-intro-txt">{{ info.artistIntro }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="product_info_footer">
      <AppFooter />
    </div>
    <div class="dialog_panel">
      <el-dialog
        center
        width="800px"
        :show-close="false"
        custom-class="pay_info_dialog"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
      >
        <div style="display: flex; font-size: 16px">
          <div style="flex: 1">
            <div class="iconBox" v-if="!info.videoUrl">
              <img
                :src="info.mainImg"
                style="
                  width: 100%;
                  border-top-left-radius: 10px;
                  border-bottom-left-radius: 10px;
                  display: block;
                "
              />
              <div class="iconClass" v-if="false">
                                          <img
                  v-if="info.nftChain == 1"
                  src="../..//assets/Frontpage/ETH.png"
                />
                                          <img
                  v-else-if="info.nftChain == 2"
                  src="../../assets/Frontpage/matic.png"
                />
              </div>
                          
            </div>

            <div class="iconBox" v-if="info.videoUrl">
              <video
                :src="info.videoUrl"
                muted
                autoplay
                loop="loop"
                style="margin: 0"
              >
                您的浏览器不支持视频播放。
              </video>

              <div class="iconClass" v-if="false">
                                          <img
                  v-if="info.nftChain == 1"
                  src="../..//assets/Frontpage/ETH.png"
                />
                                          <img
                  v-else-if="info.nftChain == 2"
                  src="../../assets/Frontpage/matic.png"
                />
              </div>
                          
            </div>
          </div>
          <div
            style="
              flex: 1;
              padding: 20px;
              display: flex;
              flex-direction: column;
              color: #000000;
            "
          >
            <div style="font-weight: 400">
              {{ $t("lang.myMarkPlace.productDetail2") }}
            </div>
            <div style="display: flex; margin-top: 20px">
              <div style="flex: 1">{{ info.artistName }}</div>
              <div style="flex: 1; text-align: right">{{ info.price }}</div>
            </div>
            <div style="display: flex">
              <div style="flex: 1">{{ info.waresName }}</div>
              <div style="flex: 1; text-align: right; color: #bcbcbc">
                {{ ethPrice }}
              </div>
            </div>
            <!-- <div style="margin-top: 15px;color:#BCBCBC;font-size:12px;">
              {{$t('lang.myMarkPlace.content')}}
            </div> -->
            <div style="flex: 1; padding-top: 30px">
              <div style="display: flex">
                <div
                  style="flex: 1"
                  v-if="payment.listFiat && payment.listFiat.length > 0"
                >
                  <el-radio v-model="radio" label="2" @change="checkRadio"
                    >{{ $t("lang.pay.other") }}
                  </el-radio>
                </div>
                <div
                  style="flex: 1"
                  v-if="payment.listDigital && payment.listDigital.length > 0"
                >
                  <el-radio v-model="radio" label="1" @change="checkRadio">
                    {{ $t("lang.myMarkPlace.digitalWallet") }}
                  </el-radio>
                </div>
              </div>
              <div v-show="radio == 2" style="margin-top: 20px">
                <div
                  v-for="(pay, index) in payment.listFiat"
                  :key="index"
                  class="pay_row active"
                  @click="changePaymethod(pay.id)"
                >
                  <div style="flex: 1; font-size: 14px">{{ pay.name }}</div>
                  <div
                    style="flex: 1; text-align: right"
                    :class="paymethod == pay.id ? 'active' : ''"
                  >
                    <i class="el-icon-check" style="font-weight: bold"></i>
                  </div>
                </div>
              </div>
            </div>
            <div style="text-align: center">
              <div slot="footer" class="dialog-footer">
                <el-button
                  type="primary"
                  round
                  class="checkout-btn"
                  v-show="isConnect"
                  :disabled="!paymethod"
                  @click="checkout()"
                  >{{ $t("lang.myMarkPlace.checkOut") }}</el-button>
                <el-button
                  type="primary"
                  round
                  style="
                    background-color: #ff7a00;
                    border: none;
                    padding: 0;
                    height: 25px;
                    line-height: 25px;
                    width: 160px;"
                  v-show="!isConnect"
                  @click="connectWallet()"
                  >{{ $t("lang.myMarkPlace.connectwallet") }}</el-button>
              </div>
              <div slot="footer" class="dialog-footer" style="margin-top: 10px">
                <el-button
                  @click="dialogVisible = false"
                  round
                  style="
                    width: 200px;
                    border: none;
                    background-color: #ffffff;
                    color: #bcbcbc;
                    opacity: 1;
                    height: 25px;
                    line-height: 25px;
                    padding: 0;
                  "
                  >{{ $t("lang.myMarkPlace.cancel") }}</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        center
        width="380px"
        custom-class="metamask_dialog"
        :visible.sync="dialogVisible1"
        :title="$t('lang.myMarkPlace.dialogTitle1')"
        :close-on-click-modal="false"
      >
        <div style="padding: 0 30px">
          <div style="text-align: center; margin-top: 10px">
            <button class="metamask_btn" @click="connectMetamask()">
              MetaMask
            </button>
          </div>
          <div style="margin-top: 20px; font-size: 12px; text-align: center">
            {{ $t("lang.myMarkPlace.contentText3") }}
            <span @click="goToLegal(2)" style="color: #ff7a00; cursor: pointer"
              >{{ $t("lang.myMarkPlace.label1") }}
            </span>
            {{ $t("lang.myMarkPlace.label3") }}
            <span @click="goToLegal(1)" style="color: #ff7a00">{{
              $t("lang.myMarkPlace.label2")
            }}</span>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        center
        width="380px"
        :show-close="false"
        custom-class="install_metamask_dialog"
        :visible.sync="dialogVisible2"
        :close-on-click-modal="false"
      >
        <div style="color: #000000; font-size: 14px">
          <div style="text-align: center">
            {{ $t("lang.myMarkPlace.installText1") }} METAMASK
          </div>
          <div style="margin-top: 10px; text-align: center; font-size: 14px">
            {{ $t("lang.myMarkPlace.contentText4") }}
          </div>
          <div style="text-align: center; margin-top: 10px">
            <button class="install_metamask_btn" @click="installMetamask()">
              {{ $t("lang.myMarkPlace.installText") }} MetaMask
            </button>
          </div>
          <div style="text-align: center; margin-top: 5px">
            <el-button
              @click="dialogVisible2 = false"
              round
              style="
                width: 200px;
                border: none;
                background-color: #ffffff;
                color: #bcbcbc;
                opacity: 1;
                padding: 10px !important;
              "
              >{{ $t("lang.myMarkPlace.cancel") }}</el-button
            >
          </div>
        </div>
      </el-dialog>
      <div class="video-full-screen" v-show="videoFullUrl" @click="videoFullUrl=''">
          <video :src="videoFullUrl" muted autoplay loop="loop"></video>
      </div>
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
import obj from "../../common/utils";
// 导入组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
var id = 0;
if (window.ethereum) {
  ethereum.on("chainChanged", (_chainId) => {
    id = obj.web3.utils.hexToNumber(_chainId);
    localStorage.setItem("chainId", id + "");
    window.location.reload();
  });
} else {
  console.log("请安装metamask!");
}

// const Web3 = require("web3")
//测试
// const web3 = new Web3(new Web3.providers.HttpProvider('https://rinkeby.infura.io/v3/46f1540fe8a44864a57eec5a0c22a89b'))
//正式
// const web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/46f1540fe8a44864a57eec5a0c22a89b'))

export default {
  name: "Product",
  components: {
    AppNavigator,
    AppFooter,
    ElImageViewer,
  },
  data() {
    return {
      activityId: "",
      info: "",
      payment: "",
      paymethod: "",
      imgHeight: "",
      imgMinHeight:0,
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      isShowRemark: false,
      isShowDetail: false,
      isShowHistory: false,
      isShowMetadata: false,
      isShowRaffleRules:false,
      autoplay: true,
      timeObj: {},
      ethPrice: "",
      radio: "2",
      isConnect: "",
      showViewer: false,
      showloading: false,
      viewerImgList: [],
      isBottom: true,
      videoFullUrl:''
    };
  },
  methods: {
    setActiveItem(index) {
      this.autoplay = false;
      this.$refs.carousel.setActiveItem(index);
      let $this = this;
      setTimeout(() => {
        $this.autoplay = true;
      }, 1000);
    },
    autoplayImage() {
      this.autoplay = true;
    },
    showRemark() {
      this.isShowRemark = !this.isShowRemark;
    },
    showDetail() {
      this.isShowDetail = !this.isShowDetail;
    },
    showHistory() {
      this.isShowHistory = !this.isShowHistory;
    },
    showMetadata() {
      this.isShowMetadata = !this.isShowMetadata;
    },
    showRaffleRules() {
      this.isShowRaffleRules = !this.isShowRaffleRules;
    },
    checkRadio() {
      let _this = this;
      if (this.radio == 1) {
        //判断localStorage是否保存用户地址
        if (localStorage.getItem("from")) {
          _this.paymethod = "";
          //判断金额是否大于商品价格
          obj.web3.eth
            .getBalance(localStorage.getItem("from"))
            .then(async function (res) {
              if (
                obj.web3.utils.fromWei(res, "ether") >
                  _this.ethPrice.split(/\s+/)[0] &&
                obj.chainId == localStorage.getItem("chainId")
              ) {
                _this.paymethod = _this.payment.listDigital[0].id;
                _this.isConnect = true;
              } else {
                _this.paymethod = "";
                _this.utils.alert(
                  _this.$t("lang.myMarkPlace.alertTitle"),
                  _this.$t("lang.myMarkPlace.alertText3"),
                  {
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonClass: "ok-btn",
                    cancelButtonClass: "cancel-btn",
                    confirmButtonText: _this.$t("lang.myMarkPlace.alertBtn"),
                    cancelButtonText: _this.$t("lang.wallet.close"),
                    autoClose: false,
                    //点击See Tutorial回调
                    ok() {
                      _this.$router.push({ path: "/faq" });
                    },
                  }
                );
              }
            });
        } else {
          _this.isConnect = false;
          _this.paymethod = _this.payment.listDigital[0].id;
        }
      } else {
        _this.paymethod = _this.payment.listFiat[0].id;
        _this.isConnect = true;
      }
    },
    changePaymethod(id) {
      this.paymethod = id;
    },
    shopNow(action) {
      let $this = this;
      if (!$this.utils.getToken()) {
        $this.$store.state.notLoginDialog = true;
        return;
      }
      this.showloading = true;
      switch (action) {
        //未登录
        case 0:
          $this.$store.state.notLoginDialog = true;
          $this.showloading = true;
          break;
        //调用订阅提醒接口
        case 1:
          $this.subscribe();
          break;
        //调用抽签接口(抽签中)
        case 2:
          $this.lot();
          break;
        //可购买(中签待缴款或零售可购买)
        case 3:
          $this.buyNow();
          break;
        default:
      }
    },
    lot() {
      let $this = this;
      //抽签
      this.$http
        .post(
          "wares/detail/lot",
          this.qs.stringify({
            token: this.utils.getToken(),
            waresId: this.waresId,
            activitySubId: this.activitySubId,
            activityId: this.activityId,
          })
        )
        .then((res) => {
          this.showloading = false;
          if (res.code === 0) {
            this.utils
              .alert(
                this.$t("lang.myprofile.note"),
                this.$t("lang.myMarkPlace.conentText2")
              )
              .then(() => {
                $this.getInfo();
              });
          } else {
            this.utils.alert("", res.msg);
          }
        });
    },
    subscribe() {
      let $this = this;
      //订阅
      this.$http
        .post(
          "message/subscribe",
          this.qs.stringify({
            token: this.utils.getToken(),
            type: 1,
            activitySubId: this.activitySubId,
            waresId: this.waresId,
          })
        )
        .then((res) => {
          this.showloading = false;
          if (res.code === 0) {
            //翻译文案
            //感谢订阅，我们会按时通知您。
            //感謝訂閱，我們會按時通知您
            this.utils
              .alert(
                this.$t("lang.myMarkPlace.title"),
                this.$t("lang.myMarkPlace.alertText1")
              )
              .then(() => {
                $this.getInfo();
              });
          }
        });
    },
    //购买
    buyNow() {
      this.utils.logEvent("BuyNowClickEvent");
      let _this = this;
      //订阅
      this.$http
        .post("/order/payChannel", { token: this.utils.getToken() })
        .then((res) => {
          this.showloading = false;
          if (res.code === 0) {
            _this.payment = res.data;
            _this.getEthPrice().then(() => {
              _this.dialogVisible = true;
              let listFiat = res.data.listFiat || [];
              let listDigital = res.data.listDigital || [];
              if (listFiat.length > 0) {
                _this.paymethod = listFiat[0].id;
                _this.radio = "2";
                _this.checkRadio();
              } else if (listDigital.length > 0) {
                _this.paymethod = listDigital[0].id;
                _this.radio = "1";
                _this.checkRadio();
              }
            });
          }
        });
    },
    //连接钱包
    connectWallet() {
      //先判断是否安装了metamask插件
      if (window.ethereum) {
        let content = this.$t("lang.login.content");
        this.dialogVisible1 = true;
      } else {
        this.dialogVisible2 = true;
      }

      // //用于国际化中的法律条款打开新窗口
      // window.openWindow = function (index) {
      //   const { href } = $this.$router.resolve({
      //     name: "Legal",
      //     query: { value: index }
      //   });
      //   window.open(href, '_blank');
      // }
      // let html = '<div><button class="metamask_btn" @click="test()">Metamask</button><div style="margin-top:20px;font-size:12px;">' + content + '</div></div>';
      // this.$alert(html, 'CONNECT YOUR WALLET', { showCancelButton: false, showConfirmButton: false, center: true, dangerouslyUseHTMLString: true });
    },
    goToLegal(index) {
      let param = {
        name: "Privacy",
      };
      if (index == 2) {
        param = {
          name: "Terms",
        };
      }
      const { href } = this.$router.resolve(param);
      window.open(href, "_blank");
    },
    //连接钱包插件
    connectMetamask() {
      let _this = this;
      //连接钱包
      obj
        .connectWallet()
        .then(() => {
          //再次判断是否确认已连接metamask,并保存了数据
          if (localStorage.getItem("from")) {
            //隐藏遮罩层
            _this.isConnect = true;
            _this.dialogVisible1 = false;
            _this.dialogVisible = true;
            _this.checkRadio();
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    installMetamask() {
      window.open("https://metamask.io/download.html");
      location.reload();
    },
    checkout() {
      let productInfo = {};
      productInfo.waresId = this.info.waresId;
      productInfo.category = this.info.category;
      productInfo.nftChain = this.info.nftChain;
      productInfo.artistName = this.info.artistName;
      productInfo.artistImg = this.info.artistImg;
      productInfo.waresName = this.info.waresName;
      productInfo.mainImg = this.info.mainImg;
      productInfo.price = this.info.price;
      productInfo.videoUrl = this.info.videoUrl;
      productInfo.activitySubId = this.info.activitySubId;
      productInfo.activityId = this.info.activityId;
      productInfo.payChannel = this.paymethod;
      sessionStorage.setItem("productInfo", this.qs.stringify(productInfo));
      sessionStorage.removeItem("shipping");
      this.$router.push({ path: "/checkOut" });
    },
    computeSecondToDate() {
      let $this = this;
      $this.timeObj.timer = setTimeout(function () {
        if ($this.timeObj.second > 0) {
          $this.timeObj.second = parseInt($this.timeObj.second) - 1;
          $this.computeSecondToDate();
        } else {
          if ($this.timeObj.minute > 0) {
            $this.timeObj.second = 59;
            $this.timeObj.minute = $this.timeObj.minute - 1;
          } else {
            if ($this.timeObj.hour > 0) {
              $this.timeObj.second = 59;
              $this.timeObj.minute = 59;
              $this.timeObj.hour = $this.timeObj.hour - 1;
            } else {
              if ($this.timeObj.day > 0) {
                $this.timeObj.second = 59;
                $this.timeObj.minute = 59;
                $this.timeObj.hour = 23;
                $this.timeObj.day = parseInt($this.timeObj.day) - 1;
              }
            }
          }
          if (
            $this.timeObj.day == 0 &&
            $this.timeObj.hour == 0 &&
            $this.timeObj.minute == 0 &&
            $this.timeObj.second == 0
          ) {
            clearTimeout($this.timeObj.timer);
            $this.getInfo();
          } else {
            $this.computeSecondToDate();
          }
        }
      }, 1000);
    },
    validParam() {
      let flag = true,re = /^[0-9]+[0-9]*/;
      if(!this.waresId || !this.activitySubId || !this.activityId) {
        flag = false;
      }else if(!re.test(this.waresId) || !re.test(this.activitySubId) || !re.test(this.activityId)) {
        flag = false;
      }else if(this.waresId.length > 7 || this.activitySubId.length > 7 || this.activityId.length > 7) {
        flag = false;
      }
      return flag;
    },
    getInfo() {
      const _this = this;
      this.waresId = this.$route.query.waresId;
      this.activitySubId = this.$route.query.activitySubId;
      this.activityId = this.$route.query.activityId;
      if(!this.validParam()) {
        this.$router.push({path:'/notFind'});
        return;
      }
      //查询橱窗详情
      this.$http
        .post(
          "wares/detail",
          this.qs.stringify({
            token: this.utils.getToken(),
            waresId: this.waresId,
            activitySubId: this.activitySubId,
          })
        )
        .then((res) => {
          if (res.code === 0) {
            let data = res.data;
            let surpriseCode = sessionStorage.getItem('surpriseCode');
            if(data.isSurprise == 1 && !surpriseCode) {
              this.$router.push({ path: "/surprise",query:{waresId:this.waresId}});
              return;
            }
            this.info = Object.assign({}, data, {
              activitySubId: _this.activitySubId,
              activityId: _this.activityId,
            });
            if (_this.info.lastSecond && _this.info.lastSecond > 0) {
              _this.$set(
                this.timeObj,
                "day",
                this.utils.getDateBySecond(this.info.lastSecond, "D")
              );
              _this.$set(
                this.timeObj,
                "hour",
                this.utils.getDateBySecond(this.info.lastSecond, "H")
              );
              _this.$set(
                this.timeObj,
                "minute",
                this.utils.getDateBySecond(this.info.lastSecond, "M")
              );
              _this.$set(
                this.timeObj,
                "second",
                this.utils.getDateBySecond(this.info.lastSecond, "S")
              );
              clearTimeout(_this.timeObj.timer);
              _this.computeSecondToDate();
            }
          }
        });
    },
    getEthPrice() {
      //eth换算
      return this.$http
        .post("order/getEthPrice", {
          price: this.info.price,
          token: this.utils.getToken(),
        })
        .then((res) => {
          if (res.code === 0) {
            this.ethPrice = res.data;
          }
        });
    },
    scrollEvent() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let bottomTop = this.$refs.bottom.offsetTop;
      if (scrollTop >= bottomTop - 220) {
        this.isBottom = false;
      } else {
        this.isBottom = true;
      }
    },
    resizeEvent() {
      this.imgHeight = ((document.body.clientWidth * 0.86 - 20) * 0.5 - 8) / 0.8;
      this.imgMinHeight = (document.body.clientWidth * 0.86 * 0.5 - 50) / 5 / 0.8;
    },
    documentHidden() {
      if(document.hidden) {
        if(this.timeObj.timer) {
          clearTimeout(this.timeObj.timer);
        }
      }else {
        this.getInfo();
      }
    },
    //打开查看器
    onPreview() {
      let index = this.$refs.carousel.activeIndex;
      let tempImgList = [];
      let waresListImgDtos = this.info.waresListImgDtos;
      //如果是图片 预览大图
      if(waresListImgDtos[index].type == 1) {
          for(let i=0; i < waresListImgDtos.length; i++) {
            if(waresListImgDtos[i].type == 1) {
                tempImgList.push(waresListImgDtos[i].url);
            }
          }
          if(tempImgList.length > 0) {
            this.showViewer = true;
          }
          let temp = [];
          for (let i = 0; i < index; i++) {
            temp.push(tempImgList.shift());
          }
          this.viewerImgList = tempImgList.concat(temp);
      }
      //如果是视频  全屏播放
      else if(waresListImgDtos[index].type == 2) {
        this.videoFullUrl = waresListImgDtos[index].url;
      }
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
  },
  created() {
    this.date = new Date();
    this.imgHeight = ((document.body.clientWidth * 0.86 - 20) * 0.5 - 8) / 0.8;
    this.imgMinHeight = (document.body.clientWidth * 0.86 * 0.5 - 50) / 5 / 0.8;
    this.getInfo();
  },
  mounted() {
    // 监听窗口变化，使得轮播图高度自适应图片高度
    window.addEventListener("resize", this.resizeEvent, false);
    //滚动事件
    window.addEventListener("scroll", this.scrollEvent, false);
    //页面不可见时
    document.addEventListener("visibilitychange", this.documentHidden, false);
  },
  destroyed() {
    this.utils.logEvent("ProductInfoPageViewEvent", {
      time: new Date() - this.date,
    });
    //解绑事件
    window.removeEventListener("scroll", this.scrollEvent, false);
    window.removeEventListener("resize", this.resizeEvent, false);
    document.removeEventListener("visibilitychange", this.documentHidden, false);
  },
  beforeRouteLeave: (to, from, next) => {
    if (to.path === "/confirmOrder") {
      next(false);
    } else {
      next((vm) => {
        if(vm.timeObj.timer) {
          clearTimeout(vm.timeObj.timer);
        }
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
};
</script>

<style lang="less">
.product_info {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0.5;
  video {
    width: 100%;
    display: block;
    object-fit: cover;
    margin-bottom: 30px;
  }
  .checkout-btn {
    background-color: #000000 !important;
    border: none !important;
    padding: 0 !important;
    height: 25px !important;
    line-height: 25px !important;
    width: 160px !important;
  }
  .checkout-btn:disabled {
    background: #bcbcbc !important;
  }
  .category_btn {
    border: none;
    padding: 0 !important;
  }

  .category_btn:hover,
  .category_btn:focus {
    background: #fff !important;
    color: #000000;
  }

  .metamask_btn {
    width: 160px;
    height: 25px;
    padding: 0 18px 0 0;
    text-align: center;
    border-radius: 13px;
    border: none;
    color: #fff;
    background: #000000 url("../../assets/Frontpage/mm-logo.png") no-repeat;
    background-position: 110px 5px;
    background-size: 18px;
    cursor: pointer;
  }

  .install_metamask_btn {
    width: 160px;
    height: 25px;
    padding: 0 !important;
    text-align: center;
    border-radius: 13px;
    border: none;
    color: #fff;
    background-color: #000000;
    margin-top: 10px;
  }

  .install_metamask_btn:hover,
  .install_metamask_btn:focus,
  .metamask_btn:hover,
  .metamask_btn:focus {
    background-color: #000000;
    color: #fff;
  }

  .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*height:600px;*/
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
  }

  .metamask_dialog,
  .install_metamask_dialog {
    border-radius: 15px;
  }

  .metamask_dialog .el-dialog__body {
    padding-top: 0px !important;
  }

  .el-dialog .el-dialog__body {
    flex: 1;
    overflow: auto;
  }

  .common_btn {
    font-size: 14px;
    color: #ffffff;
    background-color: #000000;
    width: 160px;
    padding: 0px !important;
    margin-top: 10px;
    height: 25px;
  }

  .common_btn:hover,
  .common_btn:focus {
    color: #ffffff;
    background-color: #000000;
  }

  .common_btn:disabled {
    background: #bcbcbc;
    color: #fff;
  }

  .common_btn:disabled:hover,
  .common_btn:disabled:focus {
    background: #bcbcbc;
    color: #fff;
  }

  .pay_info_dialog {
    border-radius: 10px;
  }
  .pay_info_dialog video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    margin-bottom: 40px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .pay_info_dialog .el-dialog__header,
  .install_metamask_dialog .el-dialog__header {
    display: none;
  }

  .pay_info_dialog .el-dialog__body {
    padding: 0;
  }

  .pay_info_dialog .pay_row {
    display: flex;
    border-top: 1px solid #bcbcbc;
    height: 40px;
    align-items: center;
    cursor: pointer;
  }

  .pay_info_dialog .pay_row:last-child {
    border-bottom: 1px solid #bcbcbc;
  }

  .pay_info_dialog .pay_row i {
    color: #ffffff !important;
  }

  .pay_info_dialog .pay_row .active i {
    color: #ff7a00 !important;
  }

  .min_img_box {
    width: 20%;
    padding: 0 3px;
    box-sizing: border-box;
    margin-bottom: 10px;
    cursor: pointer;
  }

  /* .min_img_box:first-child,.min_img_box:nth-child(5n+1) {
      padding-left: 0 !important;
    }

    .min_img_box:nth-child(5n) {
      padding-right: 0 !important;
    } */

  /* 轮播图组件样式的修改 */
  .el-carousel__button {
    display: block;
    width: 10px !important;
    height: 10px !important;
    background-color: #ffffff;
    border: 1px solid #000000 !important;
    border-radius: 50% !important;
    outline: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: none !important;
  }
  .el-carousel__indicator--horizontal.is-active .el-carousel__button {
    background-color: #000000 !important;
  }

  .product_info_box {
    width: 100%;

    .product_info_top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      // backdrop-filter: saturate(180%) blur(30px) opacity(80%);
      border-bottom: 1px solid #000000;
    }
    .product_info_main {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      margin-top: 40px;
      padding-top: 50px;
      .main-content {
        width: 86%;
        height: 100%;
        margin: 0 auto;
        margin-bottom: 80px;
        display: flex;
        justify-content: space-between;
        .content-left {
          width: 25%;
          color: #000000;
          padding-right: 10px;
          .product-left {
            width: 20%;
            position: fixed;
            top: 85px;
            left: 7%;
            .artist-name {
              font-size: 21px;
              margin-top: 5px;
            }
            .wares-name {
              font-size: 21px;
              line-height: 22px;
            }
          }
        }
        .content-center {
          width: 50%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          .small-image-panel {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;
            margin-top: 4px;
            .small-image {
              width: 100%;
              cursor: pointer;
            }
            video,img {
              display: block;
              margin-bottom: 0 !important;
            }
          }
          .description {
            margin-bottom: 15px;
            color: #000000;
            font-size: 14px;
          }
          .nft-remark-panel {
            border-top: 0.1em solid #000000;
            border-bottom: 0.1em solid #000000;
            .nft-remark-content {
              display: flex;
              align-items: center;
              padding: 8px 0;
              cursor: pointer;
              .nft-remark-content-txt {
                flex: 1;
                font-size: 14px;
                color: #000000;
              }
            }
            .remark-display-panel {
              color: #000000;
              font-size: 14px;
              padding-bottom: 20px;
            }
          }
          .product-details-panel {
            border-bottom: 0.1em solid #000000;
            padding: 8px 0;
            .product-details-content {
              display: flex;
              align-items: center;
              cursor: pointer;
              .product-details-content-txt {
                flex: 1;
                font-size: 14px;
                color: #000000;
              }
            }
            .details-display-panel {
              color: #000000;
              font-size: 14px;
              padding: 20px 0;
            }
          }
          .metadata-panel {
            border-bottom: 0.1em solid #000000;
            .metadata-content {
              display: flex;
              align-items: center;
              padding: 8px 0;
              cursor: pointer;
              .metadata-content-txt {
                flex: 1;
                font-size: 14px;
                color: #000000;
              }
            }
            .metadata-display-panel {
              color: #000000;
              font-size: 14px;
              padding-bottom: 20px;
              .metadata-btn {
                background-color: #f4f4f4;
                color: #000000;
                height: 25px;
                line-height: 25px;
                padding: 0;
                margin: 10px 0 0 0;
                width: 45%;
                border-radius: 13px;
              }
            }
          }
          .raffle-rules-panel {
            border-bottom: 0.1em solid #000000;
            .raffle-rules-content {
              display: flex;
              align-items: center;
              padding: 8px 0;
              cursor: pointer;
              .raffle-rules-content-txt {
                flex: 1;
                font-size: 14px;
                color: #000000;
              }
            }
            .raffle-rules-display-panel {
              color: #000000;
              font-size: 14px;
              padding-bottom: 20px;
            }
          }
        }
        .content-right {
          width: 25%;
          text-align: right;
          display: flex;
          justify-content: flex-end;
          padding-left: 10px;
          .product-right {
            width: 20%;
            position: fixed;
            top: 85px;
            right: 7%;
            .product-price {
              font-size: 21px;
              color: #000000;
            }
            .btn-panel {
              font-size: 14px;
              color: #ff7a00;
              margin: 5px 0;
            }
          }
        }
      }
      .split-line {
        border-bottom: 0.1em solid #000000;
        width: 100%;
        height: 1px;
        margin-bottom: 80px;
      }
      .artist-panel {
        width: 86%;
        margin: 0 auto;
        position: relative;
        box-sizing: border-box;
        padding-bottom: 50px;
        .artist-remark {
          color: #000000;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          font-size: 14px;
        }
        .artist-name-txt {
          margin-top: 25px;
          margin-bottom: 25px;
          color: #000000;
          font-size: 14px;
        }
        .artist-intro {
          margin-bottom: 25px;
          width: 600px;
          margin: auto;
        }
        .artist-intro-txt {
          font-size: 14px;
          color: #000000;
        }
      }
    }
  }

  /* .cabinet_info_footer {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      bottom: 0px;
      z-index: 0.5;
    } */
}
</style>