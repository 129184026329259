<template>
  <div class="Manual">
    <div class="box">
      <div class="top bg-white">
        <AppNavigator/>
      </div>
      <div class="middle"></div>
      <div style="margin-top: 80px;text-align: center;font-weight: 400;color: #000000;font-size:14px;"> {{ $t("lang.manual.youGuideTo") }} </div>
      <div class="main">
        <div style="margin: 10px auto; width: 86%;">
          <div style="margin-bottom:40px;">
            <div style="font-size: 50px;font-weight: 400;color: #000000;text-align:center;">{{ $t("lang.manual.ownerShipTransfer") }}</div>
            <div style="text-align:center">
              <img
                v-for="(item,index) in pics"
                :key="index"
                :src="item.img"
                style="width: 26%;margin-top: 40px;"
                alt=""
              />
            </div>
          </div>
          <!-- <div style="width: 100%; height: 1px; border-bottom:0.1em solid #000000"></div> -->
          <div style="margin: 80px 0;text-align:center;">
            <div class="special">{{ $t("lang.manual.ownerShipTransfer") }}</div>
            <div style="margin-top:20px;line-height:24px;" class="special">{{ $t("lang.manual.ask") }}</div>
            <div class="special" style="line-height:24px;" v-html="$t('lang.manual.que')"></div>
          </div>
        </div>
      </div>
      <div style="width: 100%;background-color:#F4F4F4;padding:80px 0">
        <div style="width: 86%;margin: 0 auto;">
            <div style="display: flex; justify-content: space-between">
              <div class="left special" style="width:70%;">
                <div style="line-height:24px;">{{ $t("lang.manual.initiation") }}</div>
                <div style="margin-top:10px;margin-bottom:30px;font-weight:400;line-height:24px;" v-html="$t('lang.manual.content')"></div>
                <div v-for="(item, index) in $t('lang.manual.contentList')" :key="index" style="margin-top:20px;font-size:14px;line-height:17px;" v-html="item.list"></div>
              </div>
              <div class="right" style="text-align: right;width:30%;">
                <img
                  src="../../assets/Frontpage/5.png"
                  style="width: 80%"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div style="text-align: center;margin-top:50px;">
                <div class="special" style="line-height:24px;">{{ $t("lang.manual.information.title") }}</div>
                <div style="margin:10px 0;line-height:24px;" class="special" v-html="$t('lang.manual.information.content')"></div>
              </div>
              <div style="margin-top:40px;">
                  <div style="width: 100%;display: flex; justify-content:space-between;">
                    <div style="flex:1;display: flex;">
                      <div style="padding-top:80px;">
                          <div style="margin-bottom:50px">
                              <div class="special" style="font-size:14px;line-height:17px;" v-html="$t('lang.manual.informations.list[0].ask')"></div>
                              <div class="special" style="margin-top:10px;font-size:14px;padding-left:33px;line-height:17px;" v-html="$t('lang.manual.informations.list[0].que')"></div>
                          </div>
                          <div>
                              <div class="special" style="font-size:14px;line-height:17px;" v-html="$t('lang.manual.informations.list[1].ask')"></div>
                              <div class="special" style="margin-top:10px;font-size:14px;padding-left:32px;line-height:17px;" v-html="$t('lang.manual.informations.list[1].que')"></div>
                          </div>
                      </div>                      
                    </div>
                    <div style="flex:1;text-align:center;">
                      <img
                        src="../../assets/Frontpage/Ownership Transfer 5.png"
                        style="width: 80%;"
                        alt=""
                      />
                    </div>
                    <div style="flex:1;display: flex;align-items:center;">
                            <div>
                                <div class="special" style="font-size:14px;line-height:17px;" v-html="$t('lang.manual.informations.list[2].ask')"></div>
                                <div class="special" style="margin-top:10px;font-size:14px;padding-left:32px;line-height:17px;" v-html="$t('lang.manual.informations.list[2].que')"></div>
                            </div>
                    </div>          
                  </div>
                  <div style="display: flex; justify-content: space-between;margin-top: 50px;">
                    <div style="flex:2">
                      <img
                        src="../../assets/Frontpage/Ownership Transfer 6.png"
                        style="width: 60%;"
                        alt=""/>
                    </div>
                    <div style="flex:1;">
                      <div style="margin-top:40px;">
                            <div class="special" style="font-size:14px;line-height:17px;" v-html="$t('lang.manual.informations.list[3].ask')"></div>
                            <div class="special" style="margin-top:10px;font-size:14px;padding-left:32px;line-height:17px;" v-html="$t('lang.manual.informations.list[3].que')"></div>
                      </div>                    
                    </div>
                  </div>
                  <div style="width: 100%;display: flex; justify-content: space-between;margin-top: 40px;">
                    <div style="flex:1;padding-top:80px;">
                        <div class="special" style="font-size:14px;line-height:17px;" v-html="$t('lang.manual.informations.list[4].ask')"></div>
                        <div class="special" style="margin-top:10px;font-size:14px;padding-left:32px;line-height:17px;" v-html="$t('lang.manual.informations.list[4].que')"></div>
                    </div>
                    <div style="flex:1;display: flex; justify-content: space-between;">
                      <div style="position: relative;width: 100%;text-align: right;">
                        <img
                          src="../../assets/Frontpage/Ownership Transfer 8.png"
                          style="width:50%;" alt=""/>
                        <img
                          src="../../assets/Frontpage/Ownership Transfer 7.png"
                          style="width:50%;position: absolute;top:20%;right: 35%;"
                          alt=""/>
                      </div>
                        <!--  <div style="width:30%;">
                            <img
                              src="../../assets/Frontpage/Ownership Transfer 8.png"
                              style="width: 30%;display:block"
                              alt=""
                            />
                          </div>
                          -->
                    </div>
                  </div>
                  <div style="display: flex; justify-content: space-between;margin-top: 160px;">
                    <div style="flex:2;">
                      <img
                        src="../../assets/Frontpage/Ownership Transfer 9.png"
                        style="width: 60%; display: block"
                        alt=""
                      />
                    </div>
                    <div style="flex:1;">
                      <div style="margin-top:50px;">
                          <div class="special" style="font-size:14px;line-height:17px;" v-html="$t('lang.manual.informations.list[5].ask')"></div>
                          <div class="special" style="margin-top:10px;font-size:14px;padding-left:32px;line-height:17px;" v-html="$t('lang.manual.informations.list[5].que')"></div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>       
      </div>
      <div style="width: 100%;">
        <div style="width: 86%;margin: 0 auto;">
              <div style=" margin-top: 80px;text-align:center;margin-bottom:40px;">
                <div class="special" style="line-height:24px;" v-html="$t('lang.manual.transfer.title')"></div>
                <div style="margin: 20px 0;line-height:24px;" class="special" v-html="$t('lang.manual.transfer.content')"></div>
              </div>
              <div style="margin-top:80px;">
                <div>
                  <div style="display: flex; justify-content: space-between">
                    <div style="width: 60%;">
                      <div class="special" style="font-size:14px;line-height:17px;" v-html="$t('lang.manual.transfers.list[0].ask')"></div>
                      <div class="special" style="margin-top:10px;font-size:14px;padding-left:32px;line-height:17px;" v-html="$t('lang.manual.transfers.list[0].que')"></div>
                    </div>
                    <div style="width:40%;text-align:right">
                      <img
                        src="../../assets/Frontpage/Ownership Transfer 9.png"
                        style="width: 100%"
                        alt=""/>
                    </div>
                  </div>
                  <div style="display: flex; justify-content: space-between;margin-bottom:80px;">
                    <div style="flex: 2;">
                      <div style="width: 100%;position: relative;">
                        <img
                            src="../../assets/Frontpage/Ownership Transfer 8.png"
                            style="width:40%;" alt=""/>
                          <img
                            src="../../assets/Frontpage/Ownership Transfer 7.png"
                            style="width:40%;position: absolute;top:20%;left: 25%;"
                            alt=""/>
                      </div>
                    </div>
                    <div style="margin-top:140px;flex: 1;">
                      <div class="special" style="font-size:14px;line-height:17px;" v-html="$t('lang.manual.transfers.list[1].ask')"></div>
                      <div class="special" style="margin-top:10px;font-size:14px;padding-left:32px;line-height:17px;" v-html="$t('lang.manual.transfers.list[1].que')"></div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>
      <div style="width: 100%;height:200px;background-color:#000000;margin-top: 250px;">
        <div style=" box-sizing: border-box;padding-top: 60px;text-align:center;font-weight: 400;color: #FFFFFF;font-size:21px;line-height:24px;">{{ $t('lang.manual.help') }}</div>
        <div style=" box-sizing: border-box;margin-top:20px;display: flex; justify-content: center">
          <div>
            <el-button
              @click="goToFaq"
              round
              style="color: #000000;"
            >{{ $t('lang.footer.faq.title') }}</el-button>
          </div>
          <div style="margin-left: 20px;">
            <el-button
              @click="goToSupport"
              round
              style="font-family: NeueHaasUnicaW1G-Regular, NeueHaasUnicaW1G;font-weight: 400;color: #000000;"
            >{{ $t('lang.footer.support.menus') }}</el-button>
          </div>
        </div>
      </div>
      <div class="footer">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
export default {
  name: "Manual",
  components: {
    AppNavigator,
    AppFooter,
  },
  data() {
    return {
      pics: [
        { img: require("../../assets/Frontpage/Ownership Transfer 1.png") },
        { img: require("../../assets/Frontpage/Ownership Transfer 2.png") },
        { img: require("../../assets/Frontpage/Ownership Transfer 3.png") },
      ],
    };
  },
  methods: {
    goToFaq() {
      this.$router.push("/faq");
    },
    goToSupport() {
      this.$router.push("/support");
    },
    goToWeb(index) {
      switch (index) {
        case 0:
          this.$router.push("/privacy");
          break;
        case 1:
          this.$router.push("/faq");
          break;
        case 2:
          //需要安装邮件客户端软件为前提
          location = "mailto:sample@jsphp.net?subject=test&cc=sample@hotmail.com&subject=主题&body=内容";
          break;
        default:
      }
    }
  },
  mounted() {
  }
};
</script>

<style lang="less" scoped>
.Manual {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: Helvetica;
  color: #000000;
  .special {
    font-family: "NeueHaasUnicaW1G-Regular", "NeueHaasUnicaW1G";
    font-weight: 400;
    font-size: 21px;
    color: #000000;
  }
  .box {
    .setFont {
      font-size: 24px;
      text-align: center;
    }

    .el-button--info {
      background-color: #f4f4f4 !important;
      font-family: "NeueHaasUnicaW1G-Regular, NeueHaasUnicaW1G";
      font-weight: 400;
      color: #000000;
      border: none !important;
      width: 160px !important;
    }
    .el-button {
      background-color: #f4f4f4 !important;
      border: none !important;
      width: 140px !important;
      padding: 0 !important;
      height: 25px;
      line-height: 25px;
      font-size: 14px;
    }

    .top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      // backdrop-filter: saturate(180%) blur(30px) opacity(80%);
      border-bottom: 1px solid #000000;
    }
    .middle {
      height: 41px;
      width: 100%;
      background-color: #ffffff;
    }
    .main {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
    .footer {
      width: 100%;
      box-sizing: border-box;
      // position: fixed;
      bottom: 0px;
    }
  }
}
</style>
