<template>
  <div></div>
</template>
<script>
export default {
  name: "links",
  data() {
    return {
    };
  },
  methods: {
  },
  created() {
    // if(this.$router.currentRoute.fullPath == '/nft') {
    //   window.location.href = 'https://mailchi.mp/zhen/sorayamafirstnft';
    // }else if(this.$router.currentRoute.fullPath == '/links') {
      window.location.href = 'https://campsite.bio/zheneration';
    // }
  }
};
</script>


</style>