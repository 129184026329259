<template>
  <div class="appFooter">
    <div class="footerBox">
      <div style="margin: 0 auto">
        <div style="width: 86%;margin:0 auto;padding-bottom:14px;">
          <div class="box" style="margin-top:15px;">
            <div class="boxx">
              <div v-for="(item, index) in list" :key="index" class="setColor2">
                <el-button type="text" mini="mini" :class="index==5?'download_app':''" @click="goToWeb(index)">{{ item.title }}</el-button>
              </div>
            </div>
            <div class="boxx" style="flex:1">
              <el-button type="text" mini="mini" class="setColor2" @click="goToPP">{{ $t("lang.footer.privacyPolicy.title") }}</el-button>
            </div>
            <div class="boxx" style="flex:1">
              <el-button type="text" mini="mini" class="setColor2" @click="goToTC">
                {{ $t("lang.footer.tc.title") }}
              </el-button>  
            </div>
            <div class="boxx" style="flex:1">
              <el-button type="text" mini="mini" class="setColor2" @click="goToWa">
                {{ $t("lang.footer.warranty.title") }}
              </el-button>  
            </div>
          </div>
        </div>
        <div>
          <div style="
              height: 0px;
              border: none;
              border-bottom: 1px solid #000000;
              width: 100%;
              box-sizing: border-box;
            "></div>
          <div class="link" style="height: 40px;line-height: 40px;width: 86%;margin:0 auto;">
            <div style="font-size:14px;font-weight:400;"><span style="font-weight:600;">ZHEN.</span> © 2022</div>
            <div>
              <span v-for="(item,suoyin) in icons" :key="suoyin" style="padding-right: 20px" @click="goToLink(suoyin)">
               <a href="javascript:void(0);" style="text-decoration: none;outline: 0 !important;color:#000000;"><i class="icon iconfont" :class="item"></i> </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "appFooter",
    data() {
      return {
        list: [
          { title: this.$t("lang.footer.legal") },
          { title: this.$t("lang.footer.support.menus") },
          { title: this.$t("lang.footer.faq.title") },
          { title: this.$t("lang.footer.contactUs") },
          { title: this.$t("lang.navigator.markPlace") },
          { title: this.$t("lang.footer.downloadApp") },
        ],
        icons: [
          "icon-Instagramlogo",
          "icon-twitter1",
          "icon-lianshu",
          "icon-shejiaotubiao-14",
          "icon-Discord"
        ]
      };
    },
    methods: {
      goToWeb(index) {
        switch (index) {
          case 0:
            this.$router.push("/privacy");
            break;
          case 1:
            this.$router.push("/support");
            break;
          case 2:
            this.$router.push("/faq");
            break;
          case 3:
            //需要安装邮件客户端软件为前提
            location="mailto:partnerships@zhen.io?subject=ZHEN. Contact Request";
            break;
          case 4:
            this.$router.push("/marketPlace");
            break;
          case 5:
            this.$router.push("/app");
            break;
          default:
            
        }
      },
      goToLink(index){
        switch (index) {
          case 0:
            window.open("https://www.instagram.com/zheneration/");     ;
            break;
          case 1: 
            window.open("https://twitter.com/zheneration");
            break;
          case 2:
            window.open("https://www.facebook.com/zheneration/");
            break;
          case 3:
            window.open("https://www.linkedin.com/company/zheneration/");
            break;
          case 4:
            window.open("https://discord.com/invite/rWBswuwB3U");
            break;
          default:
            
        }
      },
      //跳转到条件条款组件
      goToTC() {
        this.$router.push("/terms");
      },
      //跳转到条隐私组件
      goToPP() {
        this.$router.push("/privacy");
      },
      goToWa() {
        this.$router.push("/warranty");
      }
    },
  };
</script>
<style lang="less">
  .appFooter {
    width: 100%;
    height: 165px;
    padding-top: 10px;
    background-color: #DEDEDE;
    opacity: 1;
    box-sizing: border-box;
    .iconfont:hover {
      opacity: 0.6;
    }
    .footerBox {
      color: #333;
      box-sizing: border-box;
     
      .el-button,
      .el-button--text {
        border: none !important;
        padding: 0px !important;
        display: inline-block;
        height: 20px;
        line-height: 20px;
      }

      .box .setColor2 button {
        color: black;
        font-weight: 400;
      }
      // .box .setColor2:nth-child(5) {
      //   margin-left: 15%;
      // }

      .box {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .boxx {
          line-height:18px !important;
          flex: 1;
          display: flex;
          justify-content: space-between;
          button {
            font-size: 14px;
            color: #000000 !important;
            opacity: 1;
            width: auto;
          }
          button.download_app {
            background-color: #fff;
            border-radius: 13px;
            width: 150px;
            height: 25px !important;
          }
          button:hover {
            color: #000000 !important;
            opacity: 0.6;
          }
          .download_app:hover {
            color: #fff !important;
            background:#000000;
          }
        }
      }

      .common {
        text-align: left;
        font-size: 14px;
      }

      .link {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
</style>