<template>
  <div class="checkOut">
    <div class="checkOut_box">
      <div
        class="checkOut_top bg-white"
        style="border-bottom: 1px solid #000000"
      >
        <AppNavigator />
      </div>
      <div style="width: 100%; height: 80px; margin-top: 40px"></div>
      <div class="checkOut_main">
        <div
          style="
            width: 86%;
            height: 100%;
            margin: 0 auto;
            margin-bottom: 80px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div
            style="
              width: 40%;
              font-size: 21px;
              font-family: Helvetica;
              color: #000000;
            "
          >
            {{ $t("lang.pay.checkOut") }}
          </div>
          <div style="width: 60%; box-sizing: border-box">
            <div style="display: flex; font-weight: 400">
              <div style="color: #000000">
                {{ $t("lang.pay.product") }}
              </div>
              <div
                style="color: #bcbcbc; margin-left: 200px"
                v-if="info.category != 3"
              >
                {{ $t("lang.pay.shipping") }}
              </div>
              <div
                style="color: #bcbcbc; flex: 1; margin-left: 200px"
                v-if="info.category != 3"
              >
                {{ $t("lang.pay.payMent") }}
              </div>
              <div
                style="color: #bcbcbc; flex: 1; margin-left: 200px"
                v-if="info.category == 3"
              >
                {{ $t("lang.pay.payMent1") }}
              </div>
            </div>
            <div style="margin: 25px 0; color: #000000">
              {{ $t("lang.pay.productOverView") }}
            </div>
            <div
              style="
                margin-bottom: 25px;
                display: flex;
                justify-content: space-between;
              "
            >
              <div style="display: flex">
                <div style="width: 25%">
                  <div class="iconBox" v-if="!info.videoUrl">
                    <img
                      :src="info.mainImg"
                      alt=""
                      style="width: 100%"
                    />                    
                    <div class="iconClass" v-if="false">
                                                <img
                        v-if="info.nftChain == 1"
                        src="../..//assets/Frontpage/ETH.png"
                      />
                                                <img
                        v-else-if="info.nftChain == 2"
                        src="../../assets/Frontpage/matic.png"
                      />                 
                    </div>
                                       
                  </div>
                      
                  <div class="iconBox" v-if="info.videoUrl">
                    <video :src="info.videoUrl" muted autoplay loop="loop">
                      您的浏览器不支持视频播放。
                    </video>
                    <div class="iconClass"  v-if="false">
                                                <img
                        v-if="info.nftChain == 1"
                        src="../..//assets/Frontpage/ETH.png"
                      />
                                                <img
                        v-else-if="info.nftChain == 2"
                        src="../../assets/Frontpage/matic.png"
                      />
                    </div>
                                
                  </div>
                </div>
                <div style="margin-left: 50px; color: #000000">
                  <div>{{ info.artistName }}</div>
                  <div>{{ info.waresName }}</div>
                </div>
                <div style="margin-left: 96px; color: #000000">
                  <div>x1</div>
                </div>
                <div
                  style="
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    text-align: right;
                  "
                >
                  <div style="color: #000000">
                    {{ info.price }}
                  </div>
                  <div style="color: #bcbcbc">
                    {{ usdPrice }}
                  </div>
                </div>
              </div>
            </div>
            <div
              style="
                display: flex;
                justify-content: space-between;
                padding: 15px 0;
                border-top: 2px solid #000000;
              "
            >
              <div style="color: #000000">
                {{ $t("lang.pay.subTotal") }}
              </div>
              <div style="color: #000000">
                {{ info.price }}
              </div>
            </div>
            <div
              v-if="info.category != 3"
              style="
                margin-bottom: 25px;
                font-size: 11px;
                font-family: Helvetica;
                color: #bcbcbc;">
              {{ $t("lang.pay.content") }}
            </div>
            <div style="text-align: right; color: #000000">
              <span @click="goToShipping()" style="cursor: pointer">
                {{ $t("lang.pay.next") }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="checkOut_footer">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
export default {
  name: "CheckOut",
  components: {
    AppNavigator,
    AppFooter,
  },
  data() {
    return {
      info: {},
      usdPrice: "",
    };
  },
  methods: {
    goToShipping() {
      if (this.info.category == 3) {
        this.$router.push({ path: "/confirmOrder" });
      } else {
        this.$router.push({ path: "/shipping" });
      }
    },
  },
  created() {
    let info = sessionStorage.getItem("productInfo");
    if (info) {
      this.info = this.qs.parse(info);
    }
    //eth换算
    this.$http
      .post("order/getEthPrice", {
        price: this.info.price,
        token: this.utils.getToken(),
      })
      .then((res) => {
        if (res.code === 0) {
          this.usdPrice = res.data;
        }
      });
  },
};
</script>

<style lang="less" scoped>
.checkOut {
  width: 100%;
  height: 100%;
  font-size: 14px;
  .checkOut_box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .checkOut_top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      // backdrop-filter: saturate(180%) blur(20px);
    }

    .checkOut_main {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      margin: 0 auto;
      margin-bottom: 200px;
      video {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
      .checkOut_content {
        width: 100%;
        height: 400px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }
    }

    .checkOut_footer {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      bottom: 0px;
    }
  }
}
</style>