<template>
  <div class="warranty">
    <div class="box">
      <div class="top bg-white">
        <AppNavigator />
      </div>
      <div class="main">
        <div class="left_content">
          <div style="position: fixed; top: 8rem; left: 7%">
            <div class="left_title">{{ $t("lang.footer.legal") }}</div>
            <div class="left_title2" @click="gotoPolicy">
              <el-button
                type="text"
                mini="mini"
                style="font-size: 16px; opacity: 1; padding: 0 !important"
              >
                {{ $t("lang.footer.privacyPolicy.title") }}
              </el-button>
            </div>
            <div class="left_title3" @click="gotoTerms">
              <el-button
                type="text"
                mini="mini"
                style="font-size: 16px; opacity: 1; padding: 0 !important"
              >
                {{ $t("lang.footer.termsAndConditions") }}
              </el-button>
            </div>
            <div class="left_title4">
              <el-button
                type="text"
                mini="mini"
                style="font-size: 16px; opacity: 1; padding: 0 !important"
                class="active"
              >
                {{ $t("lang.footer.warranty.title") }}
              </el-button>
            </div>
          </div>
        </div>
        <div class="right_content">
          <WarranntyEn v-if="lang == 'en'"></WarranntyEn>
          <WarranntyCn v-if="lang == 'cn'"></WarranntyCn>
          <WarranntyTw v-if="lang == 'tw'"></WarranntyTw>
        </div>
      </div>
      <div class="footer">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
import WarranntyEn from "@/components/legal/warranntyEn.vue";
import WarranntyCn from "@/components/legal/warranntyCn.vue";
import WarranntyTw from "@/components/legal/warranntyTw.vue";
export default {
  name: "warranty",
  components: {
    AppNavigator,
    AppFooter,
    WarranntyEn,
    WarranntyCn,
    WarranntyTw,
  },
  data() {
    return {
      lang: "",
    };
  },
  methods: {
    gotoPolicy() {
      this.$router.push("/privacy");
    },
    gotoTerms() {
      this.$router.push("/terms");
    }
  },
  created() {
    const lang = localStorage.getItem("lang") || "en";
    this.lang = lang;
  },
};
</script>
<style lang="less" scoped>
.warranty {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: #000000;
  .box {
    height: 100%;
    .setColor {
      color: #000000;
    }
    .active {
      font-weight: bold !important;
      color: #000000 !important;
    }
    .top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      color: #000000;
      // backdrop-filter: saturate(180%) blur(30px) opacity(80%);
      border-bottom: 1px solid #000000;
    }
    .main {
      width: 86%;
      height: 100%;
      box-sizing: border-box;
      margin: 0 auto;
      margin-top: 8rem;
      display: flex;
      justify-content: space-between;
      .left_content {
        flex: 1;
        width: 70%;
        margin: 0 auto;
        .el-button--text {
          color: #bcbcbc;
        }
        .left_title {
          font-family: Helvetica;
          color: #000000;
          font-size: 28px;
        }
        .left_title2 {
          font-family: Helvetica;
          color: #bcbcbc;
          margin-top: 40px;
          margin-bottom: 10px;
        }
        .left_title3 {
          font-family: Helvetica;
          color: #bcbcbc;
        }
        .left_title4 {
          font-family: Helvetica;
          color: #bcbcbc;
          margin-top: 10px;
        }
      }
      .right_content {
        flex: 2;
        padding-bottom: 100px;
      }
    }
    .footer {
      width: 100%;
      box-sizing: border-box;
      //position: fixed;
      bottom: 0px;
    }
  }
}
</style>