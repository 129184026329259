<template>
  <div class="dashboard">
    <div class="dashboard_box">
      <div class="dashboard_top bg-white">
        <AppNavigator />
      </div>
      <div class="dashboard_sec bg-white">
        <SecondNavigator activeIndex="1" />
      </div>
      <div class="dashboard_main">
        <div class="dashboard_content">
          <div class="content-left">
            <div class="left-fixed">
              <div class="title">{{ $t("lang.dashboard.title").toUpperCase() }}</div>
              <div class="left-menu">
                <div v-for="(str,index) in menuList" :key="index" :class="index==activeIndex?'active':''" @click="tabClick(index)">{{str}}</div>
              </div>
            </div>
          </div>
          <div class="content-right">
            <router-view v-if="isRouteAlive"/>
              <!-- <div v-show="activeIndex == 0"><OrdersHistory></OrdersHistory></div>
              <div v-show="activeIndex == 1"><OwnershipHistory></OwnershipHistory></div>
              <div v-show="activeIndex == 2"><WithdrawHistory></WithdrawHistory></div>
              <div v-show="activeIndex == 3"><DepositHistory></DepositHistory></div> -->
          </div>
        </div>
        <div class="dashboard_footer" v-show="isShow">
          <AppFooter />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
import SecondNavigator from "@/components/common/SecondNavigator.vue";
export default {
  name: "Dashboard",
  components: {
    AppNavigator,
    AppFooter,
    SecondNavigator,
  },
  data() {
    return {
      menuList:[
        this.$t("lang.dashboard.orders"),
        this.$t("lang.dashboard.transfer"),
        this.$t("lang.dashboard.withdraw.title"),
        this.$t("lang.dashboard.deposit.title"),
      ],
      activeIndex:0,
      isShow:false,
      isRouteAlive:true
    };
  },
  methods: {
    tabClick(index) {
      switch(index) {
        case 0:
          this.$router.push({
            path: "/orders"
          });
          break;
        case 1:
          this.$router.push({
            path: "/ownershipTransfer"
          });
          break;
        case 2:
          this.$router.push({
            path: "/withdraw"
          });
          break;
        case 3:
          this.$router.push({
            path: "/deposit"
          });
          break;
        default:
          this.$router.push({
            path: "/notFind"
          });
      }
    },
    initFooter() {
      this.isShow = false;
      let _this = this;
      setTimeout(()=> {
        _this.isShow = true;
      },500)
    },
    reload() {
      this.isRouteAlive = false;
      this.$nextTick(function() {
        this.isRouteAlive = true;
      });
    }
  },
  created() {
  },
  mounted() {
    // this.initPage();
  }
};
</script>
<style lang="less">
.dashboard {
  width: 100%;
  color: #000000 !important;
  font-size: 14px;
  .dashboard_box {
    width: 100%;
    .dashboard_top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
    }
    .dashboard_sec {
      width: 100%;
      position: fixed;
      top: 41.5px;
      left: 0;
      z-index: 999;
      box-sizing: border-box;
    }
    .dashboard_main {
      width: 100%;
      box-sizing: border-box;
      padding-top: 150px;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      .dashboard_content {
        width: 86%;
        min-height: calc(100% - 165px);
        padding-bottom: 50px;
        box-sizing: border-box;
        text-align: center;
        margin: 0 auto;
        display: flex;
        .content-left {
          width: 30%;
          min-height: calc(100% - 165px);
          text-align: left;
          padding-right: 50px;
          .left-fixed {
            width:calc(calc(30% * 0.86) - 20px);
            position:fixed;
            top:150px;
          }
          .title {
            font-size: 21px;
          }
          .left-menu {
            margin-top:20px;
            border-bottom:1.5px solid #000000;
            div {
              height: 40px;
              line-height: 40px;
              border-top: 1.5px solid #000000;
              cursor: pointer;
            }
            div.active {
              font-weight: bold;
            }
          }
        }
        .content-right {
          width: 70%;
          min-height: calc(100% - 165px);
          text-align: left;
          padding-top: 52px;
        }
      }
    }
    // .dashboard_footer.fixed {
    //   width: 100%;
    //   position: fixed;
    //   left:0;
    //   bottom: 0;
    // }
  }
}
</style>

