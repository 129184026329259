import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //token
    credential: {
      accessToken: ''
    },
    lang: null,
    walletAddr: '',
    msg: 'Connect Wallet',
    notLoginDialog: false,
    showMarketplace:true,
  },
  mutations: {

    //设置语言
    setLang(state, lang) {
      if (state.lang == null) {
        localStorage.setItem('lang', lang);
        state.lang = lang;
      }
    },

    //设置用户地址
    setWalletAddr(state, addr) {
      localStorage.setItem('from', addr);
      state.walletAddr = addr;
    },

    //获取用户地址
    getWalletAddr(state) {
      if (localStorage.getItem('credential') != null) {
        state.walletAddr = JSON.parse(localStorage.getItem('from'));
      }
    },

    //获取token
    getCredential(state) {
      if (localStorage.getItem('credential') != null) {
        state.credential = JSON.parse(localStorage.getItem('credential'));
      }
    },

    //设置token
    setCredential(state, credential) {
      state.credential = credential;
      localStorage.setItem('credential', JSON.stringify(credential));
    },

    //清除Token
    logOut(state) {
      state.credential = { accessToken: '' }
      localStorage.removeItem('credential')
    }
  },
  actions: {
  },
  modules: {
  }
})
