<template>
  <div class="not_find_panel">
      <div class="not_find_top bg-white">
        <AppNavigator/>
      </div>
      <div class="not_find_main">
          <div class="not_find_title">404：{{$t('lang.notFind.title')}}</div>
          <div class="content" style="margin-top:20px;">{{$t('lang.notFind.content1')}}</div>
          <div class="content">{{$t('lang.notFind.content2')}}</div>
          <div style="margin-top: 20px;">
            <span class="back" @click="back();">{{$t('lang.pay.back')}}</span>
          </div>
      </div>
      <div class="not_find_footer">
        <AppFooter />
      </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
export default {
  name: "NotFind",
  components: {
    AppNavigator,
    AppFooter
  },
  data() {
    return {
    };
  },
  methods: {
    back() {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="less" scoped>
.not_find_panel {
  width: 100%;
  height: 100%;
  .not_find_top {
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    top: 0px;
    z-index: 999;
    // backdrop-filter: saturate(180%) blur(20px);
    border-bottom: 1px solid #000000;
  }
  .not_find_main {
    color:#000000;
    text-align: center;
    .not_find_title {
      font-size:50px;
      font-weight:400;
      margin-top: 120px;
    }
    .content {
      font-size:21px;
      line-height: 24px;
    }
    .back {
      cursor: pointer;
      color:#BCBCBC;
      font-size:21px;
    }
    .back:hover {
      color: #000000;
    }
  }
  .not_find_footer {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      bottom: 0px;
    }
  
}
</style>