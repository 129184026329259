<template>
  <div class="shipping">
    <div class="shipping_box">
      <div
        class="shipping_top bg-white"
        style="border-bottom: 1px solid #000000"
      >
        <AppNavigator />
      </div>
      <div class="shipping_main" style="margin-top: 120px">
        <div
          style="width: 86%; margin: 0 auto; margin-bottom: 80px; display: flex"
        >
          <div style="width: 35%; font-size: 21px; color: #000000">
            {{ $t("lang.pay.checkOut") }}
          </div>
          <div
            style="
              flex: 1;
              width: 100%;
              box-sizing: border-box;
              font-size: 14px;
            "
          >
            <div style="display: flex; font-weight: 400; padding-bottom: 30px">
              <div style="flex: 1; display: flex">
                <div style="color: #000000; flex: 1">
                  {{ $t("lang.pay.product") }}
                </div>
                <div
                  style="
                    color: #000000;
                    margin-left: 200px;
                    flex: 1;
                    margin-left: 50px;
                  "
                >
                  {{ $t("lang.pay.shipping") }}
                </div>
              </div>
              <div style="color: #bcbcbc; flex: 1; margin-left: 50px">
                {{ $t("lang.pay.payMent") }}
              </div>
            </div>
            <el-form :model="formData">
              <div style="display: flex; color: #000000">
                <div style="flex: 1; display: flex">
                  <div style="flex: 1">
                    <div>{{ $t("lang.pay.firstName") }} *</div>
                    <el-form-item>
                      <div style="margin-top: 5px">
                        <el-input
                          type="text"
                          v-model="formData.firstName"
                          autocomplete="off"
                          :placeholder="$t('lang.pay.firstNameHolder')"
                          maxlength="64"
                        />
                        <div class="el-form-item__error">
                          {{ firstNameError }}
                        </div>
                      </div>
                    </el-form-item>
                  </div>
                  <div style="flex: 1; margin-left: 50px">
                    <div>{{ $t("lang.pay.lastName") }} *</div>
                    <el-form-item>
                      <div style="margin-top: 5px">
                        <el-input
                          type="text"
                          v-model="formData.lastName"
                          autocomplete="off"
                          :placeholder="$t('lang.pay.lastNameHolder')"
                          maxlength="64"
                        />
                        <div class="el-form-item__error">
                          {{ lastNameError }}
                        </div>
                      </div>
                    </el-form-item>
                  </div>
                </div>
                <div style="flex: 1; margin-left: 50px">
                  <div>{{ $t("lang.pay.address") }} *</div>
                  <el-form-item>
                    <div style="margin-top: 5px">
                      <el-input
                        type="text"
                        v-model="formData.addressOne"
                        :placeholder="$t('lang.pay.addressHolder')"
                        autocomplete="off"
                        maxlength="256"
                      />
                      <div class="el-form-item__error">
                        {{ addressOneError }}
                      </div>
                    </div>
                  </el-form-item>
                </div>
              </div>
              <div style="display: flex; color: #000000">
                <div style="flex: 1">
                  <div>{{ $t("lang.pay.phoneNumber") }} *</div>
                  <div
                    style="display: flex; align-items: center; margin-top: 5px"
                  >
                    <div style="color: #000000; padding-right: 5px">+</div>
                    <div style="width: 60px; margin-right: 20px">
                      <AreaSelect :disabled="true" :selectedId="formData.stateId"  @countryChange="countryChange($event)"/>
                    </div>
                    <div style="flex: 1">
                      <el-input
                        type="text"
                        v-model="formData.phone"
                        autocomplete="off"
                        :placeholder="$t('lang.pay.phoneHolder')"
                        maxlength="15"
                      />
                    </div>
                  </div>
                  <div class="el-form-item__error">{{ phoneError }}</div>
                </div>
                <div style="flex: 1; display: flex; margin-left: 50px">
                  <div style="flex: 1">
                    <div>{{ $t("lang.pay.select1") }} *</div>
                    <el-form-item>
                      <div style="margin-top: 5px">
                        <el-input
                          type="text"
                          v-model="formData.stateOrProvince"
                          autocomplete="off"
                          :placeholder="$t('lang.pay.stateHolder')"
                          maxlength="128"
                        />
                        <div class="el-form-item__error">
                          {{ stateOrProvinceError }}
                        </div>
                      </div>
                    </el-form-item>
                  </div>
                  <div style="flex: 1; margin-left: 50px">
                    <div>{{ $t("lang.pay.city") }} *</div>
                    <el-form-item>
                      <div style="margin-top: 5px">
                        <el-input
                          type="text"
                          v-model="formData.city"
                          autocomplete="off"
                          :placeholder="$t('lang.pay.cityHolder')"
                          maxlength="128"
                        />
                        <div class="el-form-item__error">{{ cityError }}</div>
                      </div>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div style="display: flex; color: #000000">
                <div style="flex: 1">
                  <div>
                    {{ $t("lang.pay.email") }}
                    <span
                      v-show="formData.countryId && formData.countryId != 39"
                      >*</span
                    >
                  </div>
                  <el-form-item>
                    <div style="margin-top: 5px">
                      <el-input
                        type="text"
                        v-model="formData.email"
                        autocomplete="off"
                        :placeholder="$t('lang.pay.emailHolder')"
                        maxlength="128"
                      />
                      <div class="el-form-item__error">{{ emailError }}</div>
                    </div>
                  </el-form-item>
                </div>
                <div style="flex: 1; display: flex; margin-left: 50px">
                  <div style="flex: 1">
                    <div>
                      {{ $t("lang.pay.select2") }}
                      <span
                        v-show="formData.countryId && formData.countryId != 39"
                        >*</span
                      >
                    </div>
                    <el-form-item>
                      <div style="margin-top: 5px">
                        <el-input
                          type="text"
                          v-model="formData.postalCode"
                          autocomplete="off"
                          :placeholder="$t('lang.pay.codeHolder')"
                          maxlength="32"
                        />
                        <div class="el-form-item__error">
                          {{ postalCodeError }}
                        </div>
                      </div>
                    </el-form-item>
                  </div>
                  <div style="flex: 1; margin-left: 50px">
                    <div>{{ $t("lang.pay.country") }} *</div>
                    <el-form-item>
                      <div style="margin-top: 5px">
                        <el-select class="country-select" v-model="formData.countryId" filterable placeholder="" :no-match-text="$t('lang.other.noAreaText')" @change="countryChange1">
                            <el-option v-for="item in countryList1"
                              :key="item.id"
                              :label="item.countryName"
                              :value="item.id">
                              <span>{{ item.countryName }}</span>
                            </el-option>
                        </el-select>
                        <div class="el-form-item__error"></div>
                      </div>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </el-form>
            <div style="display: flex; color: #000000">
              <div style="flex: 1">
                <span @click="back()" style="cursor: pointer"
                  >&lt; {{ $t("lang.pay.back") }}</span
                >
              </div>
              <div style="flex: 1; text-align: right">
                <span
                  @click="next()"
                  :class="complete ? 'complete' : 'not-complete'"
                  >{{ $t("lang.pay.next2") }} &gt;</span
                >
              </div>
            </div>
            <div style="color: #000000; margin-top: 78.5px">
              *{{ $t("lang.pay.content4") }}
            </div>
          </div>
        </div>
      </div>
      <div class="shipping_footer">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
import AreaSelect from "@/components/user/AreaSelect.vue";
export default {
  name: "Shipping",
  components: {
    AppNavigator,
    AppFooter,
    AreaSelect
  },
  data() {
    return {
      info: "",
      formData: {
        firstName: "",
        lastName: "",
        addressOne: "",
        phone: "",
        stateOrProvince: "",
        city: "",
        email: "",
        postalCode: "",
        countryId: "",
        countryName: "",
        state: "",
        stateId:""
      },
      firstNameError: "",
      lastNameError: "",
      addressOneError: "",
      phoneError: "",
      emailError: "",
      stateOrProvinceError: "",
      postalCodeError: "",
      cityError: "",
      countryIdError: "",
      complete: false,
      timeout: "",
      countryList: [],
      countryList1: [],
    };
  },
  watch: {
    //监听表单内容变化 控制注册按钮是否可用
    formData: {
      handler(val, oldVal) {
        let $this = this;
        let flag = true;
        let attr = [
          "firstName",
          "lastName",
          "addressOne",
          "phone",
          "stateOrProvince",
          "city",
          "email",
          "postalCode",
          "countryId",
        ];
        if ($this.formData.countryId == 39) {
          attr = [
            "firstName",
            "lastName",
            "addressOne",
            "phone",
            "stateOrProvince",
            "city",
            "countryId",
          ];
        }
        attr.forEach(function (prop, index) {
          if (!($this.formData[prop]+"").trim()) {
            flag = false;
          }
        });
        if(!$this.formData.state) {
            flag = false; 
        }
        //如果表单都输入了值 sign up按钮变为可点。
        if (flag) {
          $this.complete = true;
        } else {
          $this.complete = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    countryChange(param) {
      this.formData.state = param.areaCode;
      this.formData.stateId = param.id;
    },
    countryChange1(param) {
      this.formData.countryName = param.countryName;
    },
    back() {
      sessionStorage.setItem("shipping", this.qs.stringify(this.formData));
      this.$router.push({ path: "/checkout" });
    },
    next() {
      //必填项没有填完不响应事件
      if (!this.complete) {
        return false;
      }
      let formData = this.formData;
      if (formData) {
        sessionStorage.setItem("shipping", this.qs.stringify(formData));
        this.$router.push({ path: "/confirmOrder" });
      }
    },
  },
  created() {
    let info = sessionStorage.getItem("productInfo");
    if (info) {
      this.info = this.qs.parse(info);
    }
    //获取商品支持的国家列表
    this.$http
      .post("deliveryAddress/countryList", {
        waresId: this.info.waresId,
        token: this.utils.getToken(),
      })
      .then((res) => {
        if (res.code === 0) {
          let list = res.data;
          this.countryList1 = list;
        }
      });

    //如果时上个页面返回 要填充页面数据
    let shipping = sessionStorage.getItem("shipping");
    if (shipping) {
      this.shipping = this.qs.parse(shipping);
      if(this.shipping.countryId) {
        this.shipping.countryId = parseInt(this.shipping.countryId);
      }
      Object.assign(this.formData, this.shipping);
    }
  },
};
</script>

<style lang="less">
.shipping {
  width: 100%;
  height: 100%;
  width: 100%;
  height: 100%;
  .complete {
    cursor: pointer;
    color: #000000;
  }
  .country-select {
    width: 100%;
  }
  .country-select input::placeholder {
    text-align: left !important;
  }
  .country-select .el-input__suffix {
    display: none;
  }
  .country-select .el-input.is-active .el-input__inner, 
  .country-select .el-input__inner:focus,
  .country-select .el-select .el-input__inner:focus {
    border-bottom-color: #000000 !important;
  }
  .not-complete {
    cursor: not-allowed;
    color: #bcbcbc;
  }
  //设置input下placeholder文字颜色
  input::placeholder {
    color: #bcbcbc !important;
    text-align: left;
    opacity: 1;
  }

  .input-search {
    width: 250px !important;
  }

  .country-search {
    width: 200px !important;
  }

  .el-input input {
    width: 100%;
    line-height: 40px;
    border: none;
    outline: medium;
    border-bottom: 0.1em solid #000000;
    opacity: 1;
    border-radius: 0;
    padding: 0 !important;
    font-size: 14px !important;
  }

  .el-input input::placeholder {
    text-align: left;
    font-size: 14px !important;
  }

  .el-input input:disabled {
    background-color: #ffffff !important;
    cursor: not-allowed;
  }

  .shipping_box {
    width: 100%;
    box-sizing: border-box;

    .shipping_top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      // backdrop-filter: saturate(180%) blur(30px);
    }

    .shipping_main {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      margin: 0 auto;
      margin-bottom: 220px;

      .shipping_content {
        width: 100%;
        height: 400px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }
    }

    .shipping_footer {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      bottom: 0px;
    }
  }
}
</style>