<template>
  <div class="confirmOrder">
    <div class="confirmOrder_box">
      <div
        class="confirmOrder_top bg-white"
        style="border-bottom: 1px solid #000000"
      >
        <AppNavigator />
      </div>
      <div class="confirmOrder_main">
        <div
          style="
            width: 86%;
            height: 100%;
            margin: 0 auto;
            margin-bottom: 80px;
            display: flex;
            justify-content: space-between;
            padding-top: 80px;
          "
        >
          <div style="width: 35%; font-size: 21px; color: #000000">
            {{ $t("lang.pay.checkOut") }}
          </div>
          <div style="flex: 1; box-sizing: border-box">
            <div style="display: flex; font-weight: 400; color: #000000">
              <div>
                {{ $t("lang.pay.product") }}
              </div>
              <div style="margin-left: 200px" v-if="info.category != 3">
                {{ $t("lang.pay.shipping") }}
              </div>
              <div
                style="flex: 1; margin-left: 200px"
                v-if="info.category != 3"
              >
                {{ $t("lang.pay.payMent") }}
              </div>
              <div
                style="flex: 1; margin-left: 200px"
                v-if="info.category == 3"
              >
                {{ $t("lang.pay.payMent1") }}
              </div>
            </div>
            <div
              style="
                display: flex;
                padding: 10px 0;
                color: #000000;
                margin-top: 10px;
              "
            >
              <div style="flex: 1; display: flex">
                <div style="flex: 1">{{ $t("lang.pay.payMethod") }} *</div>
                <div style="flex: 1; margin-left: 50px">
                  <el-radio v-model="radio" label="1" :disabled="radio == 2">
                    {{ $t("lang.pay.digitalWallet") }}</el-radio
                  >
                </div>
              </div>
              <div style="flex: 1; margin-left: 50px">
                <el-radio v-model="radio" label="2" :disabled="radio == 1">{{
                  $t("lang.pay.other")
                }}</el-radio>
              </div>
            </div>
            <div
              style="color: #000000; margin-top: 10px"
              v-if="info.category != 3"
            >
              {{ $t("lang.pay.shipTo") }}
            </div>
            <div style="margin-bottom: 25px; display: flex; margin-top: 20px">
              <div style="flex: 1; display: flex">
                <div style="flex: 1; display: flex">
                  <div style="flex: 1; color: #000000">
                    <div>{{ userName }}</div>
                    <div style="margin-top: 20px">{{ address }}</div>
                  </div>
                  <div style="flex: 1; margin-left: 50px">
                    <div class="iconBox" v-if="!info.videoUrl">
                      <img :src="info.mainImg" style="width: 60%" alt="" />
                      <div class="iconClass"  v-if="false">
                                                  <img
                          v-if="info.nftChain == 1"
                          src="../..//assets/Frontpage/ETH.png"
                        />
                                                  <img
                          v-else-if="info.nftChain == 2"
                          src="../../assets/Frontpage/matic.png"
                        />
                      </div>
                                  
                    </div>

                    <div class="iconBox" v-if="info.videoUrl">
                      <video :src="info.videoUrl" muted autoplay loop="loop">
                        您的浏览器不支持视频播放。
                      </video>
                      <div class="iconClass"  v-if="false">
                                                  <img
                          v-if="info.nftChain == 1"
                          src="../..//assets/Frontpage/ETH.png"
                        />
                                                  <img
                          v-else-if="info.nftChain == 2"
                          src="../../assets/Frontpage/matic.png"
                        />
                      </div>
                                  
                    </div>
                  </div>
                </div>
              </div>
              <div style="flex: 1; display: flex; margin-left: 50px">
                <div style="flex: 1; color: #000000">
                  <div>{{ info.artistName }}</div>
                  <div>{{ info.waresName }}</div>
                </div>
                <div style="flex: 1; text-align: right">
                  <div style="color: #000000; text-align: right">
                    {{ shipping.salePrice }}
                  </div>
                  <div style="color: #bcbcbc; text-align: right">
                    {{ shipping.ethPrice }}
                  </div>
                </div>
              </div>
            </div>
            <div style="padding-bottom: 15px">
              <div
                style="
                  width: 100%;
                  height: 1px;
                  border-bottom: 0.1em solid #000000;
                "
              ></div>
            </div>
            <div
              style="display: flex; padding: 0 0 15px 0; color: #030300"
              v-if="info.category != 3"
            >
              <div style="flex: 1"></div>
              <div style="flex: 1; display: flex">
                <div style="flex: 1">{{ $t("lang.pay.deliveryFee") }}</div>
                <div style="flex: 1; text-align: right">
                  {{ shipping.shippingTotal }}
                </div>
              </div>
            </div>
            <div style="display: flex; color: #030300; font-weight: bold">
              <div style="flex: 1"></div>
              <div style="flex: 1; display: flex">
                <div style="flex: 1">{{ $t("lang.pay.total") }}</div>
                <div style="flex: 1; text-align: right">
                  {{ shipping.orderTotal }}
                </div>
              </div>
            </div>
            <div style="color: #000000; margin-top: 40px; display: flex">
              <div style="flex: 1; text-align: left">
                <span @click="back()" style="cursor: pointer"
                  >&lt; {{ $t("lang.pay.back") }}</span
                >
              </div>
              <div style="flex: 1; text-align: center">
                <el-button
                  round
                  size="mini"
                  style="
                    width: 100%;
                    background-color: #000000;
                    color: #ffffff;
                    padding: 0;
                    font-size: 14px;
                    height: 25px;
                  "
                  @click="createOrder"
                  :showloading="showloading"
                >
                  {{ $t("lang.pay.confirmPayMent") }}</el-button
                >
              </div>
              <!--
              <div>
                  <el-dialog
                  :title="$t('lang.pay.note')"
                  :visible.sync="centerDialogVisible"
                  top="15%"
                  width="25%"
                  center>
                  <span v-html="$t('lang.pay.content6')"></span>
                  <span slot="footer" class="dialog-footer">
                   <el-button @click="centerDialogVisible = false">{{$t('lang.pay.cancel')}}</el-button>
                    <el-button type="primary" @click="goToProduct()">{{$t('lang.pay.confirm')}}</el-button>
                  </span>
                </el-dialog>
              </div>
              <div>
                  <el-dialog
                  :title="$t('lang.pay.note')"
                  :visible.sync="centerDialogVisible2"
                  top="15%"
                  width="25%"
                  center>
                  <span v-html="$t('lang.pay.content7')"></span>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="centerDialogVisible2 = false">{{$t('lang.pay.cancel')}}</el-button> 
                    <el-button type="primary" @click="goToPay()">{{$t('lang.pay.confirm')}}</el-button>
                  </span>
                </el-dialog>
              </div>
              -->
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="confirmOrder_footer">
        <AppFooter />
      </div>
      <div ref="submitPanel"></div>
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue";
import AppFooter from "@/components/common/AppFooter.vue";
import obj from "../../common/utils";
var id = 0;
if (window.ethereum) {
  ethereum.on("chainChanged", (_chainId) => {
    id = obj.web3.utils.hexToNumber(_chainId);
    localStorage.setItem("chainId", id + "");
    window.location.reload();
  });
} else {
  console.log("请安装metamask!");
}
export default {
  name: "ConfirmOrder",
  components: {
    AppNavigator,
    AppFooter,
  },
  data() {
    return {
      radio: "1",
      info: "",
      shipping: {},
      address: "",
      userName: "",
      showloading: false,
      //centerDialogVisible: false,
      //centerDialogVisible2: false,
      waresId: "",
      activitySubId: "",
      activityId: "",
    };
  },
  methods: {
    back() {
      if(this.info.category == 3) {
        this.$router.push({
          path:'/checkout'
        });
      }else {
        this.$router.push({
          path:'/shipping'
        });
      }
    },
    //返回商品详情页
    goToProduct() {
      //this.centerDialogVisible = false
      this.$router.push(
        `/product?waresId=${this.waresId}&activityId=${this.activityId}&activitySubId=${this.activitySubId}`
      );
    },
    //继续支付
    goToPay() {
      //this.centerDialogVisible2 = false
      this.showloading = false;
    },
    //点击显示转圈图标
    show() {
      //显示按钮上的loading
      this.showloading = true;
    },

    //支付接口
    async pay(orderId, price) {
      let _this = this;
      if (
        obj.chainId === localStorage.getItem("chainId") &&
        localStorage.getItem("from")
      ) {
        const txHash = await obj.pay(orderId, price);
        if (txHash.code === 4001) {
          //取消订单
          this.$http
            .post("order/cancel", { token: obj.getToken(), orderId })
            .then((res) => {
              if (res.code === 0) {
                _this.showloading = false;
              }
            });
        } else if (txHash.substring(0, 2) === "0x") {
          console.log("txHash: ", txHash);
          //metamask付款后拿到哈希跳转到orderComplete页面
          this.$http
            .post("order/pay/confirm", {
              orderId,
              txId: txHash,
              token: obj.getToken(),
            })
            .then((res) => {
              if (res.code === 0) {
                _this.$router.push({ path: "/orderComplete" });

                //hash传给node
                // _this.$http.post('notifyHash',{hash:txHash}).then(res=>{
                //       console.log(res)
                // })
              }
            });
        }
      } else {
        _this.utils.alert(
          _this.$t("lang.myMarkPlace.alertTitle"),
          _this.$t("lang.myMarkPlace.alertText3"),
          {
            showCancelButton: true,
            cancelButtonClass: "cancel-btn",
            cancelButtonText: _this.$t("lang.wallet.close"),
            autoClose: false,
          }
        );
        return;
      }
    },
    //创建订单
    createOrder() {
      let _this = this;
      _this.show();
      let param = {
        token: this.utils.getToken(),
        payChannel: this.info.payChannel,
        waresId: this.info.waresId,
        activitySubId: this.info.activitySubId,
        orderTotal: this.shipping.orderTotal,
      };
      if (this.info.category != 3) {
        param.deliveryInfo = {
          firstName: this.shipping.firstName,
          lastName: this.shipping.lastName,
          addressOne: this.shipping.addressOne,
          countryId: this.shipping.countryId,
          stateOrProvince: this.shipping.stateOrProvince,
          city: this.shipping.city,
          postalCode: this.shipping.postalCode,
          phone: "+"+this.shipping.state+" "+this.shipping.phone,
          email: this.shipping.email,
        };
      }
      //惊喜码
      let surpriseCode = sessionStorage.getItem('surpriseCode');
      if(surpriseCode) {
        param.surpriseCode = surpriseCode;
      }
      //调用下单接口
      this.$http.post("order/pay", param).then((res) => {
        if (res.code === 0) {
          if (this.info.payChannel == 1 || this.info.payChannel == 5) {
            // localStorage.setItem('orderId',res.data.orderId);
            window.location.href = res.data.payInfo;
          }
          if (this.info.payChannel == 3) {
            document.write(res.data.payInfo);
          }
          if (this.info.payChannel == 4) {
            //获取下单后的订单号
            let orderId = res.data.orderId;
            let price = res.data.ethPrice;
            //调支付接口,这里应该还要从后台获取一个错误码
            _this.pay(orderId, price);
          }
        } else if (res.code === -71002) {
          //已售罄,弹窗再跳转到详情页
          _this.show();
          //_this.centerDialogVisible = true
          obj
            .alert("", _this.$t("lang.pay.content6"), { duration: 3000 })
            .then(() => {
              _this.goToProduct();
            });
        } else if (res.code === -71001) {
          //可继续支付：留在当前支付页面
          //_this.centerDialogVisible2 = true
          obj.alert("", _this.$t("lang.pay.content7"), { duration: 3000 })
            .then(() => {
              _this.goToPay();
            });
        } else if (res.code === -71003) {
          obj.alert("", res.msg, { duration: 3000 }).then(() => {
            _this.goToProduct();
          });
        }
      });
    },
    getShipping() {
      let shipping = sessionStorage.getItem("shipping");
      let lang = localStorage.getItem("lang") || "en";
      let param = { token: this.utils.getToken(), waresId: this.info.waresId };
      if (shipping) {
        this.shipping = this.qs.parse(shipping);
        param.countryId = this.shipping.countryId;
        let $this = this;
        //根据语言拼接地址显示
        switch (lang) {
          case "en":
            if (this.shipping.postalCode) {
              $this.address = [
                $this.shipping.addressOne,
                $this.shipping.city,
                $this.shipping.stateOrProvince,
                $this.shipping.postalCode,
                $this.shipping.countryName,
              ].join(", ");
            } else {
              $this.address = [
                $this.shipping.addressOne,
                $this.shipping.city,
                $this.shipping.stateOrProvince,
                $this.shipping.countryName,
              ].join(", ");
            }
            $this.userName =
              $this.shipping.firstName + " " + $this.shipping.lastName;
            break;
          case "cn":
          case "tw":
            $this.userName = $this.shipping.lastName + $this.shipping.firstName;
            if ($this.shipping.postalCode) {
              $this.address = [
                $this.shipping.countryName,
                $this.shipping.stateOrProvince,
                $this.shipping.city,
                $this.shipping.postalCode,
                $this.shipping.addressOne,
              ].join(",");
            } else {
              $this.address = [
                $this.shipping.countryName,
                $this.shipping.stateOrProvince,
                $this.shipping.city,
                $this.shipping.addressOne,
              ].join(",");
            }
            break;
          default:
            break;
        }
      }
      //计算价格跟运费
      this.$http.post("order/countPrice", param).then((res) => {
        if (res.code === 0) {
          this.$set(this.shipping, "orderTotal", res.data.orderTotal);
          this.$set(this.shipping, "shippingTotal", res.data.shippingTotal);
          this.$set(this.shipping, "salePrice", res.data.salePrice);
          this.$set(this.shipping, "ethPrice", res.data.ethPrice);
        }
      });
    },
  },
  created() {
    let $this = this;
    let info = sessionStorage.getItem("productInfo");
    if (info) {
      $this.info = $this.qs.parse(info);
      this.waresId = $this.info.waresId;
      this.activitySubId = $this.info.activitySubId;
      this.activityId = $this.info.activityId;
      if ($this.info.payChannel == 4) {
        $this.radio = "1";
      } else {
        $this.radio = "2";
      }
      //加载shipping 相关数据
      this.getShipping();
    }
  },
};
</script>

<style lang="less">
.confirmOrder {
  width: 100%;
  height: 100%;
  font-size: 14px;
  box-sizing: border-box;
  video {
    width: 100%;
    display: block;
    object-fit: cover;
  }
  .confirmOrder_box {
    .confirmOrder_top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
      // backdrop-filter: saturate(180%) blur(30px);
    }

    .confirmOrder_main {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      margin-top: 40px;
      padding-bottom: 200px;
    }

    .confirmOrder_footer {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      bottom: 0px;
    }
  }
}
</style>