<template>
  <div class="orderNotComplete">
    <div class="orderNotComplete_box">
      <div class="orderNotComplete_top bg-white" style="border-bottom:1px solid #000000;">
        <AppNavigator/>
      </div>
      <div class="order_gap"> </div>
      <div class="orderNotComplete_main">
          <div class="orderNotComplete_content">
              <div style="margin-bottom:25px;color: #000000;font-size:21px;"> {{$t('lang.pay.notComplete')}}</div>
              <div style="margin-bottom:25px;color: #000000;" v-html="errorMsg"></div>
              <div>
                <el-button
                  @click="goToWeb"
                  round
                  size="medium"
                  style="
                    width: 160px;
                    height:25px;
                    background-color: #000000;
                    opacity: 1;
                    color: #ffffff;
                    padding: 0;
                  ">
                  {{$t('lang.pay.content3')}}</el-button>
              </div>
          </div>
      </div>
      <div class="orderNotComplete_footer">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AppNavigator from "@/components/common/AppNavigator.vue"
import AppFooter from "@/components/common/AppFooter.vue"
export default {
  name: "OrderComplete",
  components: {
    AppNavigator,
    AppFooter,
  },
  data(){
    return {
      errorMsg:''
    }
  },
  methods: {
    goToWeb(){
      this.$router.push('/marketPlace')
    }
  },
  //导航离开该组件的对应路由时调用
  beforeRouteLeave: (to, from, next) => {
       if(to.path === '/confirmOrder'){
         console.log(' beforeRouteLeave !')
         next(false)
       }else{
         next()
       }
  },
  beforeRouteEnter(to, from, next) {
      console.log(' beforeRouteEnter !')
      next()
  },
  created() {
    this.errorMsg = sessionStorage.getItem('errorMsg');
    // history.pushState(null, null, document.URL);
    // window.addEventListener('popstate', function () {
    //     history.pushState(null, null, document.URL);
    // });
  }
};
</script>

<style lang="less" scoped>
.orderNotComplete {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .orderNotComplete_box  {
    .orderNotComplete_top {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0px;
      z-index: 999;
    }
    .order_gap {
        height: 80px;
        width: 100%;
    }
    .orderNotComplete_main {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      margin-top: 40px;
      padding-bottom: 200px;
      .orderNotComplete_content {
          width: 70%;
          margin: 0 auto;
      }
    }
    .orderNotComplete_footer {
     width: 100%;
      box-sizing: border-box;
      position: fixed;
      bottom: 0px;
    }
  }
}
</style>
