<template>
  <div>
    <div class="order-list" v-show="!showInfo">
      <div
        style="
          border: none;
          border-top: 0.1em solid #000000;
          width: 100%;
          height: 1px;
        "
      ></div>
      <div
        v-show="orderList && orderList.length > 0"
        style="margin-bottom: 40px"
      >
        <div v-for="(item, index) in orderList" :key="index" class="order-unit">
          <div style="flex: 1">#{{ item.orderId }}</div>
          <div style="flex: 1; color: #bcbcbc">
            {{ item.createTime }}
          </div>
          <div
            style="width: 100px; color: #bcbcbc; cursor: pointer"
            @click="goToOrderInfo(item.orderId)"
          >
            {{ $t("lang.myprofile.orderHistory.viewOrder") }}
          </div>
        </div>
      </div>
      <div v-show="orderList.length == 0" class="no-data">
        {{ $t("lang.myprofile.noOrder") }}
      </div>
    </div>
    <div class="order-info" v-show="showInfo">
        <OrderInfo :orderId="orderId"></OrderInfo>
    </div>
  </div>
</template>

<script>
import OrderInfo from "@/components/user/OrderInfo.vue";
export default {
  name: "Orders",
  components: {
    OrderInfo,
  },
  data() {
    return {
      orderId:'',
      orderList: [],
      showInfo:false,
    };
  },
  methods: {
    goToOrderInfo(orderId) {
      this.orderId = orderId;
      this.showInfo = true;
    },
    back() {
      this.showInfo = false;
    }
  },
  created() {
    this.$parent.activeIndex = this.$route.meta.tab;
    //查询订单列表
    this.$http
      .post("order/list", { token: this.utils.getToken() })
      .then((res) => {
        if (res.code === 0) {
          let data = res.data;
          this.orderList = data;
        }
      });
  },
  mounted() {
    //初始化底部栏位 防止上下闪跳
    this.$parent.initFooter();
  }
};
</script>

<style lang="less">
.order-list .no-data {
  color: #bcbcbc;
  font-size: 14px;
  padding: 10px 0 30px 0;
}
.order-list .order-unit {
  display: flex;
  height: 40px;
  line-height: 40px;
  border-bottom: 1.5px solid #bcbcbc;
}
</style>