<template>
  <div id="app">
    <router-view />
    <div :class="['cookies-panel', showCookies]">
      <div style="display: flex">
        <div class="left">{{ $t("lang.other.cookiesText") }}</div>
        <div class="right">
          <span class="read-more" @click="readMore">{{
            $t("lang.other.readMore")
          }}</span>
          <span class="accept-all" @click="acceptCookies"
            >{{ $t("lang.other.acceptAll") }} &gt</span
          >
        </div>
      </div>
    </div>
    <el-dialog
      center
      width="420px"
      :show-close="false"
      custom-class="not_login_dialog"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
    >
      <div style="padding: 0 30px; color: #000000; font-size: 16px">
        <div style="text-align: center">{{ $t("lang.other.title") }}</div>
        <div style="margin-top: 10px; text-align: center; font-size: 14px">
          {{ $t("lang.other.content") }}
        </div>
        <div style="text-align: center; margin-top: 10px">
          <el-button class="goto_login_btn" @click="goToLogin()">
            {{ $t("lang.other.login") }}</el-button
          >
        </div>
        <div style="text-align: center; margin-top: 10px">
          <el-button class="goto_sign_btn" @click="goToSignup()">
            {{ $t("lang.other.register") }}</el-button
          >
        </div>
        <div style="text-align: center; margin-top: 5px">
          <el-button
            @click="hideDialog"
            round
            style="
              width: 200px;
              border: none;
              background-color: #ffffff;
              color: #bcbcbc;
              opacity: 1;
              padding: 10px !important;
            ">
            {{ $t("lang.other.continue") }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "App",
  data() {
    return {
      showCookies: "hide",
    };
  },
  methods: {
    hideDialog() {
      this.$store.state.notLoginDialog = false;
      let notList = [
        "/profile",
        "/myCabinet",
        "/cabinetDetail",
        "/editionDetail",
        "/orderInfo",
      ];
      let result = notList.filter(function (url) {
        return window.location.href.indexOf(url) > 0;
      });
      if (result.length > 0) {
        this.clearData();
        this.$router.push("/");
      }
    },
    goToLogin() {
      this.$store.state.notLoginDialog = false;
      this.clearData();
      this.$router.push("/login");
    },
    goToSignup() {
      this.$store.state.notLoginDialog = false;
      this.clearData();
      this.$router.push("/register");
    },
    readMore() {
      this.$router.push("/privacy");
    },
    acceptCookies() {
      localStorage.setItem("showCookies", "hide");
      this.showCookies = "hide";
    },
    clearData() {
      localStorage.removeItem("token");
      // localStorage.removeItem("lang");
      localStorage.removeItem("chainId");
      localStorage.removeItem("from");
      localStorage.removeItem("profilePhoto");
      localStorage.removeItem("credential");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("productInfo");
      sessionStorage.removeItem("shipping");
      sessionStorage.removeItem("surpriseCode");
      sessionStorage.removeItem("surpriseCode");
    }
  },
  computed: {
    ...mapState({
      showDialog(state) {
        return state.notLoginDialog;
      },
    }),
  },
  created() {
    let showCookies = localStorage.getItem("showCookies") || "show";
    this.showCookies = showCookies;
  },
  mounted() {},
};
</script>
<style lang="less">
//icon
.iconBox  {
  position: relative;
  .iconClass {
    position: absolute;
    width: 5.8%;
    top: 5.8%;
    left: 5.8%;
    img {
      width: 100%;
      background-color: transparent !important;
    }
  }
}
.video-full-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: #000000;
}
.video-full-screen video {
  width: auto !important;
  height: 100%;
  object-fit: cover;
  margin: auto;
}
.eth-txid {
  color: #3398DC;
}
.matic-txid {
  color: #8751E6;
}
.bg-black {
  background: rgba(3, 3, 3, 0.8);
  border-bottom: 1.5px solid #ffffff !important;
  color: #ffffff !important;
  .second-nav {
    background: rgba(3, 3, 3, 0.8);
    border-bottom: 1.5px solid #ffffff !important;
    color: #ffffff !important;
  }
  .el-button--text {
    color: #ffffff !important;
  }
  .scroll-panel {
    border-top: 1.5px solid #ffffff !important;
  }
  .logo_btn {
    background: url("./assets/Frontpage/ZHEN-WHITE.png") no-repeat center;
    background-size: 100%;
  }
}
.bg-white {
  background: rgba(255, 255, 255, 0.8);
  border-bottom: 1.5px solid #000000 !important;
  color: #000000 !important;
  .second-nav {
    background: rgba(255, 255, 255, 0.8);
    border-bottom: 1.5px solid #000000 !important;
    color: #000000 !important;
  }
  .el-button--text {
    color: #000000 !important;
  }
  .scroll-panel {
    border-top: 1.5px solid #000000 !important;
  }
  .logo_btn {
    background: url("./assets/Frontpage/ZHEN-Black.png") no-repeat center;
    background-size: 100%;
  }
}
.nft-loading-icon::before {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  background-image: url("./assets/Frontpage/Loading_slider_slower.gif");
  background-size: 100%;
  background-repeat: no-repeat;
  margin: auto;
}
.el-loading-spinner {
  position: fixed !important;
}
* {
  padding: 0;
  margin: 0;
  word-break: break-word;
}
.el-input input::placeholder {
  color: #bcbcbc !important;
  font-weight: normal !important;
}
// 加/减号 样式开始
.common-plus {
  width: 14px;
  height: 14px;
  cursor: pointer;
  background-image: url("./assets/Frontpage/PLUS.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.common-minus {
  width: 14px;
  height: 14px;
  cursor: pointer;
  background-image: url("./assets/Frontpage/MINUS.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.common-plus:hover,
.common-minus:hover {
  opacity: 0.6;
}
// 加/减号 样式结束
//提示信息 开始
.el-tooltip__popper {
  font-family: NeueHaasUnicaW1G !important;
  border: none !important;
  box-shadow: 0 2px 18px 0 rgba(3, 3, 3, 0.16) !important;
  color: #bcbcbc !important;
  width: 260px;
  border-radius: 13px !important;
  margin-left: 0 !important;
}
.el-icon-warning-outline {
  font-size: 12px !important;
  margin-left: 5px;
}
//提示信息 结束
.v-modal {
  background: #000000 !important;
  opacity: 0.5 !important;
}
.el-form-item__error {
  color: #e02020 !important;
}
.error_border input {
  border-bottom: 1px solid #e02020 !important;
}
//alert 开始
.nft-alert {
  width: 360px !important;
  font: 400 15px NeueHaasUnicaW1G !important;
  text-align: center !important;
  border-radius: 10px !important;
}
.nft-alert .el-message-box__headerbtn {
  opacity: 0 !important;
}
.nft-alert .el-message-box__title {
  font-size: 14px !important;
}
.nft-alert .el-message-box__message p {
  color: #000000 !important;
  line-height: 17px !important;
}
.nft-alert .el-message-box__header {
  padding-top: 20px !important;
}
.nft-alert .el-message-box__btns {
  text-align: center !important;
}
.nft-alert .ok-btn,
.nft-alert .cancel-btn {
  background-color: #fff !important;
  border: none !important;
  color: #bcbcbc !important;
  font-size: 14px !important;
}
//alert 结束
.el-button,
button {
  border: none !important;
}
.el-dialog {
  border-radius: 13px !important;
  box-shadow: 0 2px 18px 0 rgba(3, 3, 3, 0.16) !important;
}
.el-dialog .el-dialog__title {
  font-size: 14px !important;
  line-height: 17px;
}
.el-image-viewer__mask {
  background-color: #000000 !important;
  opacity: 0.9 !important;
}
.el-image-viewer__btn.el-image-viewer__actions {
  display: none !important;
}
.el-image-viewer__close,
.el-image-viewer__prev,
.el-image-viewer__next {
  display: none !important;
}
.el-image-viewer__canvas img {
  height: 100% !important;
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: #000000 !important;
  background: #fff !important;
}
.el-radio__inner::after {
  display: none;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #000000 !important;
}
.el-radio__input.is-checked .el-radio__inner::after {
  display: none;
}
.el-radio__input .el-radio__inner {
  width: 15px !important;
  height: 15px !important;
  border:none !important;
  box-shadow: none !important;
  border-radius: 50%;
  background-image:url("./assets/Frontpage/Unavailable.png") !important;
  background-repeat:no-repeat !important;
  background-size:100% 100% !important;
}
.el-radio__input.is-checked .el-radio__inner{
  background-image:url("./assets/Frontpage/Selected.png") !important;
  background-repeat:no-repeat !important;
  background-size:100% 100% !important;
}
.el-checkbox__inner {
  border-radius: 50% !important;
}
.el-checkbox__inner:hover,
.el-checkbox__input.is-focus .el-checkbox__inner{
  border-color: #000000 !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #000000 !important;
    border-color: #000000 !important;
}
#app {
  font-family: "NeueHaasUnicaW1G-Regular", "NeueHaasUnicaW1G" !important;
  font-style: normal;
  color: #ffffff !important;
  width: 100%;
  height: 100%;
  .cookies-panel {
    width: 100%;
    height: 100px;
    background-color: #f4f4f4;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 88;
    transition: height 1s;
    -moz-transition: height 1s;
    -webkit-transition: height 1s;
    -o-transition: height 1s;
    z-index: 999;
    box-shadow: 0 0 10px #bcbcbc;
    .left {
      flex: 1;
      margin: 10px 0 0 7%;
      height: 17px;
      line-height: 17px;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
    }
    .right {
      flex: 1;
      margin: 10px 7% 0 0;
      height: 17px;
      line-height: 17px;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      text-align: right;
      span {
        cursor: pointer;
      }
      span:hover {
        opacity: 0.8;
      }
      .read-more {
        margin-right: 30px;
        color: #bcbcbc;
      }
    }
  }
  .cookies-panel.hide {
    height: 0;
  }
  .cookies-panel.show {
    height: 100px;
  }
  button {
    font-family: NeueHaasUnicaW1G !important;
  }
  button[showloading="true"] {
    position: relative;
    pointer-events: none !important;
    cursor: not-allowed !important;
  }
  button[showloading="true"] span {
    opacity: 0;
  }
  button[showloading="false"] span {
    opacity: 1;
  }
  button[showloading="true"]::before {
    display: block;
    width: 15px;
    height: 15px;
    content: "";
    background: url("./assets/Frontpage/btn-loading.gif") no-repeat;
    background-size: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  button.el-button--primary {
    background-color: #000000;
    border-color: #000000;
  }
  button:hover {
    opacity: 0.8;
  }
  input:-internal-autofill-selected {
    background-color: #fff !important;
  }
}
.not_login_dialog {
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  .goto_login_btn {
    width: auto;
    height: 25px;
    line-height: 25px;
    padding: 0 60px;
    text-align: center;
    border-radius: 15px;
    border: none;
    color: #fff;
    background-color: #000000;
    margin-top: 10px;
    cursor: pointer;
  }
  .goto_sign_btn {
    width: auto;
    height: 25px;
    line-height: 25px;
    padding: 0 60px;
    text-align: center;
    border-radius: 15px;
    border: none;
    color: #000000;
    background-color: #f4f4f4;
    margin-top: 10px;
    cursor: pointer;
  }
  .goto_login_btn:focus,
  .goto_login_btn:hover,
  .goto_sign_btn:focus,
  .goto_sign_btn:hover {
    color: #ffffff !important;
    background-color: #000000 !important;
  }
  .el-dialog .el-dialog__body {
    flex: 1;
    overflow: auto;
  }
  .el-dialog__header {
    display: none;
  }
}

// #nav {
//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
